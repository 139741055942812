import React, {Component} from 'react'

class ApplyProducto extends Component {

    render(){
        
        return  (
            <React.Fragment>                
                <div className="col-md-2">
                    <div className="form-group">
                        <label className=" control-label">Opera Producto</label>
                            <select className="form-control" id="updapplyProducto" name="applyProduct">      
                                <option value={1} >Con Compras</option>                                        
                                <option value={2} >Sin Compras</option>                                        
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default ApplyProducto