import React, {Component} from 'react'
import $ from 'jquery';
import {sha256} from 'js-sha256';
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeAlertGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'
import MaterialTable from 'material-table';

document.body.classList.add('fix-sidebar');

class comerciales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            renderizar : true,
            /* controlEdit : false,
            controlDelete : false, */
        };
    }

    componentDidMount() { 
        global.licenciaStatus = 0
        const idCedi = sessionStorage.getItem("idCedi")
        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")

        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            var oldImg1 = document.getElementById("btnAlertConfirm").getAttribute("data-nimg1")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid,oldImg1)
            }
        }
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }
       
        function formDelete(id,oldImg1) {
            const inicioImg = oldImg1.lastIndexOf('/') + 1
            const finImg = oldImg1.length
            const cadenaExtraida = oldImg1.substring(inicioImg, finImg);
            if (id >= 1) {
                var datos = {
                    id: id,
                    oldImg1 : cadenaExtraida
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/comercialcedi/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data.id  == datos.id) {
                                alertaGeneral("Registro  Eliminado")
                                window.location.reload(false)
                            } else
                                alertaGeneral("Este dato esta asociado a otro concepto, por lo cual no se puede eliminar")        
                        } else 
                            closeSession()
                    },
                )
                .catch(erro => alertaGeneral("Problemas de conexión (36)"))
            } else {
                alertaGeneral("Validar la información ingresada")
            }
        }
       
        function isValidEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        var btnupdate = document.querySelector("#btnupdate");
        var emailupd = document.querySelector("#updemail");
        const resultadoupd = document.querySelector('.labelEmailupd')
       
        emailupd.addEventListener("keyup", function (event) {
            if (isValidEmail(this.value)) {
                resultadoupd.textContent = "";
                btnupdate.style.display = "block";
            } else {
                resultadoupd.textContent = "Email Incorrecto";
                btnupdate.style.display = "none";
            }
        })

        let datos = {idCedi: idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/comercialcedi",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                console.log("************ result.success : ", result.success)
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            },
        )    
        .catch(error => alertaGeneral("Problemas de conexión (37)"))
            
        async function updateForm() {
            let validLicencia = false
            const registerValid = sessionStorage.getItem("registerValid")   
            if ( registerValid == "true" ) {
                $("#updcedi").val(idCedi);
                let name = document.getElementById("updname").value
                let phone = document.querySelector("#formupdate #updphone ").value
                let email = document.getElementById("updemail").value
                let valEmail = isValidEmail(email)   
                document.getElementById("updpasswordInSite").value = sha256(document.getElementById("updpasswordInSite").value)
                let nroUserLicencias = 0
                let token = sessionStorage.getItem('token')
                await fetch(Const.urlrest + "api/comercialcedi/licencias", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "POST",
                    body: JSON.stringify({ idCedi: idCedi })
                }).then(response => response.json())
                    .then((result) => {
                        if (endSession(result.success))
                            nroUserLicencias = result.data.length
                        else 
                            closeSession()
                }).catch(error => alertaGeneral("Problemas de Conexión (38)"))        
                let nroLicencias = 0
                await fetch(Const.urlrest + "api/cedi/read", {
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "POST",
                    body: JSON.stringify({ id: idCedi })
                })
                .then(response => response.json())
                .then((result) => {
                    if (endSession(result.success))
                        nroLicencias = result.data.nroLicInSite
                    else 
                        closeSession()
                }).catch(error => alertaGeneral("Problemas de Conexión (39)"))

                let validLicencia = false
                if (global.licenciaStatus == 0)
                    if (!document.getElementById('updauxUsoLicInSite').checked)
                        validLicencia = true
                    else
                        if (nroLicencias >= nroUserLicencias + 1)
                            validLicencia = true
                        else
                            alertaGeneral("No le quedan licencias para uso de la App TAT")    
                else // this.state.licenciaStatus == 1
                    validLicencia = true

                if (valEmail) {
                    if (name.length >= 4 && phone.length >= 4) {
                        if (validLicencia) {
                            const data = new FormData(document.getElementById('formularioupdate'))
                            if (document.getElementById('updauxUsoLicInSite').checked) 
                                data.append('usoLicInSite', 1)
                            else
                                data.append('usoLicInSite', 0)

                            fetch(Const.urlrest + "api/comercialcedi/update", { // ok
                                    method: "PUT",
                                    headers: { 
                                        'Accept': 'application/json',
                                        /* 'Content-Type': 'application/json', */
                                        'Authorization': token,
                                    },
                                    body: data
                            })
                            .then(response => response.json())
                            .then(
                                (result) => {
                                    if (endSession(result.success)) {
                                        alertaGeneral("Registro Actualizado");
                                        window.location.reload(false);    
                                    } else 
                                        closeSession()
                                },
                            ).catch(error => alertaGeneral("Problemas de conexión (40)"))
                        } else
                            alertaGeneral("No quedan Licencias de App TAT para su uso")
                    } else {
                        alertaGeneral("Validar la información de nombre y nro celular ingresada")
                    }
                } else {
                    alertaGeneral("Por favor valida el email");
                }
            } else
                alertaGeneral("La imagen seleccionada no es valida, no se permite la operación de Actualización")
        }

        var la = document.getElementById('btnupdate');
        if (la) {
            la.addEventListener("click", updateForm);
        }
    }
    
    render(){

        let permisos = []

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('1')
        let boolEdit = accessUser.includes('2') 
        let boolDelete = accessUser.includes('3')
        let boolCargar = accessUser.includes('47')

        if (boolEdit) { 
            permisos.push({
                icon: 'edit',
                iconProps: {
                    style: {
                        color: "#00569b"
                    }
                },
                tooltip: 'Edit',
                onClick: (event, rowData) => formEdit(rowData.id)
            })
        }
        if (boolDelete) { 
            permisos.push({
                icon: 'delete',
                iconProps: {
                    style: {
                        color: "#ff5722"
                    }
                },
                tooltip: 'Delete User',
                onClick: (event, rowData) => alertaConfirm(rowData.id)
            })
        }

        function formEdit(id) {
            sessionStorage.setItem("registerValid", true)  
            window.scrollTo(0, 0);
            document.querySelector('.formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/comercialcedi/read",{ // ok
                headers: { // OK
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            })
            .then(response => response.json())
            .then(
                (result) => {         
                    if (endSession(result.success)) {
                        document.getElementById("updid").value = result.data.id
                        document.getElementById("updphone").value = result.data.phone
                        document.getElementById("updusoLicInSite").value = result.data.usoLicInSite
                        document.getElementById("updpasswordInSite").value = result.data.passwordInSite
                        document.getElementById("updemail").value = result.data.email
                        document.getElementById("imgshow").src = result.data.img
                        document.getElementById("updname").value = result.data.name  
                        global.licenciaStatus = result.data.usoLicInSite
                        $("#updidCedi").val(result.data.idCedi);                     
                        $("#updstatus").val(result.data.status);
    
                        let cadena = result.data.img
                        const inicioImg = cadena.lastIndexOf('/') + 1
                        const finImg = cadena.length
                        const cadenaExtraida = cadena.substring(inicioImg, finImg);
                        document.getElementById("updOldImg1").value = cadenaExtraida
                        $("#updstatus option[value=" + result.data.status + "] ").attr('selected', 'selected');
                        
                        if (document.getElementById('updusoLicInSite').value == 1)
                            $("#updauxUsoLicInSite").prop('checked', true);
                        else    
                            $("#updauxUsoLicInSite").prop('checked', false);    
                    } else 
                        closeSession()    
                },
            )     
            .catch(error => alertaGeneral("Problemas de conexión (41)"))      
        }

        const { items } = this.state;
        
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                <div className="page-wrapper">                    
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Comerciales</h3> 
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./comercial">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEdit || boolDelete 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./comerciallist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioCargar"><a href="./comercialcargar">Cargar</a></label>
                        :
                            null
                        }    
                    </div>
                    <UpdateData renderizar = {this.state.renderizar}/>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">                
                                        <div className="table-responsive m-t-40">                                            
                                            <MaterialTable
                                                data = { items }
                                                title="Datos"
                                                columns={[
                                                    { title: 'Id', field: 'id' },
                                                    { title: 'Estado', field: 'status' ,lookup: {1: 'Activo', 2: 'Inactivo'}},
                                                    { title: 'Nombre', field: 'name' },
                                                    { title: 'Lic TAT', field: 'usoLicInSite', lookup: {1: 'Activo', 0: 'Inactivo'}}
                                                ]}
                                                options={{
                                                   // exportButton: true,
                                                    headerStyle: {
                                                        backgroundColor: '#01579b',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: true
                                                }}
                                                actions={[
                                                    {
                                                    icon: 'edit',
                                                    hidden: !boolEdit,
                                                    iconProps: { style: { color: "#00569b" } },
                                                    tooltip: 'Editar',
                                                    onClick: (event, rowData) => formEdit(rowData.id)
                                                    },
                                                    rowData => ({
                                                        icon: 'delete',
                                                        hidden: !boolDelete,
                                                        iconProps: { style: { color: "#ff5722" } },
                                                        onClick: (event, rowData) => alertaConfirm(rowData.id,rowData.img)
                                                    })
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default comerciales;