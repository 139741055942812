import React, {Component} from 'react'
import InputCategory from '../atoms/InputCategory'
import InputStatus from '../atoms/InputStatus'
import InputFeatured from '../atoms/InputFeatured'
import {alertaGeneral} from '../../assets/js/GeneralScript'
import {AppContextConsumer} from '../../context/AppContext'
import $ from 'jquery'

class createData extends Component{    

    componentDidMount() {
        const input = document.querySelector('#updtypeOffert');
        input.addEventListener('change', updateValue);
        document.querySelector('.itemsumd').style.display = 'none'
        document.querySelector('.itemsumd_b2c').style.display = 'none'
        
        function updateValue(e) {
            if (e.target.value == "1") {
                document.querySelector('.itemsunidades').style.display = 'block'
                document.querySelector('.itemsumd').style.display = 'none'
                document.querySelector('.itemsunidades_b2c').style.display = 'block'
                document.querySelector('.itemsumd_b2c').style.display = 'none'
            } else {
                document.querySelector('.itemsunidades').style.display = 'none'
                document.querySelector('.itemsumd').style.display = 'block'
                document.querySelector('.itemsunidades_b2c').style.display = 'none'
                document.querySelector('.itemsumd_b2c').style.display = 'block'
            }
        }
    }

    render(){

        function xdeleteFile3() { 
            document.getElementById('img3show').src = "";
            document.getElementById("creimg3").value = "";
            sessionStorage.setItem('registerValidImg3','false')
        }

        function xdeleteFile2() { 
            document.getElementById('img2show').src = "";
            document.getElementById("creimg2").value = "";
            sessionStorage.setItem('registerValidImg2','false')
        }

        function actTipoOferta(e){
            if (e.target.value == 1) { // Unidades
                $("#updumd_b2c").val(0)
                $("#updpriceUmdTax_b2c").val(0)
                $("#updorderMinUmd_b2c").val(0)
                $("#updumd").val(0)
                $("#updpriceUmdTax").val(0)
                $("#updorderMinUmd").val(0)
            
            } else { // UMD
                $("#updpriceTax").val(0)
                $("#updorderMinTax").val(0)
                $("#updpriceTax_b2c").val(0)
                $("#updorderMinTax_b2c").val(0)
            }
        }

        function validOnChange(filex, typeLabel, typeStorage) {
            let uploadFile = filex[0]
            if (uploadFile != undefined) {
                let output = document.getElementById(typeLabel);
                output.src = URL.createObjectURL(filex[0]);
                output.onload = function() {
                    if ((output.naturalWidth.toFixed(0) <= 192) || (output.naturalHeight.toFixed(0) <= 192)) {
                        sessionStorage.setItem(typeStorage,"false")
                    } else {
                        alertaGeneral("La Imagen seleccionada no cumple con la medida mínima proporcional o menor de 192 x 192 pixels")
                        sessionStorage.setItem(typeStorage,"true")
                    }
                    URL.revokeObjectURL(output.src) // free memory
                }
            }    
        }

        return  (
            <AppContextConsumer>    
            { value  => {
                return (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-title">
                                            <h4>Crear </h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="horizontal-form">
                                                <div className="form-horizontal" id="formcreate" >
                                                    <form id="formulario">
                                                        <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updcedi" required="required"   />
                                                        <div className="row p-t-20">
                                                            <InputFeatured />
                                                            <InputStatus />
                                                        </div>  
                                                        <div className="row p-t-20">
                                                            <InputCategory />
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Nombre Producto </label>
                                                                    <input type="text" className="form-control" placeholder="" name="name" id="updname" required="required"  max="30" />                                                      
                                                                    <label className="msglabel">Mínimo 4 caracteres</label>
                                                                </div>
                                                            </div>
                                                        </div>                                             
                                                        <div className="row p-t-20">                                                       
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Descripción Producto </label>
                                                                    <textarea className="form-control" placeholder="" name="description" id="upddescription" max="255"></textarea>
                                                                    <label className="msglabel">Mínimo 4 caracteres</label>
                                                                </div>
                                                            </div>                                                       
                                                        </div>     
                                                        <div className="row p-t-20">                                                
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Nombre Tirilla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="nameTirilla" id="updnameTirilla" required="required"  max="30" />
                                                                    <label className="msglabel">Mínimo 3 caracteres</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="control-label">Link </label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="link" id="updlink" required="required"  max="120" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label"  >Tipo oferta</label>
                                                                    <select className="form-control" name="typeOffert" id="updtypeOffert" 
                                                                            onChange = {(e) => actTipoOferta(e)}
                                                                    >
                                                                        <option value="1">Unidades</option> 
                                                                        <option value="2">UMD</option>                                               
                                                                    </select>  
                                                                </div>                                                                                        
                                                            </div>
                                                        </div>


                                                        <div className="contBlock">    
                                                            <div className="row p-t-20">
                                                                <label className="col-md-6 control-label bold">Precios Producto Business to Business (B2B)</label>
                                                                <div className="separador"></div>
                                                                <div className="itemsumd">
                                                                    <div className="col-md-4 fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Precio Producto UMD</label>
                                                                            <input type="number" className="form-control" placeholder="" name="umd" id="updumd" required="required"  max="30" value="0"/> 
                                                                        </div>                                               
                                                                    </div>
                                                                    <div className="col-md-4 fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Precio Unitario UMD</label>   
                                                                            <input type="number" className="form-control" placeholder="" name="priceUmdTax" id="updpriceUmdTax" required="required"  max="30" value="0"/>
                                                                        </div>
                                                                    </div>                                                    
                                                                    <div className="col-md-4 fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Cantidad mínima UMD</label>
                                                                            <input type="number" className="form-control" placeholder="" name="orderMinUmd" id="updorderMinUmd" required="required"  max="30" value="0"/>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                                <div className="itemsunidades">
                                                                    <div className="col-md-4  fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Precio Und</label>
                                                                            <input type="number" className="form-control" placeholder="" name="priceTax" id="updpriceTax" required="required"  max="10" value="0"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5  fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Cantidad mínima Und </label>
                                                                            <input type="number" className="form-control" placeholder="" name="orderMinTax" id="updorderMinTax" required="required"  max="10" value="0"/>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                        </div>

                                                        <div className="contBlock">    
                                                            <div className="row p-t-20">
                                                                <label className="col-md-6 control-label bold">Precios Producto Business to Customer (B2C)</label>
                                                                <div className="separador"></div>
                                                                <div className="itemsumd_b2c">
                                                                    <div className="col-md-4 fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Precio Producto UMD</label>
                                                                            <input type="number" className="form-control" placeholder="" name="umd_b2c" id="updumd_b2c" required="required"  max="30" value="0"/> 
                                                                        </div>                                               
                                                                    </div>
                                                                    <div className="col-md-4 fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Precio Unitario UMD</label>   
                                                                            <input type="number" className="form-control" placeholder="" name="priceUmdTax_b2c" id="updpriceUmdTax_b2c" required="required"  max="30" value="0"/>
                                                                        </div>
                                                                    </div>                                                    
                                                                    <div className="col-md-4 fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Cantidad mínima UMD</label>
                                                                            <input type="number" className="form-control" placeholder="" name="orderMinUmd_b2c" id="updorderMinUmd_b2c" required="required"  max="30" value="0"/>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                                <div className="itemsunidades_b2c">
                                                                    <div className="col-md-4  fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Precio Und</label>
                                                                            <input type="number" className="form-control" placeholder="" name="priceTax_b2c" id="updpriceTax_b2c" required="required"  max="10" value="0"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5  fleft">
                                                                        <div className="form-group">
                                                                            <label className=" control-label">Cantidad mínima Und </label>
                                                                            <input type="number" className="form-control" placeholder="" name="orderMinTax_b2c" id="updorderMinTax_b2c" required="required"  max="10" value="0"/>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                        </div>

                                                        <div className="row p-t-20">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">SKU Interno</label>
                                                                    <input type="text" className="form-control" placeholder="" name="sku" id="updsku" required="required"  max="30" />
                                                                    <label className="msglabel">Mínimo 2 caracteres</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className = " control-label" > Código de Barras </label>
                                                                    <input type="text" className="form-control" placeholder="" name="barcode" id="updbarcode" required="required"  max="60" />
                                                                    <label className="msglabel">Mínimo 3 caracteres</label>   
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label"  >Mostrar en Vitrina</label>
                                                                    <select className="form-control" name="mostrarVitrina" id="updmostrarVitrina" >
                                                                        <option value="1">Todas</option>
                                                                        <option value="2">B2B</option>
                                                                        <option value="3">B2C</option>
                                                                        <option value="4">Ninguna</option>
                                                                    </select>  
                                                                </div>                                                                                        
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-group">
                                                                    <label className="control-label">Orden de Presentación a nivel de Categoria en Agregar y Buscar Producto </label>                                                        
                                                                    <input type="text" style={{marginLeft:5, width:30}} placeholder="99" name="order" id="updorder" required="required"  max="2" />
                                                                </div>
                                                            </div>

                                                        </div>   
                                                        <div className="row p-t-20">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Imagen 1 &nbsp;</label>
                                                                    <img id="img1show" alt="" height="50" />
                                                                    <input type="file" style = {{color: "transparent", border: "none"}} className="form-control" placeholder="" name="image1" id="creimg1" required="required" accept="image/png" onChange ={(e) => validOnChange(e.target.files,'img1show','registerValidImg1')} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">   
                                                                    <label className=" control-label">Imagen 2 &nbsp;</label>
                                                                    <img id="img2show" alt="" height="50" />  
                                                                    <input type="file" style = {{color: "transparent", border: "none"}} className="form-control" placeholder="" name="image2" id="creimg2" required="required" accept="image/png" onChange ={(e) => validOnChange(e.target.files,'img2show','registerValidImg2')} />
                                                                    <button onClick = {xdeleteFile2} id='btonImg1' >Borrar</button>
                                                                </div>
                                                            </div> 
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Imagen &nbsp;3 </label>
                                                                    <img id="img3show" alt="" height="50" />     
                                                                    <input type="file" style = {{color: "transparent", border: "none"}} className="form-control" placeholder="" name="image3" id="creimg3" required="required" accept="image/png" onChange ={(e) => validOnChange(e.target.files,'img3show','registerValidImg3')} />
                                                                    <button onClick = {xdeleteFile3} id='btonImg1' >Borrar</button>
                                                                </div>
                                                            </div>                                                           
                                                        </div>  
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-offset-2 col-sm-10">
                            <button type="button" className="btn btn-default btnadd">Ingresar</button>
                        </div>               
                    </React.Fragment>  
                )
            }}  
            </AppContextConsumer>
        )            
    }
}        

export default createData