import React, {Component}  from 'react';
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import LogoCedi from '../atoms/LogoCedi'
import Slogan from '../atoms/Slogan'

document.body.classList.add('fix-sidebar');

class Dashboard extends Component {

    render() {
        return (
            <React.Fragment>
                <Headerdashboard/> 
                <Sidebar />
                <div  className="page-wrapper">
                    <div  className="row page-titles">
                        <div  className="col-md-8 align-self-center">
                            <h3  className="text-primary">Escritorio</h3>
                        </div>
                        <div  className="col-md-4 align-self-center">
                            <ol  className="breadcrumb">
                                <li  className="breadcrumb-item"> <a href="./dashboard" > Home </a></li > 
                                <li  className="breadcrumb-item active">Escritorio</li>
                            </ol>
                        </div>
                    </div>
                    <div>
                        <br/>
                        <br/>
                        <LogoCedi/>
                        <br/>
                        <Slogan/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
