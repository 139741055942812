import React, {Component} from 'react'

class InputApp extends Component {

    render(){

        return  (
            <React.Fragment>                
                <div className="col-md-3">
                    <div className="form-group">
                        <label className=" control-label">Pedidos desde</label>
                            <select className="form-control" id="updapp" name="app">      
                                <option value="0" >Todas</option> 
                                <option value="1" >Marca Blanca</option>
                                <option value="2" >Asociativa</option>
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default InputApp