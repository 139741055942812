import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class inputMarketplaceAso extends Component {
    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }
    componentDidMount(){
        let datos = {
            sectors : this.props.sectors
        }
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/marketplace/sectors",{ // ok
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            method: "POST",
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then((result) => {
            if (endSession(result.success))
                this.setState({ items: result.data, })
            else 
                closeSession()
        }).catch(error => alertaGeneral("Problemas de Conexión (14)"))
    }

    render(){

        const { items } = this.state;

        return  (
            <React.Fragment>                
                <div className="col-md-2.5">
                    <div className="form-group">
                        <label className=" control-label">Marketplace Asociativo</label>
                            <select className="form-control" id="updidMarketplaceASo" name="idMarketplaceASo">  
                            <option value="0">Seleccionar</option>    
                            {items.map(item => (
                                <option value={item.id} >{item.name}</option>
                            ))}                                          
                            </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputMarketplaceAso