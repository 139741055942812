import React, {Component} from 'react'
import '../../assets/css/Login.css';

class Logologin extends Component{

    render(){
        
        const logo = sessionStorage.getItem("img")  
        return  (
            <React.Fragment>
                <img src={ logo }  style={{ height:55, paddingLeft:5, paddingTop:1, }} alt="Admin" className = "logologin block" />
            </React.Fragment>          
        )
    }
}

export default Logologin
