import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import CreateData from './createData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class detaillistpricescustom extends Component {

    componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi")

        $(document).on('click', '.btnadd', function () {
            let idCategory = $("#updidCategory").val()
            let idProduct = $("#updProduct").val();
            let priceTax = $("#crepriceTax").val();
            let orderMinCant = $("#creorderMinCant").val();
            let umdTax = $("#creumdTax").val();
            let orderMinUmd = $("#creorderMinUmd").val();
            let valUnidUmd = $("#crevalUnidUmd ").val();
            let idListPrices = $("#updidListPrices").val();

            if (priceTax.length >= 1) {
                var datos = {
                    idProduct: idProduct,
                    idCedi: idCedi,
                    priceTax: priceTax,
                    orderMinCant: orderMinCant,
                    orderMinUmd: orderMinUmd,
                    umdTax: umdTax,
                    valUnidUmd: valUnidUmd,
                    idListPrices: idListPrices,
                    // *******************
                    idCategory: idCategory,
                    // *******************
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/detaillistpricescustom/add", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                        method: "POST",
                        body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro Ingresado")
                            window.location.reload()    
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (72)"))
            } else {
                alertaGeneral("Validar la información ingresada")
            }
        })    
    }

    render(){  

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrearLista = accessUser.includes('19')
        let boolEditLista = accessUser.includes('20') 
        let boolDeleteLista = accessUser.includes('21')
        let boolCrearDetalle = accessUser.includes('22')
        let boolEditDetalle = accessUser.includes('23') 
        let boolDeleteDetalle = accessUser.includes('24')
        let boolCargarPrices = accessUser.includes('50')
        let boolCargarDetails = accessUser.includes('51')

        
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar /> 
                <AlertGeneral /> 
                    <div className="page-wrapper">
                        <div className="row page-titles">
                            <div className="col-md-2 align-self-center">
                                <h3 className="text-primary">Precios - Listas</h3> 
                            </div>
                            { 
                            boolCrearLista
                        ? 
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustom"><a href="./pricescustom">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEditLista || boolDeleteLista
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomList"><a href="./pricescustomList">Datos</a></label>
                        :
                            null
                        }  
                        {
                            boolCargarPrices
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomCargar"><a href="./pricescustomcargar">Datos</a></label>
                        :
                            null
                        }    
                        { 
                            boolCrearDetalle
                        ? 
                            <>
                                <div className="col-md-3 align-self-center">
                                    <h3 style = {{marginLeft:60}} className="text-primary">Precios - Detalles</h3> 
                                </div>
                                <label style = {{ fontSize:22}} id="idPricesCustomDetail">
                                    <a 
                                        href="./pricescustomdetail"> Crear
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolEditDetalle || boolDeleteDetalle
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomDetailList"><a href="./pricescustomdetaillist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargarDetails
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomDetailCargar"><a href="./pricescustomdetailcargar">Cargar</a></label>
                        :
                            null
                        } 
                        </div>
                    <CreateData />  
                </div>
            </div>
        )
    }
}

export default detaillistpricescustom;