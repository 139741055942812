import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class inputCategory extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidMount(){

        const idCedi = sessionStorage.getItem("idCedi")
        let datos =  { idCedi: idCedi }
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/category",{ // ok
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos),
        }).then(response => response.json())
            .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            },
        ).catch(error => alertaGeneral("Problemas de Conexión (6)"))
    }

    render(){

        const { items } = this.state;
        const doesNotApply = this.props.doesNotApply

        return  (
            <React.Fragment>                
                <div className="col-md-4">
                    <div className="form-group">
                        <label className=" control-label">Categoría</label>
                        <select className="form-control" id="updidCategory" name="idCategory" >   
                            { 
                                (doesNotApply == 'true')
                            ? 
                                <option value={0} key={-1}>Todos</option>
                            : 
                                null
                            }   
                            {items.map(item => (
                                <option disabled = { (item.status == 1) ? false : true } 
                                        value={item.id} key={item.id} >{item.name}
                                </option>
                            ))}                                          
                        </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputCategory