import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class InputPaymentMethods extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidMount = async()=>{
        let token = sessionStorage.getItem('token')
        await fetch(Const.urlrest + "api/paymentmethods",{
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        }).then(response => response.json())
            .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }).catch(error => alertaGeneral("Problemas de Conexión (15)"))
    }
    
    render(){

        const { items } = this.state;
        const doesNotApply = this.props.doesNotApply
        
        return  (
            <React.Fragment>                
                <div className="col-md-2.5">
                    <div className="form-group">
                        <label className=" control-label">Métodos de pago</label>
                            <select className="form-control" id="updpaymentmethods" name="paymentmethods">      
                            { 
                                (doesNotApply == 'true')
                            ?
                                <option value={0} key={0} >Todos</option>
                            :
                                null
                            }        
                            {items.map(item => (
                                <option value={item.id} key={item.id}>{item.name}</option>
                            ))}                                          
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default InputPaymentMethods