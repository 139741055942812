import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class inputTypeBusiness extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidMount = async()=>{

        const idCedi = sessionStorage.getItem("idCedi")
        let datos =  { idCedi: idCedi }
        let token = sessionStorage.getItem('token')
        await fetch(Const.urlrest + "api/typeBusiness",{ // ok
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos),
        }).then(response => response.json())
            .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
        }).catch(error => alertaGeneral("Problemas de Conexión (24)"))
    }

    render(){
 
        const { items } = this.state;
        const auxName = this.props.auxName
        const auxId = this.props.auxId
        const mostrar = this.props.mostrar

        return  (
            <React.Fragment>                
                <div className="col-md-4">
                    <div className="form-group">
                        <label className=" control-label">Tipo de Cliente</label>
                        <select className="form-control" id={auxId} name= {auxName} disabled ={this.props.disabled} onChange={(e) => this.props.parentCallback(e.target.value)} >   
                            {items.map(item => (
                                <>
                                    {
                                        (mostrar == "1" && item.name.trim() != "** Sin Definir **")
                                    ?
                                        <option disabled = { (item.status == 1) ? false : true } value={item.id} key={item.id} >{item.name}</option>
                                    :
                                        <>
                                            {
                                                ((mostrar == "2") && (item.name.trim() == '** Sin Definir **'))
                                            ?    
                                                <option disabled = { (item.status == 1) ? false : true } value={item.id} key={item.id} >{item.name}</option>
                                            :
                                                <>
                                                    {
                                                        ((mostrar == "0") && (item.name.trim() != "Todos") && item.name.trim() != "** Sin Definir **")
                                                    ?
                                                        <option disabled = { (item.status == 1) ? false : true } value={item.id} key={item.id} >{item.name}</option>
                                                    :
                                                        <>
                                                            {
                                                                (mostrar == "3") && (item.name.trim() != "Todos")
                                                            ?
                                                                <option disabled = { (item.status == 1) ? false : true } value={item.id} key={item.id} >{item.name}</option>
                                                            :
                                                                <option disabled = { (item.status == 1) ? false : true } value={item.id} key={item.id} >{item.name}</option> 
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </> 
                            ))}
                        </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputTypeBusiness



