import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'
import {AppContextProvider} from '../../context/AppContext'

document.body.classList.add('fix-sidebar');

let idCedi = 0

class ProductsCedi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            renderizar : true,
            idCedi: 0,
            idCategory: 0,
        };
    }      

    componentDidMount() { 

        idCedi = sessionStorage.getItem("idCedi")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")

        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }

        let datos = {idCedi: idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/productscedi",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            },
        ).catch(error => alertaGeneral("Problemas de conexión (78)"))  


        $(document).off().on('click', '.btnupdate', function () {

            if ($("#creimg1").src !== "") { 
                $("#updcedi").val(idCedi);
                let name = $("#updname").val();
                let valueBoolean = false 
                switch ($("#updtypeOffert").val()) { // Unidades
                    case '1': { // Unidades
                        if ($("#updpriceTax").val() != 0 &&
                            $("#updorderMinTax").val() != 0)
                            valueBoolean = true
                        break
                    }    
                    case '2': { // UMD
                        if ($("#updumd").val() != 0 &&
                            $("#updpriceUmdTax").val() != 0 && 
                            $("#updorderMinUmd").val() != 0)
                            valueBoolean = true
                        break
                    }    
                }   
                if (/* valueBoolean && */
                    name.length >= 4 
                    ) {
                        let data = new FormData(document.getElementById('formularioupdate'))
                        let estado = ""
                        let i1 = sessionStorage.getItem("actionUpdateImage1")
                        if (i1 == "true")
                            estado = estado + '1'
                        let i2 = sessionStorage.getItem("actionUpdateImage2")
                        if (i2 == "true")
                            estado = estado + '2'
                        let i3 = sessionStorage.getItem("actionUpdateImage3")
                        if (i3 == "true")
                            estado = estado + '3'
                        let i4 = sessionStorage.getItem("actionDeleteImage2")
                        if (i4 == "true")
                            estado = estado + '4'
                        let i5 = sessionStorage.getItem("actionDeleteImage3")
                        if (i5 == "true")
                            estado = estado + '5'
                        data.append("estado", estado)
                        let token = sessionStorage.getItem('token')
                        fetch(Const.urlrest + "api/productscedi/update", { // ok
                            headers: { 
                                /* 'Accept': 'application/json',
                                'Content-Type': 'application/json', */
                                'Authorization': token,
                            },
                            method: "PUT",
                            body: data
                        })
                        .then(response => response.json())
                        .then(
                            (result) => {
                                if (endSession(result.success)) {
                                    alertaGeneral("Registro  Actualizado");
                                    window.location.reload(false);    
                                } else 
                                    closeSession()
                            },
                        )
                        .catch(error => alertaGeneral("Problemas de conexión (79)"))    
                } else 
                    alertaGeneral("Validar la información ingresada");
            } else
                alertaGeneral("La Imagen 1 es obligatoria")    
        })
    }

    formEdit = (id,name,barcode,link,orderMinUmd,description,image3,image2,image1,typeOffert,idCedi,
                  nameTirilla,orderMinTax,priceTax,featured,priceUmdTax,sku,umd,idCategory,status,umd_b2c,orderMinTax_b2c,
                  priceTax_b2c,priceUmdTax_b2c,orderMinUmd_b2c,mostrarVitrina,order) => {
        sessionStorage.setItem("idCedi",idCedi) 

        sessionStorage.setItem("actionUpdateImage1",'false')
        sessionStorage.setItem("actionUpdateImage2",'false')
        sessionStorage.setItem("actionUpdateImage3",'false')
        sessionStorage.setItem("actionDeleteImage2",'false')
        sessionStorage.setItem("actionDeleteImage3",'false')

        window.scrollTo(0, 0);
        document.querySelector('.formupdate').style.display = 'block'
        $("#updid").val(id)
        $("#updname").val(name)
        $("#updbarcode").val(barcode);
        $("#updlink").val(link);
        $("#updorder").val(order);
        $("#updnameTirilla").val(nameTirilla);      

        // ********** B2B
        $("#updumd").val(umd);
        $("#updorderMinUmd").val(orderMinUmd);
        $("#updpriceUmdTax").val(priceUmdTax);
        $("#updpriceTax").val(priceTax);
        $("#updorderMinTax").val(orderMinTax);

        // ********* B2C

        $("#updumd_b2c").val(umd_b2c);
        $("#updorderMinUmd_b2c").val(orderMinUmd_b2c);
        $("#updpriceUmdTax_b2c").val(priceUmdTax_b2c);
        $("#updpriceTax_b2c").val(priceTax_b2c);
        $("#updorderMinTax_b2c").val(orderMinTax_b2c);



        $("#updfeatured").val(featured);
        $("#updsku").val(sku);
        $("#updidCategory").val(idCategory);
        $("#updidCategory").val(idCategory)
        $("#updstatus").val(status);
        $("#updtypeOffert").val(typeOffert);
        $("#updmostrarVitrina").val(mostrarVitrina);
        $("#updInitImage1").val(image1.substring(image1.lastIndexOf("/")+1,image1.length));
        
        if (image2 != null)
            $("#updInitImage2").val(image2.substring(image2.lastIndexOf("/")+1,image2.length));
        if (image3 != null)
            $("#updInitImage3").val(image3.substring(image3.lastIndexOf("/")+1,image3.length));
        if (typeOffert == '1') {
            document.querySelector('.itemsunidades').style.display = 'block'
            document.querySelector('.itemsumd').style.display = 'none'
            document.querySelector('.itemsunidades_b2c').style.display = 'block'
            document.querySelector('.itemsumd_b2c').style.display = 'none'

        } else {
            document.querySelector('.itemsunidades').style.display = 'none'
            document.querySelector('.itemsumd').style.display = 'block'
            document.querySelector('.itemsunidades_b2c').style.display = 'none'
            document.querySelector('.itemsumd_b2c').style.display = 'block'

        }

        $("#upddescription").val(description);
        $("#updidCategory option[value=" + idCategory + "] ").attr('selected', 'selected');
        $("#updtypeOffert option[value=" + typeOffert + "] ").attr('selected', 'selected');
        $("#updmostrarVitrina option[value=" + mostrarVitrina + "] ").attr('selected', 'selected');
        $("#updfeatured option[value=" + featured + "] ").attr('selected', 'selected');
        $("#updstatus option[value=" + status + "] ").attr('selected', 'selected');

        document.getElementById("img1show").src = image1
        if (image2 != null)
            document.getElementById("img2show").src = image2
        else 
            document.getElementById("img2show").src = ""
        if (image3 != null)
            document.getElementById("img3show").src = image3
        else
            document.getElementById("img3show").src = ""    

    }

    
    render(){

        var permisos = []

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('4')
        let boolEdit = accessUser.includes('5') 
        let boolDelete = accessUser.includes('6')
        let boolCargar = accessUser.includes('48')

        if (boolEdit) { 
            permisos.push({
                icon: 'edit',
                iconProps: {
                    style: {
                        color: "#00569b"
                    }
                },
                tooltip: 'Edit',
                onClick: (event, rowData) => this.formEdit(rowData.id)
            })
        }
        if (boolDelete) { 
            permisos.push({
                icon: 'delete',
                iconProps: {
                    style: {
                        color: "#ff5722"
                    }
                },
                tooltip: 'Delete User',
                onClick: (event, rowData) => alertaConfirm(rowData.id)
            })
        }

        const { items } = this.state;

        return (          
            <AppContextProvider value={ this.state }>    
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Productos</h3> 
                        </div>
                        { 
                            boolCrear 
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./productscreate">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEdit || boolDelete 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./productslist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargar
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioCargar"><a href="./productscargar">Cargar</a></label>
                        :
                            null
                        }    
                    </div>
                    <UpdateData renderizar = {this.state.renderizar} />   
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive m-t-40">                                            
                                            <MaterialTable
                                                title="Datos"
                                                columns={[
                                                    { title: 'Id', field: 'id', width:10},
                                                    { title: 'Status', field: 'status', lookup:{1:'Activo', 2:'Inactivo'}, width:40},
                                                    { title: 'Destacado', field: 'featured', lookup: {1:'Si', 0:'No'}, width:40},
                                                    { title: 'Nombre', field: 'name' },
                                                    { title: 'Oferta', field: 'typeOffert', lookup:{1:'Und', 2:'UMD'}, width:30},
                                                ]}
                                                data = {
                                                    items
                                                }
                                                options={{
                                                   // exportButton: true,
                                                    headerStyle: {
                                                        backgroundColor: '#01579b',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: true
                                                }}
                                                actions={[
                                                    {
                                                    icon: 'edit',
                                                    hidden: !boolEdit,
                                                    iconProps: { style: { color: "#00569b" } },
                                                    tooltip: 'Save User',
                                                    onClick: (event, rowData) => this.formEdit(rowData.id,rowData.name,rowData.barcode,rowData.link,rowData.orderMinUmd,
                                                                                          rowData.description,rowData.image3,rowData.image2,rowData.image1,rowData.typeOffert,
                                                                                          rowData.idCedi,rowData.nameTirilla,rowData.orderMinTax,rowData.priceTax,
                                                                                          rowData.featured,rowData.priceUmdTax,rowData.sku,rowData.umd,rowData.idCategory,
                                                                                          rowData.status,
                                                                                          rowData.umd_b2c,rowData.orderMinTax_b2c,rowData.priceTax_b2c,rowData.priceUmdTax_b2c,
                                                                                          rowData.orderMinUmd_b2c,rowData.mostrarVitrina, rowData.order
                                                                                         )
                                                    },
                                                    rowData => ({
                                                        icon: 'delete',
                                                        hidden: !boolDelete,
                                                        iconProps: { style: { color: "#ff5722" } },
                                                        tooltip: 'Delete User',
                                                        onClick: (event, rowData) => alertaConfirm(rowData.id,rowData.image1,rowData.image2,rowData.image3)
                                                    })
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>              
                </div>
            </AppContextProvider>
        )
    }
}

function formConfirmDelete() {
    var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
    var oldImg1 = document.getElementById("btnAlertConfirm").getAttribute("data-nimg1")
    var oldImg2 = document.getElementById("btnAlertConfirm").getAttribute("data-nimg2")
    var oldImg3 = document.getElementById("btnAlertConfirm").getAttribute("data-nimg3")
    if (nid >= 1) {
        document.querySelector('#mggAlertConfirm').style.display = 'none'
        formDelete(nid,oldImg1,oldImg2,oldImg3)
    }
}

function formDelete(id,oldImg1,oldImg2,oldImg3) {
    let inicioImg = oldImg1.lastIndexOf('/') + 1
    let finImg = oldImg1.length
    const cadenaExtraida1 = oldImg1.substring(inicioImg, finImg);

    inicioImg = oldImg2.lastIndexOf('/') + 1
    finImg = oldImg2.length
    const cadenaExtraida2 = oldImg2.substring(inicioImg, finImg);

    inicioImg = oldImg3.lastIndexOf('/') + 1
    finImg = oldImg3.length
    const cadenaExtraida3 = oldImg3.substring(inicioImg, finImg);

    if (id >= 1) {
        var datos = {
            id: id,
            oldImg1 : cadenaExtraida1,
            oldImg2 : cadenaExtraida2,
            oldImg3 : cadenaExtraida3,
        }
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/productscedi/destroy", { // ok
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            method: "DELETE",
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success)) {
                    if (result.data.id  == datos.id) {
                        alertaGeneral("Registro  Eliminado");
                        window.location.reload();
                    } else
                        alertaGeneral("Este dato esta asociado a otro concepto, por lo cual no se puede eliminar")        
                } else 
                    closeSession()
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (80)"))
    } else {
        alertaGeneral("Validar la información ingresada");
    }
}


export default ProductsCedi;