import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import CreateData from './createData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'
document.body.classList.add('fix-sidebar');

class avertisingcedi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi") // Cambio

        var el = document.getElementById('mggAlert');
        if (el) {
           el.addEventListener("click", closeAlertGeneral);
        }

        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
           month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        var minDate = year + '-' + month + '-' + day;
        var creschedule = document.getElementById('creschedule');
        creschedule.setAttribute("min", minDate);
 
        function valImagen(img) {
            var sizeByte = img.files[0].size;
            if (img.files[0].type == "image/jpeg" || img.files[0].type == "image/png") {
                if (sizeByte >= 1000000) {
                    alertaGeneral("La imagen supera los 1Mg permitidos")
                    img.value = ""
                }
            } else {
                alertaGeneral("Solo se permite imagen jpg y png")
                img.value = ""
            }
        }         

        const selectElement = document.querySelector('#creimg');
        selectElement.addEventListener('change', (event) => {
           valImagen(selectElement)
        });
 
        $(document).on('click', '.btnadd', function () {
            const registerValid = sessionStorage.getItem("registerValid")   
            if (registerValid == "true") {
                $('#updidcedi').val(idCedi)
                let schedule = $("#creimg").val()
                if (schedule.length >= 4 ) {
                    const data = new FormData(document.getElementById('formulario'));
                    let token = sessionStorage.getItem('token')
                    fetch(Const.urlrest + "api/advertisingcedi/add", {  // ok
                        headers: { 
                            'Authorization': token,
                        },                
                        method: "POST",
                        body: data
                    })
                    .then(response => response.json())
                    .then(
                        (result) => {
                            if (endSession(result.success)) {
                                alertaGeneral("Registro Ingresado");
                                window.location.reload();     
                            } else 
                                closeSession()
                        }
                    ).catch(error => alertaGeneral("Problemas de Conexión (1)"))
                } else {
                    alertaGeneral("Validar la información ingresada");
                }
            } else
                alertaGeneral("La imagen seleccionada no es valida, no se permite la operación de Crear")            
        })
    }

    render(){       

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('13')
        let boolOperar = accessUser.includes('14') || accessUser.includes('15')

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar /> 
                <AlertGeneral /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Imágenes Publicitarias</h3> 
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./advertising">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolOperar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./advertisinglist">Datos</a></label>
                        :
                            null
                        }    
                        <div className="col-md-7 align-self-center">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                <li className="breadcrumb-item active">Listar </li>
                            </ol>
                        </div>
                    </div>                        
                    <CreateData  />
                </div>
            </div>
        )
    }
}

export default avertisingcedi;