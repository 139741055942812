import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class inputProducto extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidMount(){

        const idCedi = sessionStorage.getItem("idCedi")
        let datos = {idCedi : idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/productscedi",{
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            method: 'POST',
            body: JSON.stringify(datos)
        }).then(response => response.json())
            .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }).catch(error => alertaGeneral("Problemas de Conexión (16)"))
    }

    render(){

        const { items } = this.state;
        const doesNotApply = this.props.doesNotApply

        return  (
            <React.Fragment>                
                <div className="col-md-8">
                    <div className="form-group">
                        <label className=" control-label">Producto </label>
                        <select className="form-control" id="updProduct" name="Product"  >      
                            { 
                                (doesNotApply == 'true')
                            ? 
                                <option value={0} key={0} >Todos</option>
                            : 
                                null
                            }  
                            {items.map(item => (
                                // <option value={item.id} key={item.id} >{item.name} </option> 
                                <option disabled = { (item.status==1) ? false : true } 
                                        value={item.id} key={item.id} >{item.name}
                                </option>
                            ))}        
                        </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputProducto