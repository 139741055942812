import React, {Component} from 'react'
import Logologin from '../atoms/Logologin'
import $ from 'jquery';
import '../../assets/css/Login.css';
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('homelogin');

class Forgot extends Component {
    
    componentDidMount() {
        $("#btngeneral").hide()       
        var el = document.getElementById('mggAlert');
        if (el) 
            el.addEventListener("click", closeAlertGeneral);

        function validarEmail(valor) {
            if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) {
              return 1
            } else {
                alertaGeneral("La dirección de email es incorrecta!.");
                return 0
            }
        }

        function user_Cedis() {
            let token = sessionStorage.getItem('token')
            let email = document.getElementById("username").value
            let emailvalido = validarEmail(email)
            if (emailvalido == "1" ) {
                var datos = { email: email }
                fetch(Const.urlrest + "api/usercedis/emailcedis", {
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },    
                    method: "POST",
                    body: JSON.stringify(datos)
                  }) 
                .then(response => response.json())
                .then(
                   (result) => {
                        if (endSession(result.success)) {
                            if (result.data.length !== 0) { 
                                var misCedis = result.data
                                var option ="";
                                if (result.searchwhere) 
                                    misCedis.forEach( function(valor, indice, array) {
                                      option +="<option value='"+valor['idCedi']+"' data_img='"+valor['b2bCedi.img']+"' nameCedi='"+valor['b2bCedi.name']+"' searchwhere='"+'V'+"'    >"+valor['b2bCedi.name']+"</option>";                          
                                    })                 
                                else
                                    misCedis.forEach( function(valor, indice, array) {
                                      if (valor['status'] == 1)
                                          option +="<option value='"+valor['id']+"' data_img='"+valor['img']+"' nameCedi='"+valor['name']+"' searchwhere='"+'F'+"'    >"+valor['name']+"</option>";                       
                                    })                
                                document.getElementById("cedilist").innerHTML = option;
                                $(".conselectorcedi").show()
                                $("#btngeneralEmail").hide()       
                                $("#btngeneral").show()    
                            } else {
                              alertaGeneral("Por favor revisa tu email no se encuentra registrado")
                            }      
                        } else 
                            closeSession()
                    }
                  ).catch(error => alertaGeneral("Problemas de Conexión (61)"))
            } else {
                  alertaGeneral("Verifica el email")
            }
          }
      

        function userLogin() {
            let idCedi = $("#cedilist").val();
            let nameCedi = $("#cedilist option:selected").attr("nameCedi");
            let email = document.getElementById("username").value
            let emailvalido = validarEmail(email)
            
            if (emailvalido == "1" ) {
                var datos = {
                    email: email,
                    idCedi: idCedi,
                    nameCedi: nameCedi,
                }
                fetch(Const.urlrest + "api/useradmin/resetcedis", { // ok
                    headers: Const.myHeaders,
                    method: "POST",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (result.data[0] != 0) {
                            alertaGeneral("Su contraseña fue enviada al correo electrónico")
                            window.location.href = "./";
                        } else 
                            alertaGeneral("Por favor revisa los datos (este mecanismo no esta habilitado para el administrador)")    
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (62)"))
            } else 
                alertaGeneral("Verifica que la cuenta de correo este bien escrita")
            return false
        }
        var la = document.getElementById('btngeneral');
        if (la) 
            la.addEventListener("click", userLogin);
        
        var lu = document.getElementById('btngeneralEmail');
        if (lu) 
            lu.addEventListener("click", user_Cedis);
        return false
        
    }


        
    render() {

        return (
            <div><AlertGeneral /> 
            <div className="makeStyles-session-2 makeStyles-background-3">
                <div className="makeStyles-content-4">
                    <div className="makeStyles-wrapper-5">
                        <div className="MuiPaper-root MuiCard-root MuiPaper-elevation1 MuiPaper-rounded">
                            <div className="MuiCardContent-root">                                            
                                <div className="text-xs-center pb-xs">
                                    <Logologin />
                                    <span style={{color:'white'}}
                                        className="MuiTypography-root MuiTypography-caption">Ingrese sus datos para continuar
                                    </span>
                                </div>
                                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                                    
                                    <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                                        <input type="text" id="username" className="MuiInputBase-input MuiInput-input"  name="username" placeholder="email" />
                                    </div>
                                </div>
                                
                                <div className="conselectorcedi MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">                                       
                                    <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">                                           
                                        <select name="cedilist" id="cedilist"  className="MuiInputBase-input MuiInput-input" >
                                            <option value="">Cedi</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained btngeneral MuiButton-containedPrimary MuiButton-fullWidth"
                                    type="text" id="btngeneralEmail" >
                                        <span className="MuiButton-label">Recuperar inicio</span>
                                        <span className="MuiTouchRipple-root"></span>
                                </button>
                                <button className="MuiButtonBase-root MuiButton-root MuiButton-contained btngeneral MuiButton-containedPrimary MuiButton-fullWidth"
                                    type="submit" id="btngeneral">
                                    <span className="MuiButton-label">Recuperar final</span>
                                    <span className="MuiTouchRipple-root"></span>
                                </button>
                                < div class = "pt-1 text-md-center" > < a href = "./" >
                                    <button className="MuiButtonBase-root MuiButton-root MuiButton-text btnrecover" tabindex="0" type="button">
                                        <span className="MuiButton-label">Volver</span>
                                        <span className="MuiTouchRipple-root"></span>
                                    </button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )}
}


export default Forgot;