import React, {Component} from 'react'
import InputProductoDetailPrice from '../atoms/InputProductoDetailPrice'
import Inputlistprices from '../atoms/InputListPrices'

class updateData extends Component {

    componentDidMount() {
        document.querySelector('.itemsumd').style.display = 'none'
        document.querySelector('.itemsund').style.display = 'none'
    }

    render(){
        return  (
            <React.Fragment>                   
                <div className="container-fluid formupdate" id="formupdate">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h4>Actualizar Detalle Lista de Precios</h4>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <input type="hidden" className="form-control" placeholder="" name="idCategory" id="updidCategory" required="required" />
                                        <input type="hidden" className="form-control" placeholder="" name="idCaso" id="updCaso" required="required" />
                                        <div className="form-horizontal" id="formcreate" >
                                            <input type="hidden" className="form-control" placeholder="" name="id" id="updid" required="required"   />    
                                            <input type="hidden" className="form-control" placeholder="" name="idcedi" id="updidcedi" required="required"   />    
                                            <div className="row p-t-20">
                                                <InputProductoDetailPrice doesNotApply = 'true'/>
                                                <Inputlistprices doesNotApply = 'false' mostrar = '1' auxName = "nidListPrices" auxId = "updidListPrices" disabled={false}/>
                                            </div>
                                            <div className="contBlock itemsund">
                                                <div className="row p-t-20">
                                                    <label className=" col-md-12 control-label bold">Tipo de Oferta "Und"</label>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className=" control-label">Precio "Und" con impuestos</label>
                                                            <input type="number" className="form-control" placeholder="" name="name" id="updpriceTax" required="required"  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        < div className = "form-group" > 
                                                               <label className=" control-label">Orden mínima "Und"</label>
                                                                <input type="number" className="form-control" placeholder="" name="name" id="updorderMinCant" required="required"  />
                                                        </div>                                                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contBlock itemsumd">
                                                <div className="row p-t-20 itemsumd">
                                                    <label className=" col-md-12 control-label bold">Tipo de Oferta "UMD"</label>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Valor Unidad UMD</label>
                                                            <input type="number" className="form-control" placeholder="" name="updlink" id="updumdTax" required="required"  max="30" />
                                                        </div>                                               
                                                    </div>                                                
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Orden mínima "UMD"</label>
                                                            <input type="text" className="form-control" placeholder="" name="updlink" id="updorderMinUmd" required="required"  max="30" />
                                                        </div>                                               
                                                    </div>
                                                     <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Precio Unitario "UMD" </label>
                                                            <input type="text" className="form-control" placeholder="" name="priceUmdTax" id="updvalUnidUmd" required="required"  max="30" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>    
                                        <div className="row p-t-20">
                                        </div>  
                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <button type="button" className="btn btn-default btnupdate">Actualizar Detalle Listas de Precios</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default updateData