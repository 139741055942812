import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class inputProductoPromotions extends Component {

    constructor(props){
        super(props);
        this.state = {
            items: [],
        }
    }

    loadListB2B  = async()=>{
        let datos = {
            idCedi : this.props.idCedi, 
            typeOffert : this.props.typeOffert
        }

        let token = sessionStorage.getItem('token')
        await fetch(Const.urlrest + "api/productscedi/listb2btypeoffert",{ // ok
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        }).then(response => response.json())
            .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
        }).catch(error => alertaGeneral("Problemas de Conexión (18)"))
    }

    loadListB2C() {
      let datos = {
          idCedi : this.props.idCedi, 
          typeOffert : this.props.typeOffert
      }
      let token = sessionStorage.getItem('token')
      fetch(Const.urlrest + "api/productscedi/listb2ctypeoffert",{ // ok
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
      }).then(response => response.json())
          .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
      }).catch(error => alertaGeneral("Problemas de Conexión (19)"))
    }

    loadListEspecialConLPB2B() {
        this.loadListB2B()
    }

    loadListEspecialSinLPB2B() {
      let datos = {
          idCedi : this.props.idCedi, 
          typeOffert : this.props.typeOffert,
          onlyListPrices : this.props.onlyListPrices,
          idListPrices: this.props.idListPrices,
      }
      let token = sessionStorage.getItem('token')
      fetch(Const.urlrest + "api/productscedi/listb2bonlylistpricestypeoffert",{ // ok
          method: 'POST',
          headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token,
        },
          body: JSON.stringify(datos)
      }).then(response => response.json())
          .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
      }).catch(error => alertaGeneral("Problemas de Conexión (20)"))
    }

    componentDidMount(){
        let typeClient      = this.props.typeClient         
        let onlyListPrices  = this.props.onlyListPrices
        let nameListPrices  = this.props.nameListPrices

        if (typeClient == 1) { // B2B
            if (nameListPrices.toUpperCase().replace(/ /g, "") != 'LISTADEPRECIOSB2B') { // Lista creada por el usuario */
                if (onlyListPrices == 0) { // Mostrar solo los productos de la lista creada por el usuario
                    this.loadListEspecialSinLPB2B()
                } else { // Mostrar la Lista de Precios B2B, con los productos de la lista creada con sus precios
                    this.loadListEspecialConLPB2B()
                }
            } else { // B2B Lista Base
                this.loadListB2B()
            }
        } else { 
            this.loadListB2C()
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if ((prevProps.typeOffert != this.props.typeOffert) || 
            (prevProps.onlyListPrices != this.props.onlyListPrices) ||
            (prevProps.idListPrices != this.props.idListPrices) ||
            (prevProps.typeClient != this.props.typeClient) ||
            (prevProps.onlyListPrices != this.props.onlyListPrices))
        {
            let typeClient      = this.props.typeClient         
            let onlyListPrices  = this.props.onlyListPrices
            let nameListPrices  = this.props.nameListPrices
    
            if (typeClient == 1) { // B2B
                if (nameListPrices.toUpperCase().replace(/ /g, "") != 'LISTADEPRECIOSB2B') { // Lista creada por el usuario */
                    if (onlyListPrices == 0) { // Mostrar solo los productos de la lista creada por el usuario
                        this.loadListEspecialSinLPB2B()
                    } else { // Mostrar la Lista de Precios B2B, con los productos de la lista creada con sus precios
                        this.loadListEspecialConLPB2B()
                    }
                } else { 
                    this.loadListB2B() // Lista de Precios B2B 
                }
            } else { 
                this.loadListB2C() // Lista de Precios B2C
            }
        }
      }    

      /* selectProduct() {
        let products = this.props.products
        $("#updProduct option[value="+ 161 +"]").attr("selected",true);
      } */


    render(){

        let caso = this.props.products[this.props.index]['Producto']

        return  (
            <React.Fragment>    
                <div className = "col-md-8">
                    <div className = "form-group">
                        <label className = "control-label">Producto</label>
                        <select className = "form-control" id = "updProduct" name = "Product" 
                                onChange = {(e) => this.props.handleProducts(e,this.props.index)}
                        >   
                            <option disabled = { false } value={-1} >
                                Seleccione un producto
                            </option>       
                            {this.state.items && this.state.items.map((item) => (  
                                <>
                                  { 
                                      (caso == item.id)
                                  ?
                                      <option disabled = { (item.status==1) ? false : true } 
                                              value={item.id} key={item.id} selected = "true"
                                      >
                                          {item.name}
                                      </option>
                                  :
                                      <option disabled = { (item.status==1) ? false : true } 
                                              value={item.id} key={item.id} 
                                      >
                                          {item.name}
                                      </option>

                                  }
                                </>
                            ))}                                                                
                        </select>    
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputProductoPromotions