import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import CreateData from './createData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class TypeClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi")
        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }

        $(document).on('click', '.btnadd', function () {
            let name = $("#crename").val();
            let status = $("#updstatus").val();
            let nota = $("#crenotas").val();
            if (name.length >= 4 ) {
                var datos = {
                    name: name,
                    idCedi: idCedi,
                    nota: nota,
                    status: status,
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/typeBusiness/add", {
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "POST",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro Ingresado");
                            window.location.reload();    
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (91)"))
            } else {
                alertaGeneral("Por favor revisa, el nombre es obligatorio");
            }
        });
   }

    render(){

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('10')
        let boolOperar = accessUser.includes('11') || accessUser.includes('12')

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Tipo de Cliente</h3> 
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./typeBusiness">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolOperar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./typeBusinesslist">Datos</a></label>
                        :
                            null
                        }    
                    </div>
                    <CreateData />  
                </div>
            </div>
        )
    }
}

export default TypeClient;