import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class inputCedis extends Component {
    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }
    componentDidMount(){
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/cedi",{  // ok
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        }).then(response => response.json())
            .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
        }).catch(error => alertaGeneral("Problemas de Conexión (7)"))
    }
    render(){
        const { items } = this.state;
        return  (
            <React.Fragment>                
                <div className="col-md-4">
                    <div className="form-group">
                        <label className=" control-label">Cedi </label>
                            <select className="form-control" id="updidCedi">      
                            {items.map(item => (
                                <option value={item.id} >{item.name}</option>
                            ))}                                          
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputCedis