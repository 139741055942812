import React, {Component} from 'react'
import InputTypeDocument from '../atoms/InputTypeDocument'
import InputStatus from '../atoms/InputStatus'
import InputPaymentMethods from '../atoms/InputPaymentMethods'
import InputRoutes from '../atoms/InputRoutes'
import InputListPrices from '../atoms/InputListPrices'
import InputComercial from '../atoms/InputComercial'
import InputTypeBusiness from '../atoms/InputTypeBusiness'
import TypeFreight from '../atoms/InputTaxes'
import $ from 'jquery'

class createData extends Component{    

  constructor(props){
    super(props);
    this.state = {
        activar : true
    }
  }

  componentDidMount() {

    let el = document.getElementById('updtypeClient');
    if (el) {
        el.addEventListener("change", typeClientPro);
    }
    const typeDisperse_b2b = sessionStorage.getItem("typeDisperse_b2b")
    const typeDisperse_b2c = sessionStorage.getItem("typeDisperse_b2c")
    if ($("#updtypeClient").val() == 1) { // B2B
        switch (typeDisperse_b2b) {
            case "10": {
                document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                document.querySelector('._typeDisperse_percentage').style.display = 'block'
                break
            }
            case "20": {
                document.querySelector('._typeDisperse_ctoTAT').style.display = 'block'
                document.querySelector('._typeDisperse_percentage').style.display = 'none'
                break
            }    
            case "30": {
                document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                document.querySelector('._typeDisperse_percentage').style.display = 'none'
                break
            }    
        }
    } else { // B2C
        switch (typeDisperse_b2c) {
            case "1000": {
                document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                document.querySelector('._typeDisperse_percentage').style.display = 'block'
                break
            } 
            default: {
                document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                document.querySelector('._typeDisperse_percentage').style.display = 'none'
                break
            }
        }
    }    

    function typeClientPro() {
      const typeDisperse_b2b = sessionStorage.getItem("typeDisperse_b2b");
      const typeDisperse_b2c = sessionStorage.getItem("typeDisperse_b2c");
      if ($("#updtypeClient").val() == 1) { // B2B
          switch (typeDisperse_b2b) {
              case "10": {
                  document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                  document.querySelector('._typeDisperse_percentage').style.display = 'block'
                  break
              }
              case "20": {
                  document.querySelector('._typeDisperse_ctoTAT').style.display = 'block'
                  document.querySelector('._typeDisperse_percentage').style.display = 'none'
                  break
              }    
              case "30": {
                  document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                  document.querySelector('._typeDisperse_percentage').style.display = 'none'
                  break
              }    
          }
      } else { // B2C
          switch (typeDisperse_b2c) {
              case "1000": {
                  document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                  document.querySelector('._typeDisperse_percentage').style.display = 'block'
                  break
              } 
              default: {
                  document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                  document.querySelector('._typeDisperse_percentage').style.display = 'none'
                  break
              }
          }
      }    
  }

  }

  vacio = (vacio) => {return true}
  
    render(){

        return (
          <React.Fragment>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-title">
                      <h4>Crear Clientes </h4>
                    </div>
                    <div className="card-body">
                      <div className="horizontal-form">
                        <div className="form-horizontal" id="formcreate">
                          <form id="formulario">
                            <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updcedis" required="required" />
                            <input type="hidden" className="form-control" placeholder="" name="idBlockUser" id="updblockuser" required="required" value="0"/>
                            <div className="row p-t-20">
                              <InputStatus />
                              <InputTypeBusiness auxName = "idTypeBusiness" auxId = "updidTypeBusiness" mostrar="3"  disabled={false} parentCallback = {this.vacio}/>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label className=" control-label">Tipo de Negocio </label>
                                  <select className="form-control" id="updtypeClient" name="typeClient">
                                    <option value="1">B2B</option>
                                    <option value="2">B2C</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Nombre Cliente{" "}</label>
                                  <input type="text" className="form-control" placeholder="" name="name" id="updname" required="required" max="90" />
                                  <label className="msglabel"> Mínimo 4 caracteres </label>
                                </div>
                              </div>
                            </div>
                            <div className="row p-t-20">
                              <InputTypeDocument />
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label className=" control-label">
                                    Documento{" "}
                                  </label>
                                  <input type="number" className="form-control" placeholder="" name="numberDocument" id="updnumberDocument" required="required" max="10" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label"> Nombre a facturar </label>
                                  <input type="text" className="form-control" placeholder="" name="name" id="updnameBilling" required="required" max="90" />
                                  <label className="msglabel"> Mínimo 4 caracteres </label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">
                                    Dirección
                                  </label>
                                  <input type="text" className="form-control" placeholder="" name="address" id="updaddress" required="required" max="90" />
                                </div>
                              </div>

                            </div>
                            <div className="contBlock">
                              <div className="row p-t-20">
                                <label className="col-md-6 control-label bold">
                                  Ubicación Georeferenciada Cliente
                                </label>
                                <div className="separador"></div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className=" control-label"> Latitud </label>
                                    <input type="text" min="0" step="0.01" className="form-control control-coo" name="latitud" placeholder="Latitud" id="updlat" required="required" max="90" />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className=" control-label">Longitud</label>
                                    <input type="text" min="0" step="0.01" className="form-control control-coo" name="longitud" placeholder="Longitud" id="updlng" required="required" max="90" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="contBlock">
                              <div className="row p-t-20">
                                <label className="col-md-6 control-label bold">Calendario de visita comercial</label>
                                <div className="separador"></div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className=" control-label">Lunes</label>
                                    <select
                                      className="form-control"
                                      id="updvisitLunes"
                                      name="visitLunes"
                                    >
                                      <option value="1">Todos</option>
                                      <option value="2">No programado</option>
                                      <option value="3">1er y 3do del mes</option>
                                      <option value="4">2er y 4to del mes</option>
                                      <option value="5">1ro del Mes</option>
                                      <option value="6">2ro del Mes</option>
                                      <option value="7">3ro del Mes</option>
                                      <option value="8">4ro del Mes</option>
                                      <option value="9">5ro del Mes</option>
                                      <option value="10">6to del Mes si no hay 5to</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className=" control-label">Martes</label>
                                    <select
                                      className="form-control"
                                      id="updvisitMartes"
                                      name="visitMartes"
                                    >
                                      <option value="1">Todos</option>
                                      <option value="2">No programado</option>
                                      <option value="3">1er y 2do del mes</option>
                                      <option value="4">3er y 4to del mes</option>
                                      <option value="5">1ro del Mes</option>
                                      <option value="6">2ro del Mes</option>
                                      <option value="7">3ro del Mes</option>
                                      <option value="8">4ro del Mes</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className=" control-label">Miércoles</label>
                                    <select
                                      className="form-control"
                                      id="updvisitMiercoles"
                                      name="visitMiercoles"
                                    >
                                      <option value="1">Todos</option>
                                      <option value="2">No programado</option>
                                      <option value="3">1er y 2do del mes</option>
                                      <option value="4">3er y 4to del mes</option>
                                      <option value="5">1ro del Mes</option>
                                      <option value="6">2ro del Mes</option>
                                      <option value="7">3ro del Mes</option>
                                      <option value="8">4ro del Mes</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className=" control-label">Jueves</label>
                                    <select
                                      className="form-control"
                                      id="updvisitJueves"
                                      name="visitJueves"
                                    >
                                      <option value="1">Todos</option>
                                      <option value="2">No programado</option>
                                      <option value="3">1er y 2do del mes</option>
                                      <option value="4">3er y 4to del mes</option>
                                      <option value="5">1ro del Mes</option>
                                      <option value="6">2ro del Mes</option>
                                      <option value="7">3ro del Mes</option>
                                      <option value="8">4ro del Mes</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className=" control-label">Viernes</label>
                                    <select
                                      className="form-control"
                                      id="updvisitViernes"
                                      name="visitViernes"
                                    >
                                      <option value="1">Todos</option>
                                      <option value="2">No programado</option>
                                      <option value="3">1er y 2do del mes</option>
                                      <option value="4">3er y 4to del mes</option>
                                      <option value="5">1ro del Mes</option>
                                      <option value="6">2ro del Mes</option>
                                      <option value="7">3ro del Mes</option>
                                      <option value="8">4ro del Mes</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className=" control-label">Sábado</label>
                                    <select
                                      className="form-control"
                                      id="updvisitSabado"
                                      name="visitSabado"
                                    >
                                      <option value="1">Todos</option>
                                      <option value="2">No programado</option>
                                      <option value="3">1er y 2do del mes</option>
                                      <option value="4">3er y 4to del mes</option>
                                      <option value="5">1ro del Mes</option>
                                      <option value="6">2ro del Mes</option>
                                      <option value="7">3ro del Mes</option>
                                      <option value="8">4ro del Mes</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className=" control-label">Domingo</label>
                                    <select
                                      className="form-control"
                                      id="updvisitDomingo"
                                      name="visitDomingo"
                                    >
                                      <option value="1">Todos</option>
                                      <option value="2">No programado</option>
                                      <option value="3">1er y 2do del mes</option>
                                      <option value="4">3er y 4to del mes</option>
                                      <option value="5">1ro del Mes</option>
                                      <option value="6">2ro del Mes</option>
                                      <option value="7">3ro del Mes</option>
                                      <option value="8">4ro del Mes</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row p-t-20">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Nombre contacto{" "} </label>
                                  <input type="text" className="form-control" placeholder="" name="nameContac" id="updnameContac" required="required" max="90" />
                                  <label className="msglabel">Mínimo 4 caracteres </label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Email usuario{" "}</label>
                                  <input type="text" className="form-control" placeholder="" name="emailUser" id="updemailUser" required="required" max="90" />
                                </div>
                              </div>
                              <InputPaymentMethods doesNotApply = 'false'/>
                            </div>
                            <div className="row p-t-20">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Teléfono</label>
                                  <input type="number" className="form-control" placeholder="" name="phone" id="updphone" required="required" max="90" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Código Interno</label>
                                  <input type="text" className="form-control" placeholder="" name="internalCode" id="updinternalCode" required="required" max="15" />
                                </div>
                              </div>
                              
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Email para entrega de Facturación Electrónica</label>
                                  <input type="text" className="form-control" placeholder="" name="emailEnvoice" id="updemailEnvoice" required="required" max="90" />
                                </div>
                              </div>
                              
                              <InputComercial doesNotApply = 'false' auxName="idCommercial" auxId = "updidCommercial"/>
                              <InputRoutes doesNotApply = 'false' auxName = "idRoute" auxId="updidRoute"/>
                            </div>
                            <div className="row p-t-20 itemsumd">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="control-label">Pedido Minimo Total Cliente Incl IVA($)</label>
                                  <input type="number" className="form-control" placeholder="" name="orderMinAllTax" id="updorderMinAllTax" required="required" max="90" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Unidad de Despacho</label>
                                  <select name="officeUnit" className="form-control" id="updofficeUnit"
                                  >
                                    <option value="0">Todas</option>
                                    <option value="1">Unidades</option>
                                    <option value="2">UMD</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Pedidos desde</label>
                                  <select className="form-control" id="updattentionXpersonsAsocAll" name="attentionXpersonsAsocAll"
                                  >
                                    <option value="0">Todas</option>
                                    <option value="1">Marca Propia</option>
                                    <option value="2">Asociativa</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row p-t-20">
                              <TypeFreight auxName = "typeTaxes" auxId = "updtypeFreight" />
                              <div className="col-md-3 porfleteVar">
                                <div className="form-group">
                                  <label className=" control-label">Porcentaje Flete Variable</label>
                                  <input type="text" className="form-control" placeholder="" name="percentageFreightVar" id="updpercentageFreightVar" required="required" max="90" />
                                </div>
                              </div>
                              <div className="col-md-2 varFijoflete">
                                <div className="form-group">
                                  <label className=" control-label">Valor Fijo Flete ($)</label>
                                  <input type="text" className="form-control" placeholder="" name="pricesFreigh" id="updpricesFreigh" required="required" max="60" />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className=" control-label">Valor Ticket No Cobrar Flete($)</label>
                                  <input type="text" className="form-control" placeholder="" name="ticketNoFreigh" id="updticketNoFreigh" required="required" max="60" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className=" control-label">Tipo de Despacho</label>
                                  <select name="typeShipping" className="form-control" id="updtypeShipping"
                                  >
                                    <option value="1">Domicilio</option>
                                    <option value="2">Recoge</option>
                                  </select>
                                </div>

                            </div>
                            <div className="row p-t-20">
                              </div>
                              <InputListPrices doesNotApply = 'false' mostrar='2' auxName = "nidListPrices" auxId = "updidListPrices" disabled={false}/>
                              

                              <div className="col-md-4 flagOnlyListPrice">
                                <div className="form-group">
                                  <label className=" control-label">Mostrar solo los productos de esta lista</label>
                                  <select name="onlyListPrices" className="form-control" id="updonlyListPrices" >
                                    <option value="0">Si</option>
                                    <option value="1">No</option>
                                  </select>
                                </div>
                              </div>

                            
                            </div>
                            <div className="row p-t-20">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className=" control-label">Comunicado</label>
                                  <textarea className="form-control" name="release" id="updrelease" max="250"></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 _typeDisperse_ctoTAT">
                              <div className="form-group">
                                <label className=" control-label">Dispersión Cto Venta TAT($)</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0"
                                  name="paramAlgoritDisperseCtoTat"
                                  id="updparamAlgoritDisperseCtoTat"
                                  required="required"
                                  max="10"
                                  value="0"
                                />
                              </div>
                            </div>
                            <div className="col-md-3 _typeDisperse_percentage">
                              <div className="form-group">
                                <label className=" control-label">Porcentaje de Dispersión</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0"
                                  name="paramAlgoritDispersePercentage"
                                  id="updparamAlgoritDispersePercentage"
                                  required="required"
                                  max="10"
                                  value="0"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-offset-2 col-sm-10">
              <button
                type="button"
                className="btn btn-default btnadd"
                id="btnadd"
              >
                Ingresar Cliente
              </button>
            </div>
          </React.Fragment>
        );
    }
}

export default createData



