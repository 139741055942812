import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeAlertGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class routescedi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    componentDidMount() {          
        const idCedi = sessionStorage.getItem("idCedi")
        var el = document.getElementById('mggAlert')
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")

        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid)
            }
        }
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }
        function formDelete(id) {
            if (id >= 1) {
                var datos = {
                    id: id
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/routescedi/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data.id == datos.id) {
                                alertaGeneral("Registro  Eliminado");
                                window.location.reload(false);
                            } else
                                alertaGeneral("Este dato esta asociado a otro concepto, por lo cual no se puede eliminar")               
                        } else 
                            closeSession()
                    },
                ).catch(error => alertaGeneral("Problemas de Conexión (87)"))
            } else {
                alertaGeneral("Validar la información ingresada");
            }
        }
        let datos = {idCedi : idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/routescedi",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (88)"))

        $(document).off().on('click', '.btnupdate', function () {
            let id = $("#updid").val();
            let name = $("#updname").val();
            let idCedi = $("#formupdate #updidCedi").val();
            let textPr = $("#updtextPr").val();
            let attentionDaysRoutes = []
            $(".rutadias .updcheckdias:checked").each(function () { // diasrutaedit
                attentionDaysRoutes.push({
                    "dia": this.value
                })
            });
            if (name.length >= 4 && textPr.length >= 4 && attentionDaysRoutes.length >= 1) {
                var datos = {
                    id: id,
                    name: name,
                    idCedi: idCedi,
                    attentionDaysRoutes: JSON.stringify(attentionDaysRoutes),
                    textPr: textPr
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/routescedi/update", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "PUT",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado")
                            window.location.reload(false)    
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (89)"))
            } else {
                alertaGeneral("Por favor revisa, todos los campos son obligatorios");
            }
        })   
   }

   render(){
      
        function formEdit(id) {
            window.scrollTo(0, 0);
            let token = sessionStorage.getItem('token')
            document.querySelector('.formupdate').style.display = 'block'
            fetch(Const.urlrest + "api/routescedi/read",{ // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({ id: id })
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        $("#updid").val(result.data.id)
                        $("#updidCedi").val(result.data.idCedi)
                        $("#updname").val(result.data.name)
                        $("#updtextPr").val(result.data.textPr)
                        let attentionDaysRoutes = result.data.attentionDaysRoutes                    
                        var checkboxes = document.querySelectorAll('.rutadias .updcheckdias')
                        for (var i = 0; i < checkboxes.length; i++) {
                            checkboxes[i].checked = false;
                            for (var j = 0; j < attentionDaysRoutes.length; j++) {
                                if (attentionDaysRoutes[j].dia == checkboxes[i].value) {
                                    checkboxes[i].checked = true;
                                }
                            }
                        }    
                    } else 
                        closeSession()
                }
            ).catch(errror => alertaGeneral("Problemas de Conexión (90)"))
        }

        var permisos = []

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('4')
        let boolEdit = accessUser.includes('5') 
        let boolDelete = accessUser.includes('6')
        let boolCargar = accessUser.includes('49')
            
        const { items } = this.state;
        
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                    <div className="page-wrapper">
                        <div className="row page-titles">
                            <div className="col-md-5 align-self-center">
                                <h3 className="text-primary">Rutas de Entrega</h3> 
                            </div>
                            { 
                                boolCrear 
                            ? 
                                <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./routescedi">Crear</a></label>
                            :
                                null
                            }
                            {
                                boolEdit || boolDelete 
                            ?
                                <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./routescedilist">Datos</a></label>
                            :
                                null
                            }    
                            {
                                boolCargar 
                            ?
                                <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdx"><a href="./routescedicargar">Cargar</a></label>
                            :
                                null
                            }
                        </div>
                        <UpdateData  />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">           
                                            <div className="table-responsive m-t-40">                                                                                                 
                                                <MaterialTable
                                                    title="Datos"
                                                    columns={[
                                                        { title: 'Id', field: 'id' },
                                                        { title: 'Nombre', field: 'name' }
                                                    ]}
                                                    data = {
                                                        items
                                                    }
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#01579b',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        filtering: true,
                                                        search: true
                                                    }}
                                                    actions={[
                                                        {
                                                        icon: 'edit',
                                                        hidden: !boolEdit,
                                                        iconProps: { style: { color: "#00569b" } },
                                                        tooltip: 'Save User',
                                                        onClick: (event, rowData) => formEdit(rowData.id)
                                                        },
                                                        rowData => ({
                                                            icon: 'delete',
                                                            hidden: !boolDelete,
                                                            iconProps: { style: { color: "#ff5722" } },
                                                            tooltip: 'Delete User',
                                                            onClick: (event, rowData) => alertaConfirm(rowData.id)
                                                        })
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>          
                        </div>
                    </div>
                </div>
            )
        }
}

export default routescedi;