import React, {Component} from 'react'
import $ from 'jquery';

class Sidebar extends Component{

    componentDidMount() {
        $(".sidebar-nav li").hide()
        let WhiteBrand = sessionStorage.getItem("WhiteBrand")
        let WhiteBrand_b2c = sessionStorage.getItem("WhiteBrand_b2c")

        let onOffLook = sessionStorage.getItem("onOffLook")
        let onOffListPrices = sessionStorage.getItem("onOffListPrices")

        let onOffNotificaciones = sessionStorage.getItem("onOffNotificaciones")
        let onOffImgPublicidad  = sessionStorage.getItem("onOffImgPublicidad")

        let access = JSON.parse(sessionStorage.getItem('access'))
        access.forEach(function (element) {
            if (element == 1 || element == 2 || element == 3 || element == 47) 
                document.querySelector('#ModComerciales_T').style.display = 'block'
            if (element == 4 || element == 5 || element == 6 || element == 49) 
                document.querySelector('#ModRuta_T').style.display = 'block'
            if (element == 7 || element == 8 || element == 9 || element == 53) 
                document.querySelector('#ModCategoria_T').style.display = 'block'
            if (element >= 10 && element <= 12) 
                document.querySelector('#ModTypeBusinesst_T').style.display = 'block'
            if (element >= 13 && element <= 15 && onOffImgPublicidad == "1") 
                document.querySelector('#ModPublicidad_T').style.display = 'block'
            if (element == 16 || element == 17 || element == 18 || element == 48) 
                document.querySelector('#ModProductos_T').style.display = 'block'
            if ((element == 19 || element == 20 || element == 21 || element == 22 || element == 23 ||element == 24 || element == 50 || element == 51) && onOffListPrices == "1")
                document.querySelector('#ModPrecios_T').style.display = 'block'
            if (element == 25 || element == 26 || element == 27 || element == 52) 
                document.querySelector('#ModEstablecimientos_T').style.display = 'block'
            if (element >= 28 && element <= 30) 
                document.querySelector('#ModUsuarios_T').style.display = 'block'
            if (element == 31 && onOffNotificaciones == "1") 
                document.querySelector('#ModNotificaciones_T').style.display = 'block'                 
            if (element >= 34 && element <= 36) 
                document.querySelector('#ModExcel_T').style.display = 'block'    
            if (element >= 37 && element <= 42) 
                document.querySelector('#ModConfig_T').style.display = 'block'
            if (element >= 43 && element <= 45)
                document.querySelector('#ModPromoEstrella_T').style.display = 'block'
            if (element == 46) 
                document.querySelector('#ModDashboard_T').style.display = 'block'
        })

        $(".itemparent").click(function () {
            $(this).next("ul").toggle();
        });
        $(".itemSubparent").click(function () {
            $(this).next("ul").toggle();
        });
    }
    render() {
        return  (
            <React.Fragment>
                <div  className="left-sidebar">
                    <div  className="scroll-sidebar">
                        <nav  className="sidebar-nav">
                            <ul id="sidebarnav">
                                <li  className="nav-devider"></li>
                                <li> <a  className="  " href="./dashboard" aria-expanded="false"><i  className="fa fa-desktop"></i><span  className="hide-menu"> Escritorio</span></a></li>
                                <li id="ModConfig_T" > <a  className="has-arrow itemparent " href="./cediconfigCEDI" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Configuración </span></a></li>     
                                <li id="ModComerciales_T"><a  className="has-arrow itemparent " href="./comerciallist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Comerciales</span></a></li>
                                <li id="ModRuta_T"><a  className="has-arrow itemSubparent " href="./routescedilist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Rutas de Entrega </span></a></li>
                                <li id="ModCategoria_T"><a className="has-arrow itemSubparent " href="./categorylist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Categorías </span></a>  </li>                             
                                <li id="ModTypeBusinesst_T"><a className="has-arrow itemSubparent " href="./typeBusinessList" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Tipo de Clientes </span></a>  </li>
                                <li id="ModPublicidad_T"><a  className="has-arrow itemSubparent" href="./advertisinglist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Imágenes publicitarias </span></a></li>
                                <li id="ModProductos_T"> <a  className="has-arrow  itemparent" href="./productslist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Productos</span></a></li>
                                <li id="ModPrecios_T"><a  className="has-arrow itemparent" href="./pricescustomlist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Precios</span></a>                                </li>
                                <li id="ModPromoEstrella_T"> <a  className="has-arrow  itemparent" href="./promoestrellalist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Promociones</span></a></li>
                                <li id="ModEstablecimientos_T"> <a  className="has-arrow  itemparent" href="./establishmentslist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Clientes</span></a></li>
                                <li id="ModNotificaciones_T"> <a  className="has-arrow  itemparent" href="./notifications" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Notificaciones</span></a></li>
                                <li id="ModUsuarios_T" > <a  className="has-arrow itemparent " href="./userlist" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Usuarios</span></a></li>     
                                <li id="ModExcel_T" > <a  className="has-arrow itemparent " href="./uploadListPrice" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">IO Excel</span></a></li>     
                                <li id="ModDashboard_T"> <a  className="has-arrow  itemparent" href="./cuadromando" aria-expanded="false"><i  className="fa fa-book"></i><span  className="hide-menu">Cuadro de Mando</span></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>                
            </React.Fragment>          
        )
    }
}

export default Sidebar