import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import $ from 'jquery'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class inputProducto extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidMount(){

        function activarTypeOffert(e) {
            
            // ***********************
            let opc_categoria = $("#updProduct").find('option:selected').attr('categoria')
            $("#updidCategory").val(opc_categoria);
            // ***********************

            let opt = $("#updProduct").find('option:selected').attr('caso')
            $("#updCaso").val(opt)
            document.querySelector('.itemsund').style.display = 'none'
            document.querySelector('.itemsumd').style.display = 'none'
            switch (opt) {
                case '1':
                    document.querySelector('.itemsund').style.display = 'block'
                    break
                case '2':
                    document.querySelector('.itemsumd').style.display = 'block'
                    break
            }
        }

        const input = document.querySelector('#updProduct')
        input.addEventListener('change', activarTypeOffert)
    
        const idCedi = sessionStorage.getItem("idCedi")
        let datos = {idCedi : idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/productscedi",{ // ok
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        }).then(response => response.json())
            .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }).catch(error => alertaGeneral("Problemas de Conexión (17)"))
    }

    render(){

        const { items } = this.state;
        const doesNotApply = this.props.doesNotApply

        return  (
            <React.Fragment>                
                <div className="col-md-8">
                    <div className="form-group">
                        <label className=" control-label">Producto </label>
                        <select className="form-control" id="updProduct" name="Product"  >      
                            { 
                                (doesNotApply == 'true')
                            ? 
                                <option value={-1} >Selección</option>
                            : 
                                null
                            }  
                            {items.map(item => (
                                <option value={item.id} caso={item.typeOffert} 
                                        // *************
                                        categoria={item.idCategory}
                                        disabled = { (item.status==1) ? false : true }
                                        // *************
                                        >{item.name} 
                                </option> 
                            ))}        
                        </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputProducto