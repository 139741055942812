import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import $ from 'jquery'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class InputTaxes extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }
    componentDidMount(){
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/typetaxes",{ // ok
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        }).then(response => response.json())
            .then((result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }).catch(error => alertaGeneral("Problemas de Conexión (23)"))
    }

    boolfreight = () =>{
        const {  items } = this.state;
        const auxName = '#'+this.props.auxName
        if ($({auxName}).val() == '1') { // Valor Fijo
            document.querySelector('.varFijoflete').style.display =  'block'
            document.querySelector('.porfleteVar').style.display  =  'none'
        } else { // Porcentaje del pedido
            document.querySelector('.varFijoflete').style.display =  'none'
            document.querySelector('.porfleteVar').style.display  =  'block'
        }    
    }

    render(){

        const {  items } = this.state;
        const auxName = this.props.auxName
        const auxId = this.props.auxId
        
        return  (
            <React.Fragment>                
                <div className="col-md-2">
                    <div className="form-group">
                        <label className=" control-label">Tipo de flete</label>
                            <select name={auxName} className="form-control" id={auxId} onClick="boolfreight">    
                            {items.map(item => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))}                                          
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default InputTaxes