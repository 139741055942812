import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeAlertGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class detaillistpricescustom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            renderizar : true,
            controlEdit : false,
            controlDelete : false,
        };
    }

    componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi");
        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")
 
        function formConfirmDelete() {
            let nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")

            let nidCedi = document.getElementById("btnAlertConfirm").getAttribute("data-nidcedi") //data-nidCedi
            let nidCategory = document.getElementById("btnAlertConfirm").getAttribute("data-nidcategory") // data-nidCategory
            let nidListPrices = document.getElementById("btnAlertConfirm").getAttribute("data-nidlistprices") //data-nidListPrices
            let nidProduct = document.getElementById("btnAlertConfirm").getAttribute("data-nidproduct")

            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid,nidCedi,nidCategory,nidListPrices,nidProduct)
            }
        }

        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }

        let datos = {idCedi : idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/detaillistpricescustom",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (73)"))

        function formDelete(id,idCedi,idCategory,idListPrices,idProduct) {
            if (id >= 1) {
                var datos = {
                    id : id,
                    idCedi : idCedi,
                    idCategory : idCategory,
                    idListPrices : idListPrices,
                    idProduct : idProduct,
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/detaillistpricescustom/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data.id  == datos.id) {
                                alertaGeneral("Registro  Eliminado")
                                window.location.reload()
                            } else
                                alertaGeneral("Este dato esta asociado a otro concepto, por lo cual no se puede eliminar")            
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (74)"))
            } else {
                alertaGeneral("Validar La información ingresada");
            }
        }

        $(document).off().on('click', '.btnupdate', function () {
            // ********************
            let idCategory = $("#updidCategory").val()
            let caso = $("#updCaso").val()
            // ********************
            let id = $("#updid").val();
            let idProduct = $("#updProduct").val(); // #formupdate 
            let priceTax = $("#updpriceTax").val();
            let orderMinCant = $("#updorderMinCant").val();
            let umdTax = $("#updumdTax").val();
            let orderMinUmd = $("#updorderMinUmd").val();
            let valUnidUmd = $("#updvalUnidUmd").val();
            let idListPrices = $("#updidListPrices").val(); // #formcreate 
            let bool_validacion = false
            switch (caso) {
                case '1':
                    if ((priceTax != 0) && (orderMinCant != 0))
                        bool_validacion = true
                    break
                case '2':
                    if ((umdTax != 0) && (orderMinUmd != 0) && (valUnidUmd != 0))
                        bool_validacion = true
                    break
            }

            if (bool_validacion) {
                var datos = {
                    // *******************
                    idCategory: idCategory,
                    // *******************
                    id: id,
                    idCedi: idCedi,
                    idProduct: idProduct,
                    priceTax: priceTax,
                    orderMinCant: orderMinCant,
                    orderMinUmd: orderMinUmd,
                    umdTax: umdTax,
                    valUnidUmd: valUnidUmd,
                    idListPrices: idListPrices
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/detaillistpricescustom/update", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "PUT",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado")
                            window.location.reload()    
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (75)"))
            } else {
               alertaGeneral("Validar la información ingresada")
            }
        });
        var v1, v2
        var access = JSON.parse(sessionStorage.getItem('access'))
        access.forEach(function (element) {  
            if (element == 23)
                v1 = true
            if (element == 24)
                v2 = true
        })
        if (v1)
            this.setState({ controlEdit : true })
        if (v2)
            this.setState({ controlDelete : true })

    }

    render(){  
        function formEdit(id) {
            window.scrollTo(0, 0);
            document.querySelector('.formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/detaillistpricescustom/read",{  // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        $("#updidCategory").val(result.data.idCategory)
                        $("#updid").val(result.data.id)
                        $("#updidListPrices").val(result.data.idListPrices)
                        $("#updProduct").val(result.data.idProduct)
                        $("#updidcedi").val(result.data.idCedi)
                        $("#updname").val(result.data.name)
                        $("#updpriceTax").val(result.data.priceTax)
                        $("#updorderMinCant").val(result.data.orderMinCant)
                        $("#updvalUnidUmd").val(result.data.valUnidUmd)
                        $("#updorderMinUmd").val(result.data.orderMinUmd)
                        $("#updumdTax").val(result.data.umdTax)
                        $("#updProduct option[value=" + result.data.idProduct + "] ").attr('selected', 'selected')
                        $("#updidListPrices option[value=" + result.data.idListPrices + "] ").attr('selected', 'selected')
                        $("#updCaso").val(result.data.b2bProductsCedi.typeOffert)
                        if (result.data.b2bProductsCedi.typeOffert == 1) { // Und
                            document.querySelector('.itemsund').style.display  =  'block'
                            document.querySelector('.itemsumd').style.display =  'none'
                        }
                        else { // UMD
                            document.querySelector('.itemsund').style.display  =  'none'
                            document.querySelector('.itemsumd').style.display =  'block'
                        }        
                    } else 
                        closeSession()
                },
            ).catch(error => alertaGeneral("Problemas de Conexión (76)"))
        }

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrearLista = accessUser.includes('19')
        let boolEditLista = accessUser.includes('20') 
        let boolDeleteLista = accessUser.includes('21')
        let boolCrearDetalle = accessUser.includes('22')
        let boolEditDetalle = accessUser.includes('23') 
        let boolDeleteDetalle = accessUser.includes('24')
        let boolCargarPrices = accessUser.includes('50')
        let boolCargarDetails = accessUser.includes('51')

           
        const { items } = this.state;
      
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                    <div className="page-wrapper">
                        <div className="row page-titles">
                            <div className="col-md-2 align-self-center">
                                <h3 className="text-primary">Precios - Listas</h3> 
                            </div>
                        { 
                            boolCrearLista
                        ? 
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustom"><a href="./pricescustom">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEditLista || boolDeleteLista
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomList"><a href="./pricescustomlist">Datos</a></label>
                        :
                            null
                        } 
                                                {
                            boolCargarPrices
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomCargar"><a href="./pricescustomcargar">Cargar</a></label>
                        :
                            null
                        }       
                        { 
                            boolCrearDetalle
                        ? 
                            <>
                                <div className="col-md-3 align-self-center">
                                    <h3 style = {{marginLeft:60}} className="text-primary">Precios - Detalles</h3> 
                                </div>
                                <label style = {{ fontSize:22}} id="idPricesCustomDetail">
                                    <a 
                                        href="./pricescustomdetail"> Crear
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolEditDetalle || boolDeleteDetalle
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomDetailList"><a href="./pricescustomdetaillist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargarDetails
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomDetailCargar"><a href="./pricescustomdetailcargar">Cargar</a></label>
                        :
                            null
                        }    
                        </div>
                         <UpdateData renderizar = {this.state.renderizar} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">   
                                            <div className="table-responsive m-t-40">                           
                                            <MaterialTable
                                                title="Datos Detalle Lista de Precios"
                                                columns={[
                                                    { title: 'Id', field: 'idProduct', width: 8 },
                                                    { title: 'Lista', field: 'b2bListPricesCustom.name' }, // 'idListPrices'
                                                    { title: 'Id Producto', field: 'b2bProductsCedi.name' } // idProduct
                                                ]}
                                                data = {
                                                    items
                                                }
                                                options={{
                                                   // exportButton: true,
                                                    headerStyle: {
                                                        backgroundColor: '#01579b',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: true
                                                }}
                                                actions={[
                                                    {
                                                    icon: 'edit',
                                                    hidden: !this.state.controlEdit,
                                                    iconProps: { style: { color: "#00569b" } },
                                                    tooltip: 'Editar',
                                                    onClick: (event, rowData) => formEdit(rowData.id)
                                                    },
                                                    rowData => ({
                                                        icon: 'delete',
                                                        hidden: !this.state.controlDelete,
                                                        iconProps: { style: { color: "#ff5722" } },
                                                        tooltip: 'Borrar',
                                                        onClick: (event, rowData) => alertaConfirm(
                                                            rowData.id,rowData.img1,rowData.img2,rowData.img3,
                                                            rowData.idCedi,rowData.idCategory,
                                                            rowData.idListPrices, rowData.idProduct)
                                                    })
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>          
                    </div>
                </div>
            </div>
        )
    }
}

export default detaillistpricescustom;