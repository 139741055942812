import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class listpricescustom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            controlEdit : false,
            controlDelete : false,
        };
    }

    componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")
        let datos = {idCedi : idCedi, mostrar:2}

        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid)
            }
        }

        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/listpricescustom",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success)) 
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (109)"))    
        
        $(document).off().on('click', '.btnupdate', function () {
            let id = $("#updid").val();
            let name = $("#updname").val();
            let idFabDist = $("#formupdate #updidFabDist").val();
            if ((name.length >= 4) && (name.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2B") &&
                (name.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2C")) {
                var datos = {
                    id: id,
                    name: name,
                    idCedi: idCedi,
                    idFabDist: idFabDist,
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/listpricescustom/update", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                        method: "PUT",
                        body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado");
                            window.location.reload();    
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (69)"))
            } else {
                alertaGeneral("Validar la información ingresada");
            }
        });
           
        function formDelete(id) {
            if (id >= 1) {
                var datos = {
                    id: id
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/listpricescustom/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data.id  == datos.id) {
                                alertaGeneral("Registro  Eliminado");
                                window.location.reload();
                            } else
                                alertaGeneral("Este dato esta asociado a otro concepto, por lo cual no se puede eliminar")                 
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (70)"))
            } else {
                alertaGeneral("Validar la información ingresada");
            }
        }
    }
  
    render(){
                            
        function formEdit(id) {
            window.scrollTo(0, 0);
            document.querySelector('.formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/listpricescustom/read",{ // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id,
                    mostrar: 2
                })
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)){
                        $("#updid").val(result.data.id)
                        $("#updidcedi").val(result.data.idCedi)
                        $("#updname").val(result.data.name)
                        $('#formupdate #updcedis  option[value="' + result.data.idCedi + '"]').attr("selected", "selected");
                        $('#formupdate #updidFabDist option[value="' + result.data.idFabDist + '"]').attr("selected", "selected");    
                    } else 
                        closeSession()
                }
            ).catch(error => alertaGeneral("Problemas de Conexión (71)"))
        }   

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrearLista = accessUser.includes('19')
        let boolEditLista = accessUser.includes('20') 
        let boolDeleteLista = accessUser.includes('21')
        let boolCrearDetalle = accessUser.includes('22')
        let boolEditDetalle = accessUser.includes('23') 
        let boolDeleteDetalle = accessUser.includes('24')
        let boolCargarPrices = accessUser.includes('50')
        let boolCargarDetails = accessUser.includes('51')
            
        const { items } = this.state;
        
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-2 align-self-center">
                            <h3 className="text-primary">Precios - Listas</h3> 
                        </div>
                        { 
                            boolCrearLista
                        ? 
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustom"><a href="./pricescustom">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEditLista || boolDeleteLista
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomList"><a href="./pricescustomlist">Datos</a></label>
                        :
                            null
                        }
                        {
                            boolCargarPrices
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomCargar"><a href="./pricescustomcargar">Cargar</a></label>
                        :
                            null
                        }    
                        { 
                            boolCrearDetalle
                        ? 
                            <>
                                <div className="col-md-3 align-self-center">
                                    <h3 style = {{marginLeft:60}} className="text-primary">Precios - Detalles</h3> 
                                </div>
                                <label style = {{ fontSize:22}} id="idPricesCustomDetail">
                                    <a 
                                        href="./pricescustomdetail"> Crear
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolEditDetalle || boolDeleteDetalle
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomDetailList"><a href="./pricescustomdetaillist">Datos</a></label>
                        :
                            null
                        }  
                        {
                            boolCargarDetails
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomDetailCargar"><a href="./pricescustomdetailcargar">Cargar</a></label>
                        :
                            null
                        }      
                    </div>
                     <UpdateData />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive m-t-40">                                            
                                            <MaterialTable
                                                title="Datos Listas de Precios"
                                                columns={[
                                                    { title: 'id', field: 'id', width:10 },
                                                    { title: 'Nombre', field: 'name' }
                                                ]}
                                                data = { items }
                                                options={{
                                                    //exportButton: true,
                                                    headerStyle: {
                                                        backgroundColor: '#01579b',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: true
                                                }}
                                                actions={[
                                                    {
                                                    icon: 'edit',
                                                    hidden: !boolEditLista,
                                                    iconProps: { style: { color: "#00569b" } },
                                                    tooltip: 'Editar',
                                                    onClick: (event, rowData) => formEdit(rowData.id)
                                                    },
                                                    rowData => ({
                                                        icon: 'delete',
                                                        hidden: !boolDeleteLista,
                                                        iconProps: { style: { color: "#ff5722" } },
                                                        tooltip: 'Borrar',
                                                        onClick: (event, rowData) => alertaConfirm(rowData.id)
                                                    })
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default listpricescustom;