import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import InputTypeDocument from '../atoms/InputTypeDocument'
import InputStatus from '../atoms/InputStatus'
import InputPaymentMethods from '../atoms/InputPaymentMethods'
import InputRoutes from '../atoms/InputRoutes'
import InputListPrices from '../atoms/InputListPrices'
import InputComercial from '../atoms/InputComercial'
import InputTypeBusiness from '../atoms/InputTypeBusiness'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import TypeFreight from '../atoms/InputTaxes'
import {endSession,alertaGeneral,closeAlertGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class establishmentsList extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            renderizar : true,
            idWhiteBrand : 0,
            idAssociative : 0,
            idWhiteBrand_b2c : 0,
            idAssociative_b2c : 0,
        };
    }

    componentDidMount() { 
    
        this.setState({idWhiteBrand      : sessionStorage.getItem("WhiteBrand")})
        this.setState({idAssociative     : sessionStorage.getItem("Associative")})
        this.setState({idWhiteBrand_b2c  : sessionStorage.getItem("WhiteBrand_b2c")})
        this.setState({idAssociative_b2c : sessionStorage.getItem("Associative_b2c")})

        const idCedi = sessionStorage.getItem("idCedi") 
        const varOnlyListPrice = document.querySelector("#updidListPrices");
        varOnlyListPrice.addEventListener('change',updateOnlyListPrice);
        document.querySelector('.flagOnlyListPrice').style.display = 'none'

        function updateOnlyListPrice(e) {
            let combo = document.getElementById("updidListPrices");
            let selected = combo.options[combo.selectedIndex].text;
            if ((selected.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2B") && (selected.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2C"))
                document.querySelector('.flagOnlyListPrice').style.display = 'block'
            else 
                document.querySelector('.flagOnlyListPrice').style.display = 'none'
        }


        const input = document.querySelector('#updtypeFreight');
        input.addEventListener('change', updateValue);

        function updateValue(e) {
            if (e.target.value == "1") {
                document.querySelector('.varFijoflete').style.display =  'block'
                document.querySelector('.porfleteVar').style.display  =  'none'
            } else {
                document.querySelector('.varFijoflete').style.display =  'none'
                document.querySelector('.porfleteVar').style.display  =  'block'
            }
        }

        function isValidEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")
       
        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid)
            }
        }

        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }

        function formDelete(id) {
            if (id >= 1) {
                var datos = {
                    id: id
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/establishmentsclientcedi/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data == '1') {
                                alertaGeneral("Registro  Eliminado");
                                window.location.reload();
                            } else
                                alertaGeneral("Este dato esta asociado a otro concepto, por lo cual no se puede eliminar")        
                        } else 
                            closeSession()
                    },
                ).catch(error => alertaGeneral("Problemas de conexión (54)"))
            } else {
                alertaGeneral("Validar la información ingresada");
            }
        }

        let datos = {idCedi : idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/establishmentsclientcedi",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            },
        ).catch(error => alertaGeneral("Problemas de conexión (55)"))  
            
        $(document).off().on('click', '.btnupdate', function () {     

            let id = $("#updid").val();
            let pricesFreigh = 0;
            let percentageFreightVar = 0;
            let status = $("#updstatus").val();
            let typeClient = $("#updtypeClient").val();
            // let idUserapp = $("#updidUserapp").val();
            let address = $("#updaddress").val();
            let typeDocument = $("#updtypeDocument").val();
            let idTypeBusiness = $("#updidTypeBusiness").val();

            let numberDocument = $("#updnumberDocument").val();
            let nameBilling = $("#updnameBilling").val();
            let phone = $("#updphone").val();
            let emailEnvoice = $("#updemailEnvoice").val();
            let internalCode = $("#updinternalCode").val();
            let name = $("#updname").val();
            let nameContac = $("#updnameContac").val();
            let lat = $("#updlat").val();
            let lng = $("#updlng").val();
            let paymentMethods = $("#updpaymentmethods").val();
            let idRoute = $("#updidRoute").val();
            let idCommercial = $("#updidCommercial").val();
            let idListPrices = $("#updidListPrices").val();
            let typeTaxes = $("#updtypeFreight").val();
            let orderMinAllTax = $("#updorderMinAllTax").val();
            let paramAlgoritDisperseCtoTat = $("#updparamAlgoritDisperseCtoTat").val();
            let paramAlgoritDispersePercentage = $("#updparamAlgoritDispersePercentage").val();
            let typeShipping = $("#updtypeShipping").val();
            let officeUnit = $("#updofficeUnit").val();
            let emailUser = $("#updemailUser").val();
            let attentionXpersonsAsocAll = $("#updattentionXpersonsAsocAll").val();
            let onlyListPrices = $("#updonlyListPrices").val();
            let visitLunes = $("#updvisitLunes").val()
            let visitMartes = $("#updvisitMartes").val()
            let visitMiercoles = $("#updvisitMiercoles").val()
            let visitJueves = $("#updvisitJueves").val()
            let visitViernes = $("#updvisitViernes").val()
            let visitSabado = $("#updvisitSabado").val()
            let visitDomingo = $("#updvisitDomingo").val()

            if ($("#updpercentageFreightVar").val()!==""){
                 percentageFreightVar = $("#updpercentageFreightVar").val();
            }
            if ($("#updpricesFreigh").val() !== "") {
                 pricesFreigh = $("#updpricesFreigh").val();
            }

            let valEmail = isValidEmail(emailUser);
            let valEmailEnvoice = isValidEmail(emailEnvoice)
            if (valEmail && valEmailEnvoice) {
                let ticketNoFreigh = $("#updticketNoFreigh").val();
                let release = $("#updrelease").val();
                if (lat !== "" && lng !== "") {
                    let coordinates = {
                      lat: lat,
                      lng: lng,
                    };

                    if (
                      nameContac.length >= 4 && 
                      name.length >= 4 &&
                      nameBilling.length >= 4 &&
                      internalCode.length !== ""
                    ) {
                        var datos = {
                          id: id,
                          idCedi: idCedi,
                          status: status,
                          typeClient: typeClient,
                          internalCode: internalCode,
                          name: name,
                          // idUserapp: idUserapp,
                          address: address,
                          typeDocument: typeDocument,
                          idTypeBusiness: idTypeBusiness,
                          numberDocument: numberDocument,
                          nameBilling: nameBilling,
                          phone: phone,
                          emailEnvoice: emailEnvoice,
                          officeUnit: officeUnit,
                          nameContac: nameContac,
                          coordinates: JSON.stringify(coordinates),
                          paymentMethods: paymentMethods,
                          idRoute: idRoute,
                          idCommercial: idCommercial,
                          idListPrices: idListPrices,
                          orderMinAllTax: orderMinAllTax,
                          paramAlgoritDisperseCtoTat: paramAlgoritDisperseCtoTat,
                          paramAlgoritDispersePercentage: paramAlgoritDispersePercentage,
                          typeShipping: typeShipping,
                          attentionXpersonsAsocAll: attentionXpersonsAsocAll,
                          typeTaxes: typeTaxes,
                          percentageFreightVar: percentageFreightVar,
                          pricesFreigh: pricesFreigh,
                          ticketNoFreigh: ticketNoFreigh,
                          release: release,
                          emailUser: emailUser,
                          onlyListPrices : onlyListPrices,
                          visitLunes : visitLunes,
                          visitMartes : visitMartes,
                          visitMiercoles : visitMiercoles,
                          visitJueves : visitJueves,
                          visitViernes : visitViernes,
                          visitSabado : visitSabado,
                          visitDomingo : visitDomingo,
                        };
                        let token = sessionStorage.getItem('token')
                        fetch(Const.urlrest +"api/establishmentsclientcedi/update", // ok
                            {
                                headers: { 
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': token,
                                },
                                method: "PUT",
                                body: JSON.stringify(datos),
                            }
                        )
                        .then((response) => response.json())
                        .then(
                          (result) => {
                                if (endSession(result.success)) {
                                    if (result.data) {
                                        alertaGeneral("Registro  Actualizado");
                                        window.location.reload(false);
                                    } else {
                                        alertaGeneral("*/");
                                    }      
                                } else 
                                    closeSession()
                          },
                        ).catch(error => alertaGeneral("Problemas de conexión (56)"))
                    } else {
                          alertaGeneral("Verifica los datos ingresados");
                    }
              } else {
                    alertaGeneral("Verifica las coordenadas");
              }
            } else {
                  alertaGeneral("Verifica el email");
             }
           });  
    }

    vacio = (vacio) => {return true}

    getResponse = (e) => {
        let datos = {
            idEstablishment :  $("#updid").val(),
            typeOffert : 1,
            caso : 1, // Borra sin tener encuenta otros factores
        }
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/wishlist/typeOffert", { // ok
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            method: "DELETE",
            body: JSON.stringify(datos)
        }).then((response) => response.json())
            .then(
                (result) => {
                    if (!endSession(result.success))
                        closeSession()
                }    
        ).catch(error => alertaGeneral("Problemas de conexión (57)"))
        return true
    }

    render(){

        function typeClientPro() {
            const typeDisperse_b2b = sessionStorage.getItem("typeDisperse_b2b")
            const typeDisperse_b2c = sessionStorage.getItem("typeDisperse_b2c")
            if ($("#updtypeClient").val() == 1) { // B2B
                switch (typeDisperse_b2b) {
                    case "10": {
                        document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                        document.querySelector('._typeDisperse_percentage').style.display = 'block'
                        break
                    }
                    case "20": {
                        document.querySelector('._typeDisperse_ctoTAT').style.display = 'block'
                        document.querySelector('._typeDisperse_percentage').style.display = 'none'
                        break
                    }    
                    case "30": {
                        document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                        document.querySelector('._typeDisperse_percentage').style.display = 'none'
                        break
                    }    
                }
            } else { // B2C
                switch (typeDisperse_b2c) {
                    case "1000": {
                        document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                        document.querySelector('._typeDisperse_percentage').style.display = 'block'
                        break
                    } 
                    default: {
                        document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                        document.querySelector('._typeDisperse_percentage').style.display = 'none'
                        break
                    }
                }
            }    
        }



        function formEdit(id) {

            let el = document.getElementById('updtypeClient');
            if (el) {
                el.addEventListener("change", typeClientPro);
            }
        
            let combo2, selected2
            window.scrollTo(0, 0);
            document.querySelector('#formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/establishmentsclientcedi/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        $("#updstatus").val(result.data.status)
                        $("#updtypeClient").val(result.data.typeClient)
                        $("#updid").val(result.data.id);
                        $("#updofficeUnit").val(result.data.officeUnit)
                        $("#updidListPrices").val(result.data.idListPrices)
                        $("#updtypeShipping").val(result.data.typeShipping)
                        $("#updtypeDocument").val(result.data.typeDocument)
                        $("#updidTypeBusiness").val(result.data.idTypeBusiness)
                        $("#updidCommercial").val(result.data.idCommercial)
                        $("#updpaymentmethods").val(result.data.paymentMethods)
                        $("#updtypeClient option[value=" + result.data.typeClient + "] ").attr('selected', 'selected')
                        $("#updstatus option[value=" + result.data.status + "] ").attr('selected', 'selected')
                        $("#updidListPrices option[value=" + result.data.idListPrices + "] ").attr('selected', 'selected')
                        $("#updtypeFreight").val(result.data.typeTaxes) //tyFreight)
                        $("#updaddress").val(result.data.address);
                        $("#updemailUser").val(result.data.emailUser);
                        $("#updnumberDocument").val(result.data.numberDocument);
                        $("#updnameBilling").val(result.data.nameBilling);
                        $("#updphone").val(result.data.phone);
                        $("#updemailEnvoice").val(result.data.emailEnvoice);
                        $("#updinternalCode").val(result.data.internalCode);
                        $("#updname").val(result.data.name);
                        $("#updnameContac").val(result.data.nameContac);
                        $("#updidRoute").val(result.data.idRoute)
                        $("#updlat").val(result.data.lat);
                        $("#updlng").val(result.data.lng);
                        
                        combo2 = document.getElementById("updidListPrices");
                        selected2 = combo2.options[combo2.selectedIndex].text;
    
                        if ((selected2.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2B") && (selected2.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2C")) 
                            document.querySelector('.flagOnlyListPrice').style.display = 'block'
                        else 
                            document.querySelector('.flagOnlyListPrice').style.display = 'none'
                        $("#updstatus option[value=" + result.data.status + "] ").attr("selected", "selected");
                        $("#updtypeDocument option[value=" +result.data.typeDocument +"] ").attr("selected", "selected");
                        $("#updidTypeBusiness option[value=" +result.data.idTypeBusiness +"] ").attr("selected", "selected");
                        $("#updidCommercial option[value=" +result.data.idCommercial +"] ").attr("selected", "selected");
                        $("#updpaymentmethods option[value=" +result.data.paymentmethods +"] ").attr("selected", "selected");
                        $("#updidRoute option[value=" + result.data.idRoute + "] ").attr("selected", "selected");
                        $("#officeUnit option[value=" +result.data.officeUnit +"] ").attr("selected", "selected");
                        $("#updattentionXpersonsAsocAll option[value=" +result.data.attentionXpersonsAsocAll +"] ").attr("selected", "selected");
                        $("#updonlyListaPrices option[value=" +result.data.onyListPrices +"] ").attr("selected","selectd");
                        $("#updtypeShipping option[value=" +result.data.typeShipping +"] ").attr("selected", "selected");
                        $("#updtypeFreight option[value=" +result.data.typeTaxes +"] ").attr("selected", "selected");
                        if (result.data.typeTaxes == '1') {
                            document.querySelector(".varFijoflete").style.display ="block";
                            document.querySelector(".porfleteVar").style.display  ="none";
                        } else {
                            document.querySelector(".varFijoflete").style.display ="none";
                            document.querySelector(".porfleteVar").style.display  ="block";
                        }
    
                        $("#updorderMinAllTax").val(result.data.orderMinAllTax);
                        $("#updparamAlgoritDisperseCtoTat").val(result.data.paramAlgoritDisperseCtoTat)
                        $("#updparamAlgoritDispersePercentage").val(result.data.paramAlgoritDispersePercentage)
                        $("#updofficeUnit").val(result.data.officeUnit);
                        $("#updattentionXpersonsAsocAll").val(result.data.attentionXpersonsAsocAll);
                        $("#updonlyListPrices").val(result.data.onlyListPrices);
    
                        $("#updvisitLunes").val(result.data.visitLunes);
                        $("#updvisitMartes").val(result.data.visitMartes);
                        $("#updvisitMiercoles").val(result.data.visitMiercoles);
                        $("#updvisitJueves").val(result.data.visitJueves);
                        $("#updvisitViernes").val(result.data.visitViernes);
                        $("#updvisitSabado").val(result.data.visitSabado);
                        $("#updvisitDomingo").val(result.data.visitDomingo);
    
                        $("#updpercentageFreightVar").val(result.data.percentageFreightVar);
                        $("#updpricesFreigh").val(result.data.pricesFreigh);
                        $("#updticketNoFreigh").val(result.data.ticketNoFreigh);
                        $("#updrelease").val(result.data.release); 
    
                        let coordenadas = JSON.parse(result.data.coordinates);
                        for (var clave in coordenadas) {
                            if (coordenadas.hasOwnProperty(clave)) {
                                document.getElementById("upd" + clave).value =
                                coordenadas[clave];
                            }
                        }
                        const typeDisperse_b2b = sessionStorage.getItem("typeDisperse_b2b")
                        const typeDisperse_b2c = sessionStorage.getItem("typeDisperse_b2c")
                
                        if ($("#updtypeClient").val() == 1) { // B2B
                            switch (typeDisperse_b2b) {
                                case "10": {
                                    document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                                    document.querySelector('._typeDisperse_percentage').style.display = 'block'
                                    break
                                }
                                case "20": {
                                    document.querySelector('._typeDisperse_ctoTAT').style.display = 'block'
                                    document.querySelector('._typeDisperse_percentage').style.display = 'none'
                                    break
                                }    
                                case "30": {
                                    document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                                    document.querySelector('._typeDisperse_percentage').style.display = 'none'
                                    break
                                }    
                            }
                        } else { // B2C
                            switch (typeDisperse_b2c) {
                                case "1000": {
                                    document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                                    document.querySelector('._typeDisperse_percentage').style.display = 'block'
                                    break
                                } 
                                default: {
                                    document.querySelector('._typeDisperse_ctoTAT').style.display = 'none'
                                    document.querySelector('._typeDisperse_percentage').style.display = 'none'
                                    break
                                }
                            }
                        }            
                    } else 
                        closeSession()
                },
            ).catch(error => alertaGeneral("Problemas de conexión (58)"))

        }

        function borrarTipoCliente(e) {
            let datos = {
                idEstablishment : $("#updid").val(),
                caso : 1,
            }
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/wishlist/typeOffert", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "DELETE",
                body: JSON.stringify(datos)
            }).catch(error => alertaGeneral("Problemas de conexión (59)"))
        }

        function borrarUnidadDespacho(e) {
            let datos
            switch (e.target.value) {
                case "1": { // Unidades
                    datos = {
                        idEstablishment : $("#updid").val(),
                        typeOffert : 2,
                        caso : 2, // Depende de Lista de Precios
                    }
                    break
                }
                case "2": { // UMD
                    datos = {
                        idEstablishment :  $("#updid").val(),
                        typeOffert : 1,
                        caso : 2, // Depende de Tipo de Oferta
                    }
                    break
                }
           }
           let token = sessionStorage.getItem('token')
           fetch(Const.urlrest + "api/wishlist/typeOffert", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "DELETE",
                body: JSON.stringify(datos)
            }).catch(error => alertaGeneral("Problemas de conexión (60)"))
        }

        const { items, idWhiteBrand, idAssociative, idWhiteBrand_b2c, idAssociative_b2c } = this.state;
      
        function validarPedidoDesde (tipoCliente,e,idWhiteBrand,idAssociative,idWhiteBrand_b2c, idAssociative_b2c) {
            switch (tipoCliente) {
                case "1": { // B2B
                    switch (e.target.value) {
                        case "0": {
                            if (idAssociative == 0) {
                                alertaGeneral("No existe un Marketplace Asociativo Asociado a la Unidad de Negocio")
                                e.target.value = "1"
                                $("#updattentionXpersonsAsocAll option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                        case "1": {
                            if (idWhiteBrand == 0) {
                                alertaGeneral("No existe un Marketplace Marca Blanca Asociado a la Unidad de Negocio")
                                e.target.value = "2"
                                $("#updattentionXpersonsAsocAll option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                        case "2": {
                            if (idAssociative == 0) {
                                alertaGeneral("No existe un Marketplace Asociativo Asociado a la Unidad de Negocio")
                                e.target.value = "1"
                                $("#updattentionXpersonsAsocAll option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                    }        
                    break
                }
                case "2": { // B2C
                    switch (e.target.value) {
                        case "0": {
                            if (idAssociative_b2c == 0) {
                                alertaGeneral("No existe un Marketplace Asociativo Asociado a la Unidad de Negocio")
                                e.target.value = "1"
                                $("#updattentionXpersonsAsocAll option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                        case "1": {
                            if (idWhiteBrand_b2c == 0) {
                                alertaGeneral("No existe un Marketplace Marca Blanca Asociado a la Unidad de Negocio")
                                e.target.value = "2"
                                $("#updattentionXpersonsAsocAll option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                        case "2": {
                            if (idAssociative_b2c == 0) {
                                alertaGeneral("No existe un Marketplace Asociativo Asociado a la Unidad de Negocio")
                                e.target.value = "1"
                                $("#updattentionXpersonsAsocAll option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                    }        
                    break
                }
            }
        }

        let permisos = []

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('25')
        let boolEdit = accessUser.includes('26') 
        let boolDelete = accessUser.includes('27')
        let boolCargar = accessUser.includes('52')

        return (
            <>
                <Headerdashboard />
                <Sidebar />
                <AlertGeneral />
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                          <h3 className="text-primary">Clientes</h3>{" "}
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./establishments">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEdit || boolDelete 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./establishmentslist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargar
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./establishmentscargar">Cargar</a></label>
                        :
                            null
                        }    
                    </div>
                    <div className="container-fluid formupdate" id="formupdate">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-title">
                                        <h4>Actualizar </h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="horizontal-form">
                                            <div className="form-horizontal" id="formcreate">
                                                <input type="hidden" className="form-control" placeholder="" name="name" id="updid" required="required" />
                                                <div className="row p-t-20">
                                                    <InputStatus renderizar = {this.state.renderizar} />
                                                    <InputTypeBusiness auxName = "idTypeBusiness" auxId = "updidTypeBusiness" mostrar = "3" disabled={false} parentCallback = {this.vacio}/>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className=" control-label">Tipo de Mercado</label>
                                                            <select
                                                                className="form-control" id="updtypeClient" name="typeClient"
                                                                onChange = {(e) => borrarTipoCliente(e)}
                                                            >
                                                                <option value="1">B2B</option>
                                                                <option value="2">B2C</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="contBlock">    
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">Identificación del Cliente</label>
                                                        <div className="separador"></div>
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label className=" control-label"> Nombre Cliente{" "}</label>
                                                                <input type="text" className="form-control" placeholder="" name="name" id="updname" required="required" max="90"/>
                                                                <label className="msglabel">Mínimo 4 caracteres</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label className=" control-label">
                                                                Dirección
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="address"
                                                                    id="updaddress"
                                                                    required="required"
                                                                />
                                                            </div>
                                                        </div>
                                                        <InputTypeDocument />
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Nro Documento{" "}</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="numberDocument"
                                                                    id="updnumberDocument"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">Nombre a facturar</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="name"
                                                                    id="updnameBilling"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                                <label className="msglabel">Mínimo 4 caracteres</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Teléfono</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="phone"
                                                                    id="updphone"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">Email para entrega de Facturación Electrónica</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="emailEnvoice"
                                                                    id="updemailEnvoice"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Código Interno</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="internalCode"
                                                                    id="updinternalCode"
                                                                    required="required"
                                                                    max="20"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row p-t-20">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">Nombre contacto{" "}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="nameContac"
                                                                    id="updnameContac"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                                <label className="msglabel">Mínimo 4 caracteres</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">Email usuario{" "}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="emailUser"
                                                                    id="updemailUser"
                                                                    required="required"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="contBlock">
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">Ubicación Georeferenciada Cliente</label>
                                                        <div className="separador"></div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Latitud</label>
                                                                <input
                                                                    type="text"
                                                                    min="0"
                                                                    step="0.01"
                                                                    className="form-control control-coo"
                                                                    name="latitud"
                                                                    placeholder="Latitud"
                                                                    id="updlat"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Longitud</label>
                                                                <input
                                                                    type="text"
                                                                    min="0"
                                                                    step="0.01"
                                                                    className="form-control control-coo"
                                                                    name="longitud"
                                                                    placeholder="Longitud"
                                                                    id="updlng"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="contBlock">
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">Calendario de visita comercial</label>
                                                        <div className="separador"></div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Lunes</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updvisitLunes"
                                                                    name="visitLunes"
                                                                >
                                                                    <option value="1">Todos</option>
                                                                    <option value="2">No programado</option>
                                                                    <option value="3">1er y 3do del mes</option>
                                                                    <option value="4">2er y 4to del mes</option>
                                                                    <option value="5">1ro del Mes</option>
                                                                    <option value="6">2ro del Mes</option>
                                                                    <option value="7">3ro del Mes</option>
                                                                    <option value="8">4ro del Mes</option>
                                                                    <option value="9">5ro del Mes</option>
                                                                    <option value="10">6to del Mes si no hay 5to</option>                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Martes</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updvisitMartes"
                                                                    name="visitMartes"
                                                                >
                                                                    <option value="1">Todos</option>
                                                                    <option value="2">No programado</option>
                                                                    <option value="3">1er y 2do del mes</option>
                                                                    <option value="4">3er y 4to del mes</option>
                                                                    <option value="5">1ro del Mes</option>
                                                                    <option value="6">2ro del Mes</option>
                                                                    <option value="7">3ro del Mes</option>
                                                                    <option value="8">4ro del Mes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Miércoles</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updvisitMiercoles"
                                                                    name="visitMiercoles"
                                                                >
                                                                    <option value="1">Todos</option>
                                                                    <option value="2">No programado</option>
                                                                    <option value="3">1er y 2do del mes</option>
                                                                    <option value="4">3er y 4to del mes</option>
                                                                    <option value="5">1ro del Mes</option>
                                                                    <option value="6">2ro del Mes</option>
                                                                    <option value="7">3ro del Mes</option>
                                                                    <option value="8">4ro del Mes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Jueves</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updvisitJueves"
                                                                    name="visitJueves"
                                                                >
                                                                    <option value="1">Todos</option>
                                                                    <option value="2">No programado</option>
                                                                    <option value="3">1er y 2do del mes</option>
                                                                    <option value="4">3er y 4to del mes</option>
                                                                    <option value="5">1ro del Mes</option>
                                                                    <option value="6">2ro del Mes</option>
                                                                    <option value="7">3ro del Mes</option>
                                                                    <option value="8">4ro del Mes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Viernes</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updvisitViernes"
                                                                    name="visitViernes"
                                                                >
                                                                    <option value="1">Todos</option>
                                                                    <option value="2">No programado</option>
                                                                    <option value="3">1er y 2do del mes</option>
                                                                    <option value="4">3er y 4to del mes</option>
                                                                    <option value="5">1ro del Mes</option>
                                                                    <option value="6">2ro del Mes</option>
                                                                    <option value="7">3ro del Mes</option>
                                                                    <option value="8">4ro del Mes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Sábado</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updvisitSabado"
                                                                    name="visitSabado"
                                                                >
                                                                    <option value="1">Todos</option>
                                                                    <option value="2">No programado</option>
                                                                    <option value="3">1er y 2do del mes</option>
                                                                    <option value="4">3er y 4to del mes</option>
                                                                    <option value="5">1ro del Mes</option>
                                                                    <option value="6">2ro del Mes</option>
                                                                    <option value="7">3ro del Mes</option>
                                                                    <option value="8">4ro del Mes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Domingo</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updvisitDomingo"
                                                                    name="visitDomingo"
                                                                >
                                                                    <option value="1">Todos</option>
                                                                    <option value="2">No programado</option>
                                                                    <option value="3">1er y 2do del mes</option>
                                                                    <option value="4">3er y 4to del mes</option>
                                                                    <option value="5">1ro del Mes</option>
                                                                    <option value="6">2ro del Mes</option>
                                                                    <option value="7">3ro del Mes</option>
                                                                    <option value="8">4ro del Mes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="contBlock">   
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">Características del Servicio</label>
                                                        <div className="separador"></div>
                                                        <InputComercial doesNotApply = 'false' auxName="idCommercial" auxId = "updidCommercial"/>
                                                        <InputPaymentMethods doesNotApply = 'false'/>
                                                        <InputRoutes doesNotApply = 'false' auxName = "idRoute" auxId="updidRoute"/>
                                                    </div>
                                                    <div className="row p-t-20">
                                                        <InputListPrices doesNotApply = 'false' mostrar='2' auxName = "nidListPrices" auxId = "updidListPrices" disabled={false} callback={this.getResponse}/>
                                                        
                                                        <div className="col-md-4 flagOnlyListPrice">
                                                            <div className="form-group">
                                                                <label className=" control-label">Mostrar solo los productos de esta lista</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updonlyListPrices"
                                                                    name="onlyListPrices"
                                                                >
                                                                    <option value="0">Si</option>
                                                                    <option value="1">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Pedidos desde</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="updattentionXpersonsAsocAll"
                                                                    name="attentionXpersonsAsocAll"
                                                                    onChange={(e) => validarPedidoDesde($("#updtypeClient").val(), e,idWhiteBrand,idAssociative,idWhiteBrand_b2c, idAssociative_b2c)}
                                                                >
                                                                    <option value="0">Todas</option>
                                                                    <option value="1">Marca Blanca</option>
                                                                    <option value="2">Asociativa</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Tipo de Despacho</label>
                                                                <select
                                                                    name="typeShipping"
                                                                    className="form-control"
                                                                    id="updtypeShipping"
                                                                >
                                                                    <option value="1">Domicilio</option>
                                                                    <option value="2">Pido Y Recogo</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <TypeFreight auxName = "typeTaxes" auxId = "updtypeFreight" />
                                                        <div className="col-md-2 varFijoflete">  
                                                            <div className="form-group">
                                                                <label className=" control-label">Valor Fijo Flete ($)</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="pricesFreigh"
                                                                    id="updpricesFreigh"
                                                                    required="required"
                                                                    max="60"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 porfleteVar">   
                                                            <div className="form-group">
                                                                <label className=" control-label">Porcentaje Flete Variable</label>
                                                                <input
                                                                    type="number"
                                                                    step="0.1" 
                                                                    className="form-control"
                                                                    placeholder="00.0"
                                                                    name="percentageFreightVar"
                                                                    id="updpercentageFreightVar"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>    
                                                </div>    
                                                <div className="contBlock">   
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">Condiciones de la Oferta</label>
                                                        <div className="separador"></div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Unidad de Despacho</label>
                                                                <select
                                                                    name="officeUnit"
                                                                    className="form-control"
                                                                    id="updofficeUnit"
                                                                    onChange = {(e) => borrarUnidadDespacho(e)}
                                                                >   
                                                                    <option value="0">Todas</option>
                                                                    <option value="1">Unidades</option>
                                                                    <option value="2">UMD</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Valor Ticket No Cobrar Flete ($)</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="ticketNoFreigh"
                                                                    id="updticketNoFreigh"
                                                                    required="required"
                                                                    max="60"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3.5">
                                                            <div className="form-group">
                                                                <label className=" control-label">Pedido Minimo Total Cliente Incl IVA($){" "}</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="orderMinAllTax"
                                                                    id="updorderMinAllTax"
                                                                    required="required"
                                                                    max="90"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 _typeDisperse_ctoTAT">
                                                            <div className="form-group">
                                                                <label className=" control-label">Dispersión Cto Venta TAT($)</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="paramAlgoritDisperseCtoTat"
                                                                    id="updparamAlgoritDisperseCtoTat"
                                                                    required="required"
                                                                    max="10"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 _typeDisperse_percentage">
                                                            <div className="form-group">
                                                                <label className=" control-label">Dispersión % Fijo</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="paramAlgoritDispersePercentage"
                                                                    id="updparamAlgoritDispersePercentage"
                                                                    required="required"
                                                                    max="3"
                                                                    step="0.1"
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>    
                                                <div className="contBlock">   
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">Comunicado</label>
                                                        <div className="separador"></div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    name="release"
                                                                    id="updrelease"
                                                                    max="250"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-offset-2 col-sm-10">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default btnupdate"
                                                            id="btnupdate"
                                                        >
                                                            Actualizar Clientes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive m-t-40">
                                            <MaterialTable
                                                title="Datos Clientes"
                                                columns={[
                                                    { title: "Id", field: "id", width: 10 },
                                                    { title: 'Estado', field: 'status', lookup: { 1: 'Activo', 2: 'Inactivo' },  width: 15 },    
                                                    { title: 'Tipo', field: 'typeClient', lookup: { 1: 'B2B', 2: 'B2C' },  width: 10 },    
                                                    { title: "Cod Int", field: "internalCode", width: 15  },
                                                    { title: "Nombre", field: "name", width: 350 },
                                                    { title: "Oferta", field: "officeUnit", lookup: { 0: 'Todo', 1: 'Und', 2: 'UMD' },width: 15  },
                                                    { title: "Visible", field: "attentionXpersonsAsocAll", lookup: { 0: 'Todo', 1: 'Marca Blanca', 2: 'Asociativo' },width: 30  },
                                                ]}
                                                data={items}
                                                options={{
                                                    // exportButton: true,
                                                    headerStyle: {
                                                    backgroundColor: "#01579b",
                                                    color: "#FFF",
                                                  },
                                                  actionsColumnIndex: -1,
                                                  filtering: true,
                                                  search: true
                                                }}
                                                actions={[
                                                  {
                                                    icon: "edit",
                                                    hidden: !boolEdit,
                                                    iconProps: { style: { color: "#00569b" } },
                                                    tooltip: "Save User",
                                                    onClick: (event, rowData) => formEdit(rowData.id),
                                                  },
                                                  (rowData) => ({
                                                    icon: "delete",
                                                    hidden: !boolDelete,
                                                    iconProps: { style: { color: "#ff5722" } },
                                                    tooltip: "Delete User",
                                                    onClick: (event, rowData) =>
                                                      alertaConfirm(rowData.id),
                                                  }),
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default establishmentsList;