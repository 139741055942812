import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import $ from 'jquery';
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class xlsxLoadPrice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items : []
        }
    }    

    componentDidMount() {          
        const idCedi = sessionStorage.getItem("idCedi")
        const datos = {idCedi : idCedi, mostrar:1}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/listpricescustom",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success)) {
                    let option = ''
                    let items = result.data
                    items.forEach( function(valor, indice, array) {
                        option +="<option value='"+valor['id']+"' nameList = '"+valor['name']+"'  >"+valor['name']+" </option>";                          
                    })              
                    document.getElementById("cedilist").innerHTML = option;
                    $(".conselectorcedi").show()
                    const indice = select.selectedIndex;
                    const opcionSeleccionada = select.options[indice];
                    $("#updNameList").val(opcionSeleccionada.text)    
                } else 
                    closeSession()
            },
        ).catch(error => alertaGeneral("Problemas de Conexión (103)"))

        $("#creidCedi").val(idCedi)

        const opcionCambiada = () => {
            const indice = select.selectedIndex;
            const opcionSeleccionada = select.options[indice];
            $("#updNameList").val(opcionSeleccionada.text)
        };

        const select = document.querySelector("#cedilist");
        select.addEventListener("change", opcionCambiada);
        
        $(document).off().on('click', '.btnupdate', function () {
            let cediOption = $("#cedilist option:selected")
            let titleList = $(cediOption).attr("nameList")
            let fileList = $('#updimg').val()
            let varEntra = false

            if (fileList.indexOf("Lista de Precios B2C",0) >= 0) 
                varEntra = true
            else 
                if (fileList.indexOf("Lista de Precios B2B",0) >= 0) 
                    varEntra = true
                else 
                    if (fileList.indexOf(titleList,0) >= 0) 
                        varEntra = true
            if (!varEntra)
            { 
                alertaGeneral("El archivo seleccionado debe empezar por el nombre de la Lista") 
            } else {
                $("#updcedi").val(idCedi)
                const data = new FormData(document.getElementById('formularioupdate'));
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/uploadlistprice/update", {    // ok
                        method: "PUT",
                        body: data,
                        headers: { 
                            'Accept': 'application/json',
                            /* 'Content-Type': 'application/json', */
                            'Authorization': token,
                        },
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        /* if (endSession(result.success)) { */
                            alertaGeneral("Lista de Precios Actualizada");
                            window.location.reload(false)   
                        /* } else 
                            closeSession() */
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (110)"))
            }
        })


    }  

    render(){

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCargarPrecios = accessUser.includes('34')
        let boolDescargarPlantilla = accessUser.includes('35') 
        let boolDescargarPedidos = accessUser.includes('36')
        
        return (          
            <React.Fragment>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral/> 
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-2 align-self-center">
                                <h3 className="text-primary">IO Excel</h3> 
                        </div>
                        { 
                            boolCargarPrecios
                        ? 
                            <label style = {{paddingLeft:20, fontSize:22}} id="ModUsuarioFdl"><a href="./uploadListPrice">Cargar Precios</a></label>
                        :
                            null
                        }
                        {
                            boolDescargarPlantilla
                        ?
                            <label style = {{paddingLeft:40, fontSize:22}} id="ModUsuarioFdc"><a href="./downloadTemplate">Descargar Formatos</a></label>
                        :
                            null
                        }
                        {
                            boolDescargarPedidos
                        ?
                            <label style = {{paddingLeft:40, fontSize:22}} id="ModUsuarioFdc"><a href="./orders">Descargar Pedidos</a></label>
                        :
                            null
                        }
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title"> </h4>   
                                        <form id="formularioupdate">
                                        <input type="hidden" className="form-control" placeholder="" name="nameList" id="updNameList" required="required"   /> 
                                            <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updcedi" required="required"   />
                                            <div className="row p-t-20">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <h3>Cargar Lista de Precios</h3>
                                                        <br></br>
                                                        <h5>Indique que Lista de precios desea cargar</h5>
                                                        <div className="conselectorcedi MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">                                       
                                                            <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">                                           
                                                                <select name="cedilist" id="cedilist"  className="MuiInputBase-input MuiInput-input" >
                                                                    <option value="" >Cedi</option>
                                                                </select>
                                                            </div>
                                                        </div>              
                                                        <h5>Seleccione por favor el archivo xlsx a cargar, el nombre debe empezar con el nombre de la lista seleccionada</h5>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <input type="file" style = {{color: "transparent", border: "none"}} className="form-control" name="img" id="updimg" required="required" accept=".xlsx" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-offset-2 col-sm-10">
                                                <button type="button" className="btn btn-default btnupdate">Cargar</button>
                                            </div>  
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>          
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default xlsxLoadPrice;