import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class inputCategory extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidMount(){
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/status",{
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then((result) => {
            if (endSession(result.success))
                this.setState({ items: result.data })
            else 
                closeSession()
        }).catch(error => alertaGeneral("Problemas de Conexión (22)"))
    }

    render(){

        const { items } = this.state;

        return  (
            <React.Fragment>                
                <div className="col-md-2">
                    <div className="form-group">
                        <label className=" control-label">Estado</label>
                        <select className="form-control" id="updstatus" name="status" >      
                            {items.map(item => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))}                                          
                        </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputCategory