import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import CreateData from './createData'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class listpricescustom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }
   componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi")
        const datos = {idCedi : idCedi, mostrar:1}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/listpricescustom",{
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (96)"))        
    }
  
    render(){
        return (          
                <div>
                    <Headerdashboard/>
                    <Sidebar />  
                    <div className="page-wrapper">
                        <div className="row page-titles">
                            <div className="col-md-5 align-self-center">
                                <h3 className="text-primary">Subir lista de precios *</h3> </div>
                            <div className="col-md-7 align-self-center">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#nogp">Home</a></li>
                                    <li className="breadcrumb-item active">Listar </li>
                                </ol>
                            </div>
                        </div>
                        <CreateData />  
                    </div>
                </div>
        )
    }
}

export default listpricescustom;