import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class InputListPrices extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidMount  = async()=>{
        let idCedi = sessionStorage.getItem("idCedi")
        let mostrar = parseInt(this.props.mostrar)
        const datos = {idCedi : idCedi, mostrar: mostrar }
        let token = sessionStorage.getItem('token')
        await fetch(Const.urlrest + "api/listpricescustom",{ // ok
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then((result) => {
            if (endSession(result.success))
                this.setState({ items: result.data })
            else 
                closeSession()
         }).catch(error => alertaGeneral("Problemas de Conexión (10)"))
    }


    render(){
    
        const doesNotApply = this.props.doesNotApply
        const mostrar = this.props.mostrar
        const auxName = this.props.auxName
        const auxId = this.props.auxId
    
        return  (
            <React.Fragment>                
                <div className="col-md-7">
                    <div className="form-group">
                        <label className=" control-label">Lista de precios</label>
                        <select  className="form-control" id = {auxId} name = {auxName} disabled = {this.props.disabled} 
                                 onChange = {(e) => this.props.callback(e)}> 
                            { 
                                (doesNotApply == 'true')
                            ? 
                                <option key={-1} value={0} >Todos</option>
                            : 
                                null
                            } 
                            {this.state.items.map(item => (
                                <>
                                    {
                                        (mostrar != "1")
                                    ?
                                        <option key={item.id} value={item.id} >{item.name}</option>
                                    :
                                        <>
                                            {
                                                (item.name != "Lista de Precios B2B") && (item.name != "Lista de Precios B2C")
                                            ?
                                                <option key={item.id} value={item.id} >{item.name}</option>
                                            :
                                                null
                                            } 
                                        </>  
                                    }    
                                </>
                            ))}                                          
                        </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default InputListPrices