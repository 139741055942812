import React, {Component} from 'react'
import InputProductoDetailPrice from '../atoms/InputProductoDetailPrice'
import Inputlistprices from '../atoms/InputListPrices'

class createData extends Component{    

    componentDidMount() {
        document.querySelector('.itemsumd').style.display = 'none'
        document.querySelector('.itemsund').style.display = 'none'
    }

    vacio = (vacio) => {return true}

    render(){

        return  (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h4>Crear Detalle de Precios</h4>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <input type="hidden" className="form-control" placeholder="" name="idCategory" id="updidCategory" required="required" />
                                        <div className="form-horizontal" id="formcreate" >
                                            <div className="row p-t-20">
                                                <InputProductoDetailPrice doesNotApply = 'true' />
                                                <Inputlistprices doesNotApply = 'false' mostrar = '1' auxName = "nidListPrices" auxId = "updidListPrices" disabled={false} callback={this.vacio}/>
                                            </div>
                                            
                                            <div className="contBlock itemsund">
                                                <div className="row p-t-20">
                                                    <label className=" col-md-12 control-label bold">Características de los productos "Und"</label>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className=" control-label">Precio Unidad con impuestos</label>
                                                            <input type="number" className="form-control" placeholder="" name="name" id="crepriceTax" required="required" value={0} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        < div className = "form-group" > 
                                                            <label className=" control-label">Orden mínima Unidades </label>
                                                            <input type="number" className="form-control" placeholder="" name="name" id="creorderMinCant" required="required" value={0} />
                                                        </div>                                                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contBlock itemsumd" >
                                                <div className="row p-t-20 ">
                                                    <label className=" col-md-12 control-label bold">Características de los productos "UMD"</label>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Valor Unidad UMD</label>
                                                            <input type="number" className="form-control" placeholder="" name="updlink" id="creumdTax" required="required"  max="30" value={0}/>
                                                        </div>                                               
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className=" control-label">Precio Unitario UMD con impuestos</label>
                                                            <input type="number" className="form-control" placeholder="" name="updlink" id="crevalUnidUmd" required="required"  max="30" value={0}/>
                                                        </div>                                               
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className=" control-label">Orden mínima UMD </label>
                                                            <input type="number" className="form-control" placeholder="" name="priceUmdTax" id="creorderMinUmd" required="required"  max="30" value={0}/>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-offset-2 col-sm-10">
                    <button type="button" className="btn btn-default btnadd">Ingresar</button>
                </div>             
            </React.Fragment>          
        )
    }   
}

export default createData