import React, {Component} from 'react'
import {alertaGeneral/* ,closeAlertGeneral,alertaConfirm */} from '../../assets/js/GeneralScript'
import InputMarketplaceAso from '../atoms/InputMarketplaceAso'
import InputRoutes from '../atoms/InputRoutes'
import InputListPrices from '../atoms/InputListPrices'
import InputComercial from '../atoms/InputComercial'
import InputTypeBusiness from '../atoms/InputTypeBusiness'
import TypeFreight from '../atoms/InputTaxes'
import $ from 'jquery'

class updateData extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {        

        const input_b2b = document.querySelector('#updtypeDisperse_b2b');
        input_b2b.addEventListener('change', updateValue_b2b);     

        const valorPorcentualFlete_b2b = document.querySelector('#updvpValorPorcentualFlete_b2b');
        valorPorcentualFlete_b2b.addEventListener('change', updatevalorPorcentualFlete_b2b);

        const valorPorcentualFlete_b2c = document.querySelector('#updvpValorPorcentualFlete_b2c');
        valorPorcentualFlete_b2c.addEventListener('change', updatevalorPorcentualFlete_b2c);

        function updatevalorPorcentualFlete_b2b(e) {
            if (e.target.value > 100) {
                $("#updvpValorPorcentualFlete_b2b").val(0) 
                alertaGeneral("El Porcentage no puede ser mayor a 100")
            }
        }

        function updatevalorPorcentualFlete_b2c(e) {
            if (e.target.value > 100) {
                $("#updvpValorPorcentualFlete_b2c").val(0) 
                alertaGeneral("El Porcentage no puede ser mayor a 100")
            }
        }

        function updateValue_b2b(e) {
            switch (e.target.value) {               
                case '10': {
                    document.querySelector('._valuePlatformBaseOrder_b2b').style.display = 'none'
                    document.querySelector('._valuePlatformBaseRef_b2b').style.display = 'none'
                    document.querySelector('._percentageFreeDisperse_b2b').style.display = 'none'
                    document.querySelector('._percentageOrdenMin_b2b').style.display = 'none'
                    document.querySelector('._percentageLimitSalesHigher_b2b').style.display = 'none'
                    document.querySelector('._paramAlgoritDisperseCtoTat_b2b').style.display = 'none'
                    document.querySelector('._paramAlgoritDispersePercentage_b2b').style.display = 'block'
                    break
                }    
                case '20': {
                    document.querySelector('._valuePlatformBaseOrder_b2b').style.display = 'block'
                    document.querySelector('._valuePlatformBaseRef_b2b').style.display = 'block'
                    document.querySelector('._percentageFreeDisperse_b2b').style.display = 'block'
                    document.querySelector('._percentageOrdenMin_b2b').style.display = 'block'
                    document.querySelector('._percentageLimitSalesHigher_b2b').style.display = 'block'
                    document.querySelector('._paramAlgoritDisperseCtoTat_b2b').style.display = 'block'
                    document.querySelector('._paramAlgoritDispersePercentage_b2b').style.display = 'none'
                    break
                }    
                case '30': {
                    document.querySelector('._valuePlatformBaseOrder_b2b').style.display = 'none'
                    document.querySelector('._valuePlatformBaseRef_b2b').style.display = 'none'
                    document.querySelector('._percentageFreeDisperse_b2b').style.display = 'none'
                    document.querySelector('._percentageOrdenMin_b2b').style.display = 'none'
                    document.querySelector('._percentageLimitSalesHigher_b2b').style.display = 'none'
                    document.querySelector('._paramAlgoritDisperseCtoTat_b2b').style.display = 'none'
                    document.querySelector('._paramAlgoritDispersePercentage_b2b').style.display = 'none'
                    break
                }
            }
        }

        const input_b2c = document.querySelector('#updtypeDisperse_b2c');
        input_b2c.addEventListener('change', updateValue_b2c);

        function updateValue_b2c(e) {
            switch (e.target.value) {                   
                case '1000': {
                    document.querySelector('._valuePlatformBaseOrder_b2c').style.display = 'none'
                    document.querySelector('._valuePlatformBaseRef_b2c').style.display = 'none'
                    document.querySelector('._paramAlgoritDisperseCtoTat_b2c').style.display = 'none'
                    document.querySelector('._paramAlgoritDispersePercentage_b2c').style.display = 'block'
                    break
                }    
                case '2000': {
                    document.querySelector('._valuePlatformBaseOrder_b2c').style.display = 'block'
                    document.querySelector('._valuePlatformBaseRef_b2c').style.display = 'block'
                    document.querySelector('._paramAlgoritDisperseCtoTat_b2c').style.display = 'none'
                    document.querySelector('._paramAlgoritDispersePercentage_b2c').style.display = 'none'
                    break
                }    
                case '3000': {
                    document.querySelector('._valuePlatformBaseOrder_b2c').style.display = 'none'
                    document.querySelector('._valuePlatformBaseRef_b2c').style.display = 'none'
                    document.querySelector('._paramAlgoritDisperseCtoTat_b2c').style.display = 'none'
                    document.querySelector('._paramAlgoritDispersePercentage_b2c').style.display = 'none'
                    break
                }
            }
        }
    }

    maxCheck = (object) => {
        if (object.target.value.length > object.target.max) {
          object.target.value = object.target.value.slice(0, object.target.max)
        }
    }

    vacio = (vacio) => {return true}


    render(){   

        function validOnChange(filex) {
            let uploadFile = filex[0]
            let output = document.getElementById('imgshow');
            output.src = URL.createObjectURL(filex[0]);
            output.onload = function() {
                if ((output.naturalWidth.toFixed(0) > 375) || (output.naturalHeight.toFixed(0) > 145)) {
                    document.getElementById("imgshow").src = ''
                    sessionStorage.setItem("registerValid","false")
                    alertaGeneral("La Imagen seleccionada no cumple con la medida promedio de 375 x 145 pixels")
                } else
                    sessionStorage.setItem("registerValid","true")
                URL.revokeObjectURL(output.src) // free memory
            }
        }

        function validarEstado(e,mercado) {
            switch (mercado) {
                case 'B2B' : {
                    switch(e.target.value) {
                        case "1": { // Marca Blanca
                            if ($("#updidMarketplace").val() == 0) {
                                alertaGeneral("No existe un Marketplace Marca Blanca Asociado a la Unidad de Negocio")
                                e.target.value = "2"
                                $("#updidMarketplace option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                        case "2": { // Asociativo
                            if ($("#updidMarketplaceASo").val() == 0) {
                                alertaGeneral("No existe un Marketplace Asociativo Asociado a la Unidad de Negocio")
                                e.target.value = "1"
                                $("#updidMarketplaceASo option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                    }
                    break
                }
                case 'B2C' : {
                    switch(e.target.value) {
                        case "1": {
                            if ($("#updidMarketplace_b2c").val() == 0) {
                                alertaGeneral("No existe un Marketplace Marca Blanca Asociado a la Unidad de Negocio")
                                e.target.value = "2"
                                $("#updidMarketplace_b2c option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                        case "2": {
                            if ($("#updidMarketplaceASo_b2c").val() == 0) {
                                alertaGeneral("No existe un Marketplace Asociativo Asociado a la Unidad de Negocio")
                                e.target.value = "1"
                                $("#updidMarketplaceASo_b2c option[value=" + e.target.value + "] ").attr('selected', 'selected');
                            }
                            break
                        }
                    }
                    break
                }

            }
        }

        return  (
            <React.Fragment>                   
                <div className="container-fluid formupdate" id="formupdate">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h3>Configuración: Datos Unidad de Negocio</h3>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <div className="form-horizontal" id="formcreate" >
                                                <input type="hidden" name="idMarketplace" id="updidMarketplace" />
                                                <input type="hidden" name="idMarketplaceASo" id="updidMarketplaceASo" />
                                                <input type="hidden" name="idMarketplace_b2c" id="updidMarketplace_b2c" />
                                                <input type="hidden" name="idMarketplaceASo_b2c" id="updidMarketplaceASo_b2c" />
                                            <form id="formulario2">
                                                <input type="hidden" className="form-control" placeholder="" name="id" id="updid" required="required"   /> 
                                                <input type="hidden" className="form-control" placeholder=""  id="updOldImg" required="required" name="oldImg"  max="30" />
                                                <input type="hidden" name="coordinates" id="updcoordinates" />
                                                <input type="hidden" name="socialRef" id="updsocialRef"  />
                                                <div className="row p-t-20">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className=" control-label">Estado del Cedi</label>                                                        
                                                            <select className="form-control" name="actCedi" id="updactCedi">      
                                                                <option value="1" >Activo</option>   
                                                                <option value="0" >Inactivo</option>                                                                        
                                                            </select>   
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className=" control-label">Nombre Administrador</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="nameAdmin" id="updnameAdmin" required="required" max="60"  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className=" control-label">Email Administrador </label>
                                                            <input type="email" className="form-control" placeholder="" name="emailAdmin" id="updemailAdmin" required="required"  max="45" />
                                                            <label className="labelEmailAdmin labelEmail"></label>
                                                        </div>  
                                                    </div>
                                                </div>
                                                <div className="row p-t-20">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className=" control-label">Teléfono Administrador</label>                                                        
                                                            <input type="number" className="form-control" placeholder="" name="phoneAdmin" id="updphoneAdmin" required="required" max="13" onInput={this.maxCheck}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2.5">
                                                        <div className="form-group">
                                                            <label className=" control-label">Password Administrador</label>
                                                            <input type="password" className="form-control" placeholder="******"
                                                                max ="90" name="password" id="updpassword" required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className=" control-label">Saldo Notificaciones</label>
                                                            <input type="number" className="form-control" placeholder=""
                                                                id="updbalanceNotifications" required="required" name="balanceNotifications"
                                                                disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className=" control-label">Método de entrega del pedido</label>
                                                            <select className="form-control" name = "methodEmail" id="updmethodEmail">      
                                                                <option value="1" >Email</option>   
                                                                <option value="2" >Webservices</option>                                                                        
                                                            </select>   
                                                    </div>                                                    
                                                    {/* <InputMarketplaceAso sectors = { sectors } />   */}
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                        <label className=" control-label">Email servicio al Cliente</label>
                                                        <input type="email" className="form-control" placeholder="" name="emailServiceClient" id="updemailServiceClient" required="required" max="45" />
                                                        <label className="labelEmailClient labelEmail"></label>
                                                        </div>
                                                    </div> 
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className=" control-label">Nombre del Cedi</label>
                                                            <input type="text" className="form-control" placeholder="" name="name" id="updname" required="required"  max="60" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Descripción Cedi</label>                                                        
                                                            {/* <input type="text" className="form-control" placeholder="" name="description"  id="upddescription" required="required"   /> */}
                                                            <textarea name="description" id="upddescription" max="750"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label className=" control-label">Slogan</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="slogan"  id="updslogan" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Logo</label>     
                                                            <img src="" style={{ marginLeft:10, }} id="imgshow" height="50" />                                                                                                           
                                                            <input type="file"  className="form-control" placeholder="" name="img" 
                                                                id="updimg" required="required" accept="image/png" style={{ width:174, borderColor: "#FFFFFF", backgroundColor: "#FFFFFF" }}
                                                                onChange ={(e) => validOnChange(e.target.files,"imgshow","updimg")} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Términos y condiciones</label>
                                                            <textarea name="textTyc" id="updtextTyc"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Política de datos </label>
                                                            <textarea name="textPd" id="updtextPd"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Autorización Tratamiento de datos </label>
                                                            <textarea name="textAutoriza" id="updtextAutoriza"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="contBlock">                                                                    
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">Zona de atención del CEDI</label>
                                                        <div className="separador"></div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label"> Latitud </label>
                                                                <div className="clr"></div>
                                                                <input type="text" step="0.01" className="form-control control-coo" placeholder="Latitud" name="latitud" id="updlat" required="required"  max="30" />                                                        
                                                            </div>
                                                        </div>  
                                                        <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label"> Longitud </label>
                                                            <div className="clr"></div>
                                                            <input type="text" step="0.01"className="form-control control-coo" placeholder="Longitud" name="longitud" id="updlng" required="required"  max="30" />                                                            
                                                        </div>
                                                        </div> 
                                                        <div className="col-md-4">
                                                        <div className="form-group">  
                                                            <label className=" control-label"> Radio /km </label>
                                                            <div className="clr"></div>
                                                            <input type="text" step="0.01" className="form-control  control-radio" placeholder="Radio" name="radio" id="updradio" required="required"  max="30" />                                                        
                                                        </div>
                                                        </div>                                                
                                                    </div>
                                                </div>
                                                <div className="contBlock">
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <label className=" control-label bold">Redes sociales </label>
                                                            <div className="form-group">                                                                    
                                                                <div className="groupInputSocial">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Facebook" name="facebook"  id="facebook"  max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Youtube"  name="youtube"  id="youtube"   max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Instagram" name="instagram"  id="instagram" max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Twitter"  name="twitter"  id="twitter"   max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Linkedin"  name ="linkedin" id="linkedin"  max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url WhatsApp"  name="whatsapp"  id="whatsapp"  max="120" />     
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                {/* ******************* Dispersión Plataforma  B2B ******************************************* */}
                                                <div>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">
                                                                Modelo de Dispersión para el Cliente (B2B)
                                                            </label>
                                                            <div className="separador"></div>
                                                            <div style = {{marginBottom:5}}className="col-md-12">
                                                                <label className=" control-label">Tipo de Dispersión</label>
                                                                <select className="form-control" name = "typeDisperse_b2b" id="updtypeDisperse_b2b">      
                                                                    <option value="10" >Beneficio - % del SubTotal de la Orden de Pedido</option>   
                                                                    <option value="20" >Beneficio - % de dispersión para pedido mínimo e incremento % para compras mayores según % libre con limite</option>
                                                                    <option value="30" >Sin Dispersión</option>
                                                                </select>   
                                                            </div>      

                                                            <div className="col-md-2 _valuePlatformBaseOrder_b2b">
                                                                <div className="form-group"> 
                                                                    <label className=" control-label"> Valor Plataforma: Base Pedido ($)</label>
                                                                    <input type="number" className="form-control" placeholder="0" name="valuePlatformBaseOrder_b2b"  id="updvaluePlatformBaseOrder_b2b"  max="8" />
                                                                </div>  
                                                            </div>
                                                            <div className="col-md-2 _valuePlatformBaseRef_b2b">
                                                                <div className="form-group"> 
                                                                    <label className=" control-label"> Valor Plataforma: Base Referencia ($)</label>
                                                                    <input type="number" className="form-control" placeholder="0" name="valuePlatformBaseRef_b2b"  id="updvaluePlatformBaseRef_b2b"  max="8" />
                                                                </div>  
                                                            </div>
                                                            <div className="col-md-2 _percentageFreeDisperse_b2b">
                                                                <div className="form-group"> 
                                                                    <label className=" control-label"> Porcentaje libre a dispersar (%)</label>
                                                                    <input type="number" step="0.1" className="form-control" placeholder="00.0" name="percentageFreeDisperse_b2b"  id="updpercentageFreeDisperse_b2b"  required="required" max="8" />
                                                                </div>  
                                                            </div> 
                                                            <div className="col-md-2 _percentageOrdenMin_b2b">
                                                                <div className="form-group"> 
                                                                    <label className=" control-label"> Porcentaje a dispersar pedido Mínimo (%)</label>
                                                                    <input type="number" step="0.1" className="form-control" placeholder="00.0" name="percentageOrdenMin_b2b"  id="updpercentageOrdenMin_b2b"  max="4" />
                                                                </div>  
                                                            </div> 
                                                            <div className="col-md-2 _percentageLimitSalesHigher_b2b">
                                                                <div className="form-group"> 
                                                                    <label className=" control-label"> Limite % Libre a Dispersar</label>
                                                                    <input type="number" step="0.1" className="form-control" placeholder="00.0" name="percentageLimitSalesHigher_b2b"  id="updpercentageLimitSalesHigher_b2b"  max="8" />
                                                                </div>  
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ******************* Dispersión Plataforma  B2C ******************************************* */}
                                                <div>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">
                                                                Modelo de Dispersión para el Cliente (B2C)
                                                            </label>
                                                            <div className="separador"></div>
                                                            <div style = {{marginBottom:5}}className="col-md-12">
                                                                <label className=" control-label">Tipo de Dispersión</label>
                                                                <select className="form-control" name = "typeDisperse_b2c" id="updtypeDisperse_b2c">      
                                                                    <option value="1000" >Cobro - % del SubTotal de la Orden de Pedido</option>
                                                                    <option value="2000" >Cobro - Costo de uso de la plataforma según valores Base y Numero de referencias</option>
                                                                    <option value="3000" >Sin Dispersión</option>
                                                                </select>   
                                                            </div>      

                                                            <div className="col-md-2 _valuePlatformBaseOrder_b2c">
                                                                <div className="form-group"> 
                                                                    <label className=" control-label"> Valor Plataforma: Base Pedido ($)</label>
                                                                    <input type="number" className="form-control" placeholder="0" name="valuePlatformBaseOrder_b2c"  id="updvaluePlatformBaseOrder_b2c"  max="8" />
                                                                </div>  
                                                            </div>
                                                            <div className="col-md-2 _valuePlatformBaseRef_b2c">
                                                                <div className="form-group"> 
                                                                    <label className=" control-label"> Valor Plataforma: Base Referencia ($)</label>
                                                                    <input type="number" className="form-control" placeholder="0" name="valuePlatformBaseRef_b2c"  id="updvaluePlatformBaseRef_b2c"  max="8" />
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>       
                                            {/* *************** Vitrina B2B *********************************************** */}
                                            <div >
                                                <div className="contBlock">
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">
                                                            Vitrina Pública Business to Business (B2B)
                                                        </label>
                                                        <div className="separador"></div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Estado Vitrina</label>                                                        
                                                                <select className="form-control" name="vitrinaB2bStatus" id="updvitrinaB2bStatus" >      
                                                                    <option value="1" >Activo</option>   
                                                                    <option value="0" >Inactivo</option>                                                                        
                                                                </select>   
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group"> 
                                                                <label className=" control-label"> Pedido Mínimo ($)</label>
                                                                <input type="number" className="form-control" placeholder="0" name="vpPedidoMinimo_b2b"  id="updvpPedidoMinimo_b2b"  max="8" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Tipo de Flete</label>
                                                                <select name="typeFreight_b2b" className="form-control" id="updtypeFreight_b2b">
                                                                    <option value="1">Valor Fijo</option>
                                                                    <option value="2">% del Pedido</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* ***************** */}                                                                                                        
                                                        <div className="col-md-2">
                                                            <div className="form-group"> 
                                                                <label className="control-label">% Flete Variable</label>
                                                                <input type="number" className="form-control" placeholder="" name="vpValorPorcentualFlete_b2b" id="updvpValorPorcentualFlete_b2b" 
                                                                required="required" min="0" max="100" step="0.1" />
                                                            </div>    
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group"> 
                                                                <label className="control-label">Valor Fijo Flete ($)</label>
                                                                <input type="number" className="form-control" placeholder="0" name="vpValorFlete_b2b" id="updvpValorFlete_b2b" required="required" min="0" />
                                                            </div>    
                                                        </div> 
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Métodos de Pago</label>
                                                                <select name="paymentmethods_b2b" className="form-control" id="updpaymentmethods_b2b">
                                                                    <option value="1">Efectivo</option>
                                                                    <option value="2">Credito</option>
                                                                    <option value="3">Datáfono</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <InputRoutes doesNotApply = 'false' auxName = "idRoute_b2b" auxId="updidRoute_b2b"/>  
                                                        
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Unidad de Despacho</label>
                                                                <select name="vpTipoOferta_b2b" className="form-control" id="updvpTipoOferta_b2b">
                                                                    <option value="0">Todas</option>
                                                                    <option value="1">Unidades</option>
                                                                    <option value="2">UMD</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group"> 
                                                                <label className=" control-label"> Compra Mín Flete $0</label>
                                                                <input type="number" className="form-control" placeholder="0"  name="vpCompraValorFleteCero_b2b"  id="updvpCompraValorFleteCero_b2b"  max="9" />     
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Pedidos desde</label>
                                                                <select className="form-control" id="updvpCediVisibleEn_b2b" name="vpCediVisibleEn_b2b">
                                                                    <option value="0">Todas</option>
                                                                    <option value="1">Marca Propia</option>
                                                                    <option value="2">Asociativa</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Tipo de Despacho</label>
                                                                <select name="vpTipoDespacho_b2b" className="form-control" id="updvpTipoDespacho_b2b">
                                                                    <option value="1">Domicilio</option>
                                                                    <option value="2">Pide y Recoge</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <InputListPrices doesNotApply = 'false' mostrar='3' auxName = "nidListPrices_b2b" auxId = "updidListPrices_b2b" disabled={false} callback = {this.vacio}/>
                                                        <InputComercial doesNotApply = 'false' auxName="idCommercial_b2b" auxId = "updidCommercial_b2b"/>
                                                        <label className="col-md-12 control-label">Comunicado</label>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    name="release_b2b"
                                                                    id="updrelease_b2b"
                                                                    max="250"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 _paramAlgoritDisperseCtoTat_b2b">
                                                            <div className="form-group ">
                                                                <label className=" control-label">Cto Venta TAT($)</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="paramAlgoritDisperseCtoTat_b2b"
                                                                    id="updparamAlgoritDisperseCtoTat_b2b"
                                                                    required="required"
                                                                    max="4"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 _paramAlgoritDispersePercentage_b2b">
                                                            <div className="form-group">
                                                                <label className=" control-label">Dispersión % Fijo</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0."
                                                                    name="paramAlgoritDispersePercentage_b2b"
                                                                    id="updparamAlgoritDispersePercentage_b2b"
                                                                    required="required"
                                                                    max="3"
                                                                    step="0.1"
                                                                />
                                                            </div>
                                                        </div>
                                                        <InputTypeBusiness auxName ="idTypeBusiness_b2b" auxId = "updidTypeBusiness_b2b" mostrar="2" disabled={false} parentCallback = {this.vacio}/>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Marketplace asignado al Pedido TAT</label>
                                                                <select className="form-control" id="updselectTatVitrinaB2b" name="selectTatVitrinaB2b" onChange={(e)=>validarEstado(e,"B2B")}>
                                                                        <option value="1">Marca Blanca</option>                 
                                                                        <option value="2">Asociativa</option> 
                                                                </select>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ******************* Vitrina B2C ******************************************* */}
                                            <div /* id = "vitrinaB2C" */>
                                                <div className="contBlock">
                                                    <div className="row p-t-20">
                                                        <label className="col-md-6 control-label bold">
                                                            Vitrina Pública Business to Customer (B2C)
                                                        </label>
                                                        <div className="separador"></div>
                                                        {/* *****************************+ */}
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Estado Vitrina</label>                                                        
                                                                <select className="form-control" name="vitrinaB2cStatus" id="updvitrinaB2cStatus" >      
                                                                    <option value="1" >Activo</option>   
                                                                    <option value="0" >Inactivo</option>                                                                        
                                                                </select>   
                                                            </div>
                                                        </div>
                                                        {/* *****************************+ */}
                                                        <div className="col-md-2">
                                                            <div className="form-group"> 
                                                                <label className=" control-label"> Pedido Mínimo ($)</label>
                                                                <input type="number" className="form-control" placeholder="0" name="vpPedidoMinimo_b2c"  id="updvpPedidoMinimo_b2c"  max="8" />
                                                            </div>  
                                                        </div>
                                                        {/* ***************** */}
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Tipo de Flete</label>
                                                                <select name="typeFreight_b2c" className="form-control" id="updtypeFreight_b2c">
                                                                    <option value="1">Valor Fijo</option>
                                                                    <option value="2">% del Pedido</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* ***************** */}
                                                        <div className="col-md-2">
                                                            <div className="form-group"> 
                                                                <label className="control-label">% Flete Variable</label>
                                                                <input type="number" className="form-control" placeholder="" name="vpValorPorcentualFlete_b2c" id="updvpValorPorcentualFlete_b2c" required="required" max="9" />
                                                            </div>    
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group"> 
                                                                <label className="control-label">Valor Fijo Flete ($)</label>
                                                                <input type="number" className="form-control" placeholder="0" name="vpValorFlete_b2c" id="updvpValorFlete_b2c" required="required" min="0" />
                                                            </div>    
                                                        </div> 
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Métodos de Pago</label>
                                                                <select name="paymentmethods_b2c" className="form-control" id="updpaymentmethods_b2c">
                                                                    <option value="1">Efectivo</option>
                                                                    <option value="2">Credito</option>
                                                                    <option value="3">Datáfono</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <InputRoutes doesNotApply = 'false' auxName = "idRoute_b2c" auxId="updidRoute_b2c"/>  
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Unidad de Despacho</label>
                                                                <select name="vpTipoOferta_b2c" className="form-control" id="updvpTipoOferta_b2c">
                                                                    <option value="0">Todas</option>
                                                                    <option value="1">Unidades</option>
                                                                    <option value="2">UMD</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group"> 
                                                                <label className=" control-label"> Compra Mín Flete $0</label>
                                                                <input type="number" className="form-control" placeholder="0"  name="vpCompraValorFleteCero_b2c"  id="updvpCompraValorFleteCero_b2c"  max="9" />     
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Pedidos desde</label>
                                                                <select className="form-control" id="updvpCediVisibleEn_b2c" name="vpCediVisibleEn_b2c">
                                                                    <option value="0">Todas</option>
                                                                    <option value="1">Marca Propia</option>
                                                                    <option value="2">Asociativa</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Tipo de Despacho</label>
                                                                <select name="vpTipoDespacho_b2c" className="form-control" id="updvpTipoDespacho_b2c">
                                                                    <option value="1">Domicilio</option>
                                                                    <option value="2">Pide y Recoge</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <InputListPrices doesNotApply = 'false' mostrar='4' auxName = "nidListPrices_b2c" auxId = "updidListPrices_b2c" disabled={false} callback = {this.vacio}/>
                                                        <InputComercial doesNotApply = 'false' auxName="idCommercial_b2c" auxId = "updidCommercial_b2c"/>
                                                        <label className="col-md-12 control-label">Comunicado</label>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    name="release_b2c"
                                                                    id="updrelease_b2c"
                                                                    max="250"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 _paramAlgoritDisperseCtoTat_b2c">
                                                            <div className="form-group ">
                                                                <label className=" control-label">Cto Venta TAT($)</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    name="paramAlgoritDisperseCtoTat_b2c"
                                                                    id="updparamAlgoritDisperseCtoTat_b2c"
                                                                    required="required"
                                                                    max="4"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 _paramAlgoritDispersePercentage_b2c">
                                                            <div className="form-group">
                                                                <label className=" control-label">Dispersión % Fijo</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="0.0"
                                                                    name="paramAlgoritDispersePercentage_b2c"
                                                                    id="updparamAlgoritDispersePercentage_b2c"
                                                                    required="required"
                                                                    max="4"
                                                                    step="0.1"
                                                                />
                                                            </div>
                                                        </div>
                                                        <InputTypeBusiness auxName = "idTypeBusiness_b2c" auxId = "updidTypeBusiness_b2c" mostrar="2" disabled={false} parentCallback = {this.vacio}/>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Marketplace asignado al Pedido TAT</label>
                                                                <select className="form-control" id="updselectTatVitrinaB2c" name="selectTatVitrinaB2c" onChange={(e)=>validarEstado(e,"B2C")}>
                                                                    <option value="1">Marca Blanca</option>                 
                                                                    <option value="2">Asociativa</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ************************************************************** */}
                                                <div className="contBlock">
                                                <div className="row p-t-20">
                                                    <label className=" col-md-6 control-label bold">App TAT</label> 
                                                    <div className="separador"></div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Nro de Licencias disponibles</label>                                                                
                                                            <input type="number" className="form-control" placeholder="" name="nroLicInSite" id="updnroLicInSite" required="required" disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <button type="button" className="btn btn-default btnupdate" id="btnupdate">Actualizar Datos Unidad de Negocio</button>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default updateData