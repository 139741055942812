import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');

class configCediLookMarketplace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        const idMarketplace = sessionStorage.getItem("WhiteBrand_b2c")

        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }

        document.querySelector('.formupdate').style.display = 'block'

        function formEdit() {
            window.scrollTo(0, 0);
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/colorsMarketplace/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({ idMarketplace: idMarketplace })
            }).then(response => response.json())
            .then(
                (result) => {
                    console.log("************************** 1 : ", result)
                    if (endSession(result.success)) {
                        document.getElementById("updidMarketplace").value = result.data.idMarketplace  
                        // ****************** TYCCONST ***************  Tabla Marketplace
                        document.getElementById("updTyCConst_FuenteColorHeader10").value = result.data.TyCConst_FuenteColorHeader10  
                        document.getElementById("updTyCConst_ColorFondoPantalla1").value = result.data.TyCConst_ColorFondoPantalla1 
                        document.getElementById("updTyCConst_ColorFondoBarra1").value = result.data.TyCConst_ColorFondoBarra1  
                        document.getElementById("updTyCConst_buttonColor").value = result.data.TyCConst_buttonColor  
                        document.getElementById("updTyCConst_selectedButtonColor").value = result.data.TyCConst_selectedButtonColor  
                        document.getElementById("updTyCConst_textoModalInfoCedi").value = result.data.TyCConst_textoModalInfoCedi  
                        // ****************** SOLICITUDSOPORTE ***************  Tabla Marketplace
                        document.getElementById("updSolicitudSoporte_ColorHeader10").value = result.data.SolicitudSoporte_ColorHeader10  
                        document.getElementById("updSolicitudSoporte_ColorFondoBoton").value = result.data.SolicitudSoporte_ColorFondoBoton  
                        document.getElementById("updSolicitudSoporte_FuenteColorHeader9").value = result.data.SolicitudSoporte_FuenteColorHeader9  
                        document.getElementById("updSolicitudSoporte_FondoAreaText9").value = result.data.SolicitudSoporte_FondoAreaText9  
                        document.getElementById("updSolicitudSoporte_FondoModal5").value = result.data.SolicitudSoporte_FondoModal5  
                        document.getElementById("updSolicitudSoporte_ColorTextoTitulo").value = result.data.SolicitudSoporte_ColorTextoTitulo  
                        document.getElementById("updSolicitudSoporte_ColorTextoConsejo").value = result.data.SolicitudSoporte_ColorTextoConsejo  
                        document.getElementById("updSolicitudSoporte_ColorBordeAreaTexto").value = result.data.SolicitudSoporte_ColorBordeAreaTexto  
                        document.getElementById("updSolicitudSoporte_ColorFondoBotonTexto").value = result.data.SolicitudSoporte_ColorFondoBotonTexto  
                        document.getElementById("updSolicitudSoporte_ColorTextoDigSuComentario").value = result.data.SolicitudSoporte_ColorTextoDigSuComentario  
                        
                        // *************************** REGISTROCONST *********  Tabla Marketplace
                        document.getElementById("updRegistroConst_ColorHeader9").value = result.data.RegistroConst_ColorHeader9  
                        document.getElementById("updRegistroConst_ColorFondoBotonRegistro").value = result.data.RegistroConst_ColorFondoBotonRegistro  
                        document.getElementById("updRegistroConst_FuenteColorHeader8").value = result.data.RegistroConst_FuenteColorHeader8  
                        document.getElementById("updRegistroConst_ColorFondo9").value = result.data.RegistroConst_ColorFondo9  
                        document.getElementById("updRegistroConst_ColorTextoInput2").value = result.data.RegistroConst_ColorTextoInput2  
                        document.getElementById("updRegistroConst_ColorTextoCondicionesClick2").value = result.data.RegistroConst_ColorTextoCondicionesClick2  
                        document.getElementById("updRegistroConst_ColorTextoBotonRegistro").value = result.data.RegistroConst_ColorTextoBotonRegistro  
                        document.getElementById("updRegistroConst_ColorLineaInputs").value = result.data.RegistroConst_ColorLineaInputs  
                        document.getElementById("updRegistroConst_ColorTextoCondiciones2").value = result.data.RegistroConst_ColorTextoCondiciones2  
                        document.getElementById("updRegistroConst_ColorPlaceHolder2").value = result.data.RegistroConst_ColorPlaceHolder2  
                        // *************************** PREGUNTASFRECUENTESCONST *********  Tabla Marketplace
                        document.getElementById("updPreguntasFrecuentesConst_ColorHeader8").value = result.data.PreguntasFrecuentesConst_ColorHeader8  
                        document.getElementById("updPreguntasFrecuentesConst_FuenteColorHeader7").value = result.data.PreguntasFrecuentesConst_FuenteColorHeader7  
                        document.getElementById("updPreguntasFrecuentesConst_ColorPreguntaCategoria").value = result.data.PreguntasFrecuentesConst_ColorPreguntaCategoria  
                        document.getElementById("updPreguntasFrecuentesConst_ColorPregunta").value = result.data.PreguntasFrecuentesConst_ColorPregunta  
                        document.getElementById("updPreguntasFrecuentesConst_ColorRespuesta").value = result.data.PreguntasFrecuentesConst_ColorRespuesta  
                        document.getElementById("updPreguntasFrecuentesConst_ColorFondoPantalla20").value = result.data.PreguntasFrecuentesConst_ColorFondoPantalla20
                        // ********+****************** OLVIDECLAVECONST *****************  Tabla Marketplace
                        document.getElementById("updOlvideClaveConst_ColorHeader7").value = result.data.OlvideClaveConst_ColorHeader7  
                        document.getElementById("updOlvideClaveConst_ColorBotonEnviar2").value = result.data.OlvideClaveConst_ColorBotonEnviar2  
                        document.getElementById("updOlvideClaveConst_FuenteColorHeader6").value = result.data.OlvideClaveConst_FuenteColorHeader6  
                        document.getElementById("updOlvideClaveConst_ColorFondo8").value = result.data.OlvideClaveConst_ColorFondo8  
                        document.getElementById("updOlvideClaveConst_ColorTexto1").value = result.data.OlvideClaveConst_ColorTexto1 
                        document.getElementById("updOlvideClaveConst_ColorTextoBotonEnviar").value = result.data.OlvideClaveConst_ColorTextoBotonEnviar 
                        document.getElementById("updOlvideClaveConst_ColorBordeInput").value = result.data.OlvideClaveConst_ColorBordeInput 
                        document.getElementById("updOlvideClaveConst_ColorTextoInput1").value = result.data.OlvideClaveConst_ColorTextoInput1 
                        // ************************** MENUCONST ************************  Tabla Marketplace
                        document.getElementById("updMenuConst_ModalOpcionesColorFondo").value = result.data.MenuConst_ModalOpcionesColorFondo 
                        document.getElementById("updMenuConst_colorFondoBotonCerrar").value = result.data.MenuConst_colorFondoBotonCerrar  
                        document.getElementById("updMenuConst_ModalOpcionesTextoColor").value = result.data.MenuConst_ModalOpcionesTextoColor  
                        document.getElementById("updMenuConst_ColorFondoModal3").value = result.data.MenuConst_ColorFondoModal3  
                        document.getElementById("updMenuConst_LineaSeparadoraMenuColor").value = result.data.MenuConst_LineaSeparadoraMenuColor  
                        // ************************** DIRECTORIOCONST ***************************  Tabla Marketplace
                        document.getElementById("updDirectorioConst_ColorFondo5").value = result.data.DirectorioConst_ColorFondo5  
                        document.getElementById("updDirectorioConst_TextoMisProveedores1").value = result.data.DirectorioConst_TextoMisProveedores1  
                        document.getElementById("updDirectorioConst_CardColorBorde1").value = result.data.DirectorioConst_CardColorBorde1  
                        document.getElementById("updDirectorioConst_CardTexto1").value = result.data.DirectorioConst_CardTexto1  
                        document.getElementById("updDirectorioConst_ColorTextoProveedores1").value = result.data.DirectorioConst_ColorTextoProveedores1  
                        document.getElementById("updDirectorioConst_ColorFondoContenedor1").value = result.data.DirectorioConst_ColorFondoContenedor1  
                        // ************************** HOMECONST *********************  Tabla Marketplace
                        document.getElementById("updHomeConst_ColorFondo2").value = result.data.HomeConst_ColorFondo2 
                        document.getElementById("updHomeConst_CardColorFondo2").value = result.data.HomeConst_CardColorFondo2 
                        document.getElementById("updHomeConst_TextoMisProveedores2").value = result.data.HomeConst_TextoMisProveedores2 
                        document.getElementById("updHomeConst_CardColorBorde2").value = result.data.HomeConst_CardColorBorde2    
                        document.getElementById("updHomeConst_CardTexto2").value = result.data.HomeConst_CardTexto2    
                        // ************************** PRINCIPALCONST *********************  Tabla Marketplace
                        document.getElementById("updPrincipalConst_ColorBotonNuevoUsu").value = result.data.PrincipalConst_ColorBotonNuevoUsu 
                        document.getElementById("updPrincipalConst_ColorFondoInputs").value = result.data.PrincipalConst_ColorFondoInputs 
                        document.getElementById("updPrincipalConst_ColorTextBotonNuevoUsu").value = result.data.PrincipalConst_ColorTextBotonNuevoUsu 
                        document.getElementById("updPrincipalConst_ColorFondoPantalla").value = result.data.PrincipalConst_ColorFondoPantalla 
                        document.getElementById("updPrincipalConst_ColorTextBotonEntrar").value = result.data.PrincipalConst_ColorTextBotonEntrar 
                        document.getElementById("updPrincipalConst_ColorTextOlvideClave").value = result.data.PrincipalConst_ColorTextOlvideClave 
                        document.getElementById("updPrincipalConst_ColorTextInputBorde").value = result.data.PrincipalConst_ColorTextInputBorde 
                        document.getElementById("updPrincipalConst_ColorTextoInputs").value = result.data.PrincipalConst_ColorTextoInputs  
                        document.getElementById("updPrincipalConst_ColorVersion").value = result.data.PrincipalConst_ColorVersion  
                        document.getElementById("updPrincipalConst_ColorBotonEntrar").value = result.data.PrincipalConst_ColorBotonEntrar 
                        // ************************* CAMBIARCLAVECONST ***************************  Tabla Marketplace
                        document.getElementById("updCambiarClaveConst_ColorHeader3").value = result.data.CambiarClaveConst_ColorHeader3 
                        document.getElementById("updCambiarClaveConst_ColorFondoBotonesModal").value = result.data.CambiarClaveConst_ColorFondoBotonesModal 
                        document.getElementById("updCambiarClaveConst_FuenteColorHeader3").value = result.data.CambiarClaveConst_FuenteColorHeader3  
                        document.getElementById("updCambiarClaveConst_ColorFondoInput").value = result.data.CambiarClaveConst_ColorFondoInput  
                        document.getElementById("updCambiarClaveConst_ColorFondo3").value = result.data.CambiarClaveConst_ColorFondo3  
                        document.getElementById("updCambiarClaveConst_borderColorInputCambiarC").value = result.data.CambiarClaveConst_borderColorInputCambiarC  
                        document.getElementById("updCambiarClaveConst_ColorTextoInput").value = result.data.CambiarClaveConst_ColorTextoInput  
                        document.getElementById("updCambiarClaveConst_colorTextoBotonCambiarC").value = result.data.CambiarClaveConst_colorTextoBotonCambiarC 
                        document.getElementById("updCambiarClaveConst_ColorTexto").value = result.data.CambiarClaveConst_ColorTexto  
                        // **************************** COMUNICACIONCEDISCONST ********************  Tabla Marketplace
                        document.getElementById("updComunicacionCedisConst_colorBordeTextoCedi1").value = result.data.ComunicacionCedisConst_colorBordeTextoCedi1  
                        document.getElementById("updComunicacionCedisConst_ColorHeader4").value = result.data.ComunicacionCedisConst_ColorHeader4 
                        document.getElementById("updComunicacionCedisConst_ColorFondoBotonesModal1").value = result.data.ComunicacionCedisConst_ColorFondoBotonesModal1  
                        document.getElementById("updComunicacionCedisConst_FuenteColorHeader4").value = result.data.ComunicacionCedisConst_FuenteColorHeader4 
                        document.getElementById("updComunicacionCedisConst_modalContentCedi2").value = result.data.ComunicacionCedisConst_modalContentCedi2  
                        document.getElementById("updComunicacionCedisConst_ColorPlaceholder1").value = result.data.ComunicacionCedisConst_ColorPlaceholder1  
                        document.getElementById("updComunicacionCedisConst_colorTextoAdvertenciaCedi1").value = result.data.ComunicacionCedisConst_colorTextoAdvertenciaCedi1  
                        document.getElementById("updComunicacionCedisConst_ColorTextoBotonesModal1").value = result.data.ComunicacionCedisConst_ColorTextoBotonesModal1  
                        document.getElementById("updComunicacionCedisConst_colorTextoCedi1").value = result.data.ComunicacionCedisConst_colorTextoCedi1  
                        document.getElementById("updComunicacionCedisConst_ColorTextoCondiciones1").value = result.data.ComunicacionCedisConst_ColorTextoCondiciones1  
                        document.getElementById("updComunicacionCedisConst_ColorTextoCondicionesClick1").value = result.data.ComunicacionCedisConst_ColorTextoCondicionesClick1  
                        document.getElementById("updComunicacionCedisConst_CediColorFondo2").value = result.data.ComunicacionCedisConst_CediColorFondo2
                        // ****************************  IMAGENES *********************************
                        document.getElementById("imgshowImagenSuperior").src = result.data.Imagenes_ImagenSuperior  
                        document.getElementById("imgshowLogoTienda").src = result.data.Imagenes_LogoTienda 
                        document.getElementById("imgshowUsuarioMarket").src = result.data.Imagenes_UsuarioMarket
                        document.getElementById("imgshowUser").src = result.data.Imagenes_User
                        document.getElementById("imgshowOjo").src = result.data.Imagenes_Ojo
                        document.getElementById("imgshowMayorque").src = result.data.Imagenes_Mayorque
                        document.getElementById("imgshowInstagramMarket").src = result.data.Imagenes_InstagramMarket
                        document.getElementById("imgshowFacebookMarket").src = result.data.Imagenes_FacebookMarket
                        document.getElementById("imgshowLinkedingMarket").src = result.data.Imagenes_LinkedingMarket
                        document.getElementById("imgshowTwitterMarket").src = result.data.Imagenes_TwitterMarket
                        document.getElementById("imgshowWhatsappMarket").src = result.data.Imagenes_WhatsappMarket
                        document.getElementById("imgshowYoutubeMarket").src = result.data.Imagenes_YoutubeMarket
                        document.getElementById("imgshowBackArrowMarket").src = result.data.Imagenes_BackArrowMarket
                        document.getElementById("imgshowHamburguesa").src = result.data.Imagenes_Hamburguesa
                        document.getElementById("imgshowCloseBlackMarket").src = result.data.Imagenes_CloseBlackMarket    
                    } else 
                        closeSession()
                },
            )
            .catch(error => alertaGeneral("Problemas de conexión (27)") )
        }
        
        function updateForm() {
            const data = new FormData(document.getElementById('formularioupdate'))
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/colorsMarketplace/update", { // ok
                headers: { 
                    'Authorization': token,
                },
                method: "PUT",
                body: data
            }).then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        alertaGeneral("Registro  Actualizado");
                        window.location.reload(false);    
                    } else 
                        closeSession()
                },
            )
            .catch(error => alertaGeneral("Problemas de conexión (51)") )        
        }             
        
        var la = document.getElementById('btnupdate');
        if (la) {
            la.addEventListener("click", updateForm);
        }
        formEdit()
    }
  
    render(){

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolUnidadNegocioDatos = accessUser.includes('37')
        let boolUnidadNegocioLook = accessUser.includes('38') 
        let boolAppAsoDatos = accessUser.includes('39')
        let boolAppAsoLook = accessUser.includes('40')
        let boolAppMbDatos = accessUser.includes('41')
        let boolAppMbLook = accessUser.includes('42')    

        let hayWhiteBrand = sessionStorage.getItem("WhiteBrand")
        let hayWhiteBrand_b2c = sessionStorage.getItem("WhiteBrand_b2c")

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <div className="page-wrapper">                    
                    <div className="row page-titles">
                        <div className="col-md-2.5 align-self-center">
                            <h3 className="text-primary">Unidad de Negocio</h3> 
                        </div>
                        { 
                            boolUnidadNegocioDatos
                        ? 
                            <label style = {{paddingLeft:30, fontSize:23}} id="ModUsuarioFdl"><a href="./cediconfigCEDI">Datos</a></label>
                        :
                            null
                        }
                        {
                            boolUnidadNegocioLook
                        ?
                            <label style = {{paddingLeft:20, fontSize:23, paddingRight:50}} id="ModUsuarioFdc"><a href="./cediconfiglookCedis">Look</a></label>
                        :
                            null
                        }    
                        { 
                            boolAppAsoDatos && (hayWhiteBrand != 0)
                        ? 
                            <>
                                <div className="col-md-1.5 align-self-center">
                                    <h3 style = {{marginLeft:10}} className="text-primary">App Mb B2B</h3> 
                                </div>
                                <label style = {{ fontSize:23, marginLeft:30}} id="ModUsuarioFdl">
                                    <a 
                                        href="./cediconfigMARKETPLACE"> Datos
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolAppAsoLook && (hayWhiteBrand != 0)
                        ?
                            <label style = {{paddingLeft:20, fontSize:23, paddingRight:50}} id="ModUsuarioFdc"><a href="./cediconfiglookmarketplace">Look</a></label>
                        :
                            null
                        }    
                        { 
                            boolAppMbDatos && (hayWhiteBrand_b2c != 0)
                        ? 
                            <>
                                <div className="col-md-1.5 align-self-center">
                                    <h3 style = {{marginLeft:10}} className="text-primary">App Mb B2C</h3> 
                                </div>
                                <label style = {{ fontSize:23, marginLeft:30}} id="ModUsuarioFdl">
                                    <a 
                                        href="./cediconfigMARKETPLACE_b2c"> Datos
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolAppMbLook && (hayWhiteBrand_b2c != 0)
                        ?
                            <label style = {{paddingLeft:20, fontSize:23}} id="ModUsuarioFdc"><a href="./cediconfiglookmarketplace_b2c">Look</a></label>
                        :
                            null
                        }    
                    </div>
                    {<UpdateData />}
                </div>
            </div>
        )
    }
}

export default configCediLookMarketplace;