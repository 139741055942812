import React, {Component} from 'react'

class purchaseTime extends Component {

    render(){

        const doesNotApply = this.props.doesNotApply

        return  (
            <React.Fragment>                
                <div className="col-md-2">
                    <div className="form-group">
                        <label className=" control-label">Ultima compra mayor a</label>
                        { 
                            (doesNotApply == 'true')
                        ? 
                            <select className="form-control" id="updPurchaseTime" name="purchaseTime">      
                                <option value={-1} >No Aplica</option>
                            </select>                                                              
                        : 
                            <select className="form-control" id="updPurchaseTime" name="purchaseTime">      
                                <option value={-1}  >No Aplica</option>
                                <option value={5}   >5 días</option>
                                <option value={7}   >7 días</option>
                                <option value={10}  >10 días</option>
                                <option value={15}  >15 días</option>
                                <option value={30}  >30 días</option>
                                <option value={45}  >45 días</option>
                                <option value={60}  >60 días</option>
                                <option value={90}  >90 días</option>
                                <option value={180} >180 días</option>
                            </select>                                                              
                        }                            
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default purchaseTime