import React, {Component} from 'react'
import $, { data } from 'jquery';
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import CreateData from './createData'
import {sha256} from 'js-sha256';
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');

class comerciales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        const idCedi = sessionStorage.getItem("idCedi")
        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
           
       function isValidEmail(email) {
           var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
           return re.test(email);
        }
        
        var email = document.querySelector("#creemail");
        const resultado = document.querySelector('.labelEmailcre')
        email.addEventListener("keyup", function (event) {
           if (isValidEmail(this.value)) {
               resultado.textContent = "";
           } else {
               resultado.textContent = "Email Incorrecto";
           }
        })

        
     
        async function addForm() {
            const registerValid = sessionStorage.getItem("registerValid")   
            if (registerValid == "true") {
                $('#creidCedi').val(idCedi)
                let name = document.getElementById("crename").value
                let phone = document.getElementById("crephone").value
                let email = document.getElementById("creemail").value
                let valEmail = isValidEmail(email)
                let nroUserLicencias = 0
                let token = sessionStorage.getItem('token')
                await fetch(Const.urlrest + "api/comercialcedi/licencias", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "POST",
                    body: JSON.stringify({ idCedi: idCedi })
                }).then(response => response.json())
                    .then((result) => {
                        if (endSession(result.success))
                            nroUserLicencias = result.data.length
                        else 
                            closeSession()
                }).catch(error => alertaGeneral("Problemas de Conexión (33)"))      
                let nroLicencias = 0
                
                await fetch(Const.urlrest + "api/cedi/read", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "POST",
                    body: JSON.stringify({ id: idCedi })
                }).then(response => response.json())
                    .then((result) => {
                        if (endSession(result.success))
                            nroLicencias = result.data.nroLicInSite
                        else 
                            closeSession()
                }).catch(error => alertaGeneral("Problemas de Conexión (34)"))  

                let validLicencia = false
                if (document.getElementById('updauxUsoLicInSite').checked)
                    if (nroUserLicencias < nroLicencias)
                        validLicencia = true
                    else
                        alertaGeneral("No le quedan licencias para uso de la App TAT")    
                else 
                    validLicencia = true
                let schedule = $("#creimg").val()
                if (valEmail){                
                    if (name.length >= 4 && phone.length >= 6 && schedule.length >= 4) {
                        if (validLicencia) {
                            let data = new FormData(document.getElementById('formulario'));
                            data.delete("passwordInSite") 
                            data.append("passwordInSite", sha256(document.getElementById("updpasswordInSite").value))
                            if (document.getElementById('updauxUsoLicInSite').checked) 
                                    data.append('usoLicInSite', 1)
                                else
                                    data.append('usoLicInSite', 0)
                             
                            fetch(Const.urlrest + "api/comercialcedi/add", { // ok
                                    method: "POST",
                                    headers: { 
                                        /* 'Accept': 'application/json',
                                        'Content-Type': 'application/json', */
                                        'Authorization': token,
                                    },
                                    body: data
                            }).then(response => response.json())
                                .then(
                                    (result) => {
                                        if (endSession(result.success)) {
                                            if (result.data != null) {
                                                alertaGeneral("Registro Ingresado")
                                                window.location.reload(false);
                                            } else 
                                                alertaGeneral("Verifica los campos")    
                                        } else 
                                            closeSession()
                            }).catch(error => alertaGeneral("Problemas de conexión (35)"))
                        } else 
                            alertaGeneral("No quedan Licencias de App TAT para su uso")
                    } else {
                        alertaGeneral("Todos los datos son obligatorios");
                    }
                } else {
                    alertaGeneral("Por favor valida el email");
                }
            } else
                alertaGeneral("La imagen seleccionada no es valida, no se permite la operación de Crear")        
        }
        var lu = document.getElementById('btnadd');
        if (lu) {
            lu.addEventListener("click", addForm);
        }
    }

    render(){        

        sessionStorage.setItem("registerValid", "true") 

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('1')
        let boolOperar = accessUser.includes('2') || accessUser.includes('3')
        let boolCargar = accessUser.includes('47')
      
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <div className="page-wrapper">                    
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Comerciales</h3> 
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./comercial">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolOperar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./comerciallist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioCargar"><a href="./comercialcargar">Cargar</a></label>
                        :
                            null
                        }    
                    </div>
                    <CreateData />
                </div>
            </div>
        )
    }
}

export default comerciales;