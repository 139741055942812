import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import Const from '../utils/defaultConstant'

document.body.classList.add('fix-sidebar');

class orders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    render(){

        const idCedi = sessionStorage.getItem("idCedi")
        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCargarPrecios = accessUser.includes('34')
        let boolDescargarPlantilla = accessUser.includes('35') 
        let boolDescargarPedidos = accessUser.includes('36')

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-2 align-self-center">
                            <h3 className="text-primary">IO Excel</h3> 
                        </div>
                        { 
                            boolCargarPrecios
                        ? 
                            <label style = {{paddingLeft:20, fontSize:22}} id="ModUsuarioFdl"><a href="./uploadListPrice">Cargar Precios</a></label>
                        :
                            null
                        }
                        {
                            boolDescargarPlantilla
                        ?
                            <label style = {{paddingLeft:40, fontSize:22}} id="ModUsuarioFdc"><a href="./downloadTemplate">Descargar Formatos</a></label>
                        :
                            null
                        }
                        {
                            boolDescargarPedidos
                        ?
                            <label style = {{paddingLeft:40, fontSize:22}} id="ModUsuarioFdc"><a href="./orders">Descargar Pedidos</a></label>
                        :
                            null
                        }
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title"> </h4>   
                                        <form action = {Const.urlrest+"api/orders/excel"} method="POST" className="tcenter">
                                            <div className="row p-t-20">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="hidden" className="form-control" placeholder="" name="idCedi" id="creidCedi" required="required" value={idCedi}  />                                                   
                                                        <h3>Descargar pedidos de los últimos 3 días</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <> 
                                                <button type="submit" className="btn btn-default btnadd" style={{ alignItems :"center" }}>Consultar</button>
                                            </>  
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>          
                    </div>
                </div>
            </div>
        )
    }
}

export default orders;


