import React, {Component} from 'react'

class inputFeatured extends Component {

    render(){
        return  (
            <React.Fragment>                
                <div className="col-md-4">
                    <div className="form-group">
                        <label className=" control-label">Producto destacado </label>
                           <select className="form-control" id="updfeatured" name="featured">      
                                      <option value="0">No destacado</option>  
                                      <option value="1">Destacado</option>                               
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputFeatured