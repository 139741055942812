import React, {Component} from 'react'

class inputTypeOffert extends Component {

    render(){

        const doesNotApply = this.props.doesNotApply

        return  (
            <React.Fragment>                
                <div className="col-md-2">
                    <div className="form-group">
                        <label className=" control-label">Tipo de Oferta</label>
                            <select className="form-control" id="updtypeOffert" name="typeOffert">      
                                { 
                                    (doesNotApply == 'true')
                                ? 
                                    <option value={0} >Todas</option>
                                : 
                                    null
                                }
                                <option value={1} >Unidades</option>
                                <option value={2} >UMD</option>
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputTypeOffert