import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');

class ConfigMarketplace extends Component {

    componentDidMount() { 
        const idMarketplace = sessionStorage.getItem("WhiteBrand")
        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")

        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }

        function validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$', 'i');
            return !!pattern.test(str);
        }
        
        $(document).off().on('click', '.btnupdate', function () {
            let id = $("#updid").val();
            let textTyc = $("#updtextTyc").val()
            let textPd = $("#updtextPd").val()
            let textAutoriza = $("#updtextAutoriza").val()
            let vitrinaMarketplace = $("#updvitrinaMarketplace").val()
            let faqs = []
            let social = []
            $(".urlsocial").each(function (index) {
                if (this.value != "") {
                    let url = validURL(this.value)
                    if (url) {
                        social.push({
                            "name": this.id,
                            "url": this.value
                        })
                    }
                }
            });
            $(".itemfaqs").each(function (index) {
                if ($(this).find(".tfaqs").value != "") {
                    let Questions = []
                    let titlefaqs = $(this).find(".tfaqs").val()
                    let groupQuestions = $(this).find(".groupInputFaqs")
                    $(groupQuestions).each(function (index) {
                        if ($(this).find(".qfaqs").val() != "" &&
                            $(this).find(".rfaqs").val() != "") {
                            Questions.push({
                                "pregunta": $(this).find(".qfaqs").val(),
                                "respuesta": $(this).find(".rfaqs").val()
                            })
                        }
                    })
                    if (Questions.length >= 1) {
                        faqs.push({
                            "categoria": titlefaqs,
                            "respuestas": Questions
                        })
                    }
                }
            })
            console.log("***************** faqs : ", JSON.stringify(faqs))
            if (textTyc.length >= 40 && textPd.length >= 40 && textAutoriza.length >= 40 ) {
                var datos = {
                    id: id,
                    socialRef: JSON.stringify(social),
                    faqs: JSON.stringify(faqs),
                    textTyc: textTyc,
                    textPd: textPd,
                    textAutoriza: textAutoriza,
                    vitrinaMarketplace: vitrinaMarketplace,
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/marketplace/update", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "PUT",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado");
                            window.scrollTo(0, 0);
                            document.querySelector('.formupdate').style.display = 'none'
                            window.location.reload(false);            
                        } else 
                            closeSession()
                    },
                )
                .catch(error => alertaGeneral("Problemas de Conexión (104)"))
            } else {
                alertaGeneral("Revisa los campos, todos son obligatorios");
            }
            
        })

        function formEdit() {
            window.scrollTo(0, 0);
            document.querySelector('.formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/marketplace/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: idMarketplace
                })
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        $("#updid").val(result.data.id)
                        $("#updsocialRef").val(result.data.socialRef)
                        $("#updtextTyc").val(result.data.textTyc)
                        $("#updtextPd").val(result.data.textPd)
                        $("#updtextAutoriza").val(result.data.textAutoriza)
                        $("#updvitrinaMarketplace").val(result.data.vitrinaMarketplace)
                        let socialRef = JSON.parse(result.data.socialRef)
                        socialRef.forEach(element => {
                            $("#" + element.name).val(element.url)
                        })
                        let faqs = JSON.parse(result.data.faqs)
                        console.log("************ faqs : ", faqs)
                        faqs.forEach(element => {
                            let respuestas = element.respuestas
                            let itemfaqs = ' <div class="itemfaqs">' +
                                '<button class="addCatFaqsmenosppal">-</button>' +
                                '<input type="text" class="form-control itemfaqs tfaqs" placeholder="Nombre categoría"  value="' + element.categoria + '"  id="tpreguntas"  max="120" /> ' +
                                '<a href="#nogo" class="addCatFaqsmas">Agregar Pregunta y respuesta</a><div class="separador"></div>';                
                            respuestas.forEach(resp => {
                                itemfaqs += '<div class="groupInputFaqs">' +
                                    '<button class="addCatFaqsmenos">-</button>' +
                                    '<input type="text" class="form-control qfaqs" value="' + resp.pregunta + '" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                                    '<input type="text" class="form-control rfaqs" placeholder="Respuesta"  value="' + resp.respuesta + '"  id="respuesta"  max="255" />' +
                                    '</div>';
                            })
                            itemfaqs += '</div> ';
                            $(".form-group-faqs").append(itemfaqs)
                        })    
                    } else 
                        closeSession()
                },
            ).catch(error => alertaGeneral("Problemas de Conexión (105)"))
            let itemfaqs = ' <div class="itemfaqs">' + 
                '<button class="addCatFaqsmenosppal">-</button>' +
                '<input type="text" class="form-control tfaqs" placeholder="Nombre categoría"   id="tpreguntas"  max="120" /> ' +
                '<a href="#nogo" class="addCatFaqsmas">Agregar Pregunta y respuesta</a><div class="separador"></div>' +
                '<div class="groupInputFaqs">' +
                '<button class="addCatFaqsmenos">-</button>' +
                '<input type="text" class="form-control qfaqs" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                '<input type="text" class="form-control rfaqs" placeholder="Respuesta"   id="respuesta"  max="255" />' +
                '</div>' +
                '</div> ';

            let itemquestion = '<div class="groupInputFaqs">' +
                '<button class="addCatFaqsmenos">-</button>' +
                '<input type="text" class="form-control qfaqs" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                '<input type="text" class="form-control rfaqs" placeholder="Respuesta"   id="respuesta"  max="255" />' +
                '</div>';

            $(document).on('click', '.addCatFaqs', function () {
                $(".form-group-faqs").append(itemfaqs)
            });
            $(document).on('click', '.addCatFaqsmenosppal', function () {
                $(this).parent(".itemfaqs").remove()
            });
            $(document).on('click', '.addCatFaqsmas', function () {
                $(this).parent(".itemfaqs").append(itemquestion)
                return false
            });
            $(document).on('click', '.addCatFaqsmenos', function () {
                $(this).parent(".groupInputFaqs").remove()
                return false
            });
        }
        formEdit()
    }

    
    render(){

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolUnidadNegocioDatos = accessUser.includes('37')
        let boolUnidadNegocioLook = accessUser.includes('38') 
        let boolAppAsoDatos = accessUser.includes('39')
        let boolAppAsoLook = accessUser.includes('40')
        let boolAppMbDatos = accessUser.includes('41')
        let boolAppMbLook = accessUser.includes('42')    
    
        let hayWhiteBrand = sessionStorage.getItem("WhiteBrand")
        let hayWhiteBrand_b2c = sessionStorage.getItem("WhiteBrand_b2c")
    
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-2.5 align-self-center">
                            <h3 className="text-primary">Unidad de Negocio</h3> 
                        </div>            
                        { 
                            boolUnidadNegocioDatos
                        ? 
                            <label style = {{paddingLeft:30, fontSize:23}} id="ModUsuarioFdl"><a href="./cediconfigCEDI">Datos</a></label>
                        :
                            null
                        }
                        {
                            boolUnidadNegocioLook
                        ?
                            <label style = {{paddingLeft:20, fontSize:23, paddingRight:50}} id="ModUsuarioFdc"><a href="./cediconfiglookCedis">Look</a></label>
                        :
                            null
                        }    
                        { 
                            boolAppAsoDatos && (hayWhiteBrand != 0)
                        ? 
                            <>
                                <div className="col-md-1.5 align-self-center">
                                    <h3 style = {{marginLeft:10}} className="text-primary">App Mb B2B</h3> 
                                </div>
                                <label style = {{ fontSize:23, marginLeft:30}} id="ModUsuarioFdl">
                                    <a 
                                        href="./cediconfigMARKETPLACE"> Datos
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolAppAsoLook && (hayWhiteBrand != 0)
                        ?
                            <label style = {{paddingLeft:20, fontSize:23, paddingRight:50}} id="ModUsuarioFdc"><a href="./cediconfiglookmarketplace">Look</a></label>
                        :
                            null
                        }    
                        { 
                            boolAppMbDatos && (hayWhiteBrand_b2c != 0)
                        ? 
                            <>
                                <div className="col-md-1.5 align-self-center">
                                    <h3 style = {{marginLeft:10}} className="text-primary">App Mb B2C</h3> 
                                </div>
                                <label style = {{ fontSize:23, marginLeft:30}} id="ModUsuarioFdl">
                                    <a 
                                        href="./cediconfigMARKETPLACE_b2c"> Datos
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolAppMbLook && (hayWhiteBrand_b2c != 0)
                        ?
                            <label style = {{paddingLeft:20, fontSize:23}} id="ModUsuarioFdc"><a href="./cediconfiglookmarketplace_b2c">Look</a></label>
                        :
                            null
                        }    
                    </div>
                    {<UpdateData />}
                </div>
            </div>
        )
    }
}

export default ConfigMarketplace;