import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import {sha256} from 'js-sha256';
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');

class configCedi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {

        const idCedi = sessionStorage.getItem("idCedi")

        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }

        function validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$', 'i');
            return !!pattern.test(str);
        }

        function isValidEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        var email = document.querySelector("#updemailAdmin");
        const resultado = document.querySelector('.labelEmailAdmin')

        email.addEventListener("keyup", function (event) {
            if (isValidEmail(this.value)) {
                resultado.textContent = "";
            } else {
                resultado.textContent = "Email Incorrecto";
            }
        })
        var email2 = document.querySelector("#updemailServiceClient");
        const resultado2 = document.querySelector('.labelEmailClient')

        email2.addEventListener("keyup", function (event) {
            if (isValidEmail(this.value)) {
                resultado2.textContent = "";
            } else {
                resultado2.textContent = "Email Incorrecto";
            }
        })

        document.querySelector('.formupdate').style.display = 'block'

        function formEdit() {

                let token = sessionStorage.getItem('token')          
                let a = fetch(Const.urlrest + "api/cedi/readvitrinab2b", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "POST",
                    body: JSON.stringify({ idCedi: idCedi })
                }).then(response => response.json())
                    .then(result => {
                        if (endSession(result.success)) {
                            document.getElementById("updvpPedidoMinimo_b2b").value = result.data.vpPedidoMinimo_b2b 
                            document.getElementById("updtypeFreight_b2b").value = result.data.typeFreight_b2b 
                            document.getElementById("updidRoute_b2b").value = result.data.idRoute_b2b
                            document.getElementById("updvpValorPorcentualFlete_b2b").value = result.data.vpValorPorcentualFlete_b2b 
                            document.getElementById("updpaymentmethods_b2b").value = result.data.paymentmethods_b2b 
                            document.getElementById("updvpValorFlete_b2b").value = result.data.vpValorFlete_b2b 
                            document.getElementById("updvpTipoOferta_b2b").value = result.data.vpTipoOferta_b2b 
                            document.getElementById("updvpCompraValorFleteCero_b2b").value = result.data.vpCompraValorFleteCero_b2b 
                            document.getElementById("updvpTipoDespacho_b2b").value = result.data.vpTipoDespacho_b2b 
                            document.getElementById("updidListPrices_b2b").value = result.data.nidListPrices_b2b 
                            document.getElementById("updidCommercial_b2b").value = result.data.idCommercial_b2b 
                            document.getElementById("updidTypeBusiness_b2b").value =result.data.idTypeBusiness_b2b
                            document.getElementById("updrelease_b2b").value = result.data.release_b2b   
                            document.getElementById("updvpCediVisibleEn_b2b").value = result.data.vpCediVisibleEn_b2b
                            document.getElementById("updparamAlgoritDisperseCtoTat_b2b").value = result.data.paramAlgoritDisperseCtoTat_b2b
                            document.getElementById("updparamAlgoritDispersePercentage_b2b").value = result.data.paramAlgoritDispersePercentage_b2b
                            document.getElementById("updselectTatVitrinaB2b").value = result.data.selectTatVitrinaB2b
                            
                            $("#typeFreight_b2b option[value=" + result.data.typeFreight_b2b + "] ").attr('selected', 'selected')
                            $("#idRoute_b2b option[value=" + result.data.idRoute_b2b + "] ").attr('selected', 'selected')
                            $("#paymentmethods_b2b option[value=" + result.data.paymentmethods_b2b + "] ").attr('selected', 'selected')
                            $("#nidListPrices_b2b option[value=" + result.data.nidListPrices_b2b + "] ").attr('selected', 'selected')
                            $("#idCommercial_b2b option[value=" + result.data.idCommercial_b2b + "] ").attr('selected', 'selected')
                            $("#idTypeBusiness_b2b option[value=" + result.data.idTypeBusiness_b2b + "] ").attr('selected', 'selected')
                            $("#updselectTatVitrinaB2b option[value=" + result.data.selectTatVitrinaB2b + "] ").attr('selected', 'selected');    
                        } else 
                            closeSession()
                    }).catch(error => alertaGeneral("Problemas de conexión (42)")) 
        
                let b = fetch(Const.urlrest + "api/cedi/readvitrinab2c", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "POST",
                    body: JSON.stringify({ idCedi: idCedi })
                }).then(response => response.json())
                    .then(result => {
                        if (endSession(result.success)) {
                            document.getElementById("updvpPedidoMinimo_b2c").value = result.data.vpPedidoMinimo_b2c 
                            document.getElementById("updtypeFreight_b2c").value = result.data.typeFreight_b2c 
                            document.getElementById("updidRoute_b2c").value = result.data.idRoute_b2c
                            document.getElementById("updvpValorPorcentualFlete_b2c").value = result.data.vpValorPorcentualFlete_b2c 
                            document.getElementById("updpaymentmethods_b2c").value = result.data.paymentmethods_b2c 
                            document.getElementById("updvpValorFlete_b2c").value = result.data.vpValorFlete_b2c 
                            document.getElementById("updvpTipoOferta_b2c").value = result.data.vpTipoOferta_b2c 
                            document.getElementById("updvpCompraValorFleteCero_b2c").value = result.data.vpCompraValorFleteCero_b2c 
                            document.getElementById("updvpTipoDespacho_b2c").value = result.data.vpTipoDespacho_b2c 
                            document.getElementById("updidListPrices_b2c").value = result.data.nidListPrices_b2c 
                            document.getElementById("updidCommercial_b2c").value = result.data.idCommercial_b2c 
                            document.getElementById("updidTypeBusiness_b2c").value = result.data.idTypeBusiness_b2c 
                            document.getElementById("updnroLicInSite").value = result.data.nroLicInSite
                            document.getElementById("updrelease_b2c").value = result.data.release_b2c   
                            document.getElementById("updvpCediVisibleEn_b2c").value = result.data.vpCediVisibleEn_b2c
                            document.getElementById("updparamAlgoritDisperseCtoTat_b2c").value = result.data.paramAlgoritDisperseCtoTat_b2c
                            document.getElementById("updparamAlgoritDispersePercentage_b2c").value = result.data.paramAlgoritDispersePercentage_b2c
    
                            document.getElementById("updselectTatVitrinaB2c").value = result.data.selectTatVitrinaB2c
    
                            $("#typeFreight_b2c option[value=" + result.data.typeFreight_b2c + "] ").attr('selected', 'selected')
                            $("#idRoute_b2c option[value=" + result.data.idRoute_b2c + "] ").attr('selected', 'selected')
                            $("#paymentmethods_b2c option[value=" + result.data.paymentmethods_b2c + "] ").attr('selected', 'selected')
                            $("#nidListPrices_b2c option[value=" + result.data.nidListPrices_b2c + "] ").attr('selected', 'selected')
                            $("#idCommercial_b2c option[value=" + result.data.idCommercial_b2c + "] ").attr('selected', 'selected')
                            $("#idTypeBusiness_b2c option[value=" + result.data.idTypeBusiness_b2c + "] ").attr('selected', 'selected')
                            $("#updselectTatVitrinaB2c option[value=" + result.data.selectTatVitrinaB2c + "] ").attr('selected', 'selected');    
                        } else 
                            closeSession()
                    }).catch(error => alertaGeneral("Problemas de Conexión (43)")) 
    
            fetch(Const.urlrest + "api/cedi/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                    method: "POST",
                    body: JSON.stringify({ id: idCedi })
                })
                .then(response => response.json())
                .then(  
                    (result) => {
                        if (endSession(result.success)) {
                            document.getElementById("updvaluePlatformBaseOrder_b2b").value = result.data.valuePlatformBaseOrder_b2b
                            document.getElementById("updvaluePlatformBaseRef_b2b").value = result.data.valuePlatformBaseRef_b2b
                            document.getElementById("updpercentageFreeDisperse_b2b").value = result.data.percentageFreeDisperse_b2b
                            document.getElementById("updpercentageOrdenMin_b2b").value = result.data.percentageOrdenMin_b2b
                            document.getElementById("updpercentageLimitSalesHigher_b2b").value = result.data.percentageLimitSalesHigher_b2b
    
                            document.getElementById("updvaluePlatformBaseOrder_b2c").value = result.data.valuePlatformBaseOrder_b2c
                            document.getElementById("updvaluePlatformBaseRef_b2c").value = result.data.valuePlatformBaseRef_b2c
                            
                            document.getElementById("updid").value = result.data.id
                            document.getElementById("updnameAdmin").value = result.data.nameAdmin
                            document.getElementById("updphoneAdmin").value = result.data.phoneAdmin
                            document.getElementById("updemailAdmin").value = result.data.emailAdmin
                            document.getElementById("updemailServiceClient").value = result.data.emailServiceClient
                            document.getElementById("updname").value = result.data.name
                            document.getElementById("upddescription").value = result.data.description
                            document.getElementById("updslogan").value = result.data.slogan
                            document.getElementById("updtextTyc").value = result.data.textTyc
                            document.getElementById("updtextPd").value = result.data.textPd
                            document.getElementById("updtextAutoriza").value = result.data.textAutoriza
                            document.getElementById("imgshow").src = result.data.img
    
                            document.getElementById("updidMarketplace").value = result.data.idMarketplace
                            document.getElementById("updidMarketplaceASo").value = result.data.idMarketplaceASo
                            document.getElementById("updidMarketplace_b2c").value = result.data.idMarketplace_b2c
                            document.getElementById("updidMarketplaceASo_b2c").value = result.data.idMarketplaceASo_b2c
    
                            document.getElementById("updbalanceNotifications").value = result.data.balanceNotifications
                            document.getElementById("updtypeDisperse_b2b").value = result.data.typeDisperse_b2b
                            document.getElementById("updnroLicInSite").value = result.data.nroLicInSite
    
                            switch (result.data.typeDisperse_b2b) {       
                                case 10: {
                                    document.querySelector('._valuePlatformBaseOrder_b2b').style.display = 'none'
                                    document.querySelector('._valuePlatformBaseRef_b2b').style.display = 'none'
                                    document.querySelector('._percentageFreeDisperse_b2b').style.display = 'none'
                                    document.querySelector('._percentageOrdenMin_b2b').style.display = 'none'
                                    document.querySelector('._percentageLimitSalesHigher_b2b').style.display = 'none'
                                    document.querySelector('._paramAlgoritDisperseCtoTat_b2b').style.display = 'none'
                                    document.querySelector('._paramAlgoritDispersePercentage_b2b').style.display = 'block'
                                    break
                                }                     
                                case 20: {
                                    document.querySelector('._valuePlatformBaseOrder_b2b').style.display = 'block'
                                    document.querySelector('._valuePlatformBaseRef_b2b').style.display = 'block'
                                    document.querySelector('._percentageFreeDisperse_b2b').style.display = 'block'
                                    document.querySelector('._percentageOrdenMin_b2b').style.display = 'block'
                                    document.querySelector('._percentageLimitSalesHigher_b2b').style.display = 'block'
                                    document.querySelector('._paramAlgoritDisperseCtoTat_b2b').style.display = 'block'
                                    document.querySelector('._paramAlgoritDispersePercentage_b2b').style.display = 'none'            
                                    break
                                }    
                                case 30: {
                                    document.querySelector('._valuePlatformBaseOrder_b2b').style.display = 'none'
                                    document.querySelector('._valuePlatformBaseRef_b2b').style.display = 'none'
                                    document.querySelector('._percentageFreeDisperse_b2b').style.display = 'none'
                                    document.querySelector('._percentageOrdenMin_b2b').style.display = 'none'
                                    document.querySelector('._percentageLimitSalesHigher_b2b').style.display = 'none'
                                    document.querySelector('._paramAlgoritDisperseCtoTat_b2b').style.display = 'none'
                                    document.querySelector('._paramAlgoritDispersePercentage_b2b').style.display = 'none'            
                                    break
                                } 
                            }
    
                            document.getElementById("updtypeDisperse_b2c").value = result.data.typeDisperse_b2c
    
                            switch (result.data.typeDisperse_b2c) {        
                                case 1000: {
                                    document.querySelector('._valuePlatformBaseOrder_b2c').style.display = 'none'
                                    document.querySelector('._valuePlatformBaseRef_b2c').style.display = 'none'
                                    document.querySelector('._paramAlgoritDisperseCtoTat_b2c').style.display = 'none'
                                    document.querySelector('._paramAlgoritDispersePercentage_b2c').style.display = 'block'
                                    break
                                }                           
                                case 2000: {
                                    document.querySelector('._valuePlatformBaseOrder_b2c').style.display = 'block'
                                    document.querySelector('._valuePlatformBaseRef_b2c').style.display = 'block'
                                    document.querySelector('._paramAlgoritDisperseCtoTat_b2c').style.display = 'none'
                                    document.querySelector('._paramAlgoritDispersePercentage_b2c').style.display = 'none'            
                                    break
                                }    
                                case 3000: {
                                    document.querySelector('._valuePlatformBaseOrder_b2c').style.display = 'none'
                                    document.querySelector('._valuePlatformBaseRef_b2c').style.display = 'none'
                                    document.querySelector('._paramAlgoritDisperseCtoTat_b2c').style.display = 'none'
                                    document.querySelector('._paramAlgoritDispersePercentage_b2c').style.display = 'none'            
                                    break
                                }
                            }
    
                            let cadena = result.data.img
                            const inicioImg = cadena.lastIndexOf('/') + 1
                            const finImg = cadena.length
                            const cadenaExtraida = cadena.substring(inicioImg, finImg);
                            document.getElementById("updOldImg").value = cadenaExtraida
                            
                            $("#updactCedi option[value=" + result.data.actCedi + "] ").attr('selected', 'selected');
                            $("#updvitrinaB2bStatus option[value=" + result.data.vitrinaB2bStatus + "] ").attr('selected', 'selected');
                            $("#updvitrinaB2cStatus option[value=" + result.data.vitrinaB2cStatus + "] ").attr('selected', 'selected');
    
                            $("#updmethodEmail option[value=" + result.data.methodEmail + "] ").attr('selected', 'selected');
    
                            $("#upd typeDisperse_b2b option[value=" + result.data. typeDisperse_b2b + "] ").attr('selected', 'selected');
                            $("#upd typeDisperse_b2c option[value=" + result.data. typeDisperse_b2c + "] ").attr('selected', 'selected');
    
                            if (result.data.socialRef){
                                let socialRef = result.data.socialRef
                                socialRef.forEach(element => {
                                    document.getElementById(element.name).value = element.url
                                })
                            }    
    
                            let coordenadas = JSON.parse(result.data.coordinates)
                            for (var clave in coordenadas) {
                                if (coordenadas.hasOwnProperty(clave)) {
                                    document.getElementById("upd" + clave).value = coordenadas[clave]
                                }
                            }    
                        } else 
                            closeSession()
                    },
                )
                .catch(error => alertaGeneral("Problemas de conexión (44)") )
        }

        function updateForm() {
            sessionStorage.setItem("typeDisperse_b2b",$("#updtypeDisperse_b2b").val())
            sessionStorage.setItem("typeDisperse_b2c",$("#updtypeDisperse_b2c").val())
            let nameAdmin = document.getElementById("updnameAdmin").value
            let phoneAdmin = document.getElementById("updphoneAdmin").value
            let emailAdmin = document.getElementById("updemailAdmin").value
            let emailServiceClient = document.getElementById("updemailServiceClient").value
            let lat = document.getElementById("updlat").value
            let lng = document.getElementById("updlng").value
            let radio = document.getElementById("updradio").value

            let name = document.getElementById("updname").value 
            let description = document.getElementById("upddescription").value 
            let slogan = document.getElementById("updslogan").value 
            let textTyc = document.getElementById("updtextTyc").value 
            let textPd = document.getElementById("updtextPd").value 
            let textAutoriza = document.getElementById("updtextAutoriza").value 
            let valuePlatformBaseOrder_b2b = document.getElementById("updvaluePlatformBaseOrder_b2b").value
            let valuePlatformBaseRef_b2b = document.getElementById("updvaluePlatformBaseRef_b2b").value
            let percentageFreeDisperse_b2b = document.getElementById("updpercentageFreeDisperse_b2b").value
            let percentageOrdenMin_b2b = document.getElementById("updpercentageOrdenMin_b2b").value
            let percentageLimitSalesHigher_b2b = document.getElementById("updpercentageLimitSalesHigher_b2b").value
                                                                            
            if (lat !== "" && lng !== "" && radio !== "") {
                
                let social = []
                $(".urlsocial").each(function (index) {
                    if (this.value != "") {
                        let url = validURL(this.value)
                        if (url) {
                            social.push({
                                'name': this.id,
                                'url': this.value
                            })
                        }
                    }
                })

                let coordinates = {
                    "lat": lat,
                    "lng": lng,
                    "radio": radio
                }

                $("#updsocialRef").val(JSON.stringify(social))
                $("#updcoordinates").val(JSON.stringify(coordinates))

                if ((phoneAdmin.length >= 7) && (nameAdmin.length >= 3) && (isValidEmail(emailServiceClient)) &&
                    (phoneAdmin >= 6) && (isValidEmail(emailAdmin)) &&
                    (name != "") && (description != "") && (slogan != "") && (textTyc != "") &&
                    (textPd != "") && (textAutoriza != "") && ( valuePlatformBaseOrder_b2b >= 0) &&
                    ( valuePlatformBaseRef_b2b >= 0) && ( percentageFreeDisperse_b2b >= 0) && 
                    ( percentageOrdenMin_b2b >= 0) && ( percentageLimitSalesHigher_b2b >= 0) ) {
                        let banderaVitrinas = true 
                        if ($("#updvitrinaB2bStatus").val() == 1 ) {
                            if (($("#vpPedidoMinimo_b2b").val() < 0)  ||
                                ($("#updtypeFreight_b2b").val() <= 0) ||
                                ($("#updvpValorPorcentualFlete_b2b").val() < 0) ||
                                ($("#updvpValorFlete_b2b").val() < 0) ||
                                ($("#updpaymentmethods_b2b").val() <= 0) ||
                                ($("#updidRoute_b2b").val() <= 0) ||
                                ($("#updvpTipoOferta_b2b").val() < 0) ||
                                ($("#updvpCompraValorFleteCero_b2b").val() < 0) ||
                                ($("#updparamAlgoritDisperse_b2b").val() < 0) ||
                                ($("#updvpTipoDespacho_b2b").val() < 0) ||
                                ($("#updidListPrices_b2b").val() < 0) ||
                                ($("#updidCommercial_b2b").val() < 0) ||
                                ($("#updrelease_b2b").val() == "")  )
                                banderaVitrinas = false
                        }
                        if ($("#updvitrinaB2cStatus").val() == 1 ) {
                            if (($("#vpPedidoMinimo_b2c").val() < 0) ||
                                ($("#updtypeFreight_b2c").val() <= 0) ||
                                ($("#updvpValorPorcentualFlete_b2c").val() < 0) ||
                                ($("#updvpValorFlete_b2c").val() < 0) ||
                                ($("#updpaymentmethods_b2c").val() <= 0) ||
                                ($("#updidRoute_b2c").val() <= 0) ||
                                ($("#updvpTipoOferta_b2c").val() < 0) ||
                                ($("#updvpCompraValorFleteCero_b2c").val() < 0) ||
                                ($("#updparamAlgoritDisperse_b2c").val() < 0) ||
                                ($("#vpCediVisibleEn_b2c").val() < 0) ||
                                ($("#updvpTipoDespacho_b2c").val() < 0) ||
                                ($("#updidListPrices_b2c").val() < 0) ||
                                ($("#updidCommercial_b2c").val() < 0) ||
                                ($("#updrelease_b2c").val() == "") )
                                banderaVitrinas = false
                        }
                        if (banderaVitrinas) {
                            const data = new FormData(document.getElementById('formulario2'))
                            data.append('vitrinaB2bStatus', document.getElementById("updvitrinaB2bStatus").value)
                            data.append('vitrinaB2cStatus', document.getElementById("updvitrinaB2cStatus").value)
                            let token = sessionStorage.getItem('token')
                            fetch(Const.urlrest + "api/cedi/update", { // ok
                                headers: { 
                                    /* 'Accept': 'application/json',
                                    'Content-Type': 'application/json', */
                                    'Authorization': token,
                                },
                                method: "PUT",
                                body: data,
                            })
                            .then(response => response.json())
                            .then(
                                (result) => {
                                    if (endSession(result.success)) {
                                        alertaGeneral("Registro  Actualizado");
                                        window.location.reload(false);    
                                    } else 
                                        closeSession()
                                },
                            )
                            .catch(error => alertaGeneral("Problemas de conexión (45)") )
                        } else
                            alertaGeneral("Por favor configure vitrina(s) Activas");   
                } else {
                    alertaGeneral("Todos los campos son obligatorios");
                }
            } else {
                alertaGeneral("Verifica las coordenadas y/o radio");
            }

            const data1 = {
                idCedi : document.getElementById("updid").value,
                vpPedidoMinimo_b2b : $("#updvpPedidoMinimo_b2b").val(),
                typeFreight_b2b : $("#updtypeFreight_b2b").val(),
                idRoute_b2b : $("#updidRoute_b2b").val(),
                vpValorPorcentualFlete_b2b : $("#updvpValorPorcentualFlete_b2b").val(),
                paymentmethods_b2b : $("#updpaymentmethods_b2b").val(),
                vpValorFlete_b2b : $("#updvpValorFlete_b2b").val(),
                vpTipoOferta_b2b : $("#updvpTipoOferta_b2b").val(),
                vpCompraValorFleteCero_b2b : $("#updvpCompraValorFleteCero_b2b").val(),
                vpTipoDespacho_b2b : $("#updvpTipoDespacho_b2b").val(),
                nidListPrices_b2b : $("#updidListPrices_b2b").val(),
                idCommercial_b2b : $("#updidCommercial_b2b").val(),
                idTypeBusiness_b2b : $("#updidTypeBusiness_b2b").val(),
                release_b2b : $("#updrelease_b2b").val(),
                vpCediVisibleEn_b2b : $("#updvpCediVisibleEn_b2b").val(),
                paramAlgoritDisperseCtoTat_b2b: $("#updparamAlgoritDisperseCtoTat_b2b").val(),
                paramAlgoritDispersePercentage_b2b: $("#updparamAlgoritDispersePercentage_b2b").val(),
                selectTatVitrinaB2b : $("#updselectTatVitrinaB2b").val(),
            }
            let token = sessionStorage.getItem('token')
            let a = fetch(Const.urlrest + "api/cedi/updatevitrinab2b", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "PUT",
                body: JSON.stringify(data1),
            }).then(response => response.json())
                .then(result => { 
                    if (!endSession(result.success))
                        closeSession()
            }).catch(error => alertaGeneral("Problemas de conexión (46)")) 

            const data2 = {
                idCedi : document.getElementById("updid").value,
                vpPedidoMinimo_b2c : $("#updvpPedidoMinimo_b2c").val(),
                typeFreight_b2c : $("#updtypeFreight_b2c").val(),
                idRoute_b2c : $("#updidRoute_b2c").val(),
                vpValorPorcentualFlete_b2c : $("#updvpValorPorcentualFlete_b2c").val(),
                paymentmethods_b2c : $("#updpaymentmethods_b2c").val(),
                vpValorFlete_b2c : $("#updvpValorFlete_b2c").val(),
                vpTipoOferta_b2c : $("#updvpTipoOferta_b2c").val(),
                vpCompraValorFleteCero_b2c : $("#updvpCompraValorFleteCero_b2c").val(),
                vpTipoDespacho_b2c : $("#updvpTipoDespacho_b2c").val(),
                nidListPrices_b2c : $("#updidListPrices_b2c").val(),
                idCommercial_b2c : $("#updidCommercial_b2c").val(),
                idTypeBusiness_b2c : $("#updidTypeBusiness_b2c").val(),
                release_b2c : $("#updrelease_b2c").val(),
                vpCediVisibleEn_b2c : $("#updvpCediVisibleEn_b2c").val(),
                paramAlgoritDisperseCtoTat_b2c: $("#updparamAlgoritDisperseCtoTat_b2c").val(),
                paramAlgoritDispersePercentage_b2c: $("#updparamAlgoritDispersePercentage_b2c").val(),
                selectTatVitrinaB2c : $("#updselectTatVitrinaB2c").val(),
            }
            let b = fetch(Const.urlrest + "api/cedi/updatevitrinab2c", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "PUT",
                body: JSON.stringify(data2),
            }).then(response => response.json())
                .then(result => {     
                    if (!endSession(result.success))
                        closeSession()
            }).catch(error => alertaGeneral("Problemas de conexión (47)")) 
        }
        var la = document.getElementById('btnupdate');
        if (la) {
            la.addEventListener("click", updateForm);
        }
        $("#updpassword").on("change",function(e) {
            let var_pass = sha256(document.getElementById("updpassword").value)
            $('#updpassword').val(var_pass) 
        })

        formEdit()

    }
  render(){

    let accessUser = JSON.parse(sessionStorage.getItem('access'))
    let boolUnidadNegocioDatos = accessUser.includes('37')
    let boolUnidadNegocioLook = accessUser.includes('38') 
    let boolAppAsoDatos = accessUser.includes('39')
    let boolAppAsoLook = accessUser.includes('40')
    let boolAppMbDatos = accessUser.includes('41')
    let boolAppMbLook = accessUser.includes('42')

    let hayWhiteBrand = sessionStorage.getItem("WhiteBrand")
    let hayWhiteBrand_b2c = sessionStorage.getItem("WhiteBrand_b2c")

    let onOffLook = sessionStorage.getItem("onOffLook")

    return (          
        <div>
            <Headerdashboard/>
            <Sidebar />  
            <AlertGeneral /> 
            <div className="page-wrapper">                    
                <div className="row page-titles">
                    <div className="col-md-2.5 align-self-center">
                        <h3 className="text-primary">Unidad de Negocio</h3> 
                    </div>
                    { 
                        boolUnidadNegocioDatos
                    ? 
                        <label style = {{paddingLeft:30, fontSize:23}} id="ModUsuarioFdl"><a href="./cediconfigCEDI">Datos</a></label>
                    :
                        null
                    }
                    {
                        boolUnidadNegocioLook && (onOffLook != 0)
                    ?
                        <label style = {{paddingLeft:20, fontSize:23, paddingRight:50}} id="ModUsuarioFdc"><a href="./cediconfiglookCedis">Look</a></label>
                    :
                        null
                    }    
                    { 
                        boolAppAsoDatos && (hayWhiteBrand != 0)
                    ? 
                        <>
                            <div className="col-md-1.5 align-self-center">
                                <h3 style = {{marginLeft:10}} className="text-primary">App Mb B2B</h3> 
                            </div>
                            <label style = {{ fontSize:23, marginLeft:30}} id="ModUsuarioFdl">
                                <a 
                                    href="./cediconfigMARKETPLACE"> Datos
                                </a>
                            </label>
                        </>
                    :
                        null
                    }
                    {
                        boolAppAsoLook && (hayWhiteBrand != 0)
                    ?
                        <label style = {{paddingLeft:10, fontSize:23, paddingRight:50}} id="ModUsuarioFdc"><a href="./cediconfiglookmarketplace">Look</a></label>
                    :
                        null
                    }    
                    { 
                        boolAppMbDatos && (hayWhiteBrand_b2c != 0)
                    ? 
                        <>
                            <div className="col-md-1.5 align-self-center">
                                <h3 style = {{marginLeft:30}} className="text-primary">App Mb B2C</h3> 
                            </div>
                            <label style = {{ fontSize:23, marginLeft:30}} id="ModUsuarioFdl">
                                <a 
                                    href="./cediconfigMARKETPLACE_b2c"> Datos
                                </a>
                            </label>
                        </>
                    :
                        null
                    }
                    {
                        boolAppMbLook && (hayWhiteBrand_b2c != 0)
                    ?
                        <label style = {{paddingLeft:20, fontSize:23}} id="ModUsuarioFdc"><a href="./cediconfiglookmarketplace_b2c">Look</a></label>
                    :
                        null
                    }    
                </div>
                <UpdateData />
            </div>
        </div>
      )
  }
}

export default configCedi;