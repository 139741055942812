import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession, alertaGeneral, closeSession} from '../../assets/js/GeneralScript'

class InputListPrices extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.typeClient !== prevProps.typeClient) {
            const idCedi = sessionStorage.getItem("idCedi")
            let mostrar = parseInt(this.props.mostrar)
            const typeClient = this.props.typeClient
            switch (typeClient) {
                case "1": { // B2B
                    mostrar = 3
                    break
                }
                case "2": { // B2C
                    mostrar = 4
                    break
                }
            }
            const datos = {idCedi : idCedi, mostrar: mostrar }
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/listpricescustom",{ // ok
                method: 'POST',
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify(datos)
            })
            .then(response => response.json())
            .then((result) => {
                if (endSession(result.success)) {
                    this.setState({ items: result.data })
                    this.props.actualizarDatosListPrices(this.state.items[0]['id'],this.state.items[0]['name'])    
                } else 
                    closeSession()
            }).catch(error => alertaGeneral("Problemas de Conexión (11)"))    
        }
    }


    componentDidMount  = async()=>{
        let idCedi = sessionStorage.getItem("idCedi")
        const datos = {idCedi : idCedi }
        let token = sessionStorage.getItem('token')
        await fetch(Const.urlrest + "api/listpricescustom",{ // ok
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        }).then(response => response.json())
        .then((result) => {
            if (endSession(result.success)) {
                this.setState({ items: result.data })
                this.props.actualizarDatosListPrices(this.state.items[0]['id'],this.state.items[0]['name'])        
            } else 
                closeSession()
        }).catch(error => alertaGeneral("Problemas de Conexión (12)"))
    }

    render(){
    
        const doesNotApply = this.props.doesNotApply
        const auxName = this.props.auxName
        const auxId = this.props.auxId
    
        return  (
            <React.Fragment>                
                <div className="col-md-7">
                    <div className="form-group">
                        <label className=" control-label">Lista de precios</label>
                        <select  className="form-control" id = {auxId} name = {auxName} disabled = {this.props.disabled} 
                                 onChange = {(e) => this.props.parentCallback(e, e.target.selectedIndex)} 
                        > 
                            {this.state.items.map(item => (
                                <option value={item.id} key={item.id} >{item.name}</option>
                            ))}                                          
                        </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default InputListPrices