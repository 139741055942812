import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';

document.body.classList.add('fix-sidebar');

class avertisingcedi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            renderizar : true,
            controlEdit : false,
            controlDelete : false,
        };
    }

    componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi")  // Cambio
        let btnAlertConfirm = document.querySelector("#btnAlertConfirm") 

        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            var oldImg1 = document.getElementById("btnAlertConfirm").getAttribute("data-nimg1")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid,oldImg1)
            }
        }

        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        } 

        function formDelete(id,oldImg1) {
            let inicioImg = oldImg1.lastIndexOf('/') + 1
            let finImg = oldImg1.length
            let cadenaExtraida1 = oldImg1.substring(inicioImg, finImg);

            if (id >= 1) {
                var datos = {
                    id: id,
                    oldImg1 : cadenaExtraida1,
                }
                let token = sessionStorage.getItem('token')  
                fetch(Const.urlrest + "api/advertisingcedi/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Eliminado");
                            window.location.reload();    
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (2)"))
            } else {
                alertaGeneral("Validar la información ingresada");
            }
        }

        let dtToday = new Date();
        let month = dtToday.getMonth() + 1;
        let day = dtToday.getDate();
        let year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        var minDate = year + '-' + month + '-' + day;
        var updschedule = document.getElementById('updschedule');
        updschedule.setAttribute("min", minDate);
        let datos = {idCedi: idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/advertisingcedi",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (3)"))

        $(document).off().on('click', '.btnupdate', function () {
            const registerValid = sessionStorage.getItem("registerValid")   
            if (registerValid == "true") {
                $("#updcedi").val(idCedi);
                let schedule = $("#updschedule").val()
                // let valurl = validURL(link)
                // if (valurl) {        
                    if (schedule.length >= 4) {
                        const data = new FormData(document.getElementById('formularioupdate'));
                        let token = sessionStorage.getItem('token')
                        fetch(Const.urlrest + "api/advertisingcedi/update", {  // ok                     
                                method: "PUT",
                                headers: { 
                                    'Authorization': token,
                                },
                                body: data
                            })
                            .then(response => response.json())
                            .then(
                                (result) => {
                                    if (endSession(result.success)) {
                                        alertaGeneral("Registro  Actualizado")
                                        window.location.reload(false)    
                                    } else 
                                        closeSession()
                                }
                            ).catch(error => alertaGeneral("Problemas de Conexión (4)"))
                    } else {
                        alertaGeneral("Validar La información ingresada");
                    }
                // } else {
                //  alertaGeneral("El link ingresado no es valido");
                // }
            } else
                alertaGeneral("La imagen seleccionada no es valida, no se permite la operación de Actualización")    
        });
        var v1, v2
        var access = JSON.parse(sessionStorage.getItem('access')) // Cambio
        access.forEach(function (element) {  
            if (element == 14)
                v1 = true
            if (element == 15)
                v2 = true
        })
        if (v1)
            this.setState({ controlEdit : true })
        if (v2)
            this.setState({ controlDelete : true })

    }

    render(){
    
        function formEdit(id) {
            sessionStorage.setItem("registerValid", true)  // Cambio
            window.scrollTo(0, 0);
            document.querySelector('.formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')  // Cambio
            fetch(Const.urlrest + "api/advertisingcedi/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            }).then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        $("#updapps").val(result.data.persAsoAll)
                        $("#updtypeBusiness").val(result.data.typeBusiness)
                        $("#updid").val(result.data.id)
                        $("#updlink").val(result.data.link)
                        $("#updschedule").val(result.data.schedule);
                        $("#upddescription").val(result.data.description)
                        $("#updidListPrices option[value=" + result.data.listPrices + "] ").attr('selected', 'selected');
                        $("#updapps option[value=" + result.data.persAsoAll + "] ").attr('selected', 'selected');
                        document.getElementById("imgshow").src = result.data.img
    
                        let cadena = result.data.img
                        const inicioImg = cadena.lastIndexOf('/') + 1
                        const finImg = cadena.length
                        const cadenaExtraida = cadena.substring(inicioImg, finImg);
                        document.getElementById("updOldImg").value = cadenaExtraida    
                    } else 
                        closeSession()
                },
            )
            .catch(erro => alertaGeneral("Problemas de conexión (5)"))   
        }

        const { items } = this.state

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('13')
        let boolEdit = accessUser.includes('14') 
        let boolDelete = accessUser.includes('15')

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Imágenes Publicitarias</h3> 
                        </div>
                        { 
                        boolCrear
                    ? 
                        <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./advertising">Crear</a></label>
                    :
                        null
                    }
                    {
                        boolEdit || boolDelete 
                    ?
                        <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./advertisinglist">Datos</a></label>
                    :
                        null
                    }    
                    </div>
                     <UpdateData renderizar = {this.state.renderizar} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">  
                                        <div className="table-responsive m-t-40">                                                                                                 
                                            <MaterialTable
                                                title="Datos"
                                                columns={[
                                                    { title: 'Id', field: 'id', width:8 },
                                                    { title: 'Límite', field: 'schedule', width:150 },
                                                    { title: "Visible", field: "persAsoAll", lookup: { 0: 'Todo', 1: 'Marca Blanca', 2: 'Asociativo' },width: 150  },
                                                    { title: 'Descripción', field: 'description', width: 1000 },                                                        
                                                ]}
                                                data = {
                                                    items
                                                }
                                                options={{
                                                    headerStyle: {
                                                        backgroundColor: '#01579b',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: true
                                                }}
                                                actions={[
                                                    {
                                                    icon: 'edit',
                                                    hidden: !this.state.controlEdit,
                                                    iconProps: { style: { color: "#00569b" } },
                                                    tooltip: 'Save User',
                                                    onClick: (event, rowData) => formEdit(rowData.id)
                                                    },
                                                    rowData => ({
                                                        icon: 'delete',
                                                        hidden: !this.state.controlDelete,
                                                        iconProps: { style: { color: "#ff5722" } },
                                                        tooltip: 'Delete User',
                                                        onClick: (event, rowData) => alertaConfirm(rowData.id,rowData.img)
                                                    })
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>          
                    </div>
                </div>
            </div>
        )
    }
}

export default avertisingcedi;