import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeAlertGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'
import InputStatus from '../atoms/InputStatus'
import InputTypeBusiness from '../atoms/InputTypeBusiness'
import InputListPricesPromotions from '../atoms/InputListPricesPromotions'
import InputProductoPromotions from '../atoms/InputProductoPromotions'

document.body.classList.add('fix-sidebar')

class Category extends Component {
    
    constructor(props) {
        super(props);
        this.state = {   
            products: [], //ok
            typeClient: "1", //ok
            typeBusiness: "", //ok
            idListPrices: "", //ok
            officeUnit: "0", //ok
            onlyListPrices: "0", //ok
            nameListPrices: "Lista de Precios B2B", //ok
            items: [], //ok
        };
    }
    componentDidMount() { 

        const idCedi = sessionStorage.getItem("idCedi");

        const varOnlyListPrice = document.querySelector("#idListPrices");
        varOnlyListPrice.addEventListener('change',updateOnlyListPrice);
        document.querySelector('.flagOnlyListPrice').style.display = 'none'

        function updateOnlyListPrice(e) {
            let combo = document.getElementById("idListPrices");
            let selected = combo.options[combo.selectedIndex].text;
            if ((selected.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2B") && (selected.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2C"))
                document.querySelector('.flagOnlyListPrice').style.display = 'block'
            else 
                document.querySelector('.flagOnlyListPrice').style.display = 'none'
        }

        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")
 
        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid)
            }
        }
        
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral); 
        } 
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }
        
        function formDelete(id) {
            if (id >= 1) {
                var datos = {
                    id: id
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/promotion/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data == '1') {
                                alertaGeneral("Registro  Eliminado");
                                window.location.reload();
                            } else
                                alertaGeneral("Este dato esta asociado a otro concepto, por lo cual no se puede eliminar")                
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (82)"))
            } else {
                alertaGeneral("Validar la información ingresada");
            }
        }

        let datos = {idCedi : idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/promotion",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (83)"))
    }

    addRegister (idCedi) {
        const toDay= new Date().toISOString().substring(0, 10);

        let id = $("#updid").val();
        let name = $("#creaname").val();
        let status = $("#updstatus").val();
        let description = $("#credescription").val();
        let porcentageOff = parseFloat($("#credporcentageOff").val());
        let officeUnit = $("#credofficeUnit").val();
        let deadLine = $("#credeadLine").val();
        let idTypeBusiness = $("#idTypeBusiness").val();
        let idListPrices = $("#idListPrices").val();
        let maximumQuantity = $("#credmaximumQuantity").val();
        let products = JSON.stringify(this.state.products)
        let typeClient = $("#typeClient").val();
        let onlyListPrices = $("#updonlyListPrices").val();
        let listProducts = JSON.parse(products)
        let flag = true
        let idTypeBusinessTodos
        if ("Todos" == $('select[name="idTypeBusiness"] option:selected').text())
            idTypeBusinessTodos = "Todos"
        else
            idTypeBusinessTodos = 0

        listProducts.map(items => {
            if ((items['Producto'] == "") || (items['Producto'] == "-1") || (items['Cantidad'] < 1 ))
                flag = false
        })
        if (name.length >= 4 && description.length >= 5 &&  listProducts.length != 0 &&
            maximumQuantity >= 1 && porcentageOff >= 0.1 && deadLine >= toDay && flag ) {
            var datos = {
                id: id,
                name: name,
                idCedi: idCedi,
                description: description,
                status: status,
                description : description,
                deadLine : deadLine,
                idTypeBusiness : idTypeBusiness,
                idTypeBusinessTodos : idTypeBusinessTodos,
                idListPrices : idListPrices,
                maximumQuantity : maximumQuantity,
                products : products,
                typeClient : typeClient,
                porcentageOff : porcentageOff,
                onlyListPrices : onlyListPrices,
                officeUnit : officeUnit,
            }
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/promotion/update", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "PUT",
                body: JSON.stringify(datos)
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        alertaGeneral("Registro  Actualizado")
                        window.location.reload()    
                    } else 
                        closeSession()
                }
            ).catch(error => alertaGeneral("Problemas de Conexión (84)"))
        } else {
            alertaGeneral("Todos los datos son obligatorios");
        }
    }

    addFormFields() {
        this.setState(({ products: [...this.state.products, { Producto: "", Cantidad: "" }] }))
    }

    removeFormFields(i) {
        let products = this.state.products;
        products.splice(i, 1);
        this.setState({ products });
    }

    handleChange(i, e) {
        let products = this.state.products;
        products[i][e.target.name] = e.target.value;
        this.setState({ products });
    }

    handleProduct = (e, index) => {
        let valorBuscado = e.target.value
        let products = this.state.products;
        let resultado = products.findIndex(item => item.Producto == valorBuscado)
        if (resultado == -1) {
            products[ index ]['Producto'] = e.target.value;
            this.setState({ products });
        } else
          alertaGeneral("Producto Repetido, seleccione otro")
    }

    handleCallback = (childData) =>{
        this.setState({typeBusiness: childData})
    }

    parentListPrices = (e,index) =>{
        this.setState({ idListPrices : e.target.value})
        this.setState({ nameListPrices : e.target.options[index].text })
        this.setState({ products : []})
    }

    handleActualizarDatosListPrices = (id,name) => {
        this.setState({ idListPrices : id })
        this.setState({ nameListPrices : name })
    }

    handleTypeClient(e) {
        this.setState({typeClient : e.target.value})
        this.setState({ products : []})
    }
    
    handleOnlyListPrices (e) {
        this.setState({onlyListPrices : e.target.value})
        this.setState({ products : []})
    }

    handleOfficeUnit(e) {
        this.setState({officeUnit:e.target.value})
        this.setState({products : []})
    }

    formEdit(id) {
        let combo2, selected2
           
        window.scrollTo(0, 0);
        document.querySelector('.formupdate').style.display = 'block'
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/promotion/read",{ // ok
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            method: "POST",
            body: JSON.stringify({
                id: id
            })
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success)) {
                    $("#creaname").val(result.data.name)
                    $("#updstatus").val(result.data.status);
                    $("#credescription").val(result.data.description)
                    $("#credeadLine").val(result.data.deadLine)
                    $("#idTypeBusiness").val(result.data.idTypeBusiness)
                    $("#idTypeBusiness option[value=" + result.data.idTypeBusiness + "] ").attr('selected', 'selected');
                    $("#idListPrices").val(result.data.idListPrices)
                    $("#idListPrices option[value=" + result.data.idListPrices + "] ").attr('selected', 'selected');
                    $("#credmaximumQuantity").val(result.data.maximumQuantity)
                    $("#credporcentageOff").val(result.data.porcentageOff)
                    $("#credofficeUnit").val(result.data.officeUnit)
                    $("#updid").val(result.data.id)
                    $("#updidcedi").val(result.data.idCedi)
                    $("#updstatus option[value=" + result.data.status + "] ").attr('selected', 'selected');
                    $("#typeClient").val(result.data.typeClient)
                    this.setState({typeClient : result.data.typeClient})
                    $("#typeClient option[value=" + result.data.typeClient + "] ").attr('selected', 'selected');
                    $("#updonlyListPrices").val(result.data.onlyListPrices);
                    this.setState({products : JSON.parse(result.data.products)})    
                } else 
                    closeSession()        
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (85)"))    
    }

    render(){

        let permisos = []

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('43')
        let boolEdit = accessUser.includes('44') 
        let boolDelete = accessUser.includes('45')
        
        const { items } = this.state;
        const toDay= new Date().toISOString().substring(0, 10);
        const idCedi = sessionStorage.getItem("idCedi")
        
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Promociones Estrella</h3> 
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./promoestrella">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEdit || boolDelete 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./promoestrellalist">Datos</a></label>
                        :
                            null
                        }    
                        <div className="col-md-7 align-self-center">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#nogp">Home</a></li>
                                <li className="breadcrumb-item active">Listar </li>
                            </ol>
                        </div>
                    </div>
                    
                    <React.Fragment>                   
                        <div className="container-fluid formupdate" id="formupdate">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-title">
                                            <h4>Actualizar </h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="horizontal-form">
                                                <div className="form-horizontal" id="formupdate" >
                                                    <input type="hidden" className="form-control" placeholder="" name="name" id="name" required="required"   />
                                                    <input type="hidden" className="form-control" placeholder="" name="idCedi" id="cedi" required="required" />       
                                                    <input type="hidden" className="form-control" placeholder="" name="id" id="updid" required="required" />       
                                                    <div className="row p-t-20">
                                                        <InputStatus />
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Tipo de Cliente</label>
                                                                <select className="form-control" id="typeClient" name="typeClient" disabled ={false} 
                                                                        onChange = {(e) => this.handleTypeClient(e)}
                                                                        defaultValue = "1"
                                                                >
                                                                    <option value="1">B2B</option>
                                                                    <option value="2">B2C</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">Nombre Promoción </label>
                                                                <input type="text" className="form-control" placeholder="" name="name" id="creaname" required="required" max="45" />
                                                                <label className="msglabel">Mínimo 4 caracteres</label>
                                                            </div>
                                                        </div>                                                   
                                                    </div>
                                                    <div className="row p-t-20">                                                              
                                                        <div className="col-md-12">
                                                            <label className=" control-label">Descripción</label>
                                                            <textarea name ="credescription" id="credescription" max="175" ></textarea>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Fecha Límite</label>
                                                                <input type="date" className="form-control" id="credeadLine"/>
                                                            </div>                                                                                        
                                                        </div>
                                                        <InputTypeBusiness auxName="idTypeBusiness" auxId="idTypeBusiness" mostrar="4" disabled={false} parentCallback = {this.handleCallback}/>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Promos Máximo a llevar</label>
                                                                <input type="number" className="form-control" id="credmaximumQuantity" value="1" min = {1}/>
                                                            </div>                                                                                        
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">% Descuento</label>
                                                                <input type="number" className="form-control" id="credporcentageOff" min = {0.10} step = {0.10}/>
                                                            </div>                                                                                        
                                                        </div>
                                                        <InputListPricesPromotions auxName="idListPrices" auxId="idListPrices" doesNotApply="false" 
                                                                         parentCallback = {this.parentListPrices} 
                                                                         actualizarDatosListPrices = {this.handleActualizarDatosListPrices} 
                                                        /> 
                                                        
                                                        <div className="col-md-4 flagOnlyListPrice">
                                                            <div className="form-group">
                                                                <label className=" control-label">Mostrar solo los productos de esta lista</label>
                                                                <select name="onlyListPrices" className="form-control" id="updonlyListPrices" 
                                                                        onChange={(e) => this.handleOnlyListPrices(e)} disabled = {true}
                                                                >
                                                                    <option value="0">Si</option>
                                                                    <option value="1">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Unidad de Despacho</label>
                                                                <select
                                                                    name="officeUnit"
                                                                    className="form-control"
                                                                    id="credofficeUnit"
                                                                    defaultValue="0"
                                                                    onChange = {(e) => this.handleOfficeUnit(e)}
                                                                    disabled = {false}
                                                                >   
                                                                    <option value="0">Todas</option>
                                                                    <option value="1">Unidades</option>
                                                                    <option value="2">UMD</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row p-t-20">                                                          
                                                        <div className="col-md-12">
                                                            <div className="form-group form-group-faqs">
                                                                <div className="setProducts">
                                                                    <button className="buttomAddProduct" onClick={() => this.addFormFields()}>Agregar Producto</button>
                                                                    <div style={{marginBottom:10}}></div>
                                                                    {this.state.products.map((element, index) => (
                                                                        <div className="groupProducts" key={index} > 
                                                                            <button className="lessProducts" onClick={() => this.removeFormFields(index)}>-</button>
                                                                            <InputProductoPromotions typeClient = {this.state.typeClient} idListPrices = {this.state.idListPrices}
                                                                                idCedi = {idCedi} typeOffert = {this.state.officeUnit} onlyListPrices = {this.state.onlyListPrices}
                                                                                nameListPrices = {this.state.nameListPrices} handleProducts = {this.handleProduct} 
                                                                                index = {index} products = {this.state.products}
                                                                            />
                                                                            <label className = "control-label" style={{marginLeft:15, marginRight:15, flexDirection: "row"}} >Cantidad</label>
                                                                            <input /* className = "form-control" */ type="text" name="Cantidad" value={element.Cantidad } min = {1} style = {{ max: 10, flexDirection: "row" }} onChange={e => this.handleChange(index, e)} />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>              
                                                        </div>
                                                    </div>  
                                                    <div className="col-sm-offset-2 col-sm-10">
                                                        <button type="button" className="btn btn-default btnupdate" onClick={() => this.addRegister(idCedi)}>Actualizar Promoción</button>
                                                    </div>               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>          
                    
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive m-t-40">                                            
                                            <MaterialTable
                                                title="Datos"
                                                columns={[
                                                    { title: 'Id', field: 'id', width: 10 },
                                                    { title: 'Tipo de Cliente', width: 155, field:'typeClient', lookup: {1: 'B2B', 2: 'B2C'}},
                                                    { title: 'Estado', field: 'status', width: 30,lookup: {1: 'Activo', 2: 'Inactivo'}}, 
                                                    { title: 'Nombre', field: 'name' }
                                                ]}
                                                data = {
                                                    items
                                                }
                                                options={{
                                                    //exportButton: true,
                                                    headerStyle: {
                                                        backgroundColor: '#01579b',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: true
                                                }}
                                                actions={[
                                                    {
                                                    icon: 'edit',
                                                    hidden: !boolEdit,
                                                    iconProps: { style: { color: "#00569b" } },
                                                    tooltip: 'Save User',
                                                    onClick: (event, rowData) => this.formEdit(rowData.id)
                                                    },
                                                    rowData => ({
                                                        icon: 'delete',
                                                        hidden: !boolDelete,
                                                        iconProps: { style: { color: "#ff5722" } },
                                                        tooltip: 'Delete User',
                                                        onClick: (event, rowData) => alertaConfirm(rowData.id)
                                                    })
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Category;