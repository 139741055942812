import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import $ from 'jquery';
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'
import * as xlsx from 'xlsx'

document.body.classList.add('fix-sidebar')

class load extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validXlsx : false,
            validImage : false,
            nameFile : "",
            json : {},
        }
    }    

    componentDidMount() {          
    }  

    render(){

      const readUploadImage = (e) => {
          if (e.target.value != "") {
              let idImages = document.getElementById("idImages")
              if (idImages.files.length >= this.state.json.length) {
                  if (this.state.validXlsx) {
                    let error = false
                    this.state.json.map(caso => {
                        if (error == false) {
                            error = true
                            for (const file of idImages.files) {
                                if (caso.image1 == file.name) {
                                    error = false
                                }
                            }    
                        }
                    })
                    if (!error)
                        this.setState({ validImage : true })
                    else {
                      this.setState({ validImage : false })
                      alertaGeneral("No hay coincidencia entre las imagenes reportadas en productos.xlsx y las seleccionadas")
                    }
                }
              } else
                  alertaGeneral("No Coincide el número de registros en productos.xlsx con el número de imagenes seleccionadas")
          } else {
              this.setState({ validImage : false})
          }
      }

      function sortJSON(data, key, orden) {
        return data.sort(function (a, b) {
            var x = a[key],
            y = b[key];
    
            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
    
            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
      }

      const readUploadFile = (e) => {
        e.preventDefault();
        let fileExcel = e.target.files
        if (e.target.files) {
            if (fileExcel[0].name != "productos.xlsx") {
                this.setState({ nameFile : "" })
                this.setState({ validXlsx : false })
                alertaGeneral("Esta intentando cargar un archivo no autorizado")
            } else {
                this.setState({ nameFile : fileExcel[0].name })
                this.setState({ validXlsx : true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = xlsx.read(data, { type: "array" })
                    const sheetName = workbook.SheetNames[0]
                    const worksheet = workbook.Sheets[sheetName]
                    const json = xlsx.utils.sheet_to_json(worksheet)
                    if (json.length !=0) {
                        this.setState({ json : json })
                        let error = false
                        json.map((item) => {
                            if (!error) {
                              if (!item.hasOwnProperty('typeOffert')) {
                                error = true
                                alertaGeneral("No existe el campo: typeOffert")
                              } else
                                  if ((typeof(item.typeOffert) != "number") || (item.typeOffert <= 0) || (item.typeOffert >=3)) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo typeOffert esta vacio o el tipo de dato no es number o valor incorrecto (1:Und, 2: UMD")
                                  }
                              if (!item.hasOwnProperty('idCategory')) {
                                  error = true
                                  alertaGeneral("No existe el campo: idCategory")
                              } else
                                  if ((typeof(item.idCategory) != "number") || (item.idCategory == 0)) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo idCategory esta vacio o el tipo de dato no es number")
                                  }
                              if (!item.hasOwnProperty('featured')) {
                                  error = true
                                  alertaGeneral("No existe el campo: featured")
                              } else
                                  if ((typeof(item.featured) != "number") || (item.featured < 0) || (item.featured > 2)) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo featured esta vacio o el tipo de dato no es number o valor invalido (0: No destacado, 1: Destacado")
                                  }
                              if (!item.hasOwnProperty('name')) {
                                  error = true
                                  alertaGeneral("No existe el campo: name")
                              } else
                                  if ((typeof(item.name) != "string") || (item.name.trim() == "")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo name esta vacio o el tipo de dato no es string")
                                  }
                              /* if (!item.hasOwnProperty('nameTirilla')) {
                                  error = true
                                  alertaGeneral("No existe el campo: nameTirilla")
                              } */ 
                              /* if (!item.hasOwnProperty('description')) {
                                  error = true
                                  alertaGeneral("No existe el campo: description")
                              } */ 
                              /* if (!item.hasOwnProperty('link')) {
                                  error = true
                                  alertaGeneral("No existe el campo: link")
                              } */ 
                              if (!item.hasOwnProperty('image1')) {
                                  error = true
                                  alertaGeneral("No existe el campo: image1")
                              } else
                                  if ((typeof(item.image1) != "string") || (item.image1.trim() == "")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo image1 esta vacio (es la única imagen obligatoria) o el tipo de dato no es string")
                                  }
                              /* if (!item.hasOwnProperty('image2')) {
                                  error = true
                                  alertaGeneral("No existe el campo: image2")
                              } */ 
                              /* if (!item.hasOwnProperty('image3')) {
                                  error = true
                                  alertaGeneral("No existe el campo: image3")
                              } */ 
                              if (!item.hasOwnProperty('priceTax')) {
                                  error = true
                                  alertaGeneral("No existe el campo: priceTax")
                              } else
                                  if ((typeof(item.priceTax) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato priceTax no es de tipo number")
                                  }
                              if (!item.hasOwnProperty('orderMinTax')) {
                                  error = true
                                  alertaGeneral("No existe el campo: orderMinTax")
                              } else
                                  if ((typeof(item.orderMinTax) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato orderMinTax no es tipo number")
                                  }
                              if (!item.hasOwnProperty('umd')) {
                                  error = true
                                  alertaGeneral("No existe el campo: umd")
                              } else
                                  if ((typeof(item.umd) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato umd no es number")
                                  }
                              if (!item.hasOwnProperty('priceUmdTax')) {
                                  error = true
                                  alertaGeneral("No existe el campo: priceUmdTax")
                              } else
                                  if ((typeof(item.priceUmdTax) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato priceUmdTax no es number")
                                  }
                              if (!item.hasOwnProperty('orderMinUmd')) {
                                  error = true
                                  alertaGeneral("No existe el campo: orderMinUmd")
                              } else
                                  if ((typeof(item.orderMinUmd) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato orderMinUmd no es number")
                                  }
                              if (!item.hasOwnProperty('sku')) {
                                  error = true
                                  alertaGeneral("No existe el campo: sku")
                              } else
                                  if ((typeof(item.sku) != "string") || (item.sku.trim() == "")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo sku esta vacio o el tipo de dato no es string")
                                  }
                              /* if (!item.hasOwnProperty('barcode')) {
                                  error = true
                                  alertaGeneral("No existe el campo: barcode")
                              } else
                                  if ((typeof(item.barcode) != "string")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo barcode esta vacio o el tipo de dato no es string")
                                  } */
                              if (!item.hasOwnProperty('status')) {
                                  error = true
                                  alertaGeneral("No existe el campo: status")
                              } else
                                  if ((typeof(item.status) != "number") || (item.status <= 0) || (item.status >= 3)) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo status esta vacio o el tipo de dato no es number o el valor es incorrecto (1: Activo, 2: Inactivo")
                                  }
                              if (!item.hasOwnProperty('umd_b2c')) {
                                  error = true
                                  alertaGeneral("No existe el campo: umd_b2c")
                              } else
                                  if ((typeof(item.umd_b2c) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato umd_b2c no es number")
                                  }
                              if (!item.hasOwnProperty('priceTax_b2c')) {
                                  error = true
                                  alertaGeneral("No existe el campo: priceTax_b2c")
                              } else
                                  if ((typeof(item.priceTax_b2c) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato priceTax_b2c no es number")
                                  }
                              if (!item.hasOwnProperty('priceUmdTax_b2c')) {
                                  error = true
                                  alertaGeneral("No existe el campo: priceUmdTax_b2c")
                              } else
                                  if ((typeof(item.priceUmdTax_b2c) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato de priceUmdTax_b2c no es number")
                                  }
                              if (!item.hasOwnProperty('orderMinTax_b2c')) {
                                  error = true
                                  alertaGeneral("No existe el campo: orderMinTax_b2c")
                              } else
                                  if ((typeof(item.orderMinTax_b2c) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato de orderMinTax_b2c no es number")
                                  }
                              if (!item.hasOwnProperty('orderMinUmd_b2c')) {
                                  error = true
                                  alertaGeneral("No existe el campo: orderMinUmd_b2c")
                              } else
                                  if ((typeof(item.orderMinUmd_b2c) != "number")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato de orderMinUmd_b2c no es number")
                                  }
                              if (!item.hasOwnProperty('mostrarVitrina')) {
                                  error = true
                                  alertaGeneral("No existe el campo: mostrarVitrina")
                              } else
                                  if ((typeof(item.mostrarVitrina) != "number") || (item.mostrarVitrina <= 0) || (item.mostrarVitrina >=5) ) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el tipo de dato no es number o tiene un valor incorrecto (1:Todas, 2:B2B, 3:B2C, 4:Ninguna")
                                  }
                              /* if (!item.hasOwnProperty('order')) {
                                  error = true
                                  alertaGeneral("No existe el campo: order")
                              } else
                                  if (typeof(item.order) != "string") {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo order esta vacio o el tipo de dato no es string")
                                  } */
                            }
                        })
                        let oJSON = sortJSON(json, 'idCategory', 'asc');
                        if (error) {
                            this.setState({ json : [] })
                            this.setState({ validXlsx : false })
                        }  
                    } else {
                        this.setState({ validXlsx : false })
                        alertaGeneral("El archivo esta vacío")
                    }
                }
                reader.readAsArrayBuffer(e.target.files[0]);
            }
        }
      }

      const botonFunction = (e) => {
          if (this.state.validImage && this.state.validXlsx ) {
              const idCedi = sessionStorage.getItem("idCedi")
              $('#updcedi').val(idCedi)
              const token = sessionStorage.getItem('token')
              let data = new FormData(document.getElementById('formulario'))
              data.append("datos",JSON.stringify(this.state.json))
              fetch(Const.urlrest + "api/productscedi/load", { // ok
                method: "POST",
                headers: { 
                    /* 'Accept': 'application/json',
                    'Content-Type': 'application/json', */
                    'Authorization': token,
                },
                body: data
              }).then(response => response.json())
                  .then((result) => {
                    if (endSession(result.success)) {
                        if (result.data.acierto == true) {
                            alertaGeneral("Registro Ingresado");
                            window.location.reload(false);
                        } else 
                            alertaGeneral("El Archivo cuenta con idCategory no registradas, anulada la carga")  
                    } else 
                        closeSession()
              }).catch(error => alertaGeneral("Problemas de conexión (114)"))
          } else {
                $("#idExcel").val("")
                alertaGeneral("Aun no ha seleccionado el archivo productos.xlsx y/o archivos de imagenes")
          }
      }

      let accessUser = JSON.parse(sessionStorage.getItem('access'))
      let boolCrear = accessUser.includes('1')
      let boolOperar = accessUser.includes('2') || accessUser.includes('3')
      let boolCargar = accessUser.includes('47')
      
      return (          
          <React.Fragment>
              <Headerdashboard/>
              <Sidebar />  
              <AlertGeneral/> 
              <AlertConfirmation />
              <div className="page-wrapper">
                  <div className="row page-titles">
                      <div className="col-md-5 align-self-center">
                          <h3 className="text-primary">Productos</h3> 
                      </div>
                      { 
                          boolCrear
                      ? 
                          <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./productscreate">Crear</a></label>
                      :
                          null
                      }
                      {
                          boolOperar 
                      ?
                          <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./productslist">Datos</a></label>
                      :
                          null
                      }    
                      {
                          boolCargar 
                      ?
                          <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioCargar"><a href="./productscargar">Cargar</a></label>
                      :
                          null
                      }
                  </div>
                  <div className="container-fluid">
                      <div className="row">
                          <div className="col-12">
                              <div className="card">
                                  <div className="card-body">
                                      <h4 className="card-title"> </h4>   
                                      <h3>Cargar Productos</h3>
                                      <br></br>
                                      <form id="formulario">
                                          <h5>Seleccione el archivo con nombre "productos.xlsx" que contiene los datos a cargar</h5>
                                          <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="excel" id="idExcel" onChange={readUploadFile} disabled={this.state.validImage} />              
                                          <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updcedi" required="required"   />
                                          <div className="row p-t-20">
                                              <div className="col-md-12">
                                                  <div className="form-group">
                                                      <h5 style={{marginTop:10}}>Seleccione los archivos (*.png) de fotos de los productos relacionados en el archivo productos.xlsx</h5>
                                                      <input type="file" multiple accept="Image/png" name="images" id="idImages" onChange={readUploadImage} disabled={!this.state.validXlsx}/>              
                                                  </div>
                                              </div>
                                          </div>
                                      </form>
                                      <div className="col-sm-offset-2 col-sm-10">
                                          <button type="button" name="boton" id="idBoton" className="btn btn-default btnupdate" onClick={botonFunction} >Cargar</button>
                                      </div>  
                                      {/* <h6 style={{marginTop:30, fontWeight:"normal"}}>Descargar plantilla del archivo "comerciales.xlsx"</h6> */}
                                  </div>
                              </div>
                          </div>
                      </div>          
                  </div>
              </div>
          </React.Fragment>
      )
    }
}

export default load;
