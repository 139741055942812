import React, {Component} from 'react'
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

class Slogan extends Component {

    constructor(props){
        super(props);
        this.state = {
            sloganText:''
        }
    }

    componentDidMount(){
        const idCedi = sessionStorage.getItem("idCedi")
        let datos = {id : idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/cedi/read",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ sloganText: result.data.slogan })
                else 
                    closeSession()
            },
        ).catch (err => alertaGeneral("Problemas de conexión (27)"))
    }

    render(){
    
        const { sloganText } = this.state;

        return  (
            <React.Fragment>                
                <div className="col-md-12">
                    <div className="form-group">
                        <h2 style={{color:'black', justifyContent:'center',textAlign :'center', padding:20 }} className=" control-label"> {sloganText} </h2>
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default Slogan