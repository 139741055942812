import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');

class configCediLookCedis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        const idCedi = sessionStorage.getItem("idCedi")
        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }

        document.querySelector('.formupdate').style.display = 'block'

        function formEdit() {
            window.scrollTo(0, 0);
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/colorsCedis/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({ idCedi: idCedi })
            }).then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        document.getElementById("updidCedi").value = result.data.idCedi
                        // ************************** MIPEDIDOCONST ********************* Tabla Colors_Cedis
                        document.getElementById("updMiPedidoConst_ColorHeader6").value = result.data.MiPedidoConst_ColorHeader6 
                        document.getElementById("updMiPedidoConst_ColorBotonPedirFooter").value = result.data.MiPedidoConst_ColorBotonPedirFooter   
                        document.getElementById("updMiPedidoConst_ColorAbrirUrlInactivoDetalle2").value = result.data.MiPedidoConst_ColorAbrirUrlInactivoDetalle2  
                        document.getElementById("updMiPedidoConst_ColorFondoBotonContinuarDetalle2").value = result.data.MiPedidoConst_ColorFondoBotonContinuarDetalle2  
                        document.getElementById("updMiPedidoConst_FuenteColorHeader5").value = result.data.MiPedidoConst_FuenteColorHeader5  
                        document.getElementById("updMiPedidoConst_ContenedorTiendaFondo1").value = result.data.MiPedidoConst_ContenedorTiendaFondo1  
                        document.getElementById("updMiPedidoConst_ColorFondo7").value = result.data.MiPedidoConst_ColorFondo7  
                        document.getElementById("updMiPedidoConst_FooterColor1").value = result.data.MiPedidoConst_FooterColor1  
                        document.getElementById("updMiPedidoConst_ColorFondoContenedorValores").value = result.data.MiPedidoConst_ColorFondoContenedorValores  
                        document.getElementById("updMiPedidoConst_ColorFondoContenedorSubtotal").value = result.data.MiPedidoConst_ColorFondoContenedorSubtotal  
                        document.getElementById("updMiPedidoConst_ColorFondoContenedorFlete").value = result.data.MiPedidoConst_ColorFondoContenedorFlete  
                        document.getElementById("updMiPedidoConst_ColorFondoContenedorTotal").value = result.data.MiPedidoConst_ColorFondoContenedorTotal  
                        document.getElementById("updMiPedidoConst_ColorTextoBotonRestarDetalle1").value = result.data.MiPedidoConst_ColorTextoBotonRestarDetalle1  
                        document.getElementById("updMiPedidoConst_ColorFondoSliderDetalle2").value = result.data.MiPedidoConst_ColorFondoSliderDetalle2  
                        document.getElementById("updMiPedidoConst_ColorFondoCard1").value = result.data.MiPedidoConst_ColorFondoCard1  
                        document.getElementById("updMiPedidoConst_ColorFondoModalDetalle2").value = result.data.MiPedidoConst_ColorFondoModalDetalle2  
                        document.getElementById("updMiPedidoConst_ColorAbrirUrlTextoDetalle2").value = result.data.MiPedidoConst_ColorAbrirUrlTextoDetalle2  
                        document.getElementById("updMiPedidoConst_ColorTextoBotonSumarDetalle1").value = result.data.MiPedidoConst_ColorTextoBotonSumarDetalle1  
                        document.getElementById("updMiPedidoConst_ColorTextoValores").value = result.data.MiPedidoConst_ColorTextoValores  
                        document.getElementById("updMiPedidoConst_ColorTextoBotonContinuarDetalle1").value = result.data.MiPedidoConst_ColorTextoBotonContinuarDetalle1  
                        document.getElementById("updMiPedidoConst_ColorTextoInputDetalle1").value = result.data.MiPedidoConst_ColorTextoInputDetalle1  
                        document.getElementById("updMiPedidoConst_ColorBolasSliderDetalle2").value = result.data.MiPedidoConst_ColorBolasSliderDetalle2  
                        document.getElementById("updMiPedidoConst_ColorBotonPedirTextoFooter").value = result.data.MiPedidoConst_ColorBotonPedirTextoFooter  
                        document.getElementById("updMiPedidoConst_ColorNombreTextoDetalle2").value = result.data.MiPedidoConst_ColorNombreTextoDetalle2  
                        document.getElementById("updMiPedidoConst_ColorTextoVariables").value = result.data.MiPedidoConst_ColorTextoVariables  
                        document.getElementById("updMiPedidoConst_ColorDescripcionTextoDetalle2").value = result.data.MiPedidoConst_ColorDescripcionTextoDetalle2  
                        document.getElementById("updMiPedidoConst_ColorFondoModal4").value = result.data.MiPedidoConst_ColorFondoModal4  
                        document.getElementById("updMiPedidoConst_ColorNombreEstablecimiento1").value = result.data.MiPedidoConst_ColorNombreEstablecimiento1  
                        document.getElementById("updMiPedidoConst_ColorBolasSliderInactivoDetalle2").value = result.data.MiPedidoConst_ColorBolasSliderInactivoDetalle2  
                        document.getElementById("updMiPedidoConst_ColorPreciosTextoDetalle2").value = result.data.MiPedidoConst_ColorPreciosTextoDetalle2  
                        document.getElementById("updMiPedidoConst_ColorFondoBotonSumarDetalle1").value = result.data.MiPedidoConst_ColorFondoBotonSumarDetalle1  
                        document.getElementById("updMiPedidoConst_ColorFondoBotonRestarDetalle1").value = result.data.MiPedidoConst_ColorFondoBotonRestarDetalle1  
                        document.getElementById("updMiPedidoConst_ColorTextoDefVariables").value = result.data.MiPedidoConst_ColorTextoDefVariables  
                        document.getElementById("updMiPedidoConst_ColorPedidoMin1").value = result.data.MiPedidoConst_ColorPedidoMin1  
                        document.getElementById("updMiPedidoConst_ColorFondoCantidad").value = result.data.MiPedidoConst_ColorFondoCantidad  
                        document.getElementById("updMiPedidoConst_ColorTextoOops").value = result.data.MiPedidoConst_ColorTextoOops  
                        document.getElementById("updMiPedidoConst_ColorTextoListaProductos").value = result.data.MiPedidoConst_ColorTextoListaProductos  
                        document.getElementById("updMiPedidoConst_ColorTextoItemsPedido").value = result.data.MiPedidoConst_ColorTextoItemsPedido  
                        // ************************** GRACIASPEDIDOCONST ************************ Tabla Colors_Cedis
                        document.getElementById("updGraciasPedidoConst_ColorHeader5").value = result.data.GraciasPedidoConst_ColorHeader5  
                        document.getElementById("updGraciasPedidoConst_BotonFinalizarColor").value = result.data.GraciasPedidoConst_BotonFinalizarColor  
                        document.getElementById("updGraciasPedidoConst_BotonFinalizarTextoColor").value = result.data.GraciasPedidoConst_BotonFinalizarTextoColor  
                        document.getElementById("updGraciasPedidoConst_NombreTextoColor").value = result.data.GraciasPedidoConst_NombreTextoColor  
                        document.getElementById("updGraciasPedidoConst_GraciasTextoColor").value = result.data.GraciasPedidoConst_GraciasTextoColor  
                        document.getElementById("updGraciasPedidoConst_MensajeTextoColor").value = result.data.GraciasPedidoConst_MensajeTextoColor  
                        document.getElementById("updGraciasPedidoConst_ColorFondo6").value = result.data.GraciasPedidoConst_ColorFondo6  
                        document.getElementById("updGraciasPedidoConst_ColorPedidoRecibido").value = result.data.GraciasPedidoConst_ColorPedidoRecibido
                        // ************************** CONDICIONESSERVICIO ********************* Tabla Colors_Cedis
                        document.getElementById("updCondicionesServicioConst_MarcoCs").value = result.data.CondicionesServicioConst_MarcoCs  
                        document.getElementById("updCondicionesServicioConst_tituloCs").value = result.data.CondicionesServicioConst_tituloCs  
                        document.getElementById("updCondicionesServicioConst_ColorTitulo").value = result.data.CondicionesServicioConst_ColorTitulo  
                        document.getElementById("updCondicionesServicioConst_LineaSeparadora").value = result.data.CondicionesServicioConst_LineaSeparadora  
                        document.getElementById("updCondicionesServicioConst_colorFondoModal1").value = result.data.CondicionesServicioConst_colorFondoModal1  
                        document.getElementById("updCondicionesServicioConst_ColorFondoScrollView").value = result.data.CondicionesServicioConst_ColorFondoScrollView  
                        document.getElementById("updCondicionesServicioConst_RutaEntregaColorTextScroll").value = result.data.CondicionesServicioConst_RutaEntregaColorTextScroll  
                        document.getElementById("updCondicionesServicioConst_ColorVariables").value = result.data.CondicionesServicioConst_ColorVariables 
                        document.getElementById("updCondicionesServicioConst_ColorFondoCerrar").value = result.data.CondicionesServicioConst_ColorFondoCerrar 
                        
                        // ************************** CONDICIONESINFORMA ********************* Tabla Colors_Cedis
                        document.getElementById("updCondicionesInforma_ColorTituloComercial").value = result.data.CondicionesInforma_ColorTituloComercial   
                        document.getElementById("updCondicionesInforma_InformacionColorTituloBlock").value = result.data.CondicionesInforma_InformacionColorTituloBlock  
                        document.getElementById("updCondicionesInforma_ColorTituloDos").value = result.data.CondicionesInforma_ColorTituloDos  
                        document.getElementById("updCondicionesInforma_ColorBordeSoporte").value = result.data.CondicionesInforma_ColorBordeSoporte  
                        document.getElementById("updCondicionesInforma_ColorTextAreaInformacionTexto").value = result.data.CondicionesInforma_ColorTextAreaInformacionTexto  
                        document.getElementById("updCondicionesInforma_ColorBotonEnviarTexto1").value = result.data.CondicionesInforma_ColorBotonEnviarTexto1  
                        document.getElementById("updCondicionesInforma_ColorBotonEnviar1").value = result.data.CondicionesInforma_ColorBotonEnviar1  
                        document.getElementById("updCondicionesInforma_ColorFondoBloack").value = result.data.CondicionesInforma_ColorFondoBloack  
    
                        // ************************** ESTABLECIMIENTOSCONST ********************* Tabla Colors_Cedis
                        document.getElementById("updEstablecimientoConst_ColorHeader1").value = result.data.EstablecimientoConst_ColorHeader1 
                        document.getElementById("updEstablecimientoConst_FuenteColorHeader").value = result.data.EstablecimientoConst_FuenteColorHeader 
                        document.getElementById("updEstablecimientoConst_ColorAreaTarjetas").value = result.data.EstablecimientoConst_ColorAreaTarjetas 
                        document.getElementById("updEstablecimientoConst_ColorPantalla").value = result.data.EstablecimientoConst_ColorPantalla 
                        document.getElementById("updEstablecimientoConst_ColorFooterE").value = result.data.EstablecimientoConst_ColorFooterE  
                        document.getElementById("updEstablecimientoConst_ColorInvitacionE").value = result.data.EstablecimientoConst_ColorInvitacionE 
                        document.getElementById("updEstablecimientoConst_ColorNombre").value = result.data.EstablecimientoConst_ColorNombre 
                        document.getElementById("updEstablecimientoConst_CardColorBorde").value = result.data.EstablecimientoConst_CardColorBorde 
                        document.getElementById("updEstablecimientoConst_ColorDireccion").value = result.data.EstablecimientoConst_ColorDireccion 
                        document.getElementById("updEstablecimientoConst_ColorTarjeta").value = result.data.EstablecimientoConst_ColorTarjeta  
                        // *********************************** MILISTA *************************** Tabla Colors_Cedis
                        document.getElementById("updMiListaConst_ColorTextoMiListaVacio").value = result.data.MiListaConst_ColorTextoMiListaVacio
                        document.getElementById("updMiListaConst_ColorHeader").value = result.data.MiListaConst_ColorHeader  
                        document.getElementById("updMiListaConst_ColorBordeTabla").value = result.data.MiListaConst_ColorBordeTabla   
                        document.getElementById("updMiListaConst_ColorFondoBotonContinuarDetalle").value = result.data.MiListaConst_ColorFondoBotonContinuarDetalle  
                        document.getElementById("updMiListaConst_ColorAbrirUrlInactivoDetalle").value = result.data.MiListaConst_ColorAbrirUrlInactivoDetalle  
                        document.getElementById("updMiListaConst_ColorImagenLoading").value = result.data.MiListaConst_ColorImagenLoading  
                        document.getElementById("updMiListaConst_ColorActivityIndicator").value = result.data.MiListaConst_ColorActivityIndicator  
                        document.getElementById("updMiListaConst_ColorTabla").value = result.data.MiListaConst_ColorTabla 
                        document.getElementById("updMiListaConst_ColorLineaInformacion").value = result.data.MiListaConst_ColorLineaInformacion 
                        document.getElementById("updMiListaConst_FuenteColorHeader1").value = result.data.MiListaConst_FuenteColorHeader1  
                        document.getElementById("updMiListaConst_ColorFondoCards").value = result.data.MiListaConst_ColorFondoCards  
                        document.getElementById("updMiListaConst_ColorBotonCerrarCards").value = result.data.MiListaConst_ColorBotonCerrarCards  
                        document.getElementById("updMiListaConst_NombreTienda").value = result.data.MiListaConst_NombreTienda 
                        document.getElementById("updMiListaConst_ColorFondo").value = result.data.MiListaConst_ColorFondo 
                        document.getElementById("updMiListaConst_FooterColor").value = result.data.MiListaConst_FooterColor  
                        document.getElementById("updMiListaConst_ColorBotonMas").value = result.data.MiListaConst_ColorBotonMas  
                        document.getElementById("updMiListaConst_ColorPreciosProducto").value = result.data.MiListaConst_ColorPreciosProducto  
                        document.getElementById("updMiListaConst_ColorPreciosTextoDetalle").value = result.data.MiListaConst_ColorPreciosTextoDetalle  
                        document.getElementById("updMiListaConst_ColorFondoBotonSumarDetalle").value = result.data.MiListaConst_ColorFondoBotonSumarDetalle  
                        document.getElementById("updMiListaConst_FondoColorCanasta").value = result.data.MiListaConst_FondoColorCanasta  
                        document.getElementById("updMiListaConst_ColorIconoMenos").value = result.data.MiListaConst_ColorIconoMenos  
                        document.getElementById("updMiListaConst_ColorIconoMas").value = result.data.MiListaConst_ColorIconoMas  
                        document.getElementById("updMiListaConst_ColorOrderName").value = result.data.MiListaConst_ColorOrderName  
                        document.getElementById("updMiListaConst_ColorFondoCanasta").value = result.data.MiListaConst_ColorFondoCanasta  
                        document.getElementById("updMiListaConst_ColorFondoModalDetalle").value = result.data.MiListaConst_ColorFondoModalDetalle  
                        document.getElementById("updMiListaConst_ColorFondoSliderDetalle").value = result.data.MiListaConst_ColorFondoSliderDetalle  
                        document.getElementById("updMiListaConst_ColorTextoBotonRestarDetalle").value = result.data.MiListaConst_ColorTextoBotonRestarDetalle  
                        document.getElementById("updMiListaConst_ColorTextoBotonSumarDetalle").value = result.data.MiListaConst_ColorTextoBotonSumarDetalle  
                        document.getElementById("updMiListaConst_ColorFondoEstablecimiento").value = result.data.MiListaConst_ColorFondoEstablecimiento  
                        document.getElementById("updMiListaConst_ColorTextAreaInformacion").value = result.data.MiListaConst_ColorTextAreaInformacion  
                        document.getElementById("updMiListaConst_ColorInputCard").value = result.data.MiListaConst_ColorInputCard  
                        document.getElementById("updMiListaConst_ColorTextoCanasta").value = result.data.MiListaConst_ColorTextoCanasta  
                        document.getElementById("updMiListaConst_colorBorderInputCant").value = result.data.MiListaConst_colorBorderInputCant  
                        document.getElementById("updMiListaConst_ColorNombreProducto").value = result.data.MiListaConst_ColorNombreProducto  
                        document.getElementById("updMiListaConst_ColorDescripcionProducto").value = result.data.MiListaConst_ColorDescripcionProducto  
                        document.getElementById("updMiListaConst_ColorPedidoMin").value = result.data.MiListaConst_ColorPedidoMin  
                        document.getElementById("updMiListaConst_ColorNombreTextoDetalle").value = result.data.MiListaConst_ColorNombreTextoDetalle  
                        document.getElementById("updMiListaConst_ColorBolasSliderDetalle").value = result.data.MiListaConst_ColorBolasSliderDetalle  
                        document.getElementById("updMiListaConst_ColorTextoInputDetalle").value = result.data.MiListaConst_ColorTextoInputDetalle  
                        document.getElementById("updMiListaConst_ColorTextoBotonContinuarDetalle").value = result.data.MiListaConst_ColorTextoBotonContinuarDetalle  
                        document.getElementById("updMiListaConst_ColorBotonEnviarTexto").value = result.data.MiListaConst_ColorBotonEnviarTexto  
                        document.getElementById("updMiListaConst_InformacionColorTitulo").value = result.data.MiListaConst_InformacionColorTitulo  
                        document.getElementById("updMiListaConst_ColorInputCardTexto").value = result.data.MiListaConst_ColorInputCardTexto  
                        document.getElementById("updMiListaConst_ColorTextoHallazgos").value = result.data.MiListaConst_ColorTextoHallazgos  
                        document.getElementById("updMiListaConst_ColorTitulosTabla").value = result.data.MiListaConst_ColorTitulosTabla  
                        document.getElementById("updMiListaConst_ColorTextoBotonHallazgos").value = result.data.MiListaConst_ColorTextoBotonHallazgos  
                        document.getElementById("updMiListaConst_ColorFondoModal").value = result.data.MiListaConst_ColorFondoModal  
                        document.getElementById("updMiListaConst_ColorNombreComercial").value = result.data.MiListaConst_ColorNombreComercial  
                        document.getElementById("updMiListaConst_ColorBotonMenos").value = result.data.MiListaConst_ColorBotonMenos  
                        document.getElementById("updMiListaConst_ColorBolasSliderInactivoDetalle").value = result.data.MiListaConst_ColorBolasSliderInactivoDetalle  
                        document.getElementById("updMiListaConst_ColorAbrirUrlTextoDetalle").value = result.data.MiListaConst_ColorAbrirUrlTextoDetalle  
                        document.getElementById("updMiListaConst_ColorModalInformacion").value = result.data.MiListaConst_ColorModalInformacion 
                        document.getElementById("updMiListaConst_ColorFondoBotonRestarDetalle").value = result.data.MiListaConst_ColorFondoBotonRestarDetalle  
                        document.getElementById("updMiListaConst_ColorDescripcionTextoDetalle").value = result.data.MiListaConst_ColorDescripcionTextoDetalle  
                        document.getElementById("updMiListaConst_ColorBotonEliminarCards").value = result.data.MiListaConst_ColorBotonEliminarCards 
                        document.getElementById("updMiListaConst_ColorBordeFooter").value = result.data.MiListaConst_ColorBordeFooter
                        document.getElementById("updMiListaConst_ColorTituloHallazgos").value = result.data.MiListaConst_ColorTituloHallazgos
                        document.getElementById("updMiListaConst_ColorBotonFondoHallazgos").value = result.data.MiListaConst_ColorBotonFondoHallazgos
                        document.getElementById("updMiListaConst_ColorFondoInforma").value = result.data.MiListaConst_ColorFondoInforma
                        document.getElementById("updMiListaConst_ColorTextoInforma").value = result.data.MiListaConst_ColorTextoInforma
                        document.getElementById("updMiListaConst_ColorTextoBuscar").value = result.data.MiListaConst_ColorTextoBuscar
                        document.getElementById("updMiListaConst_ColorTextoBuscarCasilla").value = result.data.MiListaConst_ColorTextoBuscarCasilla
                        document.getElementById("updMiListaConst_ColorFondoBuscarCasilla").value = result.data.MiListaConst_ColorFondoBuscarCasilla
                        document.getElementById("updMiListaConst_ColorInputCardFondo").value = result.data.MiListaConst_ColorInputCardFondo
                        document.getElementById("updMiListaConst_ColorPedidoMin2").value = result.data.MiListaConst_ColorPedidoMin2
                        document.getElementById("updMiListaConst_ColorFondoRecuadroHallazgos").value = result.data.MiListaConst_ColorFondoRecuadroHallazgos
                        document.getElementById("updMiListaConst_ColorBordeOrdenar").value = result.data.MiListaConst_ColorBordeOrdenar
                        document.getElementById("updMiListaConst_ColorBordeOpcionesOrdenar").value = result.data.MiListaConst_ColorBordeOpcionesOrdenar
                        document.getElementById("updMiListaConst_ColorTextoOpcionesOrdenar").value = result.data.MiListaConst_ColorTextoOpcionesOrdenar
                        document.getElementById("updMiListaConst_ColorSeparadorTarjeta").value = result.data.MiListaConst_ColorSeparadorTarjeta
                        document.getElementById("updMiListaConst_ColorFondoCantidadMayor").value = result.data.MiListaConst_ColorFondoCantidadMayor
                        
                        // *********************************** AGREGARCONST *************************** Tabla Colors_Cedis
                        document.getElementById("updAgregarConst_ContenedorTiendaFondo").value = result.data.AgregarConst_ContenedorTiendaFondo  
                        document.getElementById("updAgregarConst_FuenteColorHeader2").value = result.data.AgregarConst_FuenteColorHeader2  
                        document.getElementById("updAgregarConst_ColorFondoCard").value = result.data.AgregarConst_ColorFondoCard  
                        document.getElementById("updAgregarConst_ColorFondoModalDetalle1").value = result.data.AgregarConst_ColorFondoModalDetalle1  
                        document.getElementById("updAgregarConst_ColorFondoSliderDetalle1").value = result.data.AgregarConst_ColorFondoSliderDetalle1  
                        document.getElementById("updAgregarConst_colorFondoSeleccionarCategorias").value = result.data.AgregarConst_colorFondoSeleccionarCategorias 
                        document.getElementById("updAgregarConst_colorFondoProduDestCategorias").value = result.data.AgregarConst_colorFondoProduDestCategorias  
                        document.getElementById("updAgregarConst_ColorFondoCategorias").value = result.data.AgregarConst_ColorFondoCategorias 
                        document.getElementById("updAgregarConst_colorFondoModalSubCategorias").value = result.data.AgregarConst_colorFondoModalSubCategorias  
                        document.getElementById("updAgregarConst_colorFondoModalCerrar").value = result.data.AgregarConst_colorFondoModalCerrar  
                        document.getElementById("updAgregarConst_colorTextoSubCategorias").value = result.data.AgregarConst_colorTextoSubCategorias  
                        document.getElementById("updAgregarConst_colorTextoNomCategoria").value = result.data.AgregarConst_colorTextoNomCategoria  
                        document.getElementById("updAgregarConst_colorTextoNotaCategoria").value = result.data.AgregarConst_colorTextoNotaCategoria  
                        document.getElementById("updAgregarConst_colorTextoSeleccionarCategorias").value = result.data.AgregarConst_colorTextoSeleccionarCategorias  
                        document.getElementById("updAgregarConst_colorFondoModalCerrarEquis").value = result.data.AgregarConst_colorFondoModalCerrarEquis  
                        document.getElementById("updAgregarConst_colorTextoModalNombreCat").value = result.data.AgregarConst_colorTextoModalNombreCat   
                        document.getElementById("updAgregarConst_ColorNombreEstablecimiento").value = result.data.AgregarConst_ColorNombreEstablecimiento  
                        document.getElementById("updAgregarConst_ColorNombreCard").value = result.data.AgregarConst_ColorNombreCard  
                        document.getElementById("updAgregarConst_ColorDescripcionCard").value = result.data.AgregarConst_ColorDescripcionCard  
                        document.getElementById("updAgregarConst_colorBtnTextoDetalle").value = result.data.AgregarConst_colorBtnTextoDetalle  
                        document.getElementById("updAgregarConst_ColorBolasSliderDetalle1").value = result.data.AgregarConst_ColorBolasSliderDetalle1  
                        document.getElementById("updAgregarConst_ColorNombreTextoDetalle1").value = result.data.AgregarConst_ColorNombreTextoDetalle1  
                        document.getElementById("updAgregarConst_ColorTextoBotonAgregarDetalle").value = result.data.AgregarConst_ColorTextoBotonAgregarDetalle  
                        document.getElementById("updAgregarConst_ColorSombraCard").value = result.data.AgregarConst_ColorSombraCard  
                        document.getElementById("updAgregarConst_ColorFondoModal1").value = result.data.AgregarConst_ColorFondoModal1  
                        document.getElementById("updAgregarConst_ColorHeader2").value = result.data.AgregarConst_ColorHeader2  
                        document.getElementById("updAgregarConst_colorBtnDetalle").value = result.data.AgregarConst_colorBtnDetalle  
                        document.getElementById("updAgregarConst_ColorActivityIndicator1").value = result.data.AgregarConst_ColorActivityIndicator1  
                        document.getElementById("updAgregarConst_ColorAbrirUrlInactivoDetalle1").value = result.data.AgregarConst_ColorAbrirUrlInactivoDetalle1  
                        document.getElementById("updAgregarConst_ColorFondoBotonContinuarDetalle1").value = result.data.AgregarConst_ColorFondoBotonContinuarDetalle1  
                        document.getElementById("updAgregarConst_colorFondoSubCategorias").value = result.data.AgregarConst_colorFondoSubCategorias  
                        document.getElementById("updAgregarConst_ColorFondo1").value = result.data.AgregarConst_ColorFondo1  
                        document.getElementById("updAgregarConst_colorFondoNotas").value = result.data.AgregarConst_colorFondoNotas  
                        document.getElementById("updAgregarConst_ColorPrecioCard").value = result.data.AgregarConst_ColorPrecioCard  
                        document.getElementById("updAgregarConst_ColorAbrirUrlTextoDetalle1").value = result.data.AgregarConst_ColorAbrirUrlTextoDetalle1  
                        document.getElementById("updAgregarConst_ColorPreciosTextoDetalle1").value = result.data.AgregarConst_ColorPreciosTextoDetalle1  
                        document.getElementById("updAgregarConst_ColorBolasSliderInactivoDetalle1").value = result.data.AgregarConst_ColorBolasSliderInactivoDetalle1  
                        document.getElementById("updAgregarConst_ColorDescripcionTextoDetalle1").value = result.data.AgregarConst_ColorDescripcionTextoDetalle1  
                        document.getElementById("updAgregarConst_colorBordesCat").value = result.data.AgregarConst_colorBordesCat  
                        document.getElementById("updAgregarConst_ColorTextoPedidoMin").value = result.data.AgregarConst_ColorTextoPedidoMin  
                        document.getElementById("updAgregarConst_ColorPedidoMinDetalle").value = result.data.AgregarConst_ColorPedidoMinDetalle  
                        document.getElementById("updAgregarConst_ColorFondoBotonContinuarDetalle0").value = result.data.AgregarConst_ColorFondoBotonContinuarDetalle0  
                        document.getElementById("updAgregarConst_ColorTextoBotonAgregarDetalle0").value = result.data.AgregarConst_ColorTextoBotonAgregarDetalle0  
                        
                        // ****************** TYCMILISTACONST ***************
                        document.getElementById("updTyCMiListaConst_ColorFondoPantalla2").value = result.data.TyCMiListaConst_ColorFondoPantalla2  
                        document.getElementById("updTyCMiListaConst_buttonColor1").value = result.data.TyCMiListaConst_buttonColor1   
                        document.getElementById("updTyCMiListaConst_selectedButtonColor1").value = result.data.TyCMiListaConst_selectedButtonColor1   
                        document.getElementById("updTyCMiListaConst_ColorTextoTitulo1").value = result.data.TyCMiListaConst_ColorTextoTitulo1   
                        document.getElementById("updTyCMiListaConst_textoModalInfoCedi1").value = result.data.TyCMiListaConst_textoModalInfoCedi1   
                        document.getElementById("updTyCMiListaConst_ColorFondoModal10").value = result.data.TyCMiListaConst_ColorFondoModal10   
                        document.getElementById("updTyCMiListaConst_ColorFondoBotonCerrar1").value = result.data.TyCMiListaConst_ColorFondoBotonCerrar1   
                        // ****************** IMAGENES *********************************
                        document.getElementById("imgshowBackArrow").src = result.data.b2bColorsCedisImg.Imagenes_BackArrow 
                        document.getElementById("imgshowInstagram").src = result.data.b2bColorsCedisImg.Imagenes_Instagram
                        document.getElementById("imgshowFacebook").src = result.data.b2bColorsCedisImg.Imagenes_Facebook
                        document.getElementById("imgshowTwitter").src = result.data.b2bColorsCedisImg.Imagenes_Twitter
                        document.getElementById("imgshowLinkeding").src = result.data.b2bColorsCedisImg.Imagenes_Linkeding
                        document.getElementById("imgshowWhatsapp").src = result.data.b2bColorsCedisImg.Imagenes_Whatsapp
                        document.getElementById("imgshowYoutube").src = result.data.b2bColorsCedisImg.Imagenes_Youtube
                        document.getElementById("imgshowBasket").src = result.data.b2bColorsCedisImg.Imagenes_Basket
                        document.getElementById("imgshowBuscar").src = result.data.b2bColorsCedisImg.Imagenes_Buscar
                        document.getElementById("imgshowLista").src = result.data.b2bColorsCedisImg.Imagenes_Lista
                        document.getElementById("imgshowMas").src = result.data.b2bColorsCedisImg.Imagenes_Mas
                        document.getElementById("imgshowMenos").src = result.data.b2bColorsCedisImg.Imagenes_Menos
                        document.getElementById("imgshowCondiciones").src = result.data.b2bColorsCedisImg.Imagenes_Condiciones
                        document.getElementById("imgshowPromoEstrella").src = result.data.b2bColorsCedisImg.Imagenes_PromoEstrella
                        document.getElementById("imgshowEstadisticas").src  = result.data.b2bColorsCedisImg.Imagenes_Estadisticas
                        document.getElementById("imgshowInformacionLegalCedi").src = result.data.b2bColorsCedisImg.Imagenes_InformacionLegalCedi
                        document.getElementById("imgshowUsuario").src = result.data.b2bColorsCedisImg.Imagenes_Usuario
                        document.getElementById("imgshowTelefono").src = result.data.b2bColorsCedisImg.Imagenes_Telefono
                        document.getElementById("imgshowCheckInactivo").src = result.data.b2bColorsCedisImg.Imagenes_CheckInactivo
                        document.getElementById("imgshowCheckActivo").src = result.data.b2bColorsCedisImg.Imagenes_CheckActivo 
                        document.getElementById("imgshowCloseBlack").src = result.data.b2bColorsCedisImg.Imagenes_CloseBlack 
                        document.getElementById("imgshowCategoria").src = result.data.b2bColorsCedisImg.Imagenes_Categoria 
                        // ****************** IMAGENES DE MARCA *********************************
                        document.getElementById("imgshowLogoInternoCedi").src = result.data.b2bColorsCedisImg.Imagenes_LogoInternoCedi
                        document.getElementById("imgshowSplashScreenInternoCedi").src = result.data.b2bColorsCedisImg.Imagenes_SplashScreenInternoCedi    
                    } else 
                        closeSession()
                },
            )
            .catch(error => alertaGeneral("Problemas de conexión (48)") )
        }
        
        function updateForm() {
            let token = sessionStorage.getItem('token')
            const data = new FormData(document.getElementById('formularioupdate'))
            fetch(Const.urlrest + "api/colorsCedis/update", { // ok
                headers: { 
                    /* 'Accept': 'application/json',
                    'Content-Type': 'application/json', */
                    'Authorization': token,
                },
                method: "PUT",
                body: data 
            }).then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        alertaGeneral("Registro  Actualizado");
                        window.location.reload(false);    
                    } else 
                        closeSession()
                },
            )
            .catch(error => alertaGeneral("Problemas de conexión (49)") )        
        }             
        
        el = document.getElementById('btnupdate');
        if (el) {
            el.addEventListener("click", updateForm);
        }
        formEdit()

    }
    
    render(){

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolUnidadNegocioDatos = accessUser.includes('37')
        let boolUnidadNegocioLook = accessUser.includes('38') 
        let boolAppAsoDatos = accessUser.includes('39')
        let boolAppAsoLook = accessUser.includes('40')
        let boolAppMbDatos = accessUser.includes('41')
        let boolAppMbLook = accessUser.includes('42')    

        let hayWhiteBrand = sessionStorage.getItem("WhiteBrand")
        let hayWhiteBrand_b2c = sessionStorage.getItem("WhiteBrand_b2c")

        let onOffLook = sessionStorage.getItem("onOffLook")

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <div className="page-wrapper">                    
                    <div className="row page-titles">
                        <div className="col-md-2.5 align-self-center">
                            <h3 className="text-primary">Unidad de Negocio</h3> 
                        </div>
                        { 
                            boolUnidadNegocioDatos
                        ? 
                            <label style = {{paddingLeft:30, fontSize:23}} id="ModUsuarioFdl"><a href="./cediconfigCEDI">Datos</a></label>
                        :
                            null
                        }
                        {
                            boolUnidadNegocioLook && (onOffLook != 0)
                        ?
                            <label style = {{paddingLeft:20, fontSize:23, paddingRight:50}} id="ModUsuarioFdc"><a href="./cediconfiglookCedis">Look</a></label>
                        :
                            null
                        }    
                        { 
                            boolAppAsoDatos && (hayWhiteBrand != 0)
                        ? 
                            <>
                                <div className="col-md-1.5 align-self-center">
                                    <h3 style = {{marginLeft:10}} className="text-primary">App Mb B2B</h3> 
                                </div>
                                <label style = {{ fontSize:23, marginLeft:30}} id="ModUsuarioFdl">
                                    <a 
                                        href="./cediconfigMARKETPLACE"> Datos
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolAppAsoLook && (hayWhiteBrand != 0)
                        ?
                            <label style = {{paddingLeft:20, fontSize:23, paddingRight:50}} id="ModUsuarioFdc"><a href="./cediconfiglookmarketplace">Look</a></label>
                        :
                            null
                        }    
                        { 
                            boolAppMbDatos && (hayWhiteBrand_b2c != 0)
                        ? 
                            <>
                                <div className="col-md-1.5 align-self-center">
                                    <h3 style = {{marginLeft:10}} className="text-primary">App Mb B2C</h3> 
                                </div>
                                <label style = {{ fontSize:23, marginLeft:30}} id="ModUsuarioFdl">
                                    <a 
                                        href="./cediconfigMARKETPLACE_b2c"> Datos
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolAppMbLook && (hayWhiteBrand_b2c != 0)
                        ?
                            <label style = {{paddingLeft:20, fontSize:23}} id="ModUsuarioFdc"><a href="./cediconfiglookmarketplace_b2c">Look</a></label>
                        :
                            null
                        }    
                        </div>
                    {<UpdateData />}
                </div>
            </div>
      )
  }
}

export default configCediLookCedis;