import React, {Component} from 'react'
import '../../assets/css/Login.css';

class Logologin extends Component{

    render(){
        return  (
            <React.Fragment>
                <img /* style={{ width:240, height:85 }} */ src={ require("../../assets/images/logoClanApps.png")} alt="Admin"  className="logologin block" />
            </React.Fragment>          
        )
    }
}

export default Logologin
