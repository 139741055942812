import React, {Component} from 'react'
import InputStatus from '../atoms/InputStatus'
import {alertaGeneral} from '../../assets/js/GeneralScript'

class updateData extends Component {

    render(){
        function validOnChange(filex) {
            let output = document.getElementById('imgshow');
            output.src = URL.createObjectURL(filex[0]);
            output.onload = function() {
                if ((output.naturalWidth.toFixed(0) < 192) || (output.naturalHeight.toFixed(0) < 192)) {
                    document.getElementById("imgshow").src = ''
                    sessionStorage.setItem("registerValid","false")
                    alertaGeneral("La Imagen seleccionada no cumple con la medida mínima proporcional de 192 x 192 pixels")
                } else
                    sessionStorage.setItem("registerValid","true")
                URL.revokeObjectURL(output.src) // free memory
            }
        }

        return  (
            <React.Fragment>                   
                <div className="container-fluid formupdate" id="formupdate">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h4>Actualizar </h4>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <div className="form-horizontal" id="formcreate" >
                                            <input type="hidden" name="usoLicInSite" id="updusoLicInSite"/>
                                            <form id="formularioupdate">
                                                <input type="hidden" className="form-control" placeholder="" name ="id" id="updid" required="required"   />
                                                <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updcedi" required="required"   />
                                                <input type="hidden" className="form-control" placeholder=""  id="updOldImg1" required="required" name="oldImg1"  max="30" />
                                                <div className="row p-t-20">
                                                    <InputStatus />
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className=" control-label">Nombre Comercial </label>                                                                
                                                            <input type="text" className="form-control" placeholder="" name="name" id="updname" required="required"  />
                                                            <label className="msglabel">Mínimo 4 caracteres</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                               <label className=" control-label">Email Comercial </label>
                                                               <input type="text" className="form-control" placeholder="" name="email" id="updemail" required="required"  />
                                                               <label className="labelEmailupd labelEmail"></label>
                                                        </div>                                                                                        
                                                    </div>                                                                                                                           
                                                </div>
                                                <div className="row p-t-20">            
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className=" control-label">Celular Comercial</label>
                                                            <input type="number" className="form-control" placeholder="" name="phone" id="updphone" required="required" max="22" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Lic de App TAT</label>                                                                                                                                
                                                            <input type="checkbox" className="form-control" style={{ width: 35, height: 35 }} 
                                                                   name="auxUsoLicInSite" id="updauxUsoLicInSite"  
                                                            />        
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2.8" style={{left:-50}}>
                                                        <div className="form-group">
                                                            <label className=" control-label">Contraseña App</label>                                                                
                                                            <input type="password" className="form-control" placeholder="********" name="passwordInSite" id="updpasswordInSite" required="required"  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="form-group">
                                                            <label className=" control-label" >Imagen &nbsp;</label>
                                                            <img src="" alt="" id="imgshow" height="50" />     
                                                            <input type="file" style = {{ color: "transparent", border: "none"}} className="form-control" placeholder="" name="img" accept="image/png" id="updimg" required="required" onChange ={(e) => validOnChange(e.target.files)} /> 
                                                            <label className="resultadoupd"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-offset-2 col-sm-10">
                                                        <button type="button" className="btn btn-default btnupdate" id="btnupdate">Actualizar Comercial</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </React.Fragment>          
        )
    }
}

export default updateData