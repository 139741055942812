import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import $ from 'jquery';
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'
import InputListPrices from '../atoms/InputListPrices'
import * as xlsx from 'xlsx'

document.body.classList.add('fix-sidebar')

class load extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validXlsx : false,
            json : {},
        }
    }    

    componentDidMount() {          
    }    

    render(){

      function obtenerObjetosUnicos(objetos, comparacion) {

        if (typeof comparacion != 'function') {
            throw TypeError('El argumento comparacion debe ser una función.');
        }
      
        return objetos.reduce((a, o) => {
            if (!a.some(p => comparacion(o, p))) {
                a.push(o);
            }
      
            return a;
        }, []);
      }
      
      const readUploadFile = (e) => {
        e.preventDefault();
        let fileExcel = e.target.files
        if (e.target.files) {
            if (fileExcel[0].name != "listapreciosdetalle.xlsx") {
                this.setState({ validXlsx : false })
                alertaGeneral("Esta intentando cargar un archivo no autorizado")
            } else {
                this.setState({ validXlsx : true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = xlsx.read(data, { type: "array" })
                    const sheetName = workbook.SheetNames[0]
                    const worksheet = workbook.Sheets[sheetName]
                    const json = xlsx.utils.sheet_to_json(worksheet)
                    let unicosIdProduct = obtenerObjetosUnicos(json, (x, y) => x.idProduct == y.idProduct)
                    if ( (json.length != 0) && (json.length == unicosIdProduct.length) ) {
                        this.setState({ json : json })
                        let error = false
                        json.map((item) => {
                            if (!error) {
                              if (!item.hasOwnProperty('idProduct')) {
                                  error = true
                                  alertaGeneral("No existe el campo: idProduct")
                              } else
                                  if ((typeof(item.idProduct) != "number") || (item.idProduct == "")) {
                                      error = true
                                      alertaGeneral("Existe una fila en donde el campo idProduct esta vacio o el tipo de dato no es string")
                                  }

                              if (!item.hasOwnProperty('priceTax')) {
                                    error = true
                                    alertaGeneral("No existe el campo: priceTax")
                              } else
                                  if (typeof(item.priceTax) != "number") {
                                        error = true
                                        alertaGeneral("Existe una fila en donde el campo priceTax esta vacio o el tipo de dato no es string")
                                  }

                              if (!item.hasOwnProperty('orderMinCant')) {
                                    error = true
                                    alertaGeneral("No existe el campo: orderMinCant")
                              } else
                                  if (typeof(item.orderMinCant) != "number") {
                                        error = true
                                        alertaGeneral("Existe una fila en donde el campo orderMinCant esta vacio o el tipo de dato no es string")
                                  }

                              if (!item.hasOwnProperty('orderMinUmd')) {
                                    error = true
                                    alertaGeneral("No existe el campo: orderMinUmd")
                              } else
                                  if (typeof(item.orderMinUmd) != "number") {
                                        error = true
                                        alertaGeneral("Existe una fila en donde el campo orderMinUmd esta vacio o el tipo de dato no es string")
                                  }

                              if (!item.hasOwnProperty('umdTax')) {
                                    error = true
                                    alertaGeneral("No existe el campo: umdTax")
                              } else
                                  if (typeof(item.umdTax) != "number") {
                                        error = true
                                        alertaGeneral("Existe una fila en donde el campo umdTax esta vacio o el tipo de dato no es string")
                                  }

                              if (!item.hasOwnProperty('valUnidUmd')) {
                                    error = true
                                    alertaGeneral("No existe el campo: valUnidUmd")
                              } else
                                  if (typeof(item.valUnidUmd) != "number") {
                                        error = true
                                        alertaGeneral("Existe una fila en donde el campo valUnidUmd esta vacio o el tipo de dato no es number")
                                  }
                            }
                        })
                        if (error) {
                            this.setState({ json : [] })
                            this.setState({ validXlsx : false })
                        }  
                    } else {
                        this.setState({ validXlsx : false })
                        alertaGeneral("El archivo esta vacío o no contiene idProduct únicos")
                    }

                }
                reader.readAsArrayBuffer(e.target.files[0]);
            }
        }
      }

      const botonFunction = (e) => {       
          if ( this.state.validXlsx ) {
              const idCedi = sessionStorage.getItem("idCedi")
              $('#updcedi').val(idCedi)
              const token = sessionStorage.getItem('token')
              let data = {
                  idCedi : idCedi,
                  idListPrices : $("#idListPrices").val(),
                  datos :JSON.stringify(this.state.json),
              }
              fetch(Const.urlrest + "api/detaillistpricescustom/load", {
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify(data)
              }).then(response => response.json())
                  .then((result) => {
                        if (endSession(result.success)) {
                            if (result.data.data != null) {
                                alertaGeneral("Registro Ingresado");
                                window.location.reload(false);
                            } else 
                                alertaGeneral("Verifica los campos: idProduct inexistentes y/o valores numéricos en relación a el tipo de Oferta (Unidad o UMD")     
                        } else 
                            closeSession()
              }).catch(error => alertaGeneral("Problemas de conexión (112)"))
          } else {
              $("#idExcel").val("")
              alertaGeneral("Aun no ha seleccionado el archivo listapreciosdetalle.xlsx")
          }
      }

      let accessUser = JSON.parse(sessionStorage.getItem('access'))
      let boolCrearLista = accessUser.includes('19')
      let boolEditLista = accessUser.includes('20') 
      let boolDeleteLista = accessUser.includes('21')
      let boolCrearDetalle = accessUser.includes('22')
      let boolEditDetalle = accessUser.includes('23') 
      let boolDeleteDetalle = accessUser.includes('24')
      let boolCargarPrices = accessUser.includes('50')
      let boolCargarDetails = accessUser.includes('51')
    
      return (          
          <React.Fragment>
              <Headerdashboard/>
              <Sidebar />  
              <AlertGeneral/> 
              <AlertConfirmation />
              <div className="page-wrapper">
                  <div className="row page-titles">
                      <div className="col-md-2 align-self-center">
                          <h3 className="text-primary">Precios - Listas</h3> 
                      </div>
                      { 
                            boolCrearLista
                        ? 
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustom"><a href="./pricescustom">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEditLista || boolDeleteLista
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomList"><a href="./pricescustomlist">Datos</a></label>
                        :
                            null
                        } 
                                                {
                            boolCargarPrices
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomCargar"><a href="./pricescustomcargar">Cargar</a></label>
                        :
                            null
                        }       
                        { 
                            boolCrearDetalle
                        ? 
                            <>
                                <div className="col-md-3 align-self-center">
                                    <h3 style = {{marginLeft:60}} className="text-primary">Precios - Detalles</h3> 
                                </div>
                                <label style = {{ fontSize:22}} id="idPricesCustomDetail">
                                    <a 
                                        href="./pricescustomdetail"> Crear
                                    </a>
                                </label>
                            </>
                        :
                            null
                        }
                        {
                            boolEditDetalle || boolDeleteDetalle
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomDetailList"><a href="./pricescustomdetaillist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargarDetails
                        ?
                            <label style = {{paddingLeft:20, fontSize:22}} id="idPricesCustomDetailCargar"><a href="./pricescustomdetailcargar">Cargar</a></label>
                        :
                            null
                        }    
                  </div>
                  <div className="container-fluid">
                      <div className="row">
                          <div className="col-12"> 
                              <div className="card">
                                  <div className="card-body">
                                      <h4 className="card-title"> </h4>   
                                      <h3>Cargar Listas de Precios Detalles</h3>
                                      <br></br>
                                      <h5>Indique que Lista de precios desea cargar</h5>
                                      <InputListPrices mostrar="1" auxId = "idListPrices" auxName = "ListPrices"/>
                                      <h5>Seleccione el archivo con nombre "listapreciosdetalle.xlsx" que contiene los datos a cargar</h5>
                                      <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="excel" id="idExcel" onChange={readUploadFile} /> 
                                      <div className="col-sm-offset-2 col-sm-10">
                                          <br></br>
                                          <button type="button" name="boton" id="idBoton" className="btn btn-default btnupdate" onClick={botonFunction} >Cargar</button>
                                      </div>  
                                      {/* <h6 style={{marginTop:30, fontWeight:"normal"}}>Descargar plantilla del archivo "comerciales.xlsx"</h6> */}
                                  </div>
                              </div>
                          </div>
                      </div>          
                  </div>
              </div>
          </React.Fragment>
      )
    }
}

export default load;
