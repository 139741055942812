import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession, alertaGeneral,closeAlertGeneral,alertaConfirm,closeSession} from '../../assets/js/GeneralScript'


document.body.classList.add('fix-sidebar');

class Category extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            renderizar : true,
            /* controlEdit : false,
            controlDelete : false, */
        };
    }
   componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi")
        var el = document.getElementById('mggAlert') 
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")
 
        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid)
            }
        }
        
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral); 
        } 
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }

        
        function formDelete(id) {
            if (id >= 1) {
                var datos = {
                    id: id
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/category/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data.id  == datos.id) {
                                alertaGeneral("Registro  Eliminado")
                                window.location.reload();
                            } else
                                alertaGeneral("Este dato esta asociado a otro concepto, por lo cual no se puede eliminar")            
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (29)"))
            } else {
                alertaGeneral("Validar la información ingresada");
            }
        }

        let datos = {idCedi : idCedi}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/category",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
                else 
                    closeSession()
            }
        ).catch(error => alertaGeneral("Problemas de Conexión (30)"))
        $(document).off().on('click', '.btnupdate', function () {
            let id = $("#updid").val();
            let name = $("#updname").val();
            let status = $("#updstatus").val();
            /* let idFabDist = $("#updidFabDist").val(); */
            let nota = $("#updnotas").val();
            if (name.length >= 4 ) {
                var datos = {
                    id: id,
                    name: name,
                    idCedi: idCedi,
                    nota: nota,
                    status: status,
                    /* idFabDist: idFabDist */
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/category/update", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "PUT",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado");
                            window.location.reload();    
                        } else 
                            closeSession()
                    }
                ).catch(error => alertaGeneral("Problemas de Conexión (31)"))
            } else {
                alertaGeneral("Por favor revisa, el nombre es obligatorio");
            }
        })
    }
  
    render(){
                         
        function formEdit(id) {
            window.scrollTo(0, 0);
            document.querySelector('.formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/category/read",{ // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        $("#updstatus").val(result.data.status);
                        $("#updid").val(result.data.id)
                        $("#updidcedi").val(result.data.idCedi)
                        $("#updname").val(result.data.name)
                        $("#updnotas").val(result.data.nota)
                        $('#formupdate #updcedi  option[value="' + result.data.idCedi + '"]').attr("selected", "selected");
                        /* $('#formupdate #updidFabDist option[value="' + result.data.idFabDist + '"]').attr("selected", "selected"); */
                        $("#updstatus option[value=" + result.data.status + "] ").attr('selected', 'selected');    
                    } else 
                        closeSession()
                }
            ).catch(error => alertaGeneral("Problemas de Conexión (32)"))    
        }

        var permisos = []

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('7')
        let boolEdit = accessUser.includes('8') 
        let boolDelete = accessUser.includes('9')
        let boolCargar = accessUser.includes('53')
        
        const { items } = this.state;
        
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Categoría</h3> 
                        </div>
                        { 
                            boolCrear 
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./category">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolEdit || boolDelete 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./categorylist">Datos</a></label>
                        :
                            null
                        }   
                        {
                            boolCargar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioCargar"><a href="./categorycargar">Cargar</a></label>
                        :
                            null
                        }     
                    </div>
                    <UpdateData renderizar = {this.state.renderizar} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive m-t-40">                                            
                                            <MaterialTable
                                                title="Datos"
                                                columns={[
                                                    { title: 'Id', field: 'id' },
                                                    { title: 'Estado', field: 'status', lookup: {1: 'Activo', 2: 'Inactivo'}}, 
                                                    { title: 'Nombre', field: 'name' }
                                                ]}
                                                data = {
                                                    items
                                                }
                                                options={{
                                                    //exportButton: true,
                                                    headerStyle: {
                                                        backgroundColor: '#01579b',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: true
                                                }}
                                                actions={[
                                                    {
                                                    icon: 'edit',
                                                    hidden: !boolEdit,
                                                    iconProps: { style: { color: "#00569b" } },
                                                    tooltip: 'Save User',
                                                    onClick: (event, rowData) => formEdit(rowData.id)
                                                    },
                                                    rowData => ({
                                                        icon: 'delete',
                                                        hidden: !boolDelete,
                                                        iconProps: { style: { color: "#ff5722" } },
                                                        tooltip: 'Delete User',
                                                        onClick: (event, rowData) => alertaConfirm(rowData.id)
                                                    })
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Category;