import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import $ from 'jquery';
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'
import * as xlsx from 'xlsx'

document.body.classList.add('fix-sidebar');

class load extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validXlsx : false,
            json : {},
        }
    }    

    render(){

      const readUploadFile = (e) => {
        e.preventDefault();
        let fileExcel = e.target.files
        if (e.target.files) {
            if (fileExcel[0].name != "categorias.xlsx") {
                this.setState({ validXlsx : false })
                alertaGeneral("Esta intentando cargar un archivo no autorizado")
            } else {
                this.setState({ validXlsx : true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = xlsx.read(data, { type: "array" })
                    const sheetName = workbook.SheetNames[0]
                    const worksheet = workbook.Sheets[sheetName]
                    const json = xlsx.utils.sheet_to_json(worksheet)
                    this.setState({ json : json })
                    let error = false
                    json.map((item) => {
                        if (!error) {
                          if (!item.hasOwnProperty('name')) {
                              error = true
                              alertaGeneral("No existe el campo: name")
                          } else
                              if ((typeof(item.name) != "string") || (item.name.trim() == "")) {
                                  error = true
                                  alertaGeneral("Existe una fila en donde el campo name esta vacio o el tipo de dato no es string")
                              }
                          
                          if (!item.hasOwnProperty('nota')) {
                              /* error = true */
                              alertaGeneral("No existe el campo: nota")
                          } else
                              if ((typeof(item.nota) != "string") || (item.nota.trim() == "") ) {
                                  /* error = true */
                                  alertaGeneral("Existe una fila en donde el campo nota esta vacio o el tipo de dato no es string")
                              }

                          if (!item.hasOwnProperty('status')) {
                              error = true
                              alertaGeneral("No existe el campo: status")
                          } else
                              if ((typeof(item.status) != "number") || (item.status != 1 && item.status != 2)) {
                                  error = true
                                  $("#idExcel").val("")
                                  alertaGeneral("Existe una fila en donde el campo status esta vacio o el tipo de dato no es number o tiene un valor diferentes a (1: Activo, 2: Inactivo)")
                              }
                        }
                    })
                    if (error) {
                        this.setState({ json : [] })
                        this.setState({ validXlsx : false })
                    }
                }
                reader.readAsArrayBuffer(e.target.files[0]);
            }
        }
      }

      const botonFunction = (e) => {
          if ( this.state.validXlsx ) {
              const idCedi = sessionStorage.getItem("idCedi")
              $('#updcedi').val(idCedi)
              const token = sessionStorage.getItem('token')
              let data = {
                  idCedi : idCedi,
                  datos :JSON.stringify(this.state.json),
              }
              fetch(Const.urlrest + "api/category/load", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify(data)
              }).then(response => response.json())
                  .then((result) => {
                        if (endSession(result.success)) {
                            if (result.data != null) {
                                alertaGeneral("Registro Ingresado")
                                window.location.reload(false);      

                          } else 
                              alertaGeneral("Verifica los campos")
                        } else 
                            closeSession()
              }).catch(error => alertaGeneral("Problemas de conexión (112)"))
          } else {
              $("#idExcel").val("")
              alertaGeneral("Aun no ha seleccionado el archivo categorias.xlsx")
          }
      }

      let accessUser = JSON.parse(sessionStorage.getItem('access'))
      let boolCrear = accessUser.includes('1')
      let boolOperar = accessUser.includes('2') || accessUser.includes('3')
      let boolCargar = accessUser.includes('47')
      
      return (          
          <React.Fragment>
              <Headerdashboard/>
              <Sidebar />  
              <AlertGeneral/> 
              <AlertConfirmation />
              <div className="page-wrapper">
                  <div className="row page-titles">
                      <div className="col-md-5 align-self-center">
                          <h3 className="text-primary">Categorías</h3> 
                      </div>
                      { 
                          boolCrear
                      ? 
                          <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./category">Crear</a></label>
                      :
                          null
                      }
                      {
                          boolOperar 
                      ?
                          <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./categorylist">Datos</a></label>
                      :
                          null
                      }    
                      {
                          boolCargar 
                      ?
                          <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioCargar"><a href="./categorycargar">Cargar</a></label>
                      :
                          null
                      }
                  </div>
                  <div className="container-fluid">
                      <div className="row">
                          <div className="col-12"> 
                              <div className="card">
                                  <div className="card-body">
                                      <h4 className="card-title"> </h4>   
                                      <h3>Cargar Categorías</h3>
                                      <br></br>
                                      <h5>Seleccione el archivo con nombre "categorias.xlsx" que contiene los datos a cargar</h5>
                                      <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="excel" id="idExcel" onChange={readUploadFile} /> 
                                      <div className="col-sm-offset-2 col-sm-10">
                                          <br></br>
                                          <button type="button" name="boton" id="idBoton" className="btn btn-default btnupdate" onClick={botonFunction} >Cargar</button>
                                      </div>  
                                      {/* <h6 style={{marginTop:30, fontWeight:"normal"}}>Descargar plantilla del archivo "comerciales.xlsx"</h6> */}
                                  </div>
                              </div>
                          </div>
                      </div>          
                  </div>
              </div>
          </React.Fragment>
      )
    }
}

export default load;
