import React, {Component} from 'react';
import '../../assets/css/Admin.css';
import Headerdashboard from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import CreateData from './createData';
import Const from '../utils/defaultConstant';
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral';
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript';

document.body.classList.add('fix-sidebar');

class notificationPush extends Component {
 
   componentDidMount() { 

        let idCedi        = sessionStorage.getItem("idCedi")
        let saldoIdCedi = 0
        let idWhiteBrand
        let idAssociative

        $("#updidListPrices").val("null") // undefined
        // *******************************
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/cedi/read", { // ok
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            method: "POST",
            body: JSON.stringify({id:idCedi})
        })
        .then(response => response.json())
        .then(
        (result) => {
            if (endSession(result.success))
                saldoIdCedi = result.data.balanceNotifications
            else 
                closeSession()
        })
        .catch(error => alertaGeneral("Problemas de conexión (66)"))

        // *******************************

        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }

        $(document).on('click', '.btnadd', function () {

            if ($("#updtypeClient").val() == 1) { // B2B
                idWhiteBrand  = sessionStorage.getItem("WhiteBrand")
                idAssociative = sessionStorage.getItem("Associative")
            } else { // B2C
                idWhiteBrand  = sessionStorage.getItem("WhiteBrand_b2c")
                idAssociative = sessionStorage.getItem("Associative_b2c")
            }

            const updTituloAssociative = sessionStorage.getItem("NP_Associative")
            const updTituloWhiteBrand  = sessionStorage.getItem("NP_WhiteBrand")
            const updTituloTexto       = sessionStorage.getItem("NP_Text")

            if ((updTituloAssociative != '') && (updTituloWhiteBrand != '') 
               && (updTituloTexto != '')) {

                let forEstablishment = '(b2bEstablecimientosClientCedi.id_cedi = ' + idCedi +')'
                forEstablishment += ' AND (b2bEstablecimientosClientCedi.type_client =  ' + $("#updtypeClient").val() +')'

                switch ($("#updapp").val()) {
                    case '1': // App Marca Blanca
                        forEstablishment +=  ' AND ( b2bEstablecimientosClientCedi.attention_x_persons_asoc_all = 1)' 
                        break
                    case '2': // App Associative
                        forEstablishment +=  ' AND ( b2bEstablecimientosClientCedi.attention_x_persons_asoc_all = 2)' 
                        break
                    }

                forEstablishment += ' AND (b2bEstablecimientosClientCedi.id_block_user =  0)' // Recibe notificaciones

                if ($('#updinternalCode').val() != "") 
                    forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.internal_code = ' + "'" + $("#updinternalCode").val() + "')" 
                if ($('#updidCommercial').val() != 0) 
                    forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.id_commercial = ' + $("#updidCommercial").val() +')'
                if ($('#updidListPrices').val() != 0) 
                    forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.id_list_prices = ' + $("#updidListPrices").val() +')'
                if ($('#updidRoute').val() != 0) 
                    forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.id_route = ' + $("#updidRoute").val() +')'

                switch ($('#updpaymentmethods').val()) {
                    case '1': 
                        forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.payment_methods = 1)' // Efectivo
                        break
                    case '2': 
                        forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.payment_methods = 2)' // Credito
                        break
                    case '3': 
                        forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.payment_methods = 3)' // Datafono
                        break
                }
                
                switch ($('#updtypeOffert').val()) {
                    case '1': 
                        forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.office_Unit = 1)' // Unidades
                        break
                    case '2': 
                        forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.office_Unit = 2)' // UMD
                        break
                }

                switch ($('#updtypeShipping').val()) {
                    case '1': 
                        forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.type_shipping = 1)' // Domicilio
                        break
                    case '2': 
                        forEstablishment +=  ' AND (b2bEstablecimientosClientCedi.type_shipping = 2)' // Pido y Recojo
                        break
                }

                let forOrdersDetail = ""  // B2B 

                if ($("#updidCategory").val() != 0) {
                    switch ($('#updapplyCategory').val()) {
                        case '1':
                            forOrdersDetail +=  " AND (b2bOrdersDetail.id_category = " + $("#updidCategory").val() +')'
                            break
                        case '2':
                            forOrdersDetail +=  " AND (b2bOrdersDetail.id_category <> " + $("#updidCategory").val() +')'                                
                            break        
                    }
                }
            
                if ($("#updProduct").val() != 0) {
                    switch ($("#updapplyProducto").val()) {
                        case '1':
                            forOrdersDetail +=  " AND (b2bOrdersDetail.id_product = " + $("#updProduct").val() +')'
                            break
                        case '2':
                            forOrdersDetail +=  " AND (b2bOrdersDetail.id_product <> " + $("#updProduct").val() +')'
                            break    
                    }
                }

                if ($("#updPurchaseTime").val() != -1) {
                    const fechaHoy = new Date(new Date() - (24 * 60 * 60 * 1000 * $("#updPurchaseTime").val())).toISOString()
                    forOrdersDetail += ' AND (b2bOrdersDetail.createdAt >= "'+ fechaHoy + '")'
                }
                    let datos = {
                        forEstablishment     : forEstablishment, 
                        forOrdersDetail      : forOrdersDetail,
                        idWhiteBrand         : idWhiteBrand,
                        idAssociative        : idAssociative,
                        saldoIdCedi          : saldoIdCedi,
                        idCedi               : idCedi,
                        updTituloAssociative : updTituloAssociative,
                        updTituloWhiteBrand  : updTituloWhiteBrand,
                        updTituloTexto       : updTituloTexto,
                        tipoMarketplace      : $("#updapp").val(),
                    } 
                    let token = sessionStorage.getItem('token')
                    fetch(Const.urlrest + "api/notifications/process", { // ok
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': token,
                        },
                        method: "POST",
                        body: JSON.stringify(datos)
                    })
                    .then(response => response.json())
                    .then(
                        (result) => {
                            if (endSession(result.success)) {
                                alertaGeneral(result.data.stringResponse);
                                saldoIdCedi = result.data.bancoNotifications    
                            } else 
                                closeSession()
                        }
                    ).catch(error => alertaGeneral("Problemas de Conexión (67)"))
                  
            } else 
                alertaGeneral("Los campos de texto de la notificación no pueden estar vacíos")
        }        
    )}

  render(){       

      return (          
            <div>
                <Headerdashboard/>
                <Sidebar /> 
                 <AlertGeneral /> 
                    <div className="page-wrapper">
                        <div className="row page-titles">
                            <div className="col-md-5 align-self-center">
                                <h3 className="text-primary">Notificaciones</h3> </div>
                            <div className="col-md-7 align-self-center">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                    <li className="breadcrumb-item active">Listar </li>
                                </ol>
                            </div>
                        </div>                        
                      <CreateData  />
                </div>
            </div>
      )
  }
}

export default notificationPush;
