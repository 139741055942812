import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import CreateData from './createData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class routescedi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

   componentDidMount() {    
       const idCedi = sessionStorage.getItem("idCedi")
       var el = document.getElementById('mggAlert')
       if (el) {
           el.addEventListener("click", closeAlertGeneral);
       }
        $(document).on('click', '.btnadd', function () {
            let name = document.getElementById("crename").value
            let textPr = document.getElementById("cretextPr").value
            let attentionDaysRoutes = []
            $(".checkdias:checked").each(function () {
                attentionDaysRoutes.push({
                    "dia": this.value
                })
            });
            if (name.length >= 4 && textPr.length >= 4 && attentionDaysRoutes.length >= 1) {
                var datos = {
                    name: name,
                    idCedi: idCedi,
                    textPr: textPr,
                    attentionDaysRoutes: JSON.stringify(attentionDaysRoutes)
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/routescedi/add", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "POST",
                    body: JSON.stringify(datos)
                })
                    .then(response => response.json())
                    .then(
                        (result) => {
                            if (endSession(result.success)) {
                                alertaGeneral("Registro Ingresado");
                                window.location.reload(false);    
                            } else 
                                closeSession()
                        }
                    ).catch(error => alertaGeneral("Problemas de Conexión (86)"))
            } else {
                alertaGeneral("Por favor revisa, todos los campos son obligatorios ");
            }
     });
        
             
   }

    render(){     

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('4')
        let boolOperar = accessUser.includes('5') || accessUser.includes('6')
        let boolCargar = accessUser.includes('49')
     
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                    <div className="page-wrapper">
                        <div className="row page-titles">
                            <div className="col-md-5 align-self-center">
                                <h3 className="text-primary">Rutas de Entrega</h3> 
                            </div>
                            { 
                                boolCrear
                            ? 
                                <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./routescedi">Crear</a></label>
                            :
                                null
                            }
                            {
                                boolOperar 
                            ?
                                <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./routescedilist">Datos</a></label>
                            :
                                null
                            }    
                            {
                                boolCargar 
                            ?
                                <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdx"><a href="./routescedicargar">Cargar</a></label>
                            :
                                null
                            }    
                        </div>
                    <CreateData />  
                </div>
            </div>
        )
    }
}

export default routescedi;