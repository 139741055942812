import React, {Component} from 'react';
import InputEmoji from 'react-input-emoji';
import InputCategory from '../atoms/InputCategory'
import InputComercial from '../atoms/InputComercial'
import InputListPrices from '../atoms/InputListPrices'
import InputPaymentMethods from '../atoms/InputPaymentMethods'
import InputProducto from '../atoms/InputProducto'
import InputRoutes from '../atoms/InputRoutes'
import InputTypeOffert from '../atoms/InputTypeOffert'
import InputTypeShipping from '../atoms/InputTypeShipping'
import InputApp from '../atoms/InputApp'
import InputPurchaseTime from '../atoms/InputPurchaseTime'
import ApplyCategory from '../atoms/ApplyCategory'
import ApplyProducto from '../atoms/ApplyProducto'                                       

class createData extends Component{      
    constructor(props) {
        super(props)
        this.state = {
            inputStrTextoTituloAsociativa : "",
            inputStrTituloMarcaBlanca : "",
            inputStrTituloTexto : "",            
        }
        this.actAssociative = this.actAssociative.bind(this)
        this.actWhiteBrand  = this.actWhiteBrand.bind(this)
        this.actText        = this.actText.bind(this) 
    }

    actAssociative(text) {
        sessionStorage.setItem("NP_Associative", text)
    }

    actWhiteBrand(text) {
        sessionStorage.setItem("NP_WhiteBrand",text)
    }

    actText(text) {
        sessionStorage.setItem("NP_Text",text) 
    }

    render(){

          return  (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h4>Publicar</h4>
                                    <div className="horizontal-form">
                                        <div className="form-horizontal" id="formcreate" >
                                            <form id="formulario">
                                                <div className="contBlock">                                                
                                                    <div className="row p-t-30" >            
                                                        <label className="col-md-12 control-label bold">Por favor defina el valor de cada variable, para configurar la segmentación de la notificación</label>
                                                        <div className="separador"></div>
                                                        <InputCategory doesNotApply = 'true'/>
                                                        <ApplyCategory />
                                                        <InputComercial doesNotApply = 'true' auxName="idCommercial" auxId = "updidCommercial"/>
                                                        <InputListPrices doesNotApply = 'true' mostrar = '2' auxName = "nidListPrices" auxId = "updidListPrices" disabled={false}/>
                                                        <InputApp />
                                                        <InputProducto doesNotApply = 'true'/>
                                                        <ApplyProducto />
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Codigo Interno Establecimiento * </label>                                                                
                                                                <input type="text" className="form-control" placeholder="" name="internalCode" id="updinternalCode" required="required" max="50" />
                                                            </div>                                            
                                                        </div> 
                                                        <InputTypeOffert doesNotApply = 'true'/>
                                                        <InputTypeShipping doesNotApply = 'true'/>
                                                        <InputRoutes doesNotApply = 'true' auxName = "idRoute" auxId="updidRoute"/>
                                                        <InputPurchaseTime doesNotApply = 'false'/>
                                                        <InputPaymentMethods doesNotApply = 'true'/>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Tipo de Cliente</label>
                                                                <select
                                                                    className="form-control" id="updtypeClient" name="typeClient"
                                                                >
                                                                    <option value="1">B2B</option>
                                                                    <option value="2">B2C</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>       
                                                </div>    
                                                <div className="contBlock">    
                                                    <div className="separador"></div>    
                                                    <label className="col-md-12 control-label bold">Textos de la Notificación</label>
                                                    <div className="separador"></div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Título para App Asociativa{" "}</label>
                                                            <InputEmoji type="text" className="form-control" placeholder="Inicie con el nombre del Cedi"                                                        
                                                                name="name1" id="updTituloAssociative" required="required" 
                                                                value={this.state.inputStrTextoTituloAsociativa}
                                                                height={40}
                                                                onChange={this.actAssociative}
                                                            />
                                                            <div className="separador"></div>
                                                            <label className=" control-label">Título para App Marca Blanca{" "}</label>
                                                            <InputEmoji type="text" className="form-control" placeholder="No puede quedar vacio"
                                                                name="name2" id="updTituloWhiteBrand" required="required"                                                                    
                                                                value = {this.state.inputStrTituloMarcaBlanca}
                                                                height={25}
                                                                onChange={this.actWhiteBrand}
                                                            />
                                                            <div className="separador"></div>
                                                            <label className=" control-label">Texto de la notificación{" "}</label>
                                                            <InputEmoji type="text" className="form-control" placeholder="No puede quedar vacio"
                                                                name="name3" id="updTituloTexto" required="required"
                                                                value = {this.state.inputStrTituloTexto}
                                                                height={25}
                                                                onChange={this.actText}
                                                            />
                                                        </div>
                                                    </div>                                                        
                                                </div> 
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-offset-2 col-sm-10">
                    <button type="button" className="btn btn-default btnadd" onClick={this.actMensaje}>Publicar Notificación</button>
                </div>               
            </React.Fragment>          
        )
    }
}

export default createData