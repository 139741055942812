import React, {Component} from 'react'

class updateData extends Component {
    render(){
        return  (
            <React.Fragment>                   
                <div className="container-fluid formupdate" id="formupdate">                       
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h3>Configuración: Datos App Mb B2C</h3>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <form id="formulario">
                                            <div className="form-horizontal" id="formcreate" >
                                                <input type="hidden" className="form-control" placeholder="" name="id" id="updid"    />
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="control-label">Vitrina Pública</label>                                                        
                                                        <select name="vitrinaMarkeplace" className="form-control" id="updvitrinaMarketplace">
                                                            <option value="1">Activa</option>
                                                            <option value="2">Inactiva</option>
                                                        </select>
                                                    </div>                                                            
                                                </div>
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Política de datos </label>
                                                            <textarea name="textPd" id="updtextPd"></textarea>
                                                        </div>
                                                    </div>
                                                </div>    
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Términos y condiciones</label>
                                                            <textarea name="textTyc" id="updtextTyc"></textarea>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Autorización Tratamiento de Datos</label>
                                                            <textarea name="textAutoriza" id="updtextAutoriza"></textarea>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div className="contBlock"> 
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <label className=" control-label bold">Redes sociales </label>
                                                            <div className="form-group">                                                                    
                                                                <div className="groupInputSocial">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Facebook" name="facebook"  id="facebook"  max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Youtube"  name="youtube"  id="youtube"   max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Instagram" name="instagram"  id="instagram" max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Twitter"  name="twitter"  id="twitter"   max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Linkedin"  name ="linkedin" id="linkedin"  max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url WhatsApp"  name="whatsapp"  id="whatsapp"  max="120" />     
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div className="contBlock">  
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <div className="form-group form-group-faqs">                                                                    
                                                                <label className=" control-label bold">Preguntas frecuentes </label>
                                                                <a href="#nogo" className="addCatFaqs">Agregar Categoría pregunta</a>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </form>
                                        <div className="form-group">
                                            <div className="col-sm-offset-2 col-sm-10">
                                                <button type="button" className="btn btn-default btnupdate">Actualizar Datos App Marca Blanca B2C</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default updateData