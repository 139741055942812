export function alertaGeneral(msg){
    document.querySelector('.msglabel').innerText=msg
    document.querySelector('.mggAlert').style.display = 'block'
    setTimeout(function () {
         document.querySelector('.mggAlert').style.display = 'none'
    }, 5000);
}
export function closeAlertGeneral() {  
    let mggAlertConfirm = document.querySelector('#mggAlertConfirm')
    if (mggAlertConfirm){
        mggAlertConfirm.style.display = 'none'
    }

    document.querySelector('.mggAlert').style.display = 'none'
     let b = document.querySelector("#btnAlertConfirm")
     if (b){
           b.setAttribute("data-nid", "")            
     }
     let c = document.querySelector("#btnAlertConfirm")
     if (c){
           c.setAttribute("data-nimg1", "")            
     }
     let d = document.querySelector("#btnAlertConfirm")
     if (d){
           d.setAttribute("data-nimg2", "")            
     }
     let e = document.querySelector("#btnAlertConfirm")
     if (e){
           e.setAttribute("data-nimg3", "")            
     }
     
}

export function alertaConfirm(nid,nimg1,nimg2,nimg3,nidCedi,nidCategory,nidListPrices) {
    
    document.querySelector('.mggAlertConfirm').style.display = 'block'
    let b = document.querySelector("#btnAlertConfirm")
    b.setAttribute("data-nid", nid)
    let c = document.querySelector("#btnAlertConfirm")
    c.setAttribute("data-nimg1", nimg1)
    let d = document.querySelector("#btnAlertConfirm")
    d.setAttribute("data-nimg2", nimg2)
    let e = document.querySelector("#btnAlertConfirm")
    e.setAttribute("data-nimg3", nimg3)

    let f = document.querySelector("#btnAlertConfirm")
    f.setAttribute("data-nidcedi", nidCedi) //data-nidCedi

    let g = document.querySelector("#btnAlertConfirm")
    g.setAttribute("data-nidcategory", nidCategory) //data-nidCategory

    let i = document.querySelector("#btnAlertConfirm")
    i.setAttribute("data-nidlistprices", nidListPrices) //nidListPrices

    setTimeout(function () {
        document.querySelector('.mggAlert').style.display = 'none'
    }, 5000);
}

export function endSession(statusToken) {
    if (!statusToken) {
        return false 
    } else
        return true
}

export function closeSession() {
    alertaGeneral("Sesión finalizada")
    setTimeout(function () {           
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('id');
        sessionStorage.removeItem('email');
        window.location.href = "/";        
    }, 3000)
}