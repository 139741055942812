import React, {Component} from 'react';
import {alertaGeneral} from '../../assets/js/GeneralScript';

class updateData extends Component {
        
    render() {

        function validOnChange(filex, imgshow,ancho,alto) {
            
            let output = document.getElementById(imgshow);
            output.src = URL.createObjectURL(filex[0]);
            output.onload = function() {
                if ((output.naturalWidth.toFixed(0) > ancho) || (output.naturalHeight.toFixed(0) > alto) ) {  /*  || (filex[0].name.length >= 46)  */
                    document.getElementById(imgshow).src = ''
                    sessionStorage.setItem("registerValid","false")
                    alertaGeneral("La Imagen seleccionada no cumple con la medida mínima proporcional de ancho: "+ancho+" x alto: "+alto+" pixels, o la longitud del nombre es mayor a 46 caracteres")
                } else {
                    sessionStorage.setItem("registerValid","true")
                }
                URL.revokeObjectURL(output.src) // free memory
            }
        }

        return  (
            <React.Fragment>                   
                <div className="container-fluid formupdate" id="formupdate">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h3>Configuración: Look Unidad de Negocio</h3>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <form id="formularioupdate">
                                            <div className="form-horizontal" id="formcreate" >
                                                <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updidCedi" required="required"   />
                                                <div className="row p-t-20">
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Splash Screen y Logo Interno en Unidad de Negocio</label>
                                                            <div className="separador"></div>  
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                <img src="" alt="" id="imgshowSplashScreenInternoCedi" width="112.5" height="178.25" />   
                                                                <label style ={{marginLeft:10}} className=" control-label">Imagen Splash Screen</label>                                                        
                                                                <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_SplashScreenInternoCedi"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }} id="updImagenes_SplashScreenInternoCedi" required="required" 
                                                                   onChange ={(e) => validOnChange(e.target.files,"imgshowSplashScreenInternoCedi",450,713)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowLogoInternoCedi" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Logo</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_LogoInternoCedi" style={{width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }} id="updImagenes_LogoInternoCedi" required="required" 
                                                                        onChange ={(e) => validOnChange(e.target.files,"imgshowLogoInternoCedi",375,145)}  />
                                                                </div>
                                                            </div>

                                                        </div> 

                                                    </div>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Tus Clientes</label>
                                                            <div className="separador"></div>  
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_ColorPantalla" id="updEstablecimientoConst_ColorPantalla" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_ColorHeader1" id="updEstablecimientoConst_ColorHeader1" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_FuenteColorHeader" id="updEstablecimientoConst_FuenteColorHeader" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Selecciona ..."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_ColorInvitacionE" id="updEstablecimientoConst_ColorInvitacionE" required="required" 
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_ColorAreaTarjetas" id="updEstablecimientoConst_ColorAreaTarjetas" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Borde Tarjeta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_CardColorBorde" id="updEstablecimientoConst_CardColorBorde" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Tarjeta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_ColorTarjeta" id="updEstablecimientoConst_ColorTarjeta" required="required" 
                                                                    />
                                                                </div>
                                                            </div>                                                        

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Nombre Establecimiento</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_ColorNombre" id="updEstablecimientoConst_ColorNombre" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Direccion y Lista de Precio</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_ColorDireccion" id="updEstablecimientoConst_ColorDireccion" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pie de Página </label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="EstablecimientoConst_ColorFooterE" id="updEstablecimientoConst_ColorFooterE" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowBackArrow" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Flecha Retroceso</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_BackArrow" id="updImagenes_BackArrow" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowBackArrow",75,75)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowInstagram" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Instagram</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Instagram" id="updImagenes_Instagram" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowInstagram",75,75)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowFacebook" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Facebook</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Facebook" id="updImagenes_Facebook" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowFacebook",75,75)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowTwitter" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Twitter</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Twitter" id="updImagenes_Twitter" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowTwitter",75,75)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowLinkeding" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Linkeding</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Linkeding" id="updImagenes_Linkeding" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowLinkeding",75,75)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowWhatsapp" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Whatsapp</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Whatsapp" id="updImagenes_Whatsapp" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowWhatsapp",75,75)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowYoutube" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Youtube</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Youtube" id="updImagenes_Youtube" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowYoutube",75,75)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCloseBlack" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Cerrar "X"</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_CloseBlack" id="updImagenes_CloseBlack" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowCloseBlack",18,18)}  />
                                                                </div>          
                                                            </div>
                                                        </div>        
                                                    </div>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Lista</label>
                                                            <div className="separador"></div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla/Barra Ordenar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondo" id="updMiListaConst_ColorFondo" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorHeader" id="updMiListaConst_ColorHeader" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_FuenteColorHeader1" id="updMiListaConst_FuenteColorHeader1" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Buscar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoBuscar" id="updMiListaConst_ColorTextoBuscar" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Casilla "Buscar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoBuscarCasilla" id="updMiListaConst_ColorTextoBuscarCasilla" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Casilla "Buscar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoBuscarCasilla" id="updMiListaConst_ColorFondoBuscarCasilla" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Barra Establecimiento</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoEstablecimiento" id="updMiListaConst_ColorFondoEstablecimiento" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Nombre Establecimiento</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_NombreTienda" id="updMiListaConst_NombreTienda" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Área Canasta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoCanasta" id="updMiListaConst_ColorFondoCanasta" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Canasta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_FondoColorCanasta" id="updMiListaConst_FondoColorCanasta" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Número Canasta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoCanasta" id="updMiListaConst_ColorTextoCanasta" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Ordenar por"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBotonEnviarTexto" id="updMiListaConst_ColorBotonEnviarTexto" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Orden Opciones</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorOrderName" id="updMiListaConst_ColorOrderName" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Opciones "Ordenar por"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBordeOpcionesOrdenar" id="updMiListaConst_ColorBordeOpcionesOrdenar" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Opciones "Ordenar por"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoOpcionesOrdenar" id="updMiListaConst_ColorTextoOpcionesOrdenar" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Línea Separadora "Ordenar por"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBordeOrdenar" id="updMiListaConst_ColorBordeOrdenar" required="required" 
                                                                    />
                                                                </div>
                                                            </div>

                                                            
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Mi Lista conserva +"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoMiListaVacio" id="updMiListaConst_ColorTextoMiListaVacio" required="required" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Tarjeta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoCards" id="updMiListaConst_ColorFondoCards" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Separador Tarjeta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorSeparadorTarjeta" id="updMiListaConst_ColorSeparadorTarjeta" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3"> 
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Eliminar Tarjeta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBotonEliminarCards" id="updMiListaConst_ColorBotonEliminarCards" required="required" 
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color "X" Eliminar Tarjeta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBotonCerrarCards" id="updMiListaConst_ColorBotonCerrarCards" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Nombre Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorNombreProducto" id="updMiListaConst_ColorNombreProducto" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Descripción Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorDescripcionProducto" id="updMiListaConst_ColorDescripcionProducto" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Precios Producto/SubTotal</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorPreciosProducto" id="updMiListaConst_ColorPreciosProducto" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Menos</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBotonMenos" id="updMiListaConst_ColorBotonMenos" required="required" 
                                                                    />
                                                                </div>
                                                            </div>                                                            
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Icono Menos</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorIconoMenos" id="updMiListaConst_ColorIconoMenos" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Borde Cantidad Und / UMD</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_colorBorderInputCant" id="updMiListaConst_colorBorderInputCant" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Und / UMD</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorInputCardFondo" id="updMiListaConst_ColorInputCardFondo" required="required" 
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                <label className=" control-label">Color Texto Cantidad Und / UMD</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorInputCardTexto" id="updMiListaConst_ColorInputCardTexto" required="required" 
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                <label className=" control-label">Color Fondo Cantidad > 0 Und / UMD</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoCantidadMayor" id="updMiListaConst_ColorFondoCantidadMayor" required="required" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Más</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBotonMas" id="updMiListaConst_ColorBotonMas" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Icono Más</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorIconoMas" id="updMiListaConst_ColorIconoMas" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Pedido Mín"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorPedidoMin2" id="updMiListaConst_ColorPedidoMin2" required="required" 
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Barra SubMenú</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_FooterColor" id="updMiListaConst_FooterColor" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Separador SubMenú</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBordeFooter" id="updMiListaConst_ColorBordeFooter" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Indicador de Actividad</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorActivityIndicator" id="updMiListaConst_ColorActivityIndicator" required="required" 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowBasket" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Basket</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Basket" id="updImagenes_Basket" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowBasket",48,48)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowBuscar" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Buscar</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Buscar" id="updImagenes_Buscar" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowBuscar",75,75)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowLista" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">ImagenLista</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Lista" id="updImagenes_Lista" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowLista",136,198)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowMas" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Más</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Mas" id="updImagenes_Mas" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowMas",44,44)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowMenos" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Menos</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Menos" id="updImagenes_Menos" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowMenos",44,44)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCondiciones" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Condiciones</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Condiciones"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}id="updImagenes_Condiciones" required="required" 
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowCondiciones",45,45)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowPromoEstrella" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen PromoEstrella</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_PromoEstrella"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}id="updImagenes_PromoEstrella" required="required" 
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowPromoEstrella",45,45)}  />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowInformacionLegalCedi" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Información Legal Cedi</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_InformacionLegalCedi"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}id="updImagenes_InformacionLegalCedi" required="required" 
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowInformacionLegalCedi",50,50)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowUsuario" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Usuario</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Usuario" id="updImagenes_Usuario" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowUsuario",85,85)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowEstadisticas" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Estadísticas</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Estadisticas" id="updImagenes_Estadisticas" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                    onChange ={(e) => validOnChange(e.target.files,"imgshowEstadisticas",50,50)}  />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ************ MI LISTA - DETALLE PRODUCTO ************************************** */}
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Lista y Modal: Detalle Producto</label>
                                                            <div className="separador"></div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoModalDetalle" id="updMiListaConst_ColorFondoModalDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Slider</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoSliderDetalle" id="updMiListaConst_ColorFondoSliderDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Punto Slider Activo</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBolasSliderDetalle" id="updMiListaConst_ColorBolasSliderDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Punto Slider Inactivo</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBolasSliderInactivoDetalle" id="updMiListaConst_ColorBolasSliderInactivoDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Ver+"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorAbrirUrlInactivoDetalle" id="updMiListaConst_ColorAbrirUrlInactivoDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Ver+"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorAbrirUrlTextoDetalle" id="updMiListaConst_ColorAbrirUrlTextoDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Nombre Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorNombreTextoDetalle" id="updMiListaConst_ColorNombreTextoDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Descripción</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorDescripcionTextoDetalle" id="updMiListaConst_ColorDescripcionTextoDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Pedido Mínimo</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorPedidoMin" id="updMiListaConst_ColorPedidoMin" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Precio Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorPreciosTextoDetalle" id="updMiListaConst_ColorPreciosTextoDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Restar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoBotonRestarDetalle" id="updMiListaConst_ColorFondoBotonRestarDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón Restar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoBotonRestarDetalle" id="updMiListaConst_ColorTextoBotonRestarDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Cantidad Und / UMD</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorInputCard" id="updMiListaConst_ColorInputCard" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Cantidad Und / UMD</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoInputDetalle" id="updMiListaConst_ColorTextoInputDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Sumar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoBotonSumarDetalle" id="updMiListaConst_ColorFondoBotonSumarDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón Sumar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoBotonSumarDetalle" id="updMiListaConst_ColorTextoBotonSumarDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Continuar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoBotonContinuarDetalle" id="updMiListaConst_ColorFondoBotonContinuarDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón "Continuar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoBotonContinuarDetalle" id="updMiListaConst_ColorTextoBotonContinuarDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Indicador de Actividad</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorImagenLoading" id="updMiListaConst_ColorImagenLoading" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                    {/* *************** MI LISTA - HALLAZGOS */}
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Lista y Modal: Hemos encontrado que ...</label>
                                                            <div className="separador"></div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoRecuadroHallazgos" id="updMiListaConst_ColorFondoRecuadroHallazgos" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoModal" id="updMiListaConst_ColorFondoModal" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Título "Hemos ..."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTituloHallazgos" id="updMiListaConst_ColorTituloHallazgos" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Estos productos ..."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoHallazgos" id="updMiListaConst_ColorTextoHallazgos" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botones Opciones</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBotonFondoHallazgos" id="updMiListaConst_ColorBotonFondoHallazgos" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botones Opciones</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoBotonHallazgos" id="updMiListaConst_ColorTextoBotonHallazgos" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Líneas Tabla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorBordeTabla" id="updMiListaConst_ColorBordeTabla" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Títulos Tabla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTabla" id="updMiListaConst_ColorTabla" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Textos Tabla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTitulosTabla" id="updMiListaConst_ColorTitulosTabla" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Lista y Modal: Condiciones de Servicio</label>
                                                            <div className="separador"></div>   
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_MarcoCs" id="updCondicionesServicioConst_MarcoCs" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_colorFondoModal1" id="updCondicionesServicioConst_colorFondoModal1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Condiciones ..."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_tituloCs" id="updCondicionesServicioConst_tituloCs" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Cerrar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_ColorFondoCerrar" id="updCondicionesServicioConst_ColorFondoCerrar" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Línea Separadora</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_LineaSeparadora" id="updCondicionesServicioConst_LineaSeparadora" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Condiciones de Servicio</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_ColorVariables" id="updCondicionesServicioConst_ColorVariables" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Título "Comunicado"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_ColorTitulo" id="updCondicionesServicioConst_ColorTitulo" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Cuadro "Comunicado"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_ColorFondoScrollView" id="updCondicionesServicioConst_ColorFondoScrollView" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Comunicado</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesServicioConst_RutaEntregaColorTextScroll" id="updCondicionesServicioConst_RutaEntregaColorTextScroll" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Lista y Modal: Términos y Condiciones</label>
                                                            <div className="separador"></div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="TyCMiListaConst_ColorFondoPantalla2" id="updTyCMiListaConst_ColorFondoPantalla2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="TyCMiListaConst_ColorFondoModal10" id="updTyCMiListaConst_ColorFondoModal10" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Título</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="TyCMiListaConst_ColorTextoTitulo1" id="updTyCMiListaConst_ColorTextoTitulo1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Botón y Texto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="TyCMiListaConst_buttonColor1" id="updTyCMiListaConst_buttonColor1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Selector</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="TyCMiListaConst_selectedButtonColor1" id="updTyCMiListaConst_selectedButtonColor1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Contenido</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="TyCMiListaConst_textoModalInfoCedi1" id="updTyCMiListaConst_textoModalInfoCedi1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Bóton Cerrar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="TyCMiListaConst_ColorFondoBotonCerrar1" id="updTyCMiListaConst_ColorFondoBotonCerrar1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ********************** MI LISTA - INFORMACION ************************************************** */}
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Mi Lista - Información</label>
                                                            <div className="separador"></div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorFondoInforma" id="updMiListaConst_ColorFondoInforma" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3"> 
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorModalInformacion" id="updMiListaConst_ColorModalInformacion" required="required" 
                                                                      />
                                                                </div>
                                                            </div> 
                                                            <div className="col-md-3"> 
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Título Información y Email</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_InformacionColorTitulo" id="updMiListaConst_InformacionColorTitulo" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3"> 
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Título Comercial Asignado</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesInforma_ColorTituloComercial" id="updCondicionesInforma_ColorTituloComercial" required="required" 
                                                                      />
                                                                </div>
                                                            </div> 
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Nombre Comercial</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorNombreComercial" id="updMiListaConst_ColorNombreComercial" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Línea Separadora</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorLineaInformacion" id="updMiListaConst_ColorLineaInformacion" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Envíale un .."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextoInforma" id="updMiListaConst_ColorTextoInforma" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Comentario</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiListaConst_ColorTextAreaInformacion" id="updMiListaConst_ColorTextAreaInformacion" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Borde Comentario</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesInforma_ColorBordeSoporte" id="updCondicionesInforma_ColorBordeSoporte" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3"> 
                                                                <div className="form-group">
                                                                    <label className=" control-label"> Color Texto "Escribe aquí"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesInforma_ColorTituloDos" id="updCondicionesInforma_ColorTituloDos" required="required" 
                                                                      />
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Comentario</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesInforma_ColorTextAreaInformacionTexto" id="updCondicionesInforma_ColorTextAreaInformacionTexto" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Enviar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesInforma_ColorBotonEnviar1" id="updCondicionesInforma_ColorBotonEnviar1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3"> 
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón "Enviar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesInforma_ColorBotonEnviarTexto1" id="updCondicionesInforma_ColorBotonEnviarTexto1" required="required" 
                                                                      />
                                                                </div> 
                                                            </div> 

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Bloquear ..."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesInforma_InformacionColorTituloBlock" id="updCondicionesInforma_InformacionColorTituloBlock" required="required" 
                                                                      />
                                                                </div>
                                                            </div> 
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Imagen Bloquear</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="CondicionesInforma_ColorFondoBloack" id="updCondicionesInforma_ColorFondoBloack" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCheckActivo" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Botón Chequeo Activo</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_CheckActivo"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}id="updImagenes_CheckActivo" required="required" 
                                                                     onChange ={(e) => validOnChange(e.target.files,"imgshowCheckActivo",30,30)}  />
                                                                </div>                                                        
                                                            </div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCheckInactivo" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Botón Chequeo Inactivo</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_CheckInactivo"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}id="updImagenes_CheckInactivo" required="required" 
                                                                     onChange ={(e) => validOnChange(e.target.files,"imgshowCheckInactivo",30,30)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowTelefono" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Teléfono</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Telefono" id="updImagenes_Telefono" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                     onChange ={(e) => validOnChange(e.target.files,"imgshowTelefono",30,30)}  />
                                                                </div>
                                                            </div>
                                                        </div>    
                                                    </div>
                                                    {/* ********************** MI LISTA - AGREGAR PRODUCTOS *********************** */}
                                                    <div className="contBlock">    
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Lista y Modal: Agregar Productos</label>
                                                            <div className="separador"></div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorFondoCategorias" id="updAgregarConst_ColorFondoCategorias" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorHeader2" id="updAgregarConst_ColorHeader2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_FuenteColorHeader2" id="updAgregarConst_FuenteColorHeader2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Establecimiento</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ContenedorTiendaFondo" id="updAgregarConst_ContenedorTiendaFondo" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Establecimiento</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorNombreEstablecimiento" id="updAgregarConst_ColorNombreEstablecimiento" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Seleccionar Categorías</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorFondoSeleccionarCategorias" id="updAgregarConst_colorFondoSeleccionarCategorias" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Título Seleccionar Categorías</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorTextoSeleccionarCategorias" id="updAgregarConst_colorTextoSeleccionarCategorias" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Área Despliegue Categorías</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorFondoProduDestCategorias" id="updAgregarConst_colorFondoProduDestCategorias" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Línea Separadora Categorías</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorBordesCat" id="updAgregarConst_colorBordesCat" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Nota Categoría</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorFondoNotas" id="updAgregarConst_colorFondoNotas" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Nombre Categoría</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorTextoNomCategoria" id="updAgregarConst_colorTextoNomCategoria" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Nota Categoría</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorTextoNotaCategoria" id="updAgregarConst_colorTextoNotaCategoria" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Monitor Actividad</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorActivityIndicator1" id="updAgregarConst_ColorActivityIndicator1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Tarjeta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorFondoCard" id="updAgregarConst_ColorFondoCard" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Sombra Tarjeta</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorSombraCard" id="updAgregarConst_ColorSombraCard" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Nombre Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorNombreCard" id="updAgregarConst_ColorNombreCard" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Descripción Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorDescripcionCard" id="updAgregarConst_ColorDescripcionCard" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Precio Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorPrecioCard" id="updAgregarConst_ColorPrecioCard" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Agregar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorBtnDetalle" id="updAgregarConst_colorBtnDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón "Agregar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorBtnTextoDetalle" id="updAgregarConst_colorBtnTextoDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Pedido Mín"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorTextoPedidoMin" id="updAgregarConst_ColorTextoPedidoMin" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCategoria" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Imagen Categoría</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Categoria" id="updImagenes_Categoria" required="required"  style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                     onChange ={(e) => validOnChange(e.target.files,"imgshowCategoria",52,52)}  />
                                                                </div>
                                                            </div> 

                                                        </div>
                                                    </div>        
                                                    {/* ********************** MI LISTA - AGREGAR PRODUCTOS - DETALLE *********************** */}
                                                    <div className="contBlock">    
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Lista y Modal: Agregar Productos - Detalle</label>
                                                            <div className="separador"></div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorFondoModal1" id="updAgregarConst_ColorFondoModal1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorFondoModalDetalle1" id="updAgregarConst_ColorFondoModalDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Slider</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorFondoSliderDetalle1" id="updAgregarConst_ColorFondoSliderDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Puntos Slider Activo</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorBolasSliderDetalle1" id="updAgregarConst_ColorBolasSliderDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Puntos Slider Inactivo</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorBolasSliderInactivoDetalle1" id="updAgregarConst_ColorBolasSliderInactivoDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Ver+"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorAbrirUrlInactivoDetalle1" id="updAgregarConst_ColorAbrirUrlInactivoDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Ver +"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorAbrirUrlTextoDetalle1" id="updAgregarConst_ColorAbrirUrlTextoDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Nombre Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorNombreTextoDetalle1" id="updAgregarConst_ColorNombreTextoDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Descripción</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorDescripcionTextoDetalle1" id="updAgregarConst_ColorDescripcionTextoDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Pedido Mín</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorPedidoMinDetalle" id="updAgregarConst_ColorPedidoMinDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Precio</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorPreciosTextoDetalle1" id="updAgregarConst_ColorPreciosTextoDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Agregar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorTextoBotonAgregarDetalle0" id="updAgregarConst_ColorTextoBotonAgregarDetalle0" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón "Agregar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorTextoBotonAgregarDetalle" id="updAgregarConst_ColorTextoBotonAgregarDetalle" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Continuar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorFondoBotonContinuarDetalle1" id="updAgregarConst_ColorFondoBotonContinuarDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group"> 
                                                                    <label className=" control-label">Color Texto Botón "Continuar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorFondoBotonContinuarDetalle0" id="updAgregarConst_ColorFondoBotonContinuarDetalle0" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>        
                                                    {/* ******************* AGREGAR - SUBCATEGORIAS ************************ */}
                                                    <div className="contBlock">    
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Lista y Modal: Agregar Productos</label>
                                                            <div className="separador"></div>  
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_ColorFondo1" id="updAgregarConst_ColorFondo1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorFondoModalSubCategorias" id="updAgregarConst_colorFondoModalSubCategorias" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Cerrar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorFondoModalCerrar" id="updAgregarConst_colorFondoModalCerrar" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Cerrar "X"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorFondoModalCerrarEquis" id="updAgregarConst_colorFondoModalCerrarEquis" required="required" 
                                                                      />
                                                                </div>
                                                            </div>   

                                                            <div className="col-md-3">    
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Título</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorTextoModalNombreCat" id="updAgregarConst_colorTextoModalNombreCat" required="required" 
                                                                      />
                                                                </div>
                                                            </div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo SubCategorías</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorFondoSubCategorias" id="updAgregarConst_colorFondoSubCategorias" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto SubCategorías</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="AgregarConst_colorTextoSubCategorias" id="updAgregarConst_colorTextoSubCategorias" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="contBlock">  
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Pedido</label>
                                                            <div className="separador"></div>    
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorHeader6" id="updMiPedidoConst_ColorHeader6" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_FuenteColorHeader5" id="updMiPedidoConst_FuenteColorHeader5" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Establecimiento</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ContenedorTiendaFondo1" id="updMiPedidoConst_ContenedorTiendaFondo1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Establecimiento</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorNombreEstablecimiento1" id="updMiPedidoConst_ColorNombreEstablecimiento1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Subtotal</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoContenedorSubtotal" id="updMiPedidoConst_ColorFondoContenedorSubtotal" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Flete</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoContenedorFlete" id="updMiPedidoConst_ColorFondoContenedorFlete" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Total</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoContenedorTotal" id="updMiPedidoConst_ColorFondoContenedorTotal" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Subtotal-Flete-Total"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoValores" id="updMiPedidoConst_ColorTextoValores" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Variables Servicio</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoContenedorValores" id="updMiPedidoConst_ColorFondoContenedorValores" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Variables</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoDefVariables" id="updMiPedidoConst_ColorTextoDefVariables" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Valor Variables</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoVariables" id="updMiPedidoConst_ColorTextoVariables" required="required" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo "Oops"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoCard1" id="updMiPedidoConst_ColorFondoCard1" required="required" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Oops"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoOops" id="updMiPedidoConst_ColorTextoOops" required="required" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Lista Productos</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondo7" id="updMiPedidoConst_ColorFondo7" required="required" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Lista de Prod .."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoListaProductos" id="updMiPedidoConst_ColorTextoListaProductos" required="required" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Ítems Pedido</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoItemsPedido" id="updMiPedidoConst_ColorTextoItemsPedido" required="required" />
                                                                </div>
                                                            </div>
 

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pie de Página</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_FooterColor1" id="updMiPedidoConst_FooterColor1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Hacer Pedido"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorBotonPedirFooter" id="updMiPedidoConst_ColorBotonPedirFooter" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón "Hacer Pedido"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorBotonPedirTextoFooter" id="updMiPedidoConst_ColorBotonPedirTextoFooter" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="contBlock">  
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Mi Pedido y Modal: Detalle Producto</label>
                                                            <div className="separador"></div>  
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoModal4" id="updMiPedidoConst_ColorFondoModal4" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoModalDetalle2" id="updMiPedidoConst_ColorFondoModalDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Slider</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoSliderDetalle2" id="updMiPedidoConst_ColorFondoSliderDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Puntos Slider Activo</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorBolasSliderDetalle2" id="updMiPedidoConst_ColorBolasSliderDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Puntos Slider Inactivos</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorBolasSliderInactivoDetalle2" id="updMiPedidoConst_ColorBolasSliderInactivoDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo "Ver +"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorAbrirUrlInactivoDetalle2" id="updMiPedidoConst_ColorAbrirUrlInactivoDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Ver +"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorAbrirUrlTextoDetalle2" id="updMiPedidoConst_ColorAbrirUrlTextoDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Nombre Producto</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorNombreTextoDetalle2" id="updMiPedidoConst_ColorNombreTextoDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Descripción</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorDescripcionTextoDetalle2" id="updMiPedidoConst_ColorDescripcionTextoDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Pedido Mín"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorPedidoMin1" id="updMiPedidoConst_ColorPedidoMin1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Precio</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorPreciosTextoDetalle2" id="updMiPedidoConst_ColorPreciosTextoDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Restar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoBotonRestarDetalle1" id="updMiPedidoConst_ColorFondoBotonRestarDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón Restar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoBotonRestarDetalle1" id="updMiPedidoConst_ColorTextoBotonRestarDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Cantidad Und / UMD</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoCantidad" id="updMiPedidoConst_ColorFondoCantidad" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Cantidad Und / UMD</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoInputDetalle1" id="updMiPedidoConst_ColorTextoInputDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón Sumar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoBotonSumarDetalle1" id="updMiPedidoConst_ColorFondoBotonSumarDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón Sumar</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoBotonSumarDetalle1" id="updMiPedidoConst_ColorTextoBotonSumarDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Continuar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorFondoBotonContinuarDetalle2" id="updMiPedidoConst_ColorFondoBotonContinuarDetalle2" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto Botón "Continuar"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="MiPedidoConst_ColorTextoBotonContinuarDetalle1" id="updMiPedidoConst_ColorTextoBotonContinuarDetalle1" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>   
                                                    <div className="contBlock">  
                                                        <div className="row p-t-20">
                                                            <label className="col-md-6 control-label bold">Vista: Gracias Pedido</label>
                                                            <div className="separador"></div>   
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="GraciasPedidoConst_ColorFondo6" id="updGraciasPedidoConst_ColorFondo6" required="required" 
                                                                      />
                                                                </div>
                                                            </div>  
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Barra</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="GraciasPedidoConst_ColorHeader5" id="updGraciasPedidoConst_ColorHeader5" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Pedido Recibido .."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="GraciasPedidoConst_ColorPedidoRecibido" id="updGraciasPedidoConst_ColorPedidoRecibido" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Gracias por tu ..."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="GraciasPedidoConst_GraciasTextoColor" id="updGraciasPedidoConst_GraciasTextoColor" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Estamos .."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="GraciasPedidoConst_MensajeTextoColor" id="updGraciasPedidoConst_MensajeTextoColor" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Texto "Asesor y nombre"</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="GraciasPedidoConst_NombreTextoColor" id="updGraciasPedidoConst_NombreTextoColor" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Color Fondo Botón "Volver a ..."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="GraciasPedidoConst_BotonFinalizarColor" id="updGraciasPedidoConst_BotonFinalizarColor" required="required" 
                                                                      />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label"> Color Texto Botón "Volver a ..."</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="GraciasPedidoConst_BotonFinalizarTextoColor" id="updGraciasPedidoConst_BotonFinalizarTextoColor" required="required" 
                                                                      />
                                                                </div>
                                                            </div>

                                                        </div>    
                                                    </div>        

                                                </div>
                                            </div>  
                                        </form>      
                                        <div className="form-group">
                                            <div className="col-sm-12">
                                                <button type="button" className="btn btn-default btnupdate" id="btnupdate">Actualizar Look Unidad de Negocio</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </React.Fragment>          
        )
    }
}

export default updateData