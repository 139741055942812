import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

const rootElement = document.getElementById("root");
const isProduction = process.env.NODE_ENV === "production";
ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<App />} //If not pass, nothing appears at the time of new version check.
  >
    <App />
  </CacheBuster>,
  rootElement
);


