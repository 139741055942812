import React, {Component} from 'react'

class createData extends Component{    
    render(){
        return  (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h4>Crear </h4>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <div className="form-horizontal" id="formcreate" >
                                       <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updidCedi" required="required"   />                                                   
                                            <div className="row p-t-20">
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label className=" control-label">Nombre Ruta</label>                                                                
                                                        <input type="text" className="form-control" placeholder="" name="name" id="crename" required="required"  />
                                                          <label className="msglabel">Mínimo 4 caracteres</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <label className=" control-label">Días Recorrido ruta</label>
                                                    <div className="form-group rutadias">          
                                                        <label><input type="checkbox" value="lunes"  className="form-control checkdias" id="dia1" /> Lunes</label>                                                                                                                                                    
                                                        <label><input type="checkbox"  value="martes"  className="form-control checkdias " id="dia2"/>Martes</label>    
                                                        <label><input type="checkbox"  value="miércoles"  className="form-control  checkdias" id="dia3"/> Miércoles</label>                                                                                                                                                    
                                                        <label><input type="checkbox"  value="jueves"  className="form-control  checkdias" id="dia4"/>Jueves</label> 
                                                        <label><input type="checkbox"  value="viernes"  className="form-control  checkdias" id="dia5"/> Viernes</label>                                                                                                                                                    
                                                        <label><input type="checkbox"  value="sábado"  className="form-control  checkdias" id="dia6"/>Sabado</label>    
                                                         <label><input type="checkbox"  value="domingo"  className="form-control  checkdias" id="dia7"/>Domingo</label>                                                                         
                                                    </div> 
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className=" control-label"> Política de ruta</label>
                                                        <textarea className="form-control" id="cretextPr" max="250"> </textarea>
                                                        <label className="msglabel">Mínimo 4 caracteres</label>
                                                    </div>                                                                                        
                                                </div>              
                                            </div>
                                            <div className="row p-t-20"></div>
                                            <div className="form-group"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-offset-2 col-sm-10">
                    <button type="button" className="btn btn-default btnadd">Ingresar una ruta</button>
                </div>               
            </React.Fragment>          
        )
    }
}

export default createData