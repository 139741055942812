import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import CreateData from './createData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

class establishments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi")

        const varOnlyListPrice = document.querySelector("#updidListPrices");
        varOnlyListPrice.addEventListener('change',updateOnlyListPrice);
        document.querySelector('.flagOnlyListPrice').style.display = 'none'

        function updateOnlyListPrice(e) {
            let combo = document.getElementById("updidListPrices");
            let selected = combo.options[combo.selectedIndex].text;
            if ((selected.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2B") && (selected.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2C"))
                document.querySelector('.flagOnlyListPrice').style.display = 'block'
            else 
                document.querySelector('.flagOnlyListPrice').style.display = 'none'
        }


        const input = document.querySelector('#updtypeFreight');
        input.addEventListener('change', updateValue);
        document.querySelector('.porfleteVar').style.display = 'none'
        
        function updateValue(e) {
            if (e.target.value == "2") {               
                document.querySelector('.porfleteVar').style.display = 'block'
                document.querySelector('.varFijoflete').style.display = 'none'
            } else {               
                document.querySelector('.porfleteVar').style.display = 'none'
                document.querySelector('.varFijoflete').style.display = 'block'
            }
        }

        function isValidEmail(email) {
         var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         return re.test(email);
        }

        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        
        $(document).on('click', '.btnadd', function () {

            let pricesFreigh = 0;
            let percentageFreightVar = 0;
            let status = $("#updstatus").val();
            let typeClient = $("#updtypeClient").val();
            let internalCode = $("#updinternalCode").val()
            let address = $("#updaddress").val();
            let typeDocument = $("#updtypeDocument").val();
            let idTypeBusiness = $("#updidTypeBusiness").val();
            let numberDocument = $("#updnumberDocument").val();
            let nameBilling = $("#updnameBilling").val();
            let phone = $("#updphone").val();
            let emailEnvoice = $("#updemailEnvoice").val();
            let name = $("#updname").val();
            let nameContac = $("#updnameContac").val();
            let lat = $("#updlat").val();
            let lng = $("#updlng").val();
            let paymentMethods = $("#updpaymentmethods").val();
            let idRoute = $("#updidRoute").val();
            let idCommercial = $("#updidCommercial").val();
            let idListPrices = $("#updidListPrices").val();
            let typeTaxes = $("#updtypeFreight").val();                          
            let orderMinAllTax = $("#updorderMinAllTax").val();
            let typeShipping = $("#updtypeShipping").val();
            let onlyListPrices = $("#updonlyListPrices").val();
            let officeUnit = $("#updofficeUnit").val();

            let visitLunes = $("#updvisitLunes").val();
            let visitMartes = $("#updvisitMartes").val();
            let visitMiercoles = $("#updvisitMiercoles").val();
            let visitJueves = $("#updvisitJueves").val();
            let visitViernes = $("#updvisitViernes").val();
            let visitSabado = $("#updvisitSabado").val();
            let visitDomingo = $("#updvisitDomingo").val();


            let attentionXpersonsAsocAll = $("#updattentionXpersonsAsocAll").val();
            let ticketNoFreigh = $("#updticketNoFreigh").val();
            let release = $("#updrelease").val();
            let emailUser = $("#updemailUser").val();
            let idBlockUser = 0
            let paramAlgoritDispersePercentage = $("#updparamAlgoritDispersePercentage").val();
            let paramAlgoritDisperseCtoTat = $("#updparamAlgoritDisperseCtoTat").val();
            
            if ($("#updpercentageFreightVar").val() !== "") {
                percentageFreightVar = $("#updpercentageFreightVar").val();
            }
            if ($("#updpricesFreigh").val() !== "") {
                pricesFreigh = $("#updpricesFreigh").val();
            }
            
            let valEmail = isValidEmail(emailUser);
            let valEmailEnvoice = isValidEmail(emailEnvoice)
            if (valEmail && valEmailEnvoice) {
                if (lat !== "" && lng !== "") {
                    let coordinates = {
                      lat: lat,
                      lng: lng,
                    };
                    if (
                      nameContac.length >= 4 &&
                      name.length >= 4 &&
                      nameBilling.length >= 4
                    ) {
                        var datos = {
                          idCedi: idCedi, 
                          status: status, 
                          typeClient: typeClient, 
                          name: name, 
                          address: address, 
                          typeDocument: typeDocument, 
                          idTypeBusiness: idTypeBusiness, 
                          numberDocument: numberDocument, 
                          nameBilling: nameBilling, 
                          phone: phone, 
                          emailEnvoice: emailEnvoice, 
                          internalCode: internalCode, 
                          officeUnit: officeUnit, 
                          nameContac: nameContac, 
                          coordinates: JSON.stringify(coordinates), 
                          paymentMethods: paymentMethods, 
                          idRoute: idRoute, 
                          idCommercial: idCommercial, 
                          idListPrices: idListPrices, 
                          orderMinAllTax: orderMinAllTax, 
                          typeShipping: typeShipping, 
                          onlyListPrices: onlyListPrices, 
                          attentionXpersonsAsocAll: attentionXpersonsAsocAll, 
                          typeTaxes: typeTaxes, 
                          percentageFreightVar: percentageFreightVar, 
                          pricesFreigh: pricesFreigh, 
                          ticketNoFreigh: ticketNoFreigh, 
                          release: release, 
                          emailUser: emailUser, 
                          idBlockUser: idBlockUser, 
                          paramAlgoritDisperseCtoTat: paramAlgoritDisperseCtoTat, 
                          paramAlgoritDispersePercentage: paramAlgoritDispersePercentage, 
                          visitLunes : visitLunes, 
                          visitMartes : visitMartes, 
                          visitMiercoles : visitMiercoles, 
                          visitJueves : visitJueves, 
                          visitViernes : visitViernes, 
                          visitSabado : visitSabado, 
                          visitDomingo : visitDomingo, 
                          infoLunes : 1, 
                          infoMartes : 1, 
                          infoMiercoles : 1, 
                          infoJueves : 1, 
                          infoViernes : 1, 
                          infoSabado : 1, 
                          infoDomingo : 1, 
                          origenCliente: 1, 
                          WhiteBrand      : sessionStorage.getItem("WhiteBrand"),
                          Associative     : sessionStorage.getItem("Associative"),
                          WhiteBrand_b2c  : sessionStorage.getItem("WhiteBrand_b2c"),
                          Associative_b2c : sessionStorage.getItem("Associative_b2c"),
                          nameCedi : sessionStorage.getItem('nameCedi'),
                        };
                        let token = sessionStorage.getItem('token')
                        fetch(Const.urlrest +"api/establishmentsclientcedi/add",
                            {
                                headers: { 
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': token,
                                },
                                method: "POST",
                                body: JSON.stringify(datos),
                            }
                        )
                        .then((response) => response.json())
                        .then(
                          (result) => {
                            if (endSession(result.success)) {
                                if (result.data) {
                                    alertaGeneral("Registro Ingresado");
                                    window.location.reload(false);
                                } else { 
                                      alertaGeneral(
                                          "Por favor revisa la información ingresada, todos los campos son obligatorios"
                                    )
                                }      
                            } else 
                                closeSession()
                          }
                        ).catch(error => alertaGeneral("Problemas de Conexión (53)"))
                    } else {
                        alertaGeneral("Verifica los datos ingresados, Todos son obligatorios");
                    }
                } else {
                  alertaGeneral("Verifica las coordenadas");
                }
            } else {
                alertaGeneral("Verifica el email");
            }
        });                           
    }
    
    render(){    
        
        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('25')
        let boolOperar = accessUser.includes('26') || accessUser.includes('27')
        let boolCargar = accessUser.includes('52')

        return (          
            <div>
               <Headerdashboard/>
                <Sidebar /> 
                <AlertGeneral />  
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Clientes</h3> 
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./establishments">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolOperar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./establishmentslist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargar
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./establishmentscargar">Cargar</a></label>
                        :
                            null
                        }    
                    </div>
                    <CreateData />  
                </div>
            </div>
        )
    }
}

export default establishments;