import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import $ from 'jquery';
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'
import * as xlsx from 'xlsx'

document.body.classList.add('fix-sidebar');

class load extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validXlsx : false,
            json : {},
        }
    }    

    render(){

      function validarEmail(valor) {
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) 
            return true
        else
            return false
      }

      const readUploadFile = (e) => {
        e.preventDefault();
        let fileExcel = e.target.files
        if (e.target.files) {
            if (fileExcel[0].name != "clientes.xlsx") {
                this.setState({ validXlsx : false })
                alertaGeneral("Esta intentando cargar un archivo no autorizado")
            } else {
                this.setState({ validXlsx : true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = xlsx.read(data, { type: "array" })
                    const sheetName = workbook.SheetNames[0]
                    const worksheet = workbook.Sheets[sheetName]
                    const json = xlsx.utils.sheet_to_json(worksheet)
                    this.setState({ json : json })
                    let error = false
                    json.map((item) => {
                        if (!error) {
                            if (!item.hasOwnProperty('name')) {
                                error = true
                                alertaGeneral("No existe el campo: name")
                            } else
                                if ((typeof(item.name) != "string") || (item.name.trim() == "")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo name esta vacio o el tipo de dato no es string")
                                }
                            
                            if (!item.hasOwnProperty('emailUser')) {
                                alertaGeneral("No existe el campo: emailUser")
                            } else
                                if ((typeof(item.emailUser) != "string") || (item.emailUser.trim() == "") || (!validarEmail(item.emailUser))) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo emailUser esta vacio o el tipo de dato no es string o el emailUser es incorrecto en formato")
                                }

                            if (!item.hasOwnProperty('address')) {
                                error = true
                                alertaGeneral("No existe el campo: address")
                            } else
                                if ((typeof(item.address) != "string") || (item.address.trim() == "")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo address esta vacio o el tipo de dato no es string")
                                }

                            if (!item.hasOwnProperty('phone')) {
                                error = true
                                alertaGeneral("No existe el campo: phone")
                            } else
                                if ((typeof(item.phone) != "number") || (item.phone === 0)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo phone esta vacio o el tipo de dato no es string")
                                }

                            if (!item.hasOwnProperty('coordinates')) {
                                error = true
                                alertaGeneral("No existe el campo: coordinates")
                            } else
                                if ((typeof(item.coordinates) != "string") || (item.coordinates.trim() == "")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo coordinates esta vacio o el tipo de dato no es string")
                                }

                            if (!item.hasOwnProperty('typeDocument')) {
                                error = true
                                alertaGeneral("No existe el campo: typeDocument")
                            } else
                                if ((typeof(item.typeDocument) != "number") || (item.typeDocument < 1) || (item.typeDocument > 2)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo typeDocument esta vacio o el tipo de dato no es number o el valor no corresponde (1: Cédula, 2: Nit")
                                }
                                
                            if (!item.hasOwnProperty('numberDocument')) {
                                error = true
                                alertaGeneral("No existe el campo: numberDocument")
                            } else
                                if ((typeof(item.numberDocument) != "number") || (item.numberDocument == 0)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo numberDocument esta vacio o el tipo de dato no es number")
                                }

                            if (!item.hasOwnProperty('nameBilling')) {
                                error = true
                                alertaGeneral("No existe el campo: nameBilling")
                            } else
                                if ((typeof(item.nameBilling) != "string") || (item.nameBilling.trim() == "")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo nameBilling esta vacio o el tipo de dato no es string")
                                }

                            if (!item.hasOwnProperty('paymentMethods')) {
                                error = true
                                alertaGeneral("No existe el campo: paymentMethods")
                            } else
                                if ((typeof(item.paymentMethods) != "number") || (item.paymentMethods < 1) || (item.paymentMethods > 3)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo paymentMethods esta vacio o el tipo de dato no es number o el valor no corresponde a (1: Efectivo, 2: Crédito, 3: Datáfono")
                                }

                            if (!item.hasOwnProperty('nameContac')) {
                                error = true
                                alertaGeneral("No existe el campo: nameContac")
                            } else
                                if ((typeof(item.nameContac) != "string") || (item.nameContac.trim() == "")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo nameContac esta vacio o el tipo de dato no es string")
                                }

                            if (!item.hasOwnProperty('idRoute')) {
                                error = true
                                alertaGeneral("No existe el campo: idRoute")
                            } else
                                if ((typeof(item.idRoute) != "number") || (item.idRoute == 0)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo idRoute esta vacio o el tipo de dato no es number")
                                }

                            if (!item.hasOwnProperty('idCommercial')) {
                                error = true
                                alertaGeneral("No existe el campo: idCommercial")
                            } else
                                if ((typeof(item.idCommercial) != "number") || (item.idCommercial == 0)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo idCommercial esta vacio o el tipo de dato no es number")
                                }
                                
                            if (!item.hasOwnProperty('idListPrices')) {
                                error = true
                                alertaGeneral("No existe el campo: idListPrices")
                            } else
                                if ((typeof(item.idListPrices) != "number") || (item.idListPrices == 0)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo idListPrices esta vacio o el tipo de dato no es number")
                                }
        
                            if (!item.hasOwnProperty('onlyListPrices')) {
                                error = true
                                alertaGeneral("No existe el campo: onlyListPrices")
                            } else
                                if ( (typeof(item.onlyListPrices) != "number") || (item.onlyListPrices < 0) || (item.onlyListPrices > 1) ) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo onlyListPrices esta vacio o el tipo de dato no es number o no es (0: Mostrar solo los productos de la lista ,1: No mostrar solo los productos de la lista")
                                }
                                    
                            if (!item.hasOwnProperty('orderMinAllTax')) {
                                error = true
                                alertaGeneral("No existe el campo: orderMinAllTax")
                            } else
                                if ( (typeof(item.orderMinAllTax) != "number") ) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo orderMinAllTax esta vacio o el tipo de dato no es number")
                                }
        
                            if (!item.hasOwnProperty('status')) {
                                error = true
                                alertaGeneral("No existe el campo: status")
                            } else
                                if ( (typeof(item.status) != "number") || (item.status < 1) || (item.status > 2) ) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo status esta vacio o el tipo de dato no es number o el valor no es (1: Activo, 2: Inactivo")
                                }
                                    
                            if (!item.hasOwnProperty('attentionXpersonsAsocAll')) {
                                error = true
                                alertaGeneral("No existe el campo: attentionXpersonsAsocAll")
                            } else
                                if ((typeof(item.attentionXpersonsAsocAll) != "number") || (item.attentionXpersonsAsocAll != 0)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo attentionXpersonsAsocAll esta vacio o el tipo de dato no es number o el valor no corresponde a (0: Todas, 1: Marca Propia, 2: Asociativa")
                                }
        
                            if (!item.hasOwnProperty('officeUnit')) {
                                error = true
                                alertaGeneral("No existe el campo: officeUnit")
                            } else
                                if ( (typeof(item.officeUnit) != "number") || (item.officeUnit < 0) || (item.officeUnit > 2) ) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo officeUnit esta vacio o el tipo de dato no es number o el valor no corresponde a (0: Todas,1: Unidades, 2: UMD")
                                }
                                    
                            if (!item.hasOwnProperty('typeShipping')) {
                                error = true
                                alertaGeneral("No existe el campo: typeShipping")
                            } else
                                if ((typeof(item.typeShipping) != "number") || (item.typeShipping < 1) || (item.typeShipping > 2) ) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo typeShipping esta vacio o el tipo de dato no es number o el valor no corresponde a (1: Domicilio , 2: Recoge")
                                }
        
                            if (!item.hasOwnProperty('typeTaxes')) {
                                error = true
                                alertaGeneral("No existe el campo: typeTaxes")
                            } else
                                if ( (typeof(item.typeTaxes) != "number") || (item.typeTaxes < 1) || (item.typeTaxes > 2) )  {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo typeTaxes esta vacio o el tipo de dato no es number o el valor no corresponde a (1: Valor Fijo, 2: % del Pedido")
                                }
                                    
                            if (!item.hasOwnProperty('pricesFreigh')) {
                                error = true
                                alertaGeneral("No existe el campo: pricesFreigh")
                            } else
                                if ((typeof(item.pricesFreigh) != "number")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo pricesFreigh esta vacio o el tipo de dato no es number")
                                }
        
                            if (!item.hasOwnProperty('ticketNoFreigh')) {
                                error = true
                                alertaGeneral("No existe el campo: ticketNoFreigh")
                            } else
                                if ((typeof(item.ticketNoFreigh) != "number")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo ticketNoFreigh esta vacio o el tipo de dato no es number")
                                }
                                    
                            if (!item.hasOwnProperty('percentageFreightVar')) {
                                error = true
                                alertaGeneral("No existe el campo: percentageFreightVar")
                            } else
                                if ((typeof(item.percentageFreightVar) != "number")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo percentageFreightVar esta vacio o el tipo de dato no es number")
                                }
        
                            if (!item.hasOwnProperty('release')) {
                                error = true
                                alertaGeneral("No existe el campo: release")
                            } else
                                if ((typeof(item.release) != "string") || (item.release.trim() == "")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo release esta vacio o el tipo de dato no es string")
                                }
                                    
                            if (!item.hasOwnProperty('idBlockUser')) {
                                error = true
                                alertaGeneral("No existe el campo: idBlockUser")
                            } else
                                if ((typeof(item.idBlockUser) != "number") || (item.idBlockUser < 0) || (item.idBlockUser > 1)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo idBlockUser esta vacio o el tipo de dato no es number o el valor corresponde a (0: Inactivo , 1: Activo")
                                }
        
                            if (!item.hasOwnProperty('internalCode')) {
                                error = true
                                alertaGeneral("No existe el campo: internalCode")
                            } else
                                if ((typeof(item.internalCode) != "string")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo internalCode esta vacio o el tipo de dato no es string")
                                }
                                    
                            if (!item.hasOwnProperty('typeClient')) {
                                error = true
                                alertaGeneral("No existe el campo: typeClient")
                            } else
                                if ((typeof(item.typeClient) != "number") || (item.typeClient < 1) || (item.typeClient > 2)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo typeClient esta vacio o el tipo de dato no es number o el valor no corresponde a (1:B2B, 2:B2C")
                                }
        
                            if (!item.hasOwnProperty('idTypeBusiness')) {
                                error = true
                                alertaGeneral("No existe el campo: idTypeBusiness")
                            } else
                                if ((typeof(item.idTypeBusiness) != "number") || (item.idTypeBusiness == 0) ) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo idTypeBusiness esta vacio o el tipo de dato no es number")
                                }
                                    
                            if (!item.hasOwnProperty('emailEnvoice') || (!validarEmail(item.emailEnvoice))) {
                                error = true
                                alertaGeneral("No existe el campo: emailEnvoice")
                            } else
                                if ((typeof(item.emailEnvoice) != "string") || (item.emailEnvoice.trim() == "")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo emailEnvoice esta vacio o el tipo de dato no es string")
                                }
        
                            if (!item.hasOwnProperty('paramAlgoritDisperseCtoTat')) {
                                error = true
                                alertaGeneral("No existe el campo: paramAlgoritDisperseCtoTat")
                            } else
                                if ((typeof(item.paramAlgoritDisperseCtoTat) != "number")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo paramAlgoritDisperseCtoTat esta vacio o el tipo de dato no es number")
                                }
                                    
                            if (!item.hasOwnProperty('paramAlgoritDispersePercentage')) {
                                error = true
                                alertaGeneral("No existe el campo: paramAlgoritDispersePercentage")
                            } else
                                if ((typeof(item.paramAlgoritDispersePercentage) != "number")) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo paramAlgoritDispersePercentage esta vacio o el tipo de dato no es number")
                                }
                                    
                            if (!item.hasOwnProperty('visitLunes')) {
                                error = true
                                alertaGeneral("No existe el campo: visitLunes")
                            } else
                                if ((typeof(item.visitLunes) != "number") || (item.visitLunes < 0) || (item.visitLunes > 10)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo visitLunes esta vacio o el tipo de dato no es number o el valor no corresponde a (1:Todos, 2:No programado, 3:1er y 3do del mes, 4:2er y 4to del mes, 5:1ro del Mes, 6:2ro del Mes, 7:3ro del Mes, 8:4ro del Mes, 9:5ro del Mes, 10:6to del Mes si no hay 5to)")
                                }
                                    
                            if (!item.hasOwnProperty('visitMartes')) {
                                error = true
                                alertaGeneral("No existe el campo: visitMartes")
                            } else
                                if ((typeof(item.visitMartes) != "number") || (item.visitMartes < 0) || (item.visitMartes > 10) ) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo visitMartes esta vacio o el tipo de dato no es number o el valor no corresponde a (1:Todos, 2:No programado, 3:1er y 3do del mes, 4:2er y 4to del mes, 5:1ro del Mes, 6:2ro del Mes, 7:3ro del Mes, 8:4ro del Mes, 9:5ro del Mes, 10:6to del Mes si no hay 5to)")
                                }
                                    
                            if (!item.hasOwnProperty('visitMiercoles')) {
                                error = true
                                alertaGeneral("No existe el campo: visitMiercoles")
                            } else
                                if ((typeof(item.visitMiercoles) != "number") || (item.visitMiercoles < 0)  || (item.visitMiercoles > 10) ) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo visitMiercoles esta vacio o el tipo de dato no es number o el valor no corresponde a (1:Todos, 2:No programado, 3:1er y 3do del mes, 4:2er y 4to del mes, 5:1ro del Mes, 6:2ro del Mes, 7:3ro del Mes, 8:4ro del Mes, 9:5ro del Mes, 10:6to del Mes si no hay 5to)")
                                }
                                    
                            if (!item.hasOwnProperty('visitJueves')) {
                                error = true
                                alertaGeneral("No existe el campo: visitJueves")
                            } else
                                if ((typeof(item.visitJueves) != "number") || (item.visitJueves < 0) || (item.visitJueves > 10)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo visitJueves esta vacio o el tipo de dato no es number o el valor no corresponde a (1:Todos, 2:No programado, 3:1er y 3do del mes, 4:2er y 4to del mes, 5:1ro del Mes, 6:2ro del Mes, 7:3ro del Mes, 8:4ro del Mes, 9:5ro del Mes, 10:6to del Mes si no hay 5to)")
                                }
                                    
                            if (!item.hasOwnProperty('visitViernes')) {
                                error = true
                                alertaGeneral("No existe el campo: visitViernes")
                            } else
                                if ((typeof(item.visitViernes) != "number") || (item.visitViernes < 0) || (item.visitViernes > 10)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo visitViernes esta vacio o el tipo de dato no es number o el valor no corresponde a (1:Todos, 2:No programado, 3:1er y 3do del mes, 4:2er y 4to del mes, 5:1ro del Mes, 6:2ro del Mes, 7:3ro del Mes, 8:4ro del Mes, 9:5ro del Mes, 10:6to del Mes si no hay 5to)")
                                }
                                    
                            if (!item.hasOwnProperty('visitSabado')) {
                                error = true
                                alertaGeneral("No existe el campo: visitSabado")
                            } else
                                if ((typeof(item.visitSabado) != "number") || (item.visitSabado < 0) || (item.visitSabado > 10)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo visitSabado esta vacio o el tipo de dato no es number o el valor no corresponde a (1:Todos, 2:No programado, 3:1er y 3do del mes, 4:2er y 4to del mes, 5:1ro del Mes, 6:2ro del Mes, 7:3ro del Mes, 8:4ro del Mes, 9:5ro del Mes, 10:6to del Mes si no hay 5to)")
                                }
                                    
                            if (!item.hasOwnProperty('visitDomingo')) {
                                error = true
                                alertaGeneral("No existe el campo: visitDomingo")
                            } else
                                if ((typeof(item.visitDomingo) != "number") || (item.visitDomingo < 0) || (item.visitDomingo > 10)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo visitDomingo esta vacio o el tipo de dato no es number o el valor no corresponde a (1:Todos, 2:No programado, 3:1er y 3do del mes, 4:2er y 4to del mes, 5:1ro del Mes, 6:2ro del Mes, 7:3ro del Mes, 8:4ro del Mes, 9:5ro del Mes, 10:6to del Mes si no hay 5to)")
                                }
                                    
                            if (!item.hasOwnProperty('origenCliente')) {
                                error = true
                                alertaGeneral("No existe el campo: origenCliente")
                            } else
                                if ((typeof(item.origenCliente) != "number") || (item.origenCliente < 2) || (item.origenCliente > 6)) {
                                    error = true
                                    alertaGeneral("Existe una fila en donde el campo origenCliente esta vacio o el tipo de dato no es number o el valor no corresponde a (2: Aso B2B , 3: Marca Blanca B2B ,4: TAT, 5: Aso B2C,6: Marca Blanca B2C")
                                }
                        }
                    })
                    if (error) {
                        this.setState({ json : [] })
                        this.setState({ validXlsx : false })
                    }
                }
                reader.readAsArrayBuffer(e.target.files[0]);
            }
        }
      }

      const botonFunction = (e) => {
          if ( this.state.validXlsx ) {
              const idCedi = sessionStorage.getItem("idCedi")
              $('#updcedi').val(idCedi)
              const token = sessionStorage.getItem('token')
              let data = {
                  idCedi : idCedi,
                  datos :JSON.stringify(this.state.json),
              }
              fetch(Const.urlrest + "api/establishmentsclientcedi/load", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify(data)
              }).then(response => response.json())
                    .then((result) => {
                        if (endSession(result.success)) {
                            if (result.data.data === true) {
                                window.location.reload(false)
                                alertaGeneral("Registro Ingresado");
                            } else {
                                $("#idExcel").val("")
                                alertaGeneral("Verifica los campos: "+result.data.message);
                            }      
                        } else 
                            closeSession()
              }).catch(error => alertaGeneral("Problemas de conexión (118)"))
          } else {
              $("#idExcel").val("")
              alertaGeneral("Aun no ha seleccionado el archivo clientes.xlsx")
          }
      }

      let accessUser = JSON.parse(sessionStorage.getItem('access'))
      let boolCrear = accessUser.includes('25')
      let boolOperar = accessUser.includes('26') || accessUser.includes('27')
      let boolCargar = accessUser.includes('52')
    
      return (          
          <React.Fragment>
              <Headerdashboard/>
              <Sidebar />  
              <AlertGeneral/> 
              <AlertConfirmation />
              <div className="page-wrapper">
                  <div className="row page-titles">
                      <div className="col-md-5 align-self-center">
                          <h3 className="text-primary">Clientes</h3> 
                      </div>
                      { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./establishments">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolOperar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./establishmentslist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargar
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./establishmentscargar">Cargar</a></label>
                        :
                            null
                        }    
                  </div>
                  <div className="container-fluid">
                      <div className="row">
                          <div className="col-12"> 
                              <div className="card">
                                  <div className="card-body">
                                      <h4 className="card-title"> </h4>   
                                      <h3>Cargar Clientes</h3>
                                      <br></br>
                                      <h5>Seleccione el archivo con nombre "clientes.xlsx" que contiene los datos a cargar</h5>
                                      <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="excel" id="idExcel" onChange={readUploadFile} /> 
                                      <div className="col-sm-offset-2 col-sm-10">
                                          <br></br>
                                          <button type="button" name="boton" id="idBoton" className="btn btn-default btnupdate" onClick={botonFunction} >Cargar</button>
                                      </div>  
                                      {/* <h6 style={{marginTop:30, fontWeight:"normal"}}>Descargar plantilla del archivo "comerciales.xlsx"</h6> */}
                                  </div>
                              </div>
                          </div>
                      </div>          
                  </div>
              </div>
          </React.Fragment>
      )
    }
}

export default load;
