import React, {Component} from 'react'
import InputStatus from '../atoms/InputStatus'
import $ from 'jquery';
import {alertaGeneral} from '../../assets/js/GeneralScript'

class createData extends Component{    
    render(){
        function validOnChange(filex) {
            let uploadFile = filex[0]
            let output = new Image() // document.getElementById('imgshow');
            output.src = URL.createObjectURL(uploadFile);
            $('#imgshow').attr('src', output.src);
            output.onload = function() {
                if ((output.naturalWidth.toFixed(0) < 192) || (output.naturalHeight.toFixed(0) < 192)) {
                    sessionStorage.setItem("registerValid","false")
                    document.getElementById("imgshow").src = ''
                    alertaGeneral("La Imagen seleccionada no cumple con la medida mínima proporcional de 192 x 192 pixels")
                } else {
                    sessionStorage.setItem("registerValid","true")
                    
                }
                URL.revokeObjectURL(output.src) // free memory
            }
        }

        return  (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h4>Crear </h4>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <div className="form-horizontal" id="formcreate" >
                                        <form id="formulario">
                                            <input type="hidden" className="form-control" placeholder="" name="idCedi" id="creidCedi" required="required"   />                                                   
                                            <div className="row p-t-20">                                                    
                                                <InputStatus />
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className=" control-label">Nombre Comercial </label>                                                                
                                                        <input type="text" className="form-control" placeholder="" name="name" id="crename" required="required"  />
                                                        <label className="msglabel">Mínimo 4 caracteres</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                           <label className=" control-label">Email Comercial </label>
                                                           <input type="text" className="form-control" placeholder="" name="email" id="creemail" required="required"  />
                                                           <label className="labelEmailcre labelEmail"></label>
                                                    </div>                                                                                        
                                                </div>  
                                            </div>
                                            <div className="row p-t-20">                                                                  
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className=" control-label">Celular Comercial </label>                                                                
                                                        <input type="number" className="form-control" placeholder=""  max="22" name="phone" id="crephone" required="required"  />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className=" control-label">Uso de Licencia</label>                                                                                                                                
                                                        <input type="checkbox" className="form-control" style={{ width: 35, height: 35 }} 
                                                               name="auxUsoLicInSite" id="updauxUsoLicInSite" 
                                                        />        
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label className=" control-label">Contraseña App</label>                                                                
                                                        <input type="password" className="form-control" placeholder="********" name="passwordInSite" id="updpasswordInSite" required="required"  />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label className=" control-label">Imagen &nbsp;</label>
                                                        <img id="imgshow" alt="" height="50" />     
                                                        <input type="file" style = {{color: "transparent", border: "none"}} className="form-control" placeholder="" name="img" id="creimg" required="required" accept="image/png" onChange ={(e) => validOnChange(e.target.files)} />
                                                        <label className="resultado"></label>
                                                  </div>
                                                </div>
                                            </div>
                                         </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-offset-2 col-sm-10">
                    <button type="button" className="btn btn-default btnadd" id="btnadd">Crear Comercial</button>
                </div>               
            </React.Fragment>          
        )
    }
}


export default createData