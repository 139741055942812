import React, {Component} from 'react'
import Logologin from '../atoms/Logologin'
import '../../assets/css/Login.css';
import { sha256 } from 'js-sha256';
import $ from 'jquery';
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import {alertaGeneral,closeAlertGeneral} from '../../assets/js/GeneralScript'

document.body.classList.add('homelogin')

class Login extends Component {
    
  componentDidMount() { 

    var el = document.getElementById('mggAlert');
    if (el) {
        el.addEventListener("click", closeAlertGeneral);
    }

    function validarEmail(valor) {
      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) {
        return 1
      } else {
          alertaGeneral("La dirección de email es incorrecta!.");
          return 0
      }
    }

    function user_Cedis() {

      let email = document.getElementById("username").value
      let emailvalido = validarEmail(email)
      let token = sessionStorage.getItem('token')
      if (emailvalido == "1" ) {
          var datos = { email: email }
          fetch(Const.urlrest + "api/usercedis/emailcedis", { // ok
              headers: { 
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': token,
              },
              method: "POST",
              body: JSON.stringify(datos)
            }) 
          .then(response => response.json())
          .then(
             (result) => {
                if (result.data.length != 0) { 
                    var misCedis = result.data
                    var option ="";
                    if (result.searchwhere) 
                        misCedis.forEach( function(valor, indice, array) {
                          if(valor['b2bCedi.status'] == 1) {
                            option +="<option value='"+valor['idCedi']+"' data_img='"+valor['b2bCedi.img']+"' typeDisperse_b2b='"+valor['b2bCedi.typeDisperse_b2b']+"' typeDisperse_b2c='"+valor['b2bCedi.typeDisperse_b2c']+"' nameCedi='"+valor['b2bCedi.name']+"' idWhiteBrand='"+valor['b2bCedi.idMarketplace']+"' idAssociative='"+valor['b2bCedi.idMarketplaceASo'] +"' idWhiteBrand_b2c='"+valor['b2bCedi.idMarketplace_b2c']+"' idAssociative_b2c='"+valor['b2bCedi.idMarketplaceASo_b2c']+"' onOffLook='"+valor['b2bCedi.onOffLook']+"' onOffListPrices='"+valor['b2bCedi.onOffListPrices']+"' onOffNotificaciones='"+valor['b2bCedi.onOffNotificaciones']+"' onOffImgPublicidad='"+valor['b2bCedi.onOffImgPublicidad']+"' searchwhere='"+'V'+"'    >"+valor['b2bCedi.name']+"</option>";                          
                          }
                        })                 
                    else
                        misCedis.forEach( function(valor, indice, array) {
                          if(valor['status'] == 1) {
                            option +="<option value='"+valor['id']+"' data_img='"+valor['img']+"' typeDisperse_b2b='"+valor['typeDisperse_b2b']+"' typeDisperse_b2c='"+valor['typeDisperse_b2c']+"' nameCedi='"+valor['name']+"' idWhiteBrand='"+valor['idMarketplace']+"' idAssociative='"+valor['idMarketplaceASo']  +"' idWhiteBrand_b2c='"+valor['idMarketplace_b2c']+"' idAssociative_b2c='"+valor['idMarketplaceASo_b2c']+"' onOffLook='"+valor['onOffLook']+"' onOffListPrices='"+valor['onOffListPrices']+"' onOffNotificaciones='"+valor['onOffNotificaciones']+"' onOffImgPublicidad='"+valor['onOffImgPublicidad']+"' searchwhere='"+'F'+"'    >"+valor['name']+"</option>";
                          }
                        })                   
                    document.getElementById("cedilist").innerHTML = option;
                    $(".conselectorcedi").show()
                    $("#btngeneralEmail").hide()       
                } else 
                    alertaGeneral("Por favor revisa tu email no se encuentra registrado")      
              }
            ).catch(error => alertaGeneral("Problemas de Conexión (63)"))
      } else {
            alertaGeneral("Verifica el email")
      }
    }

    function userLogin() { 
      
      let idCedi = $("#cedilist").val();
      sessionStorage.setItem("idCedi",idCedi) // Cambio

      let cediOption = $("#cedilist option:selected")
      let search_where = $(cediOption).attr("searchwhere") 
      sessionStorage.setItem("img",$(cediOption).attr("data_img"))  // Cambio
      sessionStorage.setItem("nameCedi",$(cediOption).attr("nameCedi"))  // Cambio
      sessionStorage.setItem("WhiteBrand",$(cediOption).attr("idWhiteBrand"))  // Cambio
      sessionStorage.setItem("Associative",$(cediOption).attr("idAssociative"))  // Cambio

      sessionStorage.setItem("WhiteBrand_b2c",$(cediOption).attr("idWhiteBrand_b2c"))  // Cambio
      sessionStorage.setItem("Associative_b2c",$(cediOption).attr("idAssociative_b2c"))  // Cambio

      sessionStorage.setItem("typeDisperse_b2b",$(cediOption).attr("typeDisperse_b2b"))  // Cambio
      sessionStorage.setItem("typeDisperse_b2c",$(cediOption).attr("typeDisperse_b2c"))  // Cambio
      
      sessionStorage.setItem("onOffLook",$(cediOption).attr("onOffLook"))  // Cambio
      sessionStorage.setItem("onOffListPrices",$(cediOption).attr("onOffListPrices"))  // Cambio

      sessionStorage.setItem("onOffNotificaciones",$(cediOption).attr("onOffNotificaciones"))  // Cambio
      sessionStorage.setItem("onOffImgPublicidad",$(cediOption).attr("onOffImgPublicidad"))  // Cambio

      let email = document.getElementById("username").value
      let emailvalido = validarEmail(email)
      let pass = document.getElementById("password").value
      $( "#myselect" ).val();
      let password = sha256(pass)
      var datos = {
          email: email,
          password: password,
          idCedi: idCedi,
      }
      if (emailvalido == "1" && pass.length >= 5 && pass.length <= 30) {
        if (search_where == 'V') {
            fetch(Const.urlrest + "api/usercedis/logincedis", { // ok
                headers: { 
                  /* 'Accept': 'application/json', */
                  'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify(datos)
            })
            .then(response => response.json())
            .then(
              (result) => {
                if (result.message == 'One row') {
                   sessionStorage.setItem("id", result.data.id)  // Cambio
                   sessionStorage.setItem("email", result.data.email)  // Cambio
                   sessionStorage.setItem("name", result.data.name)  // Cambio
                   sessionStorage.setItem("access", result.data.access)
                   sessionStorage.setItem("token", result.data.token)
                   sessionStorage.setItem("idCedi", idCedi)  // Cambio
                   sessionStorage.setItem("sectors",result.data.b2bCedi.sectors)  // Cambio
                   sessionStorage.setItem("img",result.data.b2bCedi.img)  // Cambio   
                   window.location.href = "/dashboard"
                } else {
                    alertaGeneral("Por favor revisa los datos")
                }
              }
            ).catch(error => alertaGeneral("Problemas de Conexión (64)"))
        }  else {
              fetch(Const.urlrest + "api/usercedis/logincedisadm", { // ok
                headers: { 
                  /* 'Accept': 'application/json', */
                  'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify(datos)
              })
              .then(response => response.json())
              .then(
                (result) => {
                  if (result.message == 'One row') {
                     sessionStorage.setItem("id", result.data.id)  // Cambio
                     sessionStorage.setItem("email", result.data.emailAdmin)  // Cambio
                     sessionStorage.setItem("name", result.data.nameAdmin)  // Cambio
                     sessionStorage.setItem("access", '["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53"]');
                     sessionStorage.setItem("token", result.data.token)
                     sessionStorage.setItem("sectors", result.data.sectors)  // Cambio
                     sessionStorage.setItem("idCedi", idCedi)  // Cambio
                     window.location.href = "/dashboard"
                  } else {
                      alertaGeneral("Por favor revisa los datos") 
                  }
                }
              ).catch(error => alertaGeneral("Problemas de Conexión (65)"))
        }
      } else {
          alertaGeneral("Verifica los datos de acceso")
      }
    }
    
    var lu = document.getElementById('btngeneral');
    if (lu) 
      lu.addEventListener("click", userLogin);

    var la = document.getElementById('btngeneralEmail');
    if (la) 
        la.addEventListener("click", user_Cedis);
    return false
  }

  render() {

    return ( 
      <React.Fragment>
          <AlertGeneral />  
          <div className="makeStyles-session-2 makeStyles-background-3">
              <div className="makeStyles-content-4">
                  <div className="makeStyles-wrapper-5">
                      <div className="MuiPaper-root MuiCard-root MuiPaper-elevation1 MuiPaper-rounded">
                          <div className="MuiCardContent-root">                                 
                              <div className="text-xs-center pb-xs">
                                  <Logologin /> 
                                  <span className="MuiTypography-root MuiTypography-caption">Ingrese sus datos para continuar</span>
                              </div>
                              <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">            
                                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                                      <input type="text" placeholder="Email" id="username" className="MuiInputBase-input MuiInput-input"  name="username"   />
                                  </div>
                              </div>
                          <div className="conselectorcedi MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">                                       
                              <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">                                           
                                  <select name="cedilist" id="cedilist"  className="MuiInputBase-input MuiInput-input" >
                                      <option value="">Cedi</option>
                                  </select>
                              </div>
                          </div>
                          <div className="conselectorcedi  MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                              <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">                                           
                                  <input id="password"  placeholder="Password" type="password" className="MuiInputBase-input MuiInput-input"     />
                              </div>
                          </div>
                          <button
                              className="MuiButtonBase-root MuiButton-root MuiButton-contained btngeneral MuiButton-containedPrimary MuiButton-fullWidth"
                              type="text" id="btngeneralEmail" >
                                  <span className="MuiButton-label">Ingresar</span>
                                  <span className="MuiTouchRipple-root"></span>
                          </button>
                          <button 
                              className="conselectorcedi MuiButtonBase-root MuiButton-root MuiButton-contained btngeneral MuiButton-containedPrimary MuiButton-fullWidth" 
                              type="text" id="btngeneral" >
                              <span className="MuiButton-label">Ingresar</span>
                              <span className="MuiTouchRipple-root"></span>
                          </button>
                          <div className="pt-1 text-md-center">
                              <a href="./forgot">
                                  <button className="MuiButtonBase-root MuiButton-root MuiButton-text btnrecover" type="button">
                                      <span className="MuiButton-label">Recuperar contraseña?</span>
                                </button>
                              </a>
                          </div>                        
                      </div>
                  </div>
              </div>
          </div>
      </div> 
    </React.Fragment>
    ) 
  }
}

export default Login;