import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'
import InputStatus from '../atoms/InputStatus'
import InputTypeBusiness from '../atoms/InputTypeBusiness'
import InputListPricesPromotions from '../atoms/InputListPricesPromotions'
import InputProductoPromotions from '../atoms/InputProductoPromotions'

document.body.classList.add('fix-sidebar')

class PromoEstrella extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [{ Producto: "", Cantidad : "" }],
            typeClient: "1",
            typeBusiness: "",
            idListPrices: "",
            officeUnit: "0",
            onlyListPrices: "0",
            nameListPrices: "Lista de Precios B2B"
        };
    }

    componentDidMount() { 

        document.querySelector('.flagOnlyListPrice').style.display = 'none'

        const varOnlyListPrice = document.querySelector("#idListPrices");
        varOnlyListPrice.addEventListener('change',updateOnlyListPrice);

        function updateOnlyListPrice(e) {
            let combo = document.getElementById("idListPrices");
            let selected = combo.options[combo.selectedIndex].text;
            if ((selected.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2B") && (selected.toUpperCase().replace(/ /g, "") != "LISTADEPRECIOSB2C"))
                document.querySelector('.flagOnlyListPrice').style.display = 'block'
            else 
                document.querySelector('.flagOnlyListPrice').style.display = 'none'
        }

        const varTypeClient = document.querySelector("#typeClient");
        varTypeClient.addEventListener('change',typeClientOnlyListPrice);

        function typeClientOnlyListPrice(e) {
            document.querySelector('.flagOnlyListPrice').style.display = 'none'
        }

        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
    }

    addRegister (idCedi) {

        const toDay= new Date().toISOString().substring(0, 10);
        let name = $("#crename").val();
        let status = $("#updstatus").val();
        let description = $("#credescription").val();
        let deadLine = $("#credeadLine").val();
        let idTypeBusiness = $("#idTypeBusiness").val();
        let idListPrices = $("#idListPrices").val();
        let maximumQuantity = $("#credmaximumQuantity").val();
        let products = JSON.stringify(this.state.products); 
        let typeClient = $("#typeClient").val();
        let porcentageOff = parseFloat($("#credporcentageOff").val());
        let officeUnit = $("#credofficeUnit").val();
        let flag = false
        let idTypeBusinessTodos
        if ("Todos" == $('select[name="idTypeBusiness"] option:selected').text())
            idTypeBusinessTodos = "Todos"
        else
            idTypeBusinessTodos = 0

        let listProducts = JSON.parse(products)
        listProducts.map(items => {
            if ((items['Producto'] == "") || (items['Producto'] == "-1") || (items['Cantidad'] < 1 ))
                flag = true
        })

        if (name.length >= 4 && description.length >= 5 &&  products.length != 0 &&
            maximumQuantity >= 1 && porcentageOff >= 0.1 && deadLine >= toDay &&
            !flag ) {
            let datos = {
                idCedi: idCedi,
                name: name,
                description: description,
                status: status,
                deadLine: deadLine,
                idTypeBusiness: idTypeBusiness,
                idTypeBusinessTodos: idTypeBusinessTodos,
                idListPrices: idListPrices,
                maximumQuantity: maximumQuantity,
                products: products,
                typeClient: typeClient,
                porcentageOff: porcentageOff,
                officeUnit: officeUnit,
            }
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "api/promotion/add", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify(datos)
            })
            .then(response => response.json())
            .then(
                (result) => {
                    console.log("*************** result : ", result)
                    if (endSession(result.success)) {
                        alertaGeneral("Registro Ingresado");
                        window.location.reload();    
                    } else 
                        closeSession()
                }
            ).catch(error => alertaGeneral("Problemas de Conexión (81)"))
        } else {
            alertaGeneral("Por favor revisa que la configuración esta incompleta");
        }
    }

    addFormFields() {
        this.setState(({ products: [...this.state.products, { Producto: "", Cantidad: "" }] }))
    }

    removeFormFields(i) {
        
        let products = this.state.products;
        let antes = products
        products.splice(i, 1);
        this.setState({ products });
    }

    handleChange(index,e) {
        let products = this.state.products;
        products[index]['Cantidad'] = e.target.value;
        this.setState({ products });
    }

    handleProduct = (e, index) => {
        let valorBuscado = e.target.value
        let products = this.state.products;
        let resultado = products.findIndex(item => item.Producto == valorBuscado)
        if (resultado == -1) {
            products[ index ]['Producto'] = e.target.value;
            this.setState({ products });
        } else
          alertaGeneral("Producto Repetido, seleccione otro")
    }

    parentListPrices = (e,index) =>{
        this.setState({ idListPrices : e.target.value})
        this.setState({ nameListPrices : e.target.options[index].text })
        this.setState({ products : []})
    }

    handleActualizarDatosListPrices = (id,name) => {
        this.setState({ idListPrices : id })
        this.setState({ nameListPrices : name })
    }

    handleTypeClient(e) {
        this.setState({typeClient : e.target.value})
        this.setState({ products : []})
    }
    
    handleOnlyListPrices (e) {
        this.setState({onlyListPrices : e.target.value})
        this.setState({ products : []})
    }

    handleOfficeUnit(e) {
        this.setState({officeUnit:e.target.value})
        this.setState({products : []})
    }

    vacio = (vacio) => {return true}

    render(){

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('43')
        let boolOperar = accessUser.includes('44') || accessUser.includes('45')

        const toDay= new Date().toISOString().substring(0, 10);
        const idCedi = sessionStorage.getItem("idCedi")

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Promociones Estrella</h3> 
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./promoestrella">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolOperar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./promoestrellalist">Datos</a></label>
                        :
                            null
                        }    
                    </div>
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-title">
                                            <h4>Crear </h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="horizontal-form">
                                                <div className="form-horizontal" id="formcreate" >
                                                    <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updcedi" required="required"   />
                                                    <div className="row p-t-20">
                                                        <InputStatus />
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Tipo de Cliente</label>
                                                                <select className="form-control" id="typeClient" name="typeClient" disabled ={false} 
                                                                        onChange = {(e) => this.handleTypeClient(e)}
                                                                        defaultValue = "1"
                                                                >
                                                                    <option value="1">B2B</option>
                                                                    <option value="2">B2C</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">Nombre Promoción </label>
                                                                <input type="text" className="form-control" placeholder="" name="name" id="crename" required="required" max="45" />
                                                                <label className="msglabel">Mínimo 4 caracteres</label>
                                                            </div>
                                                        </div>                                                   
                                                    </div>
                                                    <div className="row p-t-20">                                                              
                                                        <div className="col-md-12">
                                                            <label className=" control-label">Descripción</label>
                                                            <textarea name ="credescription" id="credescription" max="175" ></textarea>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Fecha Límite</label>
                                                                <input type="date" className="form-control" id="credeadLine" defaultValue = {toDay} max="6" />
                                                            </div>                                                                                        
                                                        </div>
                                                        <InputTypeBusiness auxName="idTypeBusiness" auxId="idTypeBusiness" mostrar="4" disabled={false} parentCallback = {this.vacio}/>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Promos Máximo a llevar</label>
                                                                <input type="number" className="form-control" id="credmaximumQuantity" value="1" min = {1}/>
                                                            </div>                                                                                        
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">% Descuento</label>
                                                                <input type="number" className="form-control" id="credporcentageOff" min = {0.10} step = {0.10} />
                                                            </div>                                                                                        
                                                        </div>
                                                        <InputListPricesPromotions auxName="idListPrices" auxId="idListPrices" doesNotApply="false" 
                                                                         parentCallback = {this.parentListPrices} 
                                                                         actualizarDatosListPrices = {this.handleActualizarDatosListPrices}
                                                        /> 
                                                        <div className="col-md-4 flagOnlyListPrice">
                                                            <div className="form-group">
                                                                <label className=" control-label">Mostrar solo los productos de esta lista</label>
                                                                <select name="onlyListPrices" className="form-control" id="updonlyListPrices" onChange={(e) => this.handleOnlyListPrices(e)}>
                                                                    <option value="0">Si</option>
                                                                    <option value="1">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">Unidad de Despacho</label>
                                                                <select
                                                                    name="officeUnit"
                                                                    className="form-control"
                                                                    id="credofficeUnit"
                                                                    defaultValue="0"
                                                                    onChange = {(e) => this.handleOfficeUnit(e)}
                                                                >   
                                                                    <option value="0">Todas</option>
                                                                    <option value="1">Unidades</option>
                                                                    <option value="2">UMD</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row p-t-20">                                                          
                                                        <div className="col-md-12">
                                                            <div className="form-group form-group-faqs ">
                                                                <div className="setProducts">
                                                                    <button className="buttomAddProduct" onClick={() => this.addFormFields()}>Agregar Producto</button>
                                                                    <div style={{marginBottom:10}}></div>
                                                                    {this.state.products.map((element, index) => (
                                                                        <div  className="groupProducts" key={index} > 
                                                                            <button className="lessProducts" onClick={() => this.removeFormFields(index)}>-</button>
                                                                            <InputProductoPromotions typeClient = {this.state.typeClient} idListPrices = {this.state.idListPrices}
                                                                                idCedi = {idCedi} typeOffert = {this.state.officeUnit} onlyListPrices = {this.state.onlyListPrices}
                                                                                nameListPrices = {this.state.nameListPrices} handleProducts = {this.handleProduct} 
                                                                                index = {index} products = {this.state.products}
                                                                            />
                                                                            <label ClassName = " control-label" style={{marginLeft:15, marginRight:15}} >Cantidad</label>
                                                                            <input /* ClassName = "form-control" */ type="text" name="Cantidad" value = { element.Cantidad || 0} min = {1} style = {{ max : 10 }}onChange = {(e) => this.handleChange(index, e)} />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>              
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-offset-2 col-sm-10">
                            <button type="button" className="btn btn-default btnadd" onClick={() => this.addRegister(idCedi)}>Ingresar Promoción</button>
                        </div>               
                    </React.Fragment>          
                </div>
            </div>
        )
    }
}

export default PromoEstrella;