import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import $ from 'jquery';
import Const from '../utils/defaultConstant'
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar')

let idCedi
class downloadTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items : []
        }
    }    

    componentDidMount() {          
        idCedi = sessionStorage.getItem("idCedi")
        const datos = {idCedi : idCedi, mostrar:1}
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "api/listpricescustom",{ // ok
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success)) {
                    let option = ''
                    let items = result.data                        
                    items.forEach( function(valor, indice, array) {
                        option +="<option value='"+valor['id']+"' nameList = '"+valor['name']+"'  >"+valor['name']+" </option>";                          
                    })              
                    document.getElementById("cedilist").innerHTML = option;
                    $(".conselectorcedi").show()
                    const indice = select.selectedIndex;
                    const opcionSeleccionada = select.options[indice];
                    $("#updNameList").val(opcionSeleccionada.text)    
                } else 
                    closeSession()
            },
        ).catch(error => alertaGeneral("Problemas de Conexión (102)"))

        $("#creidCedi").val(idCedi)
        
        const opcionCambiada = () => {
            const indice = select.selectedIndex;
            const opcionSeleccionada = select.options[indice];
            $("#updNameList").val(opcionSeleccionada.text)
        };

        const select = document.querySelector("#cedilist");
        select.addEventListener("change", opcionCambiada);
        
    }  

    render(){

        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCargarPrecios = accessUser.includes('34')
        let boolDescargarPlantilla = accessUser.includes('35') 
        let boolDescargarPedidos = accessUser.includes('36')
        
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-2 align-self-center">
                            <h3 className="text-primary">IO Excel</h3> 
                        </div>
                        { 
                            boolCargarPrecios
                        ? 
                            <label style = {{paddingLeft:20, fontSize:22}} id="ModUsuarioFdl"><a href="./uploadListPrice">Cargar Precios</a></label>
                        :
                            null
                        }
                        {
                            boolDescargarPlantilla
                        ?
                            <label style = {{paddingLeft:40, fontSize:22}} id="ModUsuarioFdc"><a href="./downloadTemplate">Descargar Formatos</a></label>
                        :
                            null
                        }
                        {
                            boolDescargarPedidos
                        ?
                            <label style = {{paddingLeft:40, fontSize:22}} id="ModUsuarioFdc"><a href="./orders">Descargar Pedidos</a></label>
                        :
                            null
                        }
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title"> </h4>   
                                        <form action = {Const.urlrest+"api/uploadlistprice/format"} method="POST" className="tcenter" id='formulario'>  
                                            <input type="hidden" className="form-control" placeholder="" name="nameList" id="updNameList" required="required"   /> 
                                            <input type="hidden" className="form-control" placeholder="" name="idCedi" id="creidCedi" required="required" /> 
                                            <div className="row p-t-20">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <h3>Descargar Formatos de Lista de Precios</h3>
                                                        <br></br>
                                                        <h5>Indique que formato desea descargar</h5>
                                                        <div className="conselectorcedi MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">                                       
                                                            <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">                                           
                                                                <select name="cedilist" id="cedilist" className="MuiInputBase-input MuiInput-input">
                                                                    <option value="" >Cedi</option>
                                                                </select>
                                                            </div>
                                                        </div>              
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-offset-2 col-sm-10">
                                                <button type="submit"  className="btn btn-default btnupdate">Descargar*</button>
                                            </div>  
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>          
                    </div>
                </div>
          </div>
        )
    }
}

export default downloadTemplate;