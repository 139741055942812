import React, {Component} from 'react'
import {alertaGeneral} from '../../assets/js/GeneralScript'

class updateData extends Component {

    render(){

        function validOnChange(filex) {
            let uploadFile = filex[0]
            let output = document.getElementById('imgshow');
            output.src = URL.createObjectURL(filex[0]);
            output.onload = function() {
                if ((output.naturalWidth.toFixed(0) < 500) || (output.naturalHeight.toFixed(0) < 250)) {
                    document.getElementById("imgshow").src = ""
                    sessionStorage.setItem("registerValid","false")
                    alertaGeneral("La Imagen seleccionada no cumple con la medida mínima promedio de 500 x 250 pixels")
                } else
                sessionStorage.setItem("registerValid","true")
                URL.revokeObjectURL(output.src) // free memory
            }
        }

        return  (
            <React.Fragment>                   
                <div className="container-fluid formupdate" id="formupdate">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h4>Actualizar </h4>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <div className="form-horizontal" id="formcreate" >
                                            <form id="formularioupdate">
                                                <input type="hidden" className="form-control" placeholder="" name="id" id="updid" required="required"   />
                                                <input type="hidden" className="form-control" placeholder="" name="idCedi" id="updcedi" required="required"   />
                                                <input type="hidden" className="form-control" placeholder=""  id="updOldImg" required="required" name="oldImg"  max="30" />
                                                <div className="row p-t-20">
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className=" control-label">Link </label>                                                                
                                                            <input type="text" className="form-control" placeholder="" name="link" id="updlink"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2.5">
                                                        <div className="form-group">
                                                            <label className=" control-label">límite de Publicación </label>                                                                
                                                            <input type="date" className="form-control" placeholder="" name="schedule" id="updschedule" required="required"  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className="control-label">Tipo de Negocio </label>                                                        
                                                                <select name="typeBusiness" className="form-control" id="updtypeBusiness">
                                                                    <option value="1">B2B</option>
                                                                    <option value="2">B2C</option>
                                                                </select>
                                                            </div>                                                            
                                                        </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className=" control-label">Visible en</label>
                                                            <select className="form-control" id="updapps"  name="persAsoAll">       
                                                                <option value="0" >Todas</option>   
                                                                <option value="1" >Marca Propia</option> 
                                                                <option value="2" >Asociativa</option>                                                                         
                                                            </select>                                                              
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Descripción</label>    
                                                            <textarea name="description" id="upddescription" className="form-control" max="250"></textarea> 
                                                        </div>
                                                    </div>  
                                                </div>
                                                <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Imagen &nbsp;</label> 
                                                            <img src="" alt="" id="imgshow" height="50" />         
                                                            <input type="file" accept="image/png" style = {{color: "transparent", border: "none"}} className="form-control" placeholder="" name="img" id="updimg" required="required" onChange ={(e) => validOnChange(e.target.files)} />
                                                        </div>
                                                    </div>

                                                <div className="form-group">
                                                    <div className="col-sm-offset-2 col-sm-10">
                                                        <button type="button" className="btn btn-default btnupdate">Actualizar Imágenes Publicitarias</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default updateData