import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './components/login/Login';
import Forgot from './components/login/Forgot';
import Dashboard from './components/dashboard/Dashboard';
import CuadroMando from './components/cuadromando/CuadroMando';
import Category from './components/category';
import CategoryList from './components/category/list';
import CategoryCargar from './components/category/load';
import TypeBusiness from './components/typeBusiness';
import TypeBusinessList from './components/typeBusiness/list';
import Orders from './components/orders'
import Routes from './components/routescedy'
import RoutesList from './components/routescedy/list'
import RoutesCargar from './components/routescedy/load'
import PricesCustomdetail from './components/pricescustomdetail'
import PricesCustomdetailList from './components/pricescustomdetail/list'
import PricesCustomdetailCargar from './components/pricescustomdetail/load'
import PromoEstrella from './components/promoestrella'
import PromoEstrellaList from './components/promoestrella/list'
import PricesCustom from './components/pricescustom'
import PricesCustomList from './components/pricescustom/list'
import PricesCustomCargar from './components/pricescustom/load'
import UploadExcel from './components/uploadexcel'
import Advertising from './components/advertising'
import AdvertisingList from './components/advertising/list'
import ProductsCreate from './components/productscedi'
import ProductsList from './components/productscedilist'
import ProductsCargar from './components/productscedi/load'
import Comercial from './components/comercial'
import ComercialList from './components/comercial/list'
import ComercialCargar from './components/comercial/load'
import CediconfigCEDI from './components/configcediCEDI'
import Cediconfiglookcedis from './components/configcedilookcedis'
import CediconfigMARKETPLACE from './components/configcediMARKETPLACE'
import Cediconfiglookmarketplace from './components/configcedilookmarketplace'
import CediconfigMARKETPLACE_b2c from './components/configcediMARKETPLACE_b2c'
import Cediconfiglookmarketplace_b2c from './components/configcedilookmarketplace_b2c'
import Establishments from './components/establishments/index'
import Establishmentslist from './components/establishments/list'
import Establishmentscargar from './components/establishments/load'
import Notifications from './components/notifications'
import user from './components/user/index'
import userlist from './components/user/list'
import uploadListPrice from './components/xlsx/uploadListPrice'
import downloadTemplate from './components/xlsx/downloadTemplate'

function App() {
  return (
    <div >
      <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/forgot" component={Forgot} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/cuadromando" component={CuadroMando} />
              <Route path="/category" component={Category} />  
              <Route path="/categorylist" component={CategoryList} /> 
              <Route path="/categorycargar" component={CategoryCargar} />  
              <Route path="/typeBusiness" component={TypeBusiness} />  
              <Route path="/typeBusinesslist" component={TypeBusinessList} />  
              <Route path="/orders" component={Orders} /> 
              <Route path="/routescedi" component={Routes} /> 
              <Route path="/routescedilist" component={RoutesList} /> 
              <Route path="/routescedicargar" component={RoutesCargar} /> 
              <Route path="/pricescustomdetail" component={PricesCustomdetail} /> 
              <Route path="/pricescustomdetaillist" component={PricesCustomdetailList} /> 
              <Route path="/pricescustomdetailcargar" component={PricesCustomdetailCargar} /> 
              <Route path="/promoestrella" component={PromoEstrella} />
              <Route path="/promoestrellalist" component={PromoEstrellaList} />
              <Route path="/pricescustom" component={PricesCustom} /> 
              <Route path="/pricescustomlist" component={PricesCustomList} /> 
              <Route path="/pricescustomcargar" component={PricesCustomCargar} /> 
              <Route path="/notifications" component={Notifications} /> 
              <Route path="/uploadexcel" component={UploadExcel} /> 
              <Route path="/advertising" component={Advertising} /> 
              <Route path="/advertisinglist" component={AdvertisingList} /> 
              <Route path="/productscreate" component={ProductsCreate} /> 
              <Route path="/productslist" component={ProductsList} />
              <Route path="/productscargar" component={ProductsCargar} /> 
              <Route path="/comercial" component={Comercial} /> 
              <Route path="/comerciallist" component={ComercialList} /> 
              <Route path="/comercialcargar" component={ComercialCargar} />
              <Route path="/cediconfigCEDI" component={CediconfigCEDI} />
              <Route path="/cediconfiglookcedis" component={Cediconfiglookcedis} />
              <Route path="/cediconfigMARKETPLACE" component={CediconfigMARKETPLACE} />
              <Route path="/cediconfiglookmarketplace" component={Cediconfiglookmarketplace} />
              <Route path="/cediconfigMARKETPLACE_b2c" component={CediconfigMARKETPLACE_b2c} />
              <Route path="/cediconfiglookmarketplace_b2c" component={Cediconfiglookmarketplace_b2c} />
              <Route path="/establishmentscargar" component={Establishmentscargar} />  
              <Route path="/establishmentslist" component={Establishmentslist} />  
              <Route path="/establishments" component={Establishments} />  
              <Route path="/orders" component={Orders} /> 
              <Route path="/usercreate" component={user} /> 
              <Route path="/userlist" component={userlist} />      
              <Route path="/uploadListPrice" component={uploadListPrice} />
              <Route path="/downloadTemplate" component={downloadTemplate} />      
            </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
