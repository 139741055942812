import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import CreateData from './createData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession,alertaGeneral,closeAlertGeneral,closeSession} from '../../assets/js/GeneralScript'
import {AppContextProvider} from '../../context/AppContext'

document.body.classList.add('fix-sidebar')

class ProductsCedi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            /* items: [], */
            idCedi: 0,
            idCategory: 0,
        };
    }

    
    componentDidMount() { 
        const idCedi = sessionStorage.getItem("idCedi")
        var el = document.getElementById('mggAlert') 
        if (el) {
           el.addEventListener("click", closeAlertGeneral);
        }
        
        $(document).on('click', '.btnadd', function () { 
            $('#updcedi').val(idCedi)               
            let name = $("#updname").val();
            let imagen = $("#creimg1").val()

            if( imagen !== "" && name.length >= 4
                ){
                const data = new FormData(document.getElementById('formulario'));
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "api/productscedi/add", { // ok
                    headers: { 
                        'Authorization': token,
                    },
                    method: "POST",
                    body: data
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro Ingresado")
                            window.location.reload(false)    
                        } else 
                            closeSession()
                    },
                ).catch(error => alertaGeneral("Problemas de conexión (77)"))
            } else {
                alertaGeneral("Validar la información ingresada");
            }
        });
    }
  
    render(){          
        
        let accessUser = JSON.parse(sessionStorage.getItem('access'))
        let boolCrear = accessUser.includes('16')
        let boolOperar = accessUser.includes('17') || accessUser.includes('18')
        let boolCargar = accessUser.includes('48')

        return (          
            <AppContextProvider value={ this.state }> 
                <Headerdashboard/>
                <Sidebar /> 
                <AlertGeneral /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-primary">Productos</h3> 
                        </div>
                        { 
                            boolCrear
                        ? 
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdl"><a href="./productscreate">Crear</a></label>
                        :
                            null
                        }
                        {
                            boolOperar 
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioFdc"><a href="./productslist">Datos</a></label>
                        :
                            null
                        }    
                        {
                            boolCargar
                        ?
                            <label style = {{paddingLeft:30, fontSize:22}} id="ModUsuarioCargar"><a href="./productscargar">Cargar</a></label>
                        :
                            null
                        }    
                    </div>
                    <CreateData />  
                </div>
            </AppContextProvider>
        )
    }
}

export default ProductsCedi;