import React, {Component} from 'react'

class roles  extends Component {        

    constructor(props) {
        super(props);
        this.state = {
            onOffNotificaciones : true,
            onOffImgPublicidad : true,
            onOffLook : true,
            onOffListPrices : true,
        }
    }

    componentDidMount() {
        let a = sessionStorage.getItem("onOffNotificaciones") 
        if (a == 1)
            this.setState({ onOffNotificaciones : false})
        let b = sessionStorage.getItem("onOffImgPublicidad") 
        if (b == 1)
            this.setState({ onOffImgPublicidad : false})
        let c = sessionStorage.getItem("onOffLook") 
        if (c == 1)
            this.setState({ onOffLook : false})
        let d = sessionStorage.getItem("onOffListPrices") 
        if (d == 1)
            this.setState({ onOffListPrices : false})
    
    

    }

    render(){

        return  (
            <React.Fragment>         
                <div className="col-md-6">
                    <div className="form-group diasruta"> 
                        <label className="control-label">Comerciales</label>  
                        <label><input type="checkbox" value="1"  className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox" value="2"  className="form-control checkmodulo" />Editar</label>    
                        <label><input type="checkbox" value="3"  className="form-control checkmodulo" />Eliminar</label>
                        <label><input type="checkbox" value="47" className="form-control checkmodulo" />Cargar</label>
                        <label className=" control-label">Productos</label>  
                        <div className="clear"></div>     
                        <label><input type="checkbox" value="16"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="17"  className="form-control checkmodulo" />Editar</label>    
                        <label><input type="checkbox"  value="18"  className="form-control checkmodulo" />Eliminar</label>
                        <label><input type="checkbox"  value="48"  className="form-control checkmodulo" />Cargar</label>
                        <label className=" control-label">Rutas de Entrega</label>  
                        <div className="clear"></div>
                        <label><input type="checkbox" value="4"  className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox" value="5"  className="form-control checkmodulo" />Editar</label>    
                        <label><input type="checkbox" value="6"  className="form-control checkmodulo" />Eliminar</label>
                        <label><input type="checkbox" value="49" className="form-control checkmodulo" />Cargar</label>
                        <label className=" control-label">Clientes</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="25"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="26"  className="form-control checkmodulo" />Editar</label>    
                        <label><input type="checkbox"  value="27"  className="form-control checkmodulo" />Eliminar</label>
                        <label><input type="checkbox"  value="52"  className="form-control checkmodulo" />Cargar</label>
                        <label className=" control-label">Promociones</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="43"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="44"  className="form-control checkmodulo" />Editar</label> {/* . */}
                        <label><input type="checkbox"  value="45"  className="form-control checkmodulo" />Eliminar</label>                           
                        <label className=" control-label">Usuarios</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="28"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="29"  className="form-control checkmodulo" />Editar</label>    
                        <label><input type="checkbox"  value="30"  className="form-control checkmodulo" /> Eliminar</label>                           
                        <div className="clear separador"></div> 
                        <label className="control-label">Configuración App MB B2C</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="41"  className="form-control checkmodulo" disabled = {this.state.onOffLook} />Datos</label>
                        <label><input type="checkbox" value="42"  className="form-control checkmodulo" disabled = {this.state.onOffLook} />Look And Feel</label>
                        <label className="control-label">Configuración Cedi</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="37"  className="form-control checkmodulo" />Datos Cedi</label>
                        <label><input type="checkbox" value="38"  className="form-control checkmodulo" disabled = {this.state.onOffLook} />Look Cedi</label>
                        <div className="clear separador"></div> 
                        <label className=" control-label">Cuadro de Mando</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="46"  className="form-control checkmodulo" />Publicar</label>                           
                        <div className="clear separador"></div> 
                    </div>                                                               
                </div> 
                <div className="col-md-6">
                    <div className="form-group diasruta"> 
                        <label className=" control-label">Categorías</label>  
                        <label><input type="checkbox" value="7"  className="form-control checkmodulo" />Crear </label>    {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                        <label><input type="checkbox"  value="8" className="form-control checkmodulo" />Editar  </label>                                
                        <label><input type="checkbox"  value="9" className="form-control checkmodulo" />Eliminar </label>
                        <label><input type="checkbox"  value="53" className="form-control checkmodulo" />Cargar </label>
                        <label className=" control-label">Lista de Precios</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="19"   className="form-control checkmodulo" disabled = {this.state.onOffListPrices} />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="20"  className="form-control checkmodulo" disabled = {this.state.onOffListPrices} />Editar</label>    
                        <label><input type="checkbox"  value="21"  className="form-control checkmodulo" disabled = {this.state.onOffListPrices} />Eliminar</label>
                        <label><input type="checkbox"  value="50"  className="form-control checkmodulo" disabled = {this.state.onOffListPrices} />Cargar</label>
                        <label className=" control-label">Detalle Lista de Precios</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="22"   className="form-control checkmodulo"  disabled = {this.state.onOffListPrices} />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="23"  className="form-control checkmodulo"  disabled = {this.state.onOffListPrices} />Editar</label>    
                        <label><input type="checkbox"  value="24"  className="form-control checkmodulo"  disabled = {this.state.onOffListPrices} />Eliminar</label>
                        <label><input type="checkbox"  value="51"  className="form-control checkmodulo"  disabled = {this.state.onOffListPrices} />Cargar</label>                                                                                                                                                                  
                        <label className=" control-label" >Imágenes Publicitarias</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="13"   className="form-control checkmodulo" disabled = {this.state.onOffImgPublicidad}/>Crear</label>
                        <label><input type="checkbox"  value="14"  className="form-control checkmodulo" disabled = {this.state.onOffImgPublicidad}/>Editar</label>    
                        <label><input type="checkbox"  value="15"  className="form-control checkmodulo" disabled = {this.state.onOffImgPublicidad}/> Eliminar</label>
                        <label className="control-label">IO Excel</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="34"   className="form-control checkmodulo" />C.Precios</label>            
                        <label><input type="checkbox" value="35"   className="form-control checkmodulo" />D.Plantillas</label>    
                        <label><input type="checkbox" value="36"   className="form-control checkmodulo" />D.Pedidos</label>
                        <label className=" control-label">Tipo de Clientes</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="10"   className="form-control checkmodulo" />&nbsp; &nbsp; Crear &nbsp; &nbsp; &nbsp; </label>                                                                                                                                                    
                        <label><input type="checkbox"  value="11"  className="form-control checkmodulo" />&nbsp; &nbsp; Editar &nbsp; &nbsp; &nbsp; </label>    
                        <label><input type="checkbox"  value="12"  className="form-control checkmodulo" />&nbsp; &nbsp; Eliminar &nbsp; &nbsp; &nbsp; </label>
                        <label className="control-label">Configuración App MB B2B</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="39"  className="form-control checkmodulo" disabled = {this.state.onOffLook} />Datos</label>
                        <label><input type="checkbox" value="40"  className="form-control checkmodulo" disabled = {this.state.onOffLook} />Look And Feel</label> 
                        <label className="control-label">Notificaciones</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="31"  className="form-control checkmodulo" disabled = {this.state.onOffNotificaciones} />Publicar</label> 
                        <div className="clear separador"></div> 
                        {/* <div className="clear separador"></div> */} 
                        <label className=" control-label">App Diseño</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="41"  className="form-control checkmodulo" disabled = {this.state.onOffLook} />App Diseño</label>                           
                        <div className="clear separador"></div> 
                    </div>                                                               
                </div>                                                        
            </React.Fragment>          
        )
    }
}

export default roles