import React, {Component} from 'react';
import '../../assets/css/Admin.css';
import Headerdashboard from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import Const from '../utils/defaultConstant';
import AlertGeneral from '../atoms/AlertGeneral';
import {endSession,alertaGeneral,closeSession} from '../../assets/js/GeneralScript';
import Chart from 'chart.js';
import jsPDF from 'jspdf';

document.body.classList.add('fix-sidebar');

class notificationPush extends Component {
  nuevaFecha = ''
  constructor(props) {
    super(props)
    const date = new Date()
    this.state = {
      BarMyChart: '',
      VarMyChart: '',
      PieMyChart: '',
      pieMyChart: '',
      data: [],
      defaultValue: date.toLocaleDateString('en-CA'),
    }
    this.nuevaFecha = date.toLocaleDateString('en-CA')

  }

  getDataColors = opacity => {
    const colors = ['#7448c2', '#21c0d7', '#d99e2b', '#cd3a81', '#9c99cc', '#e14eca', '#97EA18', '#ff0000', '#d6ff00', '#0038ff']
    return colors.map(color => opacity ? `${color + opacity}` : color)
  }

  preparar_canvas(varCtx, name_canvas, comerciales, ventas, titulo,tipoGrafico) {

    const valores = {
      type: tipoGrafico,
      data: {
          labels: comerciales,
          datasets: [{
              label: "",
              data: ventas,
              backgroundColor: this.getDataColors(),
              borderColor: this.getDataColors(30),
              borderWidth: 1
          }]
      },
      options: {
          legend: { display: false },
          title: {
            display: true,
            text: titulo
          },
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          },
      }
    }
    switch (name_canvas) { 
        case 'BarMyChart': { 
            this.setState({ BarMyChart: new Chart(varCtx, valores )})
            break
        }
        case 'VarMyChart': { 
            this.setState({ VarMyChart: new Chart(varCtx, valores )})
            break 
        }
        case 'PieMyChart': { 
            this.setState({ PieMyChart: new Chart(varCtx, valores )})
            break 
        }
        case 'pieMyChart': { 
            this.setState({ pieMyChart: new Chart(varCtx, valores )})
            break 
        }
    }
  }
  
  barChart(data) {
    const datos = data.data

    let comerciales = []
    let marketplaceLabel = []
    let tmpMarketplaceLabel = []
    datos.forEach( elemento => {
      if (!comerciales.includes(elemento['b2bUserComercialCedi.name'])) {
        comerciales.push(elemento['b2bUserComercialCedi.name']);
      }
      if (!tmpMarketplaceLabel.includes(elemento.origenPedido)) {
        tmpMarketplaceLabel.push(elemento.origenPedido)
        switch (elemento.origenPedido) {
          case 1 : {
              marketplaceLabel.push("ASO B2B")
              break
          }
          case 2 : {
            marketplaceLabel.push("MB B2B")
            break
          }
          case 3 : {
            marketplaceLabel.push("TAT")
            break
          }
          case 4 : {
            marketplaceLabel.push("ASO B2C")
            break
          }
          case 5 : {
            marketplaceLabel.push("MB B2C")
            break
          }
        }
      }
    })

    let ventas = []
    let operaciones = []
    let total_ventas = 0
    let total_operaciones = 0
    let contador_ventas = 0
    let contador_operaciones = 0

    comerciales.forEach( (elemento) => {
        contador_ventas = 0
        contador_operaciones = 0
        datos.forEach( (caso) => {
          if (caso['b2bUserComercialCedi.name'] == elemento) {
              contador_ventas = contador_ventas + caso.subtotal
              contador_operaciones = contador_operaciones + 1
          }
        })
        total_ventas = total_ventas + (contador_ventas/1000)
        total_operaciones = total_operaciones + contador_operaciones
        ventas.push(contador_ventas/1000)
        operaciones.push(contador_operaciones)
    })

    let marketplaceVentas = []
    let marketplaceOperaciones = []
    let contador_marketplaceVentas = 0
    let contador_marketplaceOperaciones = 0
    let total_ventas_marketplaces = 0
    let total_operaciones_marketplaces = 0

    tmpMarketplaceLabel.forEach( (elemento) => {
      contador_marketplaceVentas = 0
      contador_marketplaceOperaciones = 0
      datos.forEach( (caso) => {
        if (caso.origenPedido == elemento) {
            contador_marketplaceVentas = contador_marketplaceVentas + caso.subtotal
            contador_marketplaceOperaciones = contador_marketplaceOperaciones + 1
        }
      })
      total_ventas_marketplaces = total_ventas_marketplaces + (contador_marketplaceVentas/1000)
      total_operaciones_marketplaces = total_operaciones_marketplaces + contador_marketplaceOperaciones

      marketplaceVentas.push(contador_marketplaceVentas/1000)
      marketplaceOperaciones.push(contador_marketplaceOperaciones)
    })

    var barmyChart = document.getElementById('Bar-myChart').getContext('2d');
    this.preparar_canvas(barmyChart, 'BarMyChart',comerciales, ventas, 'Total Ventas en Miles Equipo Comercial: $ '+total_ventas.toFixed(1),'horizontalBar')

    var varmyChart = document.getElementById('Var-myChart').getContext('2d');
    this.preparar_canvas(varmyChart, 'VarMyChart',comerciales, operaciones, 'Total Operaciones Equipo Comercial: '+total_operaciones,'horizontalBar')

    var PiemyChart = document.getElementById('Pie-myChart').getContext('2d');
    this.preparar_canvas(PiemyChart,'PieMyChart',marketplaceLabel, marketplaceVentas, 'Total Ventas en Miles por Marketplace $ '+total_ventas_marketplaces.toFixed(1),'bar')

    var piemyChart = document.getElementById('pie-myChart').getContext('2d');
    this.preparar_canvas(piemyChart, 'pieMyChart',marketplaceLabel, marketplaceOperaciones, 'Total Operaciones por Marketplace : '+total_operaciones_marketplaces,'bar')
  }

  cargarData() {
    let idCedi = sessionStorage.getItem("idCedi")
    let token = sessionStorage.getItem('token')
    fetch(Const.urlrest + "api/orders/cuadromando", { // ok
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      method: "POST",
      body: JSON.stringify({
          idCedi:idCedi,
          defaultValueYear: this.nuevaFecha.substring(0,4),
          defaultValueMonth: this.nuevaFecha.substring(5,7),
          defaultValueDay: this.nuevaFecha.substring(8,10)
      })
    })
    .then(response => response.json())
    .then(data => {
        if (endSession(data.success)) {
            this.setState({ data : data })
            this.barChart(this.state.data)            
        } else 
            closeSession()
    }).catch(error => alertaGeneral("Problemas de conexión (52)"))
  }

  componentDidMount() { 
    this.cargarData()
  }    

  render(){     
    
    const handlerPDF = (e) => {
      const fecha = document.getElementById("updschedule").value
      const nameCedi = sessionStorage.getItem('nameCedi')
      const canvasVentas = document.getElementById("Bar-myChart")
      const canvasImageVentas = canvasVentas.toDataURL('image/jpeg', 1.0)
      const canvasOperaciones = document.getElementById("Var-myChart")
      const canvasImageOperaciones = canvasOperaciones.toDataURL('image/jpeg', 1.0)

      const canvasMarketplaceVentas = document.getElementById("Pie-myChart")
      const canvasImageMarketplaceVentas = canvasMarketplaceVentas.toDataURL('image/jpeg', 1.0)
      const canvasMarketplaceOperaciones = document.getElementById("pie-myChart")
      const canvasImageMarketplaceOperaciones = canvasMarketplaceOperaciones.toDataURL('image/jpeg', 1.0)

      let pdf = new jsPDF()
      pdf.setFontSize(16)
      pdf.text(fecha + " Informe Diario : "+nameCedi, 5, 10)
      pdf.addImage(canvasImageVentas, 'JPEG',15,15,180,70) // 'JPEG',15,15,180,70)
      pdf.addImage(canvasImageOperaciones, 'JPEG',15,90,180,70) // 'JPEG',15,90,180,70)
      pdf.addImage(canvasImageMarketplaceVentas, 'JPEG',15,165,180,55) // 'JPEG',15,165,180,55)
      pdf.addImage(canvasImageMarketplaceOperaciones, 'JPEG',15,225,180,55) // 'JPEG',15,225,180,55)
      pdf.text("ClanApps SAS", 80,290)
      pdf.save("Report.pdf")
    }

    const handler = (e) => {
      this.nuevaFecha = e.target.value
      this.state.BarMyChart.destroy()
      this.state.VarMyChart.destroy()
      this.state.PieMyChart.destroy()
      this.state.pieMyChart.destroy()
      this.cargarData()
    }

      return (          
            <div>
                <Headerdashboard/>
                <Sidebar /> 
                <AlertGeneral /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div style={{ display: "flex" }}/* className="col-md-12 align-self-center" */>
                            <h3 className="text-primary">Cuadro de Mando</h3> 
                            <label style={{ marginLeft:15, marginTop:10, fontSize:16, justifyContent:"center" }}/* className=" control-label" */> Fecha </label>                                                                
                            <input class="row align-items-center" style={{ marginLeft:7, fontSize:16, maxWidth:145 }} type="date" /* className="form-control" */ placeholder="" name="schedule" id="updschedule" required="required" defaultValue = {this.nuevaFecha} onChange={e=>handler(e)} />
                            <button type="button" style={{ marginLeft:55 }} className="btn btn-default btnupdate" onClick = {e=>handlerPDF(e)}>Descargar PDF</button>
                        </div>
                    </div>    
                    <div style={{ display: "flex" }}>
                      <div style= {{ width:600, height:320 }}>
                        <canvas id="Bar-myChart" ></canvas>
                      </div>
                      <div style= {{ width:600, height:320 }}>
                        <canvas id="Var-myChart" ></canvas>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style= {{ width:600, height:300 }}>
                        <canvas id="Pie-myChart" ></canvas>
                      </div>
                      <div style= {{ width:600, height:30 }}>
                        <canvas id="pie-myChart" ></canvas>
                      </div>
                    </div>
                </div>
            </div>
      )
  }
}

export default notificationPush;
